@use "sass:map";

@import "@bootstrap/scss/buttons";

// ===============================================================
// Voices.com Buttons
// ===============================================================
@import '../variables/buttons-variables';
@import '../mixins/buttons-mixins';
@import '../utilities/shadows';
@import '../utilities/text';

// TODO : Replace any instances where .btn elements get the class .disabled applied, and actually toggle the [disabled] state
// TODO : Investigate .ghost-btn-blue and see if we can remove this class from the codebase... I am wondering if this is just .btn-outline-primary class?
// 15.a - Basic button definition  *********************************************
.btn {
	// Generate Base Button Styles
	@include btn-styles();

	a, a:hover {
		text-decoration: none !important;
	}

	// 15.b - Button modifiers  *********************************************
	/* additional style variants added to basic .btn element */
	&.btn-bold {
		@extend .font-bold;
	}

	&.btn-uppercase {
		text-transform: uppercase;
	}

	&.btn-lowercase {
		text-transform: lowercase;
	}

	&.btn-capcase {
		text-transform: capitalize;
	}

	&.btn-block {
		display: block;
		width: 100%;
	}

	// Padding for icons in buttons.
	> i.fa, i.fal, i.fas, i.fab {
		padding-right: .65rem;
	}

	// TODO - Remove these super specific media queries... These specific styles should be handled at a component level
	@media (max-width: 383px) {
		&.btn-tn-block {
			white-space: normal;
			display: block;
			width: 100%;
		}
	}

	// TODO - Remove these super specific media queries... These specific styles should be handled at a component level
	@media (max-width: 480px) {
		&.btn-xxs-block {
			white-space: normal;
			display: block;
			width: 100%;
		}
	}

	// TODO - Remove these super specific media queries... These specific styles should be handled at a component level
	@media (max-width: 768px) {
		&.btn-xs-block {
			white-space: normal;
			display: block;
			width: 100%;
		}
	}

	/**
	 * Button Sizes
	 *
	 * Iterate through all button sizes and render out classes to the CSS by using our custom @include automate-btn-size-styles() mixin
	 * Expected Result: .btn-xs, .btn-sm, .btn-md, .btn-lg, .btn-xl
	 *
	 */
	@include automate-btn-size-styles();

	/**
	 * Button Colours
	 *
	 * Iterate through all button colours and render out classes to the CSS by using our custom @include automate-btn-color-styles() mixin
	 * Expected Result: .btn-default, .btn-primary, .btn-success, .btn-info, .btn-warning, .btn-danger, .btn-white
	 *
	 */
	@include automate-btn-color-styles();

	// Square buttons
	&.btn-square {
		border-radius: 0;
	}

	// Lozenge buttons
	&.btn-circle {
		border-radius: unquote("1em/50%");
		padding: .5em 2em;
	}

	&.btn-circular {
		border-radius: 3rem;
		padding-top: 1.4rem;
		padding-bottom: 1.4rem;

		&-sm {
			padding-top: $spacing-smaller;
			padding-bottom: $spacing-smaller;
		}
	}

	.pagination-line {
		border: 1px solid red;
	}

	&.btn-pagination {
		&:hover,
		&:focus,
		&:active {
			background-color: $grey4;
		}

		i {
			color: $grey1;
		}

		&[disabled] {
			i {
				color: $grey4;
			}
		}

		&.btn-pagination-prev {
			border-top-left-radius: 1.8rem;
			border-bottom-left-radius: 1.8rem;
			border-top-right-radius: 0;
			border-bottom-right-radius: 0;
			margin-right: -5px;
		}

		&.btn-pagination-next {
			border-top-left-radius: 0;
			border-bottom-left-radius: 0;
			border-top-right-radius: 1.8rem;
			border-bottom-right-radius: 1.8rem;
		}
	}
}

.downmenu,
.headmenu {
	display: inline-block;
}

.downmenu {
	position: relative;
	width: 100%;

	@media (min-width: $screen-sm) {
		min-width: 150px;
		width: auto;
	}

	.headbtn,
	.headallbtn {
		white-space: nowrap;
		margin-right: 0;

		i {
			display: inline-block !important;
			margin-right: 0;
			margin-left: $spacing-smaller;
		}
	}

	.headbtn {
		width: 100%;

		@media (min-width: $screen-sm) {
			min-width: 150px;
			width: auto;
		}
	}

	.dropbtn {
		font-size: 16px;
		border: none;
		outline: none;
		color: $white;
		padding: 14px 16px;
		background-color: inherit;
		font-family: inherit;
		margin: 0;
	}

	.dropdown-content {
		@include shadow-1;
		display: none;
		position: absolute;
		background-color: #fff;
		min-width: 100%;
		z-index: 5;
		margin-top: 2px;
		border-bottom-left-radius: 4px;
		border-bottom-right-radius: 4px;
	}


	.menuitem {
		float: none;
		padding: $spacing-smaller;
		background-color: #fff;
		text-decoration: none;
		display: block;
		text-align: center;
		border: none;
		color: $grey1 !important;
		width: 100%;

		@media (min-width: $screen-sm) {
			text-align: left;
		}

		&:first-child {
			padding-bottom: 0.5rem;
		}

		&:last-child {
			border-bottom-left-radius: 4px;
			border-bottom-right-radius: 4px;
			padding-bottom: 0.5rem;
		}

		&:hover {
			color: $grey1 !important;
			background-color: $grey5;
		}
	}
}

.headbtn:focus + .dropdown-content, .headallbtn:focus + .dropdown-content, .dropdown-content:hover {
	display: flex;
	flex-direction: column;
}

.pagination-container {
	position: relative;

	.pagination-line {
		display: inline-block;
		position: absolute;
		right: 50%;
		padding-top: 2px;
		padding-bottom: 2px;
		width: 1px;
		height: 22px;
		box-sizing: border-box;
		top: 7px;

		background-color: $grey4;
	}
}

// 15.g - Special Case Buttons
// Overwrite Bootstrap disabled styles
a.btn.disabled {
	pointer-events: all; //reset the 'none' Bootstrap setting, which unintentionally will remove the "cursor:not-allowed" property
}

// Job Board (Panel) Funds release button.
// TODO : Move to a more logical (component based) .scss file ... these are clearly not core button styles and should not be defined here.
.btn.btn-job-funds-release {
	@media screen and (max-width: $screen-xs - 1px) {
		display: inline-block;
		position: relative;
		margin-top: 0;
		@include text-sm;
		float: right;
	}
	@media screen and (min-width: $screen-xs) {
		margin-top: 0.5em;
		@include text-sm;
	}
	@media screen and (min-width: $screen-md) {
		margin-top: 0;
	}
}

// Right-justified buttons
.btn-r, a.btn-r {
	margin-right: 0;
	margin-left: .6rem;
	float: right;
}

/* Loading Spinner must not have padding */
.btn i.fas.fa-circle-notch {
	padding-right: 0;
	margin: 0 !important;
}

// TODO - Find a better way of handling next/previous button arrow styles... this seems super hacky
.btn.btn-circle[aria-describedby="prev-job"],
.btn.btn-circle[aria-describedby="next-job"] {
	padding: 0;
	width: 35px;
	height: 35px;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	border-radius: 100%;

	i {
		padding-right: 0;
	}
}

// 15.h - Button groups
.btn-group {
	display: block;
	@include clearfix;

	&[data-toggle="buttons"] .btn {
		min-width: 10rem;
	}

	&.open {
		.dropdown-toggle {
			box-shadow: none;
			-webkit-box-shadow: none;
		}
	}

	.btn {
		margin-right: 0;

		&.active {
			background-color: map.get($button-colors-map, button-primary-solid-bg-color-normal);
			color: map.get($button-colors-map, button-primary-solid-font-color-normal); ;
			border-color: transparent;
			box-shadow: none;
			outline: none;
			cursor: default;
		}

		&.btn-outline ~ .btn-outline,
		&[class*="btn-outline-"] ~ [class*="btn-outline-"] {
			border-left: none;
		}
	}
}

// Reset Styles if .btns are inheriting labels
.form-group label.btn,
.form-group .form-label.btn {
	font-weight: $font-weight-medium;
	font-size: map.get($button-sizes-map, "button-font-size-md");
	margin-bottom: 0;
}

// 15.i - Rules for buttons on a dark background  *****************************
@include automate-btn-color-adjustments-foreach-bg-styles($button-color-types);

.cursor-pointer {
	cursor: pointer;
}

.fa-circle-notch {
	padding: 0 !important;
	margin: 0 !important;
}

.btn-link {
	color: $blue0;
}

button.close {
	position: absolute;
	top: 0;
	padding: 0;
	cursor: pointer;
	background: transparent;
	border: 0;
	font-size: 24px;
}


.lazyloader-btn {
	background-color: $white;
	color: $blue1;
	width: 100%;
	outline: none;
	border: none;
	border-radius: 0 0 8px 8px;
	padding: 1.2rem;
	font-weight: bold;
	font-size: 1.6rem;
	transition: color 0.3s ease;

	&:hover,
	&:focus {
		color: $blue0;
	}

	&.non-interactive {
		color: $grey3;
		cursor: default;
	}
}