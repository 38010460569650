/**
 * SUBNAV
*/

/* Desktop Menu */
.sub-navigation {
	display: none;
	@media (max-width: ($screen-sm - 1px)) {
		&.desktop-view-only {
			display: block;
		}
	}

	@media (min-width: $screen-sm) {
		display: block;
	}

	.badge,
	.number-in-circle {
		display: inline-block;
		background-color: $grey1;
		color: $white;
		font-size: 12px;
		line-height: 1.5;
		vertical-align: text-bottom;
		padding: 1px 8px;
		border-radius: 8px;
		min-width: 10px;
		font-weight: 400;
	}
	> ul {
		padding: 0;
		border-bottom: 1px $grey4 solid;
		margin-bottom: 2.4rem;
		> li {
			display: inline-block;
			position: relative;
			&:hover > a {
				color: $text-dark;
				&::before {
					opacity: 1;
				}
			}
			&:hover > a::before {
				border-top-color: $grey4;
			}
			&.active > a {
				color: $text-dark;
			}
			&.active .job-tab {
				font-weight: $font-weight-medium;
			}
			&.active > a::before {
				border-top-color: $blue1;
				opacity: 1;
			}
            ~ li {
                margin-left: 12px;

				@media (min-width: $screen-sm) {
					margin-left: 20px;
				}
            }
			> a {
				position: relative;
				font-size: 16px;
				display: inline-block;
				padding-top: $header-spacing;
				padding-bottom: $header-spacing;
				padding-left: 0;
				padding-right: 0;
				line-height: 20px;
				color: $grey1;
				box-sizing: border-box;
				text-decoration: none;
				&::before {
					position: absolute;
					z-index: 2;
					content: ' ';
					bottom: 0;
					left: 0;
					border-top-width: 4px;
					border-top-style: solid;
					border-top-color: transparent;
					border-top-left-radius: 4px;
					border-top-right-radius: 4px;
					width: 100%;
					opacity: 0;
					transition: opacity 0.1s linear;
				}
				&:hover,
				:focus {
					background-color: transparent;
				}
			}
		}
	}
}

/* Mobile Menu */
.sub-navigation-mobile {
	@media (min-width:$screen-sm) {
		display: none;
	}
	> ul {
		margin-top: 1.8rem;
		> li {
			display: block;
			position: relative;
			width: 100vw;
			margin: 0 -15px !important;
			box-shadow: 0 5px 8px -2px rgba(0, 0, 0, 0.1), 0 3px 5px -2px rgba(25, 34, 43, 0.1);

			&:last-child {
				margin-bottom: 0; 
			}

			button {
				border-radius: 0;

				&:after {
					display: none;
				}
			}

			> .dropdown-toggle {
				position: relative;
				width: 100%;
				padding-left: 15px;
				padding-bottom: 12px;
				text-align: left;
				font-weight: 400;
				color: $grey1;
				border: none;

				.dropdown-arrow {
					position: absolute;
					z-index: 2;
					top: 2px;
					right: 15px;
					font-size: 16px;
					transition: transform 0.3s;
					&::before {
						@extend .far;
						content: $fa-var-chevron-down;
						width: 100%;
						transition: all 0.3s;
					}
					+ a {
						transition: none;
					}
				}
			}
			.dropdown-hr {
				padding: 0 15px;
				width: 100%;
				> hr {
					border-top: 1px solid $white;
					margin: 0;
				}
			}
			&.open {
				box-shadow: none;
				> .dropdown-menu {
					background-color: $white;
					box-shadow: 0 5px 8px -2px rgba(0, 0, 0, 0.1),
					0 3px 5px -2px rgba(25, 34, 43, 0.1);
				}
				> .dropdown-toggle {
					.dropdown-arrow {
						&::before {
							transform: rotate(180deg);
						}
					}
				}
				.dropdown-hr {
					> hr {
						border-top: 1px solid $grey4;
					}
				}
			}

			> .dropdown-menu {
				position: absolute;
				width: 100%;
				padding: 8px 12px;
				float: none;
				box-shadow: none;
				border: none;
				> li {
					padding: 0;
					color: $grey1;
					background: $white;
					a {
						display: block;
						padding: $spacing-smaller $spacing-small;
						color: $grey1;
						border-radius: 4px;
						&:hover {
							color: $grey1 !important; //overrride Bootstrap !important
							text-decoration: none;
							background-color: $grey5;
						}
						&:focus {
							color: $white !important;
							outline: none;
							background-color: $blue2;
						}
						&:active {
							color: $white !important;
							outline: none;
							background-color: $blue2;
						}
					}
					.active {
						padding: 8px 12px;
						margin-bottom: 0;
						color: $text-dark;
						border-radius: 4px;
						font-weight: 500;
						text-decoration: none;
						background-color: $grey5;
						cursor: pointer;
					}
				}
			}
			.number-in-circle {
				display: inline-block;
				background-color: $grey1;
				color: $white;
				font-size: 12px;
				line-height: 1.5;
				vertical-align: text-bottom;
				padding: 1px 8px;
				border-radius: 8px;
				min-width: 10px;
				font-weight: 400;
			}
		}
	}
}

.title-tab-nav-wrapper {
	background-color: $white;

	@media  (min-width: $screen-sm) {
		background-color: transparent;
	}
}
