//To be used in Design System to create standardized sizes for icons on the platform.
//Current primary use case is for Font-Awesome icons. Use classes on the <i> tag rendering the icon.
.icon-size-sm {
	width: 1.6rem;
	height: 1.6rem;
	padding: 0.2rem;
	font-size: 1.2rem;
	text-align: center;
}

.icon-size-md {
	width: 2.0rem;
	height: 2.0rem;
	padding: 0.2rem;
	font-size: 1.6rem;
	text-align: center;
}

.icon-size-lg {
	width: 3.2rem;
	height: 3.2rem;
	padding: 0.4rem;
	font-size: 2.4rem;
	text-align: center;
}

.icon-size-36 {
	width: 3.6rem;
	height: 3.6rem;
	padding: 0rem !important;
	font-size: 3.6rem;
}

// This is custom icon sizing to fix public feedbacks page sizing temporarily.
// When the public feedback and demo page are redesigned (current date: June 11th) this should be removed.
i.icon-size-public-pages {
	width: 2em !important;
	height: 2em !important;
}