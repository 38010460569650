// Parent Container, including Content + Button
.readmore-container {
  &.readmore-container-wrapped {
    flex-wrap: wrap;
    .readmore-content {
      width: 100%;
    }
  }

  // Collapsed
  &[aria-expanded="false"] {
    display: flex;
    .content-area {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
  }

  // Opened
  &[aria-expanded="true"] {
    .readmore-content {
      display: inline-block;
      &[data-max-rows] {
        max-height: none;
      }
    }
  }

  // Inline Container Variant Styles
  &.readmore-container-inline {
    // Opened
    &[aria-expanded="true"] {
      .content-area,
      .readmore-btn {
        display: inline;
      }
    }

    .content-area {
      display: inline-block;
    }

    .readmore-btn {
      display: inline-block;
      margin-left: .8rem;
    }
  }
}

// Hidden Content (is displayed when "Read More" button is clicked)
.readmore-content {
  display: none;
  &[data-max-rows] {
    display: inline-block;
    overflow: hidden;
  }
  &[data-max-rows="1"] {
    max-height: 20px;
  }
  &[data-max-rows="2"] {
    max-height: 45px;
  }
  &[data-max-rows="3"] {
    max-height: 70px;
  }
  &[data-max-rows="4"] {
    max-height: 95px;
  }
  &[data-max-rows="5"] {
    max-height: 120px;
  }
  &[data-max-rows="6"] {
    max-height: 145px;
  }
  &[data-max-rows="7"] {
    max-height: 170px;
  }
  &[data-max-rows="8"] {
    max-height: 195px;
  }
  &[data-max-rows="15"] {
    max-height: 385px;
  }
  &[data-max-rows="16"] {
    max-height: 485px;
  }
  
}

// "Read More" button
.readmore-btn {
  font-weight: bold;
  text-align: left;
}