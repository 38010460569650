$toggle-input-width: 60px;
$toggle-input-height: 34px;
$toggle-input-padding: 4px;
$toggle-input-circle-size: 26px;

.toggle-input {

	input {
		display: none;
		&:checked + label {
			background-color: $blue1;
			&::before {
				transform: translateX(26px);
			}
			.toggle-input-on-text { opacity: 1; }
			.toggle-input-off-text { opacity: 0; }
		}
		&:focus + label {
			box-shadow: 0 0 1px #2196F3;
		}
	}

	label {
		position: relative;
		cursor: pointer;
		width: $toggle-input-width;
		height: $toggle-input-height;
		display: inline-block;
		margin-bottom: 0;
		background-color: #ccc;
		border-radius: $toggle-input-height;
		transition: background-color 0.4s ease, box-shadow 0.4s ease;
		user-select: none;
		
		&::before {
			position: absolute;
			content: "";
			height: $toggle-input-circle-size;
			width: $toggle-input-circle-size;
			left: $toggle-input-padding;
			bottom: $toggle-input-padding;
			background-color: $white;
			border-radius: 100%;
			transition: transform 0.4s ease;
		}

		.toggle-input-on-text,
		.toggle-input-off-text {
			position: absolute;
			left: ($toggle-input-width + 24px);
			top: $toggle-input-padding;
			font-weight: 400;
			pointer-events: none;
			transition: opacity 0.2s ease;
		}

		.toggle-input-on-text { opacity: 0; }
		.toggle-input-off-text { opacity: 1; }
	}

	&-label-text {
		font-size: 1.4rem;
		margin-top: 4px;
	}
}

// AUDITION REQUIRED TOGGLE INPUT
.toggle-input.audition-required-toggle-input {
	display: flex;
	label {
		width: 35px;
		height: 20px;
		&::before {
			height: 16px;
			width: 16px;
			left: 1px;
			bottom: 2px;
		}
		.toggle-input-on-text,
		.toggle-input-off-text {
			display: none;
			@media (min-width: $screen-xs) {
				display: block;
				color: $grey1;
				font-size: 1.2rem;
				top: 5px;
				left: -112px;
				right: -46px;
			}
		}
	}
	input:checked+label:before {
		transform: translateX(17px);
	}
}