@import "@bootstrap/scss/forms/input-group";
@import '@styles/components/input-groups-password-and-login';

.input-group {
	width: auto;

	input.form-input-error:focus + .input-group-addon {
		box-shadow: 0 0 8px 0 rgba($red, 0.6) !important;
	}
}

input:focus,
text:focus,
textarea:focus {
	+ .input-group-addon {
		-webkit-box-shadow: 0 0 8px 0 rgba(102, 175, 233, 0.6) !important; // for Firefox!
		-moz-box-shadow: 0 0 8px 0 rgba(102, 175, 233, 0.6) !important;
		box-shadow: 0 0 8px 0 rgba(102, 175, 233, 0.6) !important;
		border: 1px solid #66afe9 !important;
		outline: none;
	}
}

.form-group {
	.input-group-addon {
		.fa {
			padding: 0;
			margin: 0;
		}
	}
}

.input-group-addon {
	border: 1px solid $grey4;
	border-radius: 4px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.input-group-sm > .form-control,
.input-group-sm > .input-group-addon,
.input-group-sm > .input-group-btn > .btn {
	font-size: 1.2rem;
}

.input-group-lg > .form-control,
.input-group-lg > .input-group-addon,
.input-group-lg > .input-group-btn > .btn {
	font-size: 1.8rem;
}

.input-group-lg > .form-control,
.input-group-lg > .input-group-addon,
.input-group-lg > .input-group-btn > .btn {
	font-size: 2.6rem;
}

.input-group > .input-group-prepend > .input-group-text,
.input-group > .form-control:not(:last-child) {
	border-top-right-radius: 0;
	border-bottom-right-radius: 0;
}

.input-group > .input-group-append > .input-group-text {
	border-top-left-radius: 0;
	border-bottom-left-radius: 0;
}

.input-group-prepend {
	display: flex;
	margin-right: -1px;

	+ .form-control {
		border-top-left-radius: 0;
		border-bottom-left-radius: 0;
	}
}

.input-group-append {
	display: flex;
	margin-left: -1px;
}

.input-group-text {
	display: flex;
	align-items: center;
	padding: .375rem .75rem;
	margin-bottom: 0;
	font-size: 1.4rem;
	font-weight: 400;
	line-height: 1.5;
	color: #495057;
	text-align: center;
	white-space: nowrap;
	background-color: #e9ecef;
	border: 1px solid #ced4da;
	border-radius: 4px;
}

.input-group {
	> .input-group-addon {
		padding: 0 1em;
		font-size: 0.8em;
		font-weight: 500;
		line-height: $line-height-base;
		text-align: center;
		color: $grey1;
		background-color: $grey5;

		&.form-help {
			background: none;
			border: none;
		}

		&.white {
			background-color: #fff;
		}

		& + input {
			//border-left: none !important;
			border-top-left-radius: 0 !important;
			border-bottom-left-radius: 0 !important;
		}
	}

	&.transparent-addon {
		.input-group-addon {
			background-color: $white;
		}

		input[disabled] {
			~ .input-group-addon {
				background-color: $grey5;
			}
		}
	}
}

.input-group > .input-group-btn {
	> .btn {
		padding: 0.5rem 0.8rem;
		font-size: 1.8rem;
		background-color: $blue5;
		color: $blue4;
		border-color: $grey1;

		&:hover {
			background-color: $blue4;
			color: $blue0;
		}

		> i.fa {
			padding-right: 0;
		}
	}
}
