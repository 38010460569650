/*! Voices.com Header - Logged-in as Client or Talent */
@import '@styles/variables/global-variables';
@import '@styles/mixins/buttons-mixins';
@import '@styles/components/header-pre-login';

$header-button-class-prefix: header-btn;

.header-container {
	margin-left: auto;
	margin-right: auto;
	padding-left: 8px;
	padding-right: 8px;

	@media (min-width: $screen-sm) {
		padding-left: 20px;
		padding-right: 20px;
	}

	@media (min-width: $screen-lg) {
		width: 1170px;
		padding-left: 0;
		padding-right: 0;
	}
}

/**
 * Custom Button Classes used explicitly on the header - to prevent naming conflicts with the main .btn classes
 * By doing this, the .header-btn classes can be customized if need be
 */
.#{$header-button-class-prefix} {
	@include btn-styles($header-button-class-prefix);

	/**
	 * Button Sizes
	 *
	 * Iterate through all button sizes and render out classes to the CSS by using our custom @include automate-btn-size-styles() mixin
	 * Expected Result: .header-btn-xs, .header-btn-sm, .header-btn-md, .header-btn-lg, .header-btn-xl
	 *
	 */
	@include automate-btn-size-styles($header-button-class-prefix);

	/**
	 * Button Colours
	 *
	 * Iterate through all button colours and render out classes to the CSS by using our custom @include automate-btn-color-styles() mixin
	 * Expected Result: .header-btn-default, .header-btn-primary, .header-btn-success, .header-btn-info, .header-btn-warning, .header-btn-danger, .header-btn-white
	 *
	 */
	@include automate-btn-color-styles($header-button-class-prefix);
}

/**
 * Main Nav - Logged-In as Client or Talent
 */
.header-loggedin .nav-main {
	// ---------------------------------------------------------
	// (Logged-In) Main Nav - Hamburger
	// ---------------------------------------------------------
	.nav-main-menu-line {
		top: 1.4rem;
		width: 2.1rem;
		height: 0.3rem;
		border-radius: 0.1rem;
		transition: transform 0.3s ease, opacity 0.3s ease;
		background-color: $grey1;

		&:nth-child(2) {
			top: 2.1rem;
		}

		&:nth-child(3) {
			top: 2.8rem;
		}

		@media only screen and (min-width: $screen-sm) {
			top: 1.8rem;

			&:nth-child(2) {
				top: 2.5rem;
			}
			&:nth-child(3) {
				top: 3.2rem;
			}
		}
	}

	// ---------------------------------------------------------
	// (Logged-In) Main Nav - Searchbar
	// ---------------------------------------------------------

	&:not(.expanded-search) {
		.nav-main-form-container {
			@media only screen and (max-width: ($screen-md - 1px)) {
				flex: 1 1 auto;
				margin-left: 4rem;
				margin-right: 2rem;
				.nav-main-form {
					width: 100%;
				}
			}
			@media only screen and (max-width: ($screen-sm - 1px)) {
				display: none;
			}
		}
	}

	.nav-main-form-container {
		@media (min-width: $screen-md) {
			display: block;
		}
	}

	.nav-main-form-selector {
		@media (max-width: ($screen-sm - 1px)) {
			display: none; // Note: On smaller screens, Service Category is selected with another component.
		}
	}

	// ---------------------------------------------------------
	// (Logged-In) Main Nav - Submenu
	// ---------------------------------------------------------
	.nav-main-submenu {
		position: static;
		max-height: none;
		display: flex;
		z-index: 1;
		overflow: visible;
		@media only screen and (min-width: $screen-sm) {
			width: auto;
			height: 100%;
		}

		a.nav-main-submenu-btn {
			display: none;
			@media only screen and (min-width: $screen-sm) {
				display: block;
			}
			@media only screen and (min-width: $screen-md) {
				margin-right: 1.2rem;
				min-width: 11rem;
			}
		}
	}

	.nav-main-submenu-link-icon {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 4.4rem;
		height: 4.4rem;
		border: none;
		background: none;
		padding: 0;
		position: relative;
		border-radius: 100%;
		transition: background-color 0.3s ease-in-out;
		@media only screen and (min-width: $screen-sm) {
			width: auto;
			height: 100%;
			padding: 0 0.8rem;
			flex-direction: column;
			font-size: 1.2rem;
			text-align: center;
			border-radius: 0;
		}
		@media only screen and (min-width: $screen-lg) {
			padding: 0 1.6rem;
		}

		.far {
			font-size: 2.4rem;
			line-height: 1;
		}
	}

	.nav-main-submenu-link-icon-bubble {
		min-width: 1.7rem;
		height: 1.6rem;
		padding: 0.1rem 0.6rem;
		background-color: $red1;
		color: #fff;
		border-radius: 1rem;
		position: absolute;
		top: 0;
		right: 0;
		font-size: 1rem;
		line-height: 1.4;
		@media only screen and (min-width: $screen-sm) {
			top: 0.8rem;
			right: 2rem;
		}

		&.plus {
			padding: 0.1rem 0.4rem;
		}
	}

	.nav-main-submenu-link-text {
		display: none;
		@media only screen and (min-width: $screen-sm) {
			display: block;
		}
	}

	// Hamburger Menu
	.nav-main-submenu-hamburger {
		width: 4.4rem;
		height: 2.4rem;
		border-radius: 100%;
		// Animate hamburger lines to become X
		&.active {
			.nav-main-menu {
				&-line {
					&:nth-child(1) {
						transform: translateY(0.7rem) rotate(45deg);
					}

					&:nth-child(2) {
						opacity: 0;
					}

					&:nth-child(3) {
						transform: translateY(-0.7rem) rotate(-45deg);
					}
				}
			}
		}
	}

	.nav-main-submenu-list {
		display: flex;
		justify-content: space-between;
		width: 100%;
		align-items: center;
		@media only screen and (min-width: $screen-md) {
			justify-content: flex-end;
		}
		@media only screen and (max-width: $screen-sm) {
			padding: 5px;
		}
	}

	.nav-main-submenu-list-item {
		margin: 0;
		@media only screen and (min-width: $screen-sm) {
			height: 100%;
			display: flex;
			align-items: center;
			position: relative;
			z-index: 2;
		}

		&:hover .nav-main-submenu-link-icon {
			background-color: $grey5;
		}

		.nav-main-submenu-link-icon {
			transition: background-color 0.3s ease;
		}

		&.has-dropdown {
			// hover dropdown triangle triangle
			&::before {
				@media only screen and (min-width: $screen-md) {
					content: '\f0d8';
					width: 1.5rem;
					height: 1rem;
					position: absolute;
					bottom: 0;
					left: 0;
					right: 0;
					margin: auto;
					color: #fff;
					font-family: 'Font Awesome 5 Pro';
					visibility: hidden;
					opacity: 0;
					z-index: 1;
					font-weight: 900;
					font-size: 2rem;
					line-height: 0.8;
					overflow: hidden;
				}
			}

			&:hover,
			&:focus-within {
				.nav-main-dropdown {
					@media only screen and (min-width: $screen-sm) {
						visibility: visible;
						opacity: 1;
						transition: all 0.3s ease-in-out;
					}
				}

				&::before, // triangle
				.nav-main-dropdown-list,
				.nav-profile-info,
				.nav-main-dropdown-upgrade-btn {
					@media only screen and (min-width: $screen-sm) {
						opacity: 1;
						transform: translateY(0);
						transition: all 0.3s ease-in-out;
					}
				}
			}
		}

		&-desktop {
			visibility: hidden;
			width: 0;
			position: absolute;
			@media only screen and (min-width: $screen-lg) {
				visibility: visible;
				width: auto;
				position: relative;
			}
		}
	}

	// ---------------------------------------------------------
	// (Logged-In) Main Nav - Dropdown
	// ---------------------------------------------------------
	.nav-main-dropdown {
		display: none;
		@media only screen and (min-width: $screen-sm) {
			display: block;
			width: 25rem;
			position: absolute;
			top: 7.2rem;
			right: 0;
			background-color: #fff;
			box-shadow: 0 5px 12px 0 rgba(25, 34, 43, 0.1);
			border-radius: 0 0 0.4rem 0.4rem;
			visibility: hidden;
			opacity: 0;

		}

		&.nav-message-dropdown {
			@media only screen and (min-width: $screen-sm) {
				width: 32rem;
				.nav-main-dropdown-link {
					display: flex;

					.message-information {
						margin-left: 0.8rem;
						flex-grow: 1;
						overflow: hidden;

						p {
							margin: 0;
							color: $text-dark;
						}

						.message-title {
							font-size: 1.6rem;
							font-weight: bold;
						}

						.message-content {
							font-size: 1.4rem;
							white-space: nowrap;
							overflow: hidden;
							text-overflow: ellipsis;
						}

						.message-date {
							font-size: 1.2rem;
							color: $grey2;
						}

						&.unread-thread {
							p {
								font-weight: bold;
							}

							.message-date {
								color: $text-dark;
							}
						}
					}

					&:hover {
						.message-date {
							color: $text-dark;
						}
					}
				}

				#nav-view-all-messages {
					margin-right: -1.6rem;
					margin-left: -1.6rem;
					margin-bottom: -1.6rem;
					margin-top: 0.4rem;

					.nav-main-dropdown-link {
						justify-content: center;
						border-top: 1px solid $grey4;
						border-top-left-radius: 0;
						border-top-right-radius: 0;
						font-weight: 500;
						color: $blue1;
						padding: 1.2rem;

						&:hover, &:focus {
							background: $grey5;
							color: $blue0;
						}
					}
				}
			}
		}

		&.nav-notifications-dropdown {
			@media only screen and (min-width: $screen-sm) {
				width: 32rem;

				.nav-main-dropdown-list {
					padding: $spacing-large $spacing-medium $spacing-medium;
				}

				.nav-main-dropdown-list-item {
					margin-bottom: $spacing-large;
				}

				.notification-item {
					display: grid;
					grid-template-areas: "icon title date"
                                        ". content content"
                                        ". cta read-btn";
					grid-template-columns: .24fr 1fr .5fr;
					grid-column-gap: $spacing-smallest;
					align-items: center;

					p,
					a {
						margin: 0;
						font-size: 1.4rem;
						transition: color .1s linear;
					}

					a:hover {
						color: $blue0 !important;
					}

					.notification-icon {
						grid-area: icon;
						display: flex;
						align-items: center;

						.notification-unread-indicator {
							display: flex;
						}
					}

					.notification-title {
						grid-area: title;
						font-weight: 500;
						color: $text-dark;
					}

					.notification-date {
						grid-area: date;
						font-size: 1.2rem;
						text-align: right;
						font-weight: 500;
						color: $grey2;
					}

					.notification-content {
						grid-area: content;
						margin-top: $spacing-smallest;
						margin-bottom: $spacing-smaller;
						color: $text-dark;
					}

					.notification-cta {
						grid-area: cta;
						color: $blue1 !important;
						font-weight: 500;
					}

					.notification-read-btn {
						grid-area: read-btn;
						font-size: 1.2rem;
						text-align: right;
						color: $blue1 !important;
					}
				}

				#nav-view-all-notifications {
					margin: 0.4rem -1.6rem -1.6rem;

					.nav-main-dropdown-link {
						justify-content: center;
						border-top: 1px solid $grey4;
						border-top-left-radius: 0;
						border-top-right-radius: 0;
						font-weight: 500;
						padding: 1.2rem;
						text-align: center;

						&:hover, &:focus {
							background: $grey5;
						}
					}
				}
			}
		}

		// .nav-main-dropdown-list
		&-list {
			margin: 0;
			padding: 1.6rem 0;
			list-style-type: none;
			position: relative;
			@media only screen and (min-width: $screen-sm) {
				text-align: left;
				opacity: 0;
				transform: translateY(1rem);
			}

			@media only screen and (min-width: $screen-md) {
				padding: 1.6rem;
			}

			&-grid {
				@media only screen and (min-width: $screen-md) and (max-width: $screen-lg - 1px) {
					display: grid;
					grid-template-columns: 1fr 1fr;
					grid-auto-flow: column;
					padding-left: 0;

					&.client-account-links {
						grid-template-rows: repeat(4, 1fr);
					}

					&.help-links,
					&.talent-account-links {
						grid-template-rows: repeat(3, 1fr);
					}
				}
			}

			&::before {
				content: '';
				position: absolute;
				top: 0;
				left: 0;
				right: 0;
				height: 0.1rem;
				background-color: $grey4;

				@media only screen and (min-width: $screen-md) and (max-width: $screen-lg - 1px) {
					display: none;
				}
			}

			// .nav-main-dropdown-list-mobile
			&-mobile {
				@media only screen and (min-width: $screen-lg) {
					display: none;
				}
			}

			&-item {
				&-logout {
					display: none;
					@media only screen and (min-width: $screen-md) and (max-width: $screen-lg - 1px) {
						display: block;
					}
				}
			}

			&-logout {
				display: block;
				@media only screen and (min-width: $screen-md) and (max-width: $screen-lg - 1px) {
					display: none;
				}
			}

			&-dropdown-btn {
				@media only screen and (min-width: $screen-md) and (max-width: $screen-lg - 1px) {
					display: none;
				}
			}
		}

		// .nav-main-dropdown-link
		&-link {
			display: block;
			width: 100%;
			margin: 0 auto;
			font-size: 1.6rem;
			padding: 0.8rem 1.2rem;
			border-radius: 0.4rem;
			transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out;

			&:hover,
			&:focus {
				background-color: $grey5;
				color: $text-dark;
			}

			&:focus {
				@media only screen and (min-width: $screen-sm) {
					background-color: $grey4;
				}
			}
		}

		a {
			color: $grey1;

			&:focus {
				text-decoration: none;
			}
		}

		a.nav-main-dropdown-btn {
			margin-bottom: 1.6rem;
			color: #fff !important;
			border-radius: 0.4rem;
			background-color: $blue1;
			text-align: center;
			@media only screen and (min-width: $screen-md) {
				display: none;
			}

			&:hover,
			&:focus {
				background-color: $blue0;
			}
		}

		a.nav-main-dropdown-upgrade-btn {
			margin-bottom: 1.6rem;
			color: $text-dark;
			font-size: 1.4rem !important;
			border-radius: 0.4rem;
			text-align: center;
			width: 100%;

			&.upgrade-plus-btn {
				background-color: $purple5;

				&:hover,
				&:focus {
					background-color: $purple1;
					color: $white !important;
				}
			}

			&.upgrade-premium-btn {
				background-color: $blue5;

				&:hover,
				&:focus {
					background-color: $blue1;
					color: $white !important;
				}
			}

			&.nav-main-warning-btn {
				background-color: $yellow5;

				&:hover,
				&:focus {
					background-color: $yellow2;
					color: $text-dark !important;

					.fa-exclamation-triangle {
						color: $text-dark;
					}
				}

				.fa-exclamation-triangle {
					color: $yellow1;
					transition: color 0.3s ease-in-out;
				}
			}

			&.nav-main-error-btn {
				background-color: $red5;
				color: $red0;

				&:hover,
				&:focus {
					background-color: $red1;
					color: $white !important;

					.fa-exclamation-circle {
						color: #fff;
					}
				}

				.fa-exclamation-circle {
					color: $red0;
					transition: color 0.3s ease-in-out;
				}
			}

			.hover-image {
				display: none;
			}

			@media only screen and (min-width: $screen-md) {
				width: 87%;
			}

			&:hover,
			&:focus {
				color: $white;

				.normal-image {
					display: none;
				}

				.hover-image {
					display: inline;
				}
			}
		}

		a.nav-main-dropdown-link.nav-main-dropdown-upgrade-btn {
			display: flex;
			align-items: center;
			justify-content: center;
		}

		@media only screen and (min-width: $screen-sm) and (max-width: $screen-md - 1px) {
			.flexbox {
				display: flex;
				justify-content: space-between;
			}

			a.nav-main-dropdown-upgrade-btn {
				width: 30%;
				margin-right: 0;
				margin-top: 1rem;
				height: 3.6rem;
			}
		}

		@media only screen and (min-width: $screen-md) and (max-width: $screen-lg - 1px) {
			.flexbox {
				flex-direction: column;
			}

			a.nav-main-dropdown-upgrade-btn {
				width: 100%;
				margin-top: 1rem;
				height: 3.6rem;
				margin-bottom: 0;
			}
		}

		.nav-profile-info {
			@media only screen and (min-width: $screen-lg) {
				opacity: 0;
				transform: translateY(1rem);
			}
		}

		.nav-main-dropdown-upgrade-btn {
			@media only screen and (min-width: $screen-lg) {
				opacity: 0;
				transform: translateY(1rem);
			}
		}
	}
}

.header-loggedin {
	&.header-loggedin-client {
		.nav-main-submenu-link-icon {
			@media only screen and (min-width: $screen-sm) {
				min-width: 7rem;
			}
			@media only screen and (min-width: $screen-lg) {
				padding: 0 1.2rem;
			}
		}
	}
}

/**
 * (Logged-In) "Me Dropdown"
 */
.nav-profile {
	@media only screen and (max-width: ($screen-lg - 1px)) {
		position: fixed;
		top: 7.2rem;
		left: 0;
		width: 100%;
		height: calc(100% - 7.2rem);
		padding: 1.6rem 1.5rem;
		background-color: #fff;
		visibility: hidden;
		max-height: 0;
	}

	@media (min-width: $screen-sm) and (max-width: ($screen-lg - 1px)) {
		border-top: 1px solid $grey4;
		height: auto;
		box-shadow: 0 5px 12px 0 rgba($text-dark, 0.1);
		transition: transform .3s ease-in-out;
	}

	&.expanded {
		visibility: visible;
		max-height: none;
		overflow-y: scroll;

		// below screen-md
		@media only screen and (max-width: ($screen-md - 1px)) {
			bottom: 0;
		}

		@media only screen and (min-width: $screen-md) and (max-width: $screen-lg - 1px) {
			overflow-y: visible;
		}

		> .nav-main-dropdown {
			@media (min-width: $screen-sm) {
				max-width: 750px;
				margin-left: auto;
				margin-right: auto;
				padding-left: 1.4rem;
				padding-right: 1.4rem;
			}
			@media (min-width: $screen-md) {
				max-width: 970px;
			}
			@media (max-width: ($screen-lg - 1px)) {
				position: relative;
				top: 0;
				transform: translateY(0);
				opacity: 1;
				visibility: visible;
				width: auto;
				box-shadow: none;
			}
		}

		.nav-main-dropdown-list,
		.nav-profile-info,
		.nav-main-dropdown-upgrade-btn {
			@media only screen and (max-width: ($screen-lg - 1px)) {
				opacity: 1;
				transform: translateY(0);
				transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
			}
		}
	}

	.nav-main-dropdown {
		display: block !important;
		text-align: left;
		@media only screen and (min-width: $screen-md) and (max-width: $screen-lg - 1px) {
			display: flex !important;
		}
		@media only screen and (max-width: ($screen-lg - 1px)) {
			transform: translateY(1rem);
			opacity: 0;
			transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
		}
		@media (min-width: $screen-lg) {
			margin-top: 1px;
		}

		&-section {
			@media only screen and (min-width: $screen-md) and (max-width: $screen-lg - 1px) {
				flex-basis: 100%;
				padding: 0 $spacing-large;

				&:first-child {
					border-right: 1px solid $grey4;
					margin-left: -$spacing-medium;
					margin-right: $spacing-largest;
				}
			}
		}

		&-list {
			&::before {
				@media only screen and (min-width: $screen-md) {
					display: block;
					margin: 0 1.6rem;
				}
			}

			@media only screen and (min-width: $screen-lg) {
				padding: 1.6rem;
				text-align: left;
				opacity: 0;
				transform: translateY(1rem);
			}
		}

		.help-header {
			display: none;

			@media only screen and (min-width: $screen-md) and (max-width: $screen-lg - 1px) {
				display: flex;
				align-items: center;
				height: 55px; // Height of Client Info & Avatar
				padding-left: $spacing-small;

				i {
					margin-right: $spacing-small;
					color: $grey1;
					font-size: 2.4rem;
				}
			}
		}
	}

	// .nav-profile-info
	&-info {
		display: flex;
		height: 5.5rem;
		margin-bottom: 1.6rem;
		padding: 0 1.2rem;
		align-items: center;
		border-radius: 0.4rem;
		transition: background-color 0.3s ease-in-out;
		@media only screen and (min-width: $screen-lg) {
			padding: 0.8rem 1.2rem;
			height: auto;
			margin: 1.6rem 1.6rem 0.8rem 1.6rem;
		}

		@media only screen and (min-width: $screen-md) and (max-width: $screen-lg - 1px) {
			margin-bottom: 0;
		}

		&:not(.no-hover) {
			&:hover,
			&:focus {
				background-color: #f0f4f7;
			}
		}

		.circle-avatar-container {
			@media only screen and (min-width: $screen-lg) {
				display: none;
			}
		}
	}

	// .nav-profile-avatar
	&-avatar {
		width: 3.6rem;
		height: 3.6rem;
		margin-right: 0.8rem;
		border: 0.1rem solid $grey5;
		border-radius: 50%;
		background-position: 50% 50%;
		background-size: cover;
		@media only screen and (min-width: $screen-lg) {
			width: 2.4rem;
			height: 2.4rem;
			margin: 0;
		}
	}

	// .nav-profile-name
	&-name {
		margin: 0 0 0 0.8rem;
		padding: 0;
		font-size: 1.4rem;
		color: $text-dark;
		font-weight: 500;
		list-style-type: none;
		line-height: 1.25;
		@media only screen and (min-width: $screen-lg) {
			margin: 0;
			font-size: 1.6rem;
		}

		// .nav-profile-name-item
		&-item {
			line-height: 1.25 !important;
		}

		// .nav-profile-name-info
		&-info {
			line-height: 1.5;
			display: flex;
			@media only screen and (min-width: $screen-lg) {
				display: block;
				font-size: 1.4rem;
			}
		}

		// .nav-profile-name-account
		&-account {
			font-weight: 400;
			color: $grey1;
		}

		// .nav-profile-name-link
		&-link {
			color: $blue1;
		}

		// .nav-profile-name-tier
		&-tier {
			font-weight: 400;
			color: $grey1;
		}
	}

	// .nav-profile-bullet
	&-bullet {
		margin-right: 0.3rem;
		@media only screen and (min-width: $screen-lg) {
			display: none;
		}
	}

	&-talent-name {
		display: flex;
		align-items: center;

		.voices-badge {
			width: 1.2rem;
			height: 1.2rem;
			margin-left: $spacing-smallest;
		}
	}
}

// lessons new badge
.scssons-new {
	.status {
		position: relative;
		top: -1px;
		left: $spacing-smallest;
	}
}

// Shared favorites logo
#voices-logo {
	width: 109px;
}

// Mobile Header Rows
.header-row-with-nav {
	.row.header-row {
		@media (max-width: $screen-sm - 1px) {
			background-color: $white;
		}
	}

	.row.nav-row {
		@media (max-width: $screen-sm - 1px) {
			background-color: $white;
			margin-bottom: $spacing-large;
		}

		.sub-navigation-mobile {
			.dropdown,
			.dropdown-toggle,
			.dropdown-menu {
				@media (max-width: $screen-sm - 1px) {
					background-color: $white;
				}
			}
		}
	}
}

.nav-main-menu-btn {
	@media only screen and (min-width: $screen-md) {
		display: none;
	}
}

a.nav-main-submenu-btn-tablet {
	display: none;
	text-decoration: none !important;
	@media only screen and (min-width: $screen-sm) {
		&.btn {
			display: block;
		}
		margin-bottom: 0;
		margin-right: 1.6rem;
	}
	@media only screen and (min-width: $screen-md) {
		&.link {
			display: inline;
		}
	}
	@media only screen and (min-width: $screen-lg) {
		&.btn, &.link {
			display: none;
		}
	}

	&.btn {
		color: #fff !important;
		background-color: $blue1 !important;
		height: 3.6rem;
		transform: translateY(0);
		transition: all 0.3s ease-in-out;
		border-radius: 0.4rem;
		font-weight: 500;
		padding: 0.8rem 1.6rem;
		font-size: 1.6rem;
		line-height: 2rem;
		text-shadow: none;
		box-shadow: none;
		background-image: none;
		border: none;

		&:hover,
		&focus {
			background-color: $blue0 !important;
		}
	}

	&.link {
		color: $text-dark !important;
		border-bottom: 0;
		position: relative;
		font-size: 16px;
		font-weight: 500;

		&::before {
			@media only screen and (min-width: $screen-sm) {
				content: '';
				position: absolute;
				left: 0;
				bottom: -0.4rem;
				width: 0;
				height: 0.2rem;
				background-color: $blue1;
				opacity: 0;
				transition: opacity 0.3s ease-in-out, width 0.3s ease-in-out;
			}
		}

		&:hover,
		&:focus {
			color: $text-dark;

			&::before {
				@media only screen and (min-width: $screen-sm) {
					opacity: 1;
					width: 100%;
				}
			}
		}
	}
}

//megamenu
.megaMenu-main-item {
	display: flex;

	&-display {
		border-right: 1px solid $grey4;
	}

	&-right {
		padding-left: 5.5rem;
	}

	& p {
		color: $grey1;
	}
}

.megaMenu-talent-items, .megaMenu-work-items {
	display: flex;
	padding-top: 2rem;
	padding-bottom: 2rem;
	@media only screen and (max-width: ($screen-md - 1px)) {
		padding: 0.8rem 1.2rem 0.8rem 2rem;
		font-size: 1.6rem;
		&-text {
			flex: 1;
		}
	}

	&-text span {
		color: $grey1;
	}

	&-icon {
		width: 40px;
		height: 40px;
		border-radius: 50px;
		margin: 0.7rem 1rem;
		padding: 0.8rem 1.2rem 0.8rem 1.2rem;
		background-color: $grey5;
		@media only screen and (max-width: ($screen-md - 1px)) {
			background: none;
			border-radius: 0;
			margin: 0px;
			padding: 0px;
			width: 28px;
			height: 20px;
			text-align: center;
		}
	}

	&-active {
		border-radius: 4px;
		box-shadow: 0 2px 5px 0 rgba(25, 34, 43, 0.1), 0 2px 15px 0 rgba(0, 0, 0, 0.05);

		& span {
			color: $text-dark;
		}
	}

	&-text {
		a {
			display: flex;
			justify-content: space-between;
		}
	}
}

.megaNav {
	display: none;
	@media only screen and (min-width: $screen-md) {
		display: block;
		transition: transform 0.5s ease-in-out;
		background-color: #fff;
	}

	& .nav-secondary {
		padding: 0 1rem !important;
	}

	& .nav-secondary-link {
		padding: 1rem 0 !important;
	}

	& #find-work, & #find-talent, & #solutions  {
		color: $text-dark !important;
		font-weight: 500;
	}

	& .nav-secondary-item {
		margin: 1rem !important;
	}

	&-dropdown {
		width: 100%;
		height: auto;
		position: fixed;
		left: 0;
		padding: 1.5rem 0;

		& .header-btn {
			display: inline-block;
			background-color: $blue1 !important;
			color: $white !important;
			padding: 12px 16px;
			margin-top: 1rem;
		}

		& a {
			padding: 1.6rem 0px;
		}

		& .container {
			padding-top: 2rem;
		}

		& img {
			clip-path: circle();
			width: 5rem;
			margin-bottom: 1rem;
		}

		& .h4{
			font-size: 16px;
			font-weight: 700;
		}

		@media only screen and (min-width: $screen-md) {
			&.solutions-meganav-dropdown{
				height: 90vh;          
				overflow-y: auto;
			}
		}
	}
}

.icon-custom {
	font-size: 1.6rem;

	&-handshake, &-money-bill {
		color: $green1;
		font-size: 12px;
	}

	&-tag {
		color: $blue2;
		font-size: 13px;
		padding-left: 0.2rem;
	}

	&-lightbulb {
		color: $blue2;
		font-size: 15px;
		padding: 0.4rem 0rem 0rem 0.2rem;
	}

	&-stars, &-rocket {
		color: $yellow1;
	}

	&-rocket {
		padding-top: 0.5rem;
	}
}

.menuOverlay {
	position: absolute;
	padding: 2rem;
	background: $white;
	top: 0;
	left: 0;
	z-index: 1;
	width: 100%;
	height: 100%;
	position: absolute;
	overflow: scroll;
	transform: translateX(110%);
	transition: transform 0.5s ease;

	& a {
		color: $grey1;
		display: block;
		padding: 1.6rem 0;
		font-size: 1.6rem;

		&.menuOverlay-cta-link {
			color: $blue1 !important;
		}
	}

	& h4, & .h4 {
		padding-top: 2.4rem;
		font-size: 16px;
		font-weight: 700;

		> a {
			&.menuOverlay-cta-link {
				color: #19222b !important;
			}
		}
	}

	& .header-btn {
		display: inline-block;
		background-color: $blue1 !important;
		color: $white !important;
		padding: 12px 16px;
		margin: 1.5rem 0;
	}

	& .backtoMenu.backtoMenu, &-cta-link {
		color: $blue1 !important;
	}

	& h4 > &-cta-link {
		font-size: 2rem;
		padding: 0;
	}

	& .col-12 {
		padding: 0 1.5rem;
	}
	.solutions-cards{
        padding: 0.6rem 0;
    }
    .solutions-content{
        a{
            font-weight: 400;
            line-height: 24px;
            @media (max-width: ($screen-md - 1px)) {
                color:$blue1 !important;
            }
        }
    }
}

.footer-text a{
	display: inline;
	color:$blue1 !important;
}

.nav-main-container hr {
	margin: 1.5rem 0rem !important;
}

#browse-by {
	padding-left: 1.2rem;
	padding-top: 1rem;
	font-size: 1.4rem;
	color: $grey1;
}

.nav-main-menu-close-tablet {
	z-index: 999;
}

.tab-custom {
	@media only screen and (min-width: $screen-md) {
		display: none !important;
	}
}

.nav-main-submenu-btn-tablet {
	@media only screen and (min-width: $screen-md) {
		min-width: 90px;
	}
}

.log-in-custom {
	min-width: 45px;
}

.nav-search-list-heading {

	font-size: 1.4rem !important;
	color: $grey2;

}


.megaNav .nav-secondary-item.meganav-action-button {
	padding: 1rem;
	margin: 0 !important;
}

//header ribbon subnav
.subNav {
	background: $white;
	transition: transform 0.5s ease-in-out;
	display: flex;
	max-width: 1180px;
	align-items: center;
	margin: 0 auto;
	padding: 0 1.4rem;
	position: relative;

	.left {
		align-self: center;
		padding: 0 10px;

		&:hover {
			border-radius: 50%;
			height: 30px;
			display: flex;
			justify-content: center;
			align-items: center;
			background: $grey4;
		}
	}

	.right {
		align-self: center;
		padding: 0 10px;

		&:hover {
			border-radius: 50%;
			height: 30px;
			display: flex;
			justify-content: center;
			align-items: center;
			background: $grey4;
		}
	}
}

.side-scroll-container {
	display: flex;
	max-width: 1100px;
	align-items: center;
	margin: 0 auto;
	padding: 0 1.4rem;
	position: relative;
	overflow: hidden;

	.side-scroller {
		height: auto;
		display: flex;
		flex-wrap: nowrap;
		overflow-x: auto;
		overflow-y: hidden;
		animation-delay: 3s;
		scroll-behavior: smooth;
		-webkit-overflow-scrolling: touch;
		scrollbar-width: none; /* hide & remove space scroll-bar occupies - firefox */
	}

	/* remove space that scrollbar occupies - Chrome + Edge + Safari */
	.side-scroller::-webkit-scrollbar {
		-webkit-appearance: none;
		height: 0px;
	}
}

.scroll-link {
	flex-shrink: 0;
	width: 114px;
	height: 65px;
	margin: 1px 10px;
	overflow: hidden;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: 1.6rem;
	color: $grey1 !important;

	&:hover {
		border-bottom: 0.4rem solid $blue1;
		color: $grey0 !important;
	}

	@media only screen and (max-width: $screen-sm) {
		font-size: 1.4rem;
		width: 106px;
	}
	@media only screen and (max-width: $screen-xs) {
		font-size: 1.2rem;
		width: 85px;
		height: 50px;
		margin: 1px 5px;
	}
}

.hidden-arrow {
	opacity: 0;
	pointer-events: none;
}

// Styles for transition for secondary nav on scroll
body {
	&.scroll {
		&-down {
			.nav-secondary-container, .subNav {
				transform: translateY(-100%);
			}

			.header.header-with-subnav {
				.nav-main {
					@media only screen and (max-width: $screen-md) {
						height: 7.2rem;
					}
				}
			}
		}

		&-up {
			.nav-secondary-container, .subNav {
				transform: translateY(0);
			}

			.header.header-with-subnav {
				.nav-main {
					@media only screen and (max-width: $screen-md) {
						height: 14rem;
					}
					@media only screen and (max-width: $screen-xs) {
						height: 12.7rem;
					}
				}
			}
		}
	}
}

.header-with-subnav + #main-page {
	@media only screen and (max-width: ($screen-md - 1)) {
		padding-top: 5.2rem;
	}
}

.nav-main-container {
	@media only screen and (max-width: ($screen-sm - 1)) {
		border-bottom: solid 1px #c9d0d9;
	}
}

.show-caret-desktop {
	display: inline-block;

	@media screen and (min-width: 992px) {
		display: none !important;
	}
}

.nav-main-logo-link {
	z-index: 99999;
}

.solutions{
	&-cards{
		display: flex;
		padding: 12px;
		align-items: flex-start;
		gap: 12px;
        @media (max-width: ($screen-md - 1px)) {
			padding: 0px;
		}
        &:hover{
            border-radius: 4px;
            box-shadow: 0 2px 5px 0 rgba(25, 34, 43, 0.1), 0 2px 15px 0 rgba(0, 0, 0, 0.05);   
            .solutions-icon svg path{
				fill: $blue0;
			} 
			.solutions-content a{
				color:$blue0;
			}
        }
	}
	&-icon{
		display: flex;
		width: 3.5rem;
		height: 3.5rem;
		justify-content: center;
		align-items: center;
		flex-shrink: 0;
        svg path{
            fill: $blue1;
        }
	}
	&-content{
		display: flex;
		align-items: flex-start;
		gap: 0px;
		align-self: stretch;
		flex-direction: column;
        a{
            padding: 0.5rem 0;    
            font-size: 16px;
			font-style: normal;
			font-weight: 700;
			line-height: 20px;
            color:$blue1;
        }
        span{
            display: flex;
            align-self: flex-end;
            position: absolute;
        }

	}
    &-title{
        font-size:2rem;
    }
}