$white-transparent-hover: rgba(255, 255, 255, 0.18);
$white-transparent-active: rgba(255, 255, 255, 0.3);

/* ****************************** */
/* 20 - SUB NAV RULES */
/* ****************************** */

// Main sub-navigation elment  *********************************************
nav {
    &.nav-sub {
        //* FOCUS: ON-PAGE, USER-LEVEL NAVIGATION */
		@include set-paddings(top, $spacing-large);
		@include set-paddings(bottom, $spacing-large);

        /*  .nav-wrapper {
          position: relative;
      }*/

        @media screen and (max-width: 767px) {
            display: none;
            .nav-wrapper .mobile-tab {
                // height: 15px;
                // line-height: 15px;
                //  background-color: #c0c0c0;
            }

            //Pre loin condition
            &.pre-login {
                display: block;
				@include set-margins(bottom, 0);
            }

            /*

                  #mobile-menu {
                      display: none;
                      position: absolute;
                      top: 15px;
                      left: 0;
                  }

                  .nav-wrapper .mobile-tab:hover + #mobile-menu,
                  .nav-wrapper .mobile-tab:focus + #mobile-menu {
                      display: block;
                  }
      */
        }
        // mq

        // 20.a - Nav Pills
        .nav.nav-pills {
            > li {
                text-transform: uppercase;
                border-radius: 0;
                font-size: 1.8rem;
                border-bottom: 3px solid transparent;

                @media screen and (max-width: 480px) {
                    width: 32%;
                    text-align: center;
                    margin-right: 1%;
                    &:nth-child(3n) {
                        margin-right: 0;
                    }
                }

                @media screen and (max-width: 414px) {
                    width: 30%;
                    text-align: center;
                    margin-right: 1%;
                    &:nth-child(3n) {
                        margin-right: 0;
                    }
                }

                @media screen and (max-width: 320px) {
                    width: 48%;
                    text-align: center;
                    margin-right: 1%;
                    &:nth-child(even) {
                        margin-right: 0;
                    }

                    > a {
                        padding: 10px 5px;
                    }
                    // a
                }
                // mq
                // *********************************************
                &.active {
                    border-bottom-color: $blue1;
                    &:hover {
                        border-bottom-color: $blue1;
                    }
                    // hover
                    > a {
                        background-color: transparent;
                        color: $blue1;
                        &:hover {
                            color: $blue1;
                            //cursor: not-allowed;
                        }
                        // hover
                        &:focus {
                            color: $blue1;
                        }
                        // focus
                    }
                    // a
                }
                // active

                // *********************************************
                &:hover {
                    border-bottom-color: $grey3;
                    > a {
                        background-color: transparent;
                        // color: $blue0;

                        &:hover {
                        }
                        // hover
                        &:focus {
                        }
                        // focus
                    }
                    // a
                }

                // hover
                a {
                    color: $grey0;
                    text-decoration: none;

                    &:focus {
                        text-decoration: none;
                        background-color: transparent;
                    }
                }
                // a
            }
            // li
            /* The number-in-circle of some nav titles, ie. Responses (148) */
            .number-in-circle {
                padding: 1px 6px;
                margin-left: 3px;
                vertical-align: top;
                line-height: 25px;
                color: $white;
                font-size: 12px;
                font-weight: 700;
                border-radius: 8px;
                background: #333;
                -webkit-font-smoothing: antialiased;
            }
        }
        // nav-sub
    }
    // nav-pills

    // 20.b - Action Bar over Tables *********************************************
    &.action-bar {
        /* FOCUS: VOICES ACTION BAR OVER TABLES */
        // table-topper
        min-height: 51px;
        background-color: $blue1;
        font-size: 1.6rem;
        padding: 5px;
        @media only screen and (max-width: 320px) {
            /* , (min-device-width: 768px) and (max-device-width: 1024px)  { */
            + .table {
				@include set-margins(top, 0);
            }
        }
        // mq < 320

        @media screen and (max-width: 480px) {
            + .table {
				@include set-margins(top, 0);
            }
        }
        // mq < 500

        ul.nav {
            list-style-type: none; // suppress html bullets.

            // 20.b-i - Button elements
            &.nav-pills {
                // padding: .2rem .5rem;

                li {
                    list-style: none; // suppress html bullets.
                    text-align: center;

                    + li {
                        margin-left: 5px;
                    }

                    @media screen and (max-width: 395px) {
                        &.xxs-natural {
                            display: inline-block;
                            margin-top: 0.5rem;
							@include set-margins(right, 0);

                            &.share-space {
                                width: 99%;
                                float: left;
                            }
                            // share-space
                        }
                    }

                    @media screen and (max-width: 480px) {
                        //margin-right: .5rem;

                        + li {
                            margin-left: 0;
                        }

                        &.share-space {
                            float: none;
                            margin-bottom: 0.5rem;
                            a {
                                display: block;
                            }

                            &:nth-child(2n) {
                                margin-left: 1.2%;
                            }
                        }

                        &.sm-natural {
                            display: inline-block;

                            &.share-space {
                                width: 99%;
                                float: left;
                            }
                            // share-space
                        }

                        &.xs-natural {
                            display: inline-block;

                            &.share-space {
                                width: 49.4%;
                                float: left;
                            }
                            // share-space
                        }

                        &.xxs-natural {
                            margin-right: 0.5rem;
                        }
                        // natural
                    }
                    // mq  > 480

                    @media screen and (min-width: 480px) and (max-width: 650px) {
                        margin-right: 0.5rem;

                        &.share-space {
                            margin-bottom: 0.5rem;
                            a {
                                display: block;
                            }
                        }

                        &.sm-natural {
                            display: inline-block;

                            &.share-space {
                                width: 99%;
                                float: left;
                            }
                            // share-space
                        }
                    }
                    // mq > 479 < 545

                    @media screen and (min-width: 501px) and (max-width: 768px) {
                        padding-bottom: 0;
                        margin-right: 0.5rem;

                        + li {
                            margin-left: 0;
                        }

                        &.share-space {
                            margin-bottom: 0.5rem;
                        }
                    }
                    // mq  >= 510  < 768

                    p {
                        display: inline-block;
                        padding: 8px 12px;
                        color: $white;
                        font-size: 115%;
                        margin: 0;
                        vertical-align: top;
                    }
                    > a,
                    > button {
                        display: inline-block;
                        border-radius: 0;
                        color: $white;
                        border: 2px solid white;
                        transition: 0.15s all linear;
                        text-decoration: none;
                        padding: 0.6rem 1.5rem;
                        background-color: transparent;

                        &:hover {
                            background-color: $white-transparent-hover;
                            color: $white;
                            border-color: $white;
                            transition: 0.1s all linear;
                        }
                        // hover
                        &:active {
                            background-color: $white-transparent-active;
                            color: $white;
                            text-shadow: none;
                        }
                        // active
                        &:focus {
                            background-color: $white;
                            color: $blue1;
                        }
                    }
                    // anchor
                    &.active {
                        a,
                        button {
                            background-color: $blue0;
                            border-color: $blue4;
                            &:hover {
                                color: $white;
                            }
                            // hover
                        }
                        // a anchor
                    }
                    // active

                    &.disabled {
                        a,
                        button {
                            color: $grey3;
                            border-color: $grey3;
                            &:hover {
                                background-color: transparent;
                                cursor: not-allowed;
                            }
                            // hover
                        }
                        // a
                    }
                    // disabled

                    &.focus {
                        color: $blue1;
                    }
                    // focus

                    &.open {
                        a,
                        button {
                            color: $blue0;
                            background-color: $white;
                        }
                    }
                    // open

                    // 20.b.ii - Drop-down menus *********************************************
                    .dropdown-menu {
                        border: 2px solid $blue2;
                        margin-right: -4px;

                        > li {
                            padding-bottom: 0;
                            margin-left: 0;
                            text-align: left;
                            &:hover,
                            &:focus {
                                background-color: $blue2;
                            }
                            // hover
                            &:hover a,
                            &:focus a {
                                color: $white;
                            }
                            // hover
                            &.active {
                                background-color: $grey4;
                                cursor: not-allowed;
                                a {
                                    cursor: not-allowed;
                                    color: $blue0;
                                }
                            }
                            > a {
                                display: block;
                                text-align: left;
                                text-decoration: none;
                                border: none;
                                padding-top: 0.5rem;
                                padding-bottom: 0.5rem;
                                background: transparent;
                                transition: none;
                            }
                            // a
                        }
                        // li
                    }
                    // dropdown-menu

                    .search-block {
                        .form.form-inline {
                            .form-control {
                                @media screen and (max-width: 480px) {
                                    width: 15.5rem;
                                }
                                // mq max 480px

                                &:focus {
                                    background-color: $blue5;
                                    border-color: $blue5;
                                    color: $blue0;
                                    transition: 0.2s all linear;
                                }
                                &::-webkit-input-placeholder {
                                    color: $blue5;
                                    font-size: 1.5rem;
                                }
                                &::-moz-placeholder {
                                    color: $blue5;
                                    font-size: 1.5rem;
                                }
                                // firefox 19+
                                &:-ms-input-placeholder {
                                    color: $blue5;
                                    font-size: 1.5rem;
                                }
                                // ie
                                &input:-moz-placeholder {
                                    color: $blue5;
                                    font-size: 1.5rem;
                                }

                                &.disabled {
                                    label {
                                        color: $blue3;
                                    }
                                    // disabled label
                                    input,
                                    select,
                                    textarea {
                                        cursor: not-allowed;
                                        border: 1px solid $grey4;
                                        color: $grey3;
                                        background-color: $grey5;

                                        &::-webkit-input-placeholder {
                                            /* Chrome/Opera/Safari */
                                            color: $grey3;
                                        }
                                        &::-moz-placeholder {
                                            /* Firefox 19+ */
                                            color: $grey3;
                                        }
                                        &:-ms-input-placeholder {
                                            /* IE 10+ */
                                            color: $grey3;
                                        }
                                        &:-moz-placeholder {
                                            /* Firefox 18- */
                                            color: $grey3;
                                        }
                                    }
                                    // input, select, textarea fields
                                }
                                // disabled
                            }
                            // form-control

                            a {
                                padding: 0.5rem auto;
                                border-radius: 0;
                                background-color: transparent;
                                color: $blue5;
                                border: 2px solid $blue4;
                                transition: 0.2s all linear;
                                text-decoration: none;
                                text-align: center;

                                i.fa {
                                    // Special rules for FA in the action bar nav.
                                    padding: 0.6rem 0;
                                }
                                // i.fa

                                &:hover {
                                }
                                // a:hover
                            }
                            // add btn-outline and btn-white as base and then add styles.

                            @media screen and (max-width: 480px) {
                            }
                            // mq
                        }
                        // form.form-inline
                    }
                    // search-block

                    &#select-all-talent-job {
                        padding-left: 15px;
                    }
                    //#select-all-talent-job
                    .nav-btn {
                        padding: 0.6rem 1.5rem;
                        border: 2px solid #f4f9fd;
                        line-height: inherit;
                        border-radius: 0;
                    }
                }
                // li

                // 20.b.iv - Small Button Controls *********************************************
                .xs-group {
                    display: inline;

                    li {
                        display: inline-block;
                        list-style: none;
                        text-align: center;
                    }
                    // li

                    @media screen and (max-width: 480px) {
                        // Artifical structure to handle very small screens.
                        // Applies flexbox to children.
                        clear: both;
                        display: flex;
                        flex-direction: row;
                        flex-wrap: wrap;
                        justify-content: space-between;
                        // text-align: center;
                        margin-bottom: 0.7rem;
                        padding-top: 0.6rem;
                        font-size: 97%;

                        > li {
                            /*
              1/2  - 2 columns per row
              10px - spacing between columns
              */
                            // width: calc(1/2*100% - (1-1/2)*0.25em);
                            box-sizing: border-box;
                            margin-bottom: 0.5rem;
                            margin-left: 0;
                            &:last-of-type {
                                margin-left: 0.5rem;
                            }

                            a {
                                padding: 0.5rem 1rem;
                            }
                        }

                        // li
                    }
                    // mq < 500
                }
                // xs-group
            }
            // nav-pills
        }
        // ul.nav

        @media screen and (max-width: 768px) {
        }
        // mq
        @media screen and (max-width: 480px) {
            > ul > li.xs-natural {
            }

            > ul.nav.nav-pills {
                > li {
                    text-align: center;
                    a {
                    }
                    //
                }
                // li
            }

            // ul
        }
        // mq

        // 20.b.v - Inline Search form *****************************************
        .search-block {
            // applied to li element
            float: right;

            form.form-inline {
                // in a serach block
            }
            // form.form-inline

            /* ***** Buttons Group Method ***** */
            .input-group {
                border: 2px solid white;
                padding: 0;

                .input-group-btn {
                    .btn {
                        margin: 0;
                        background-color: $blue0;
                        // border-color: $blue4;
                        // border-width: 2px;
                        line-height: inherit;
                        // padding: .8rem 1rem .8rem 1rem;
                        border: none;
                        padding: 0.6rem 0.8rem;
                        border-radius: 0;

                        &:hover {
                            background-color: $white;
                            text-shadow: none;
                            color: $blue0;
                            > i.fa {
                                color: $blue0;
                            }
                        }
                        // hover
                        &:active {
                            background-color: $blue5;
                            > i.fa {
                                color: $blue0;
                            }
                        }
                        // active

                        > i.fa {
                            // padding: 0;
                            color: $white;
                            // font-size: 1.25rem;
                        }
                        // i.fa
                    }
                    // btn
                }
                // .input-group-btn
            }
            // input-group

            /* ***** Original Method **** */

            .form-control {
                // main input field in action bar search block
                font-size: 1.9rem;
                transition: 0.1s all linear;
                background-color: $blue0;
                border-radius: 0;
                border-width: 0;
                border-right: 2px solid $blue5;
                color: $white;
                font-weight: 300;

                &:focus {
                    background-color: $white;
                    border-color: $white;
                    color: $blue0;
                    transition: 0.2s all linear;
                    &::-webkit-input-placeholder {
                        color: $blue0;
                    }
                    &::-moz-placeholder {
                        color: $blue0;
                    }
                    // firefox 19+
                    &:-ms-input-placeholder {
                        color: $blue0;
                    }
                    // ie
                    &input:-moz-placeholder {
                        color: $blue0;
                    }
                }
                &::-webkit-input-placeholder {
                    color: $blue5;
                }
                &::-moz-placeholder {
                    color: $blue5;
                }
                // firefox 19+
                &:-ms-input-placeholder {
                    color: $blue5;
                }
                // ie
                &input:-moz-placeholder {
                    color: $blue5;
                }

                &.disabled {
                    label {
                        color: $blue3;
                    }
                    // disabled label
                    input,
                    select,
                    textarea {
                        cursor: not-allowed;
                        border: 1px solid $grey4;
                        color: $grey3;
                        background-color: $grey5;

                        &::-webkit-input-placeholder {
                            /* Chrome/Opera/Safari */
                            color: $grey3;
                        }
                        &::-moz-placeholder {
                            /* Firefox 19+ */
                            color: $grey3;
                        }
                        &:-ms-input-placeholder {
                            /* IE 10+ */
                            color: $grey3;
                        }
                        &:-moz-placeholder {
                            /* Firefox 18- */
                            color: $grey3;
                        }
                    }
                    // input, select, textarea fields
                }
                // disabled
            }
            // form-control

            a {
                padding: 0.5rem auto;
                border-radius: 0;
                background-color: transparent;
                color: $blue5;
                border: 2px solid $blue4;
                transition: 0.2s all linear;
                text-decoration: none;
                text-align: center;

                i.fa {
                    // Special rules for FA in the action bar nav.
                    padding: 0.6rem 0;
                }
                // i.fa

                &:hover {
                }
                // a:hover
            }
            // add btn-outline and btn-white as base and then add styles.

            @media screen and (max-width: 480px) {
            }
            // mq
        }
        // search-block
    }
    // action-bar primary voices action-bar

    &.profile-nav {
        margin-top: 0;

        // Useless with removal of nav from smaller screen sizes (<768px)
        // @media (max-width: 479px) {
        //     margin-top: 2rem;
        //     // display: block;
        //     vertical-align: text-bottom;
        //     .nav.nav-pills>li {
        //         float: left;
        //         vertical-align: text-bottom;
        //     }
        // }
        @media (min-width: 768px) and (max-width: 991px) {
            display: inline-block;
            .nav.nav-pills > li {
                width: 100%;
                text-align: left;
                margin: 0 auto;
                float: none;
            }
        }
    }
    //profile-nav main nav for profile pages
}
