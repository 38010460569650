.demo-upload-form-group {
	.files .template-upload.in {
		@include clearfix;
		background-color: #f7f9fb;
		border: 1px solid #b1bbc6;
		border-radius: 3px;
		padding: 1rem 2rem;
		color: #000;
		font-size: 1.6rem;
		font-weight: bold;
		.fileupload-filesize-col {
			span {
				color: $grey2;
			}
		}
		margin-bottom: 2rem;
	}
	.fileupload-current-selection {
		margin-top: 1rem;
		margin-bottom: 1rem;
	}
}