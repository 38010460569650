@import "@bootstrap/scss/pagination";
@import "@bootstrap/scss/dropdown";

// Define FontAwesome Icons (Only the things we need!)
.#{$fa-css-prefix}-list:before { content: $fa-var-list; }
.#{$fa-css-prefix}-angle-right:before { content: $fa-var-angle-right; }
.#{$fa-css-prefix}-angle-double-right:before { content: $fa-var-angle-double-right; }

// Dropdown Styles
.dropdown-menu {
	font-size: 1.4rem;  // to maintain old styles, before design system revamp. If we get an update to the dropdown styles, THIS CAN BE REMOVED!
}
.review-custom-dropdown-text-style {
	text-align: right;
	@media only screen and (max-width: 480px) {
		text-align: left;
	}
}

#pagination-controls,
#pagination-action-bar-container {
	margin-top: $spacing-medium;
}

// Pagination Numbers
.pagination {
	margin: 2rem auto;
	transition: opacity 0.15s;
	display: flex;
	padding-left: 0;
	border-radius: 4px;
	justify-content: center;

	> li {
		&:first-child {
			> a,
			> span {
				border-top-left-radius: 4px;
				border-bottom-left-radius: 4px;
			}
		}

		&:last-child {
			> a,
			> span {
				border-top-right-radius: 4px;
				border-bottom-right-radius: 4px;
			}
		}

		> a,
		> span {
			display: block;
			color: $blue1;
			border: 1px solid $grey4;
			padding: .6rem 1.2rem;
			line-height: 1.5;
			margin-left: -1px;
			background-color: $white;

			&:hover {
				color: $white;
				background: $blue3;
				border-color: $blue3;
			}
		}

		&.active {
			cursor: not-allowed;
			> a {
				z-index: 3;
				color: #fff;
				background-color: $blue2;
				border-color: $blue1;
				&:hover {
					background-color: $blue2;
					cursor: not-allowed;
				}
			}
		}

		&.disabled  {
			> a {
				color: $grey3;
				cursor: not-allowed;
				&:hover {
					color: $grey3;
				}
			}
		}
	}
}

// Loading state for pagination.
nav.loading .pagination {
	opacity: 0.6;
}

// Select how many items to display per page (10, 25, 100, etc)
.per-page-control {
	margin: 2rem 0;
	float: right;
	padding-right: 2em;

	@media screen and (max-width: 480px) {
		display: block;
		text-align: center;
		float: none;
		padding-right:0;
		margin-bottom:0;
	}

	&.open {
		> .dropdown-menu {
			display: block;
		}
	}

	a {
		color: $blue2;
		text-decoration:none;
		cursor:pointer;

		&:hover {
			text-decoration: none;
			background-color: $blue4;
			color: $blue0;
		}
	}

	ul.dropdown-menu  {
		top: auto;
		bottom: 100%;
		margin-bottom: 1px;
		min-width: 6.5rem;
		border: 1px solid $grey4;
		border-radius: 0;
		box-shadow: none;
		color: $blue0;
		background-color: $white;

		li {
			a {
				color: $blue0;
				text-decoration: none;
				font-size: 1.4rem;
				display: block;
				padding: $spacing-smallest $spacing-small;
			}

			&.active a {
				background-color: $blue1;
				color: $white;
			}
		}


		@media screen and (max-width: 480px) {
			left: calc(50% - 4rem);

		}
	}
}
