.footer {
	overflow-y: hidden;
	background-color: $blue1;
	@media (min-width: ($screen-sm - 1px)) {
		border-top: 1px solid $grey4;
	}

	&-menu {
		max-height: 0;
		overflow: hidden;
		margin: 0;
		padding: 0;
		list-style-type: none;
		transition: max-height 0.3s ease-in-out;
		@media only screen and (min-width: $screen-md) {
			max-height: none;
		}

		&-container {
			@media (min-width: $screen-sm) {
				display: grid;
				grid-template-columns: 1fr 1fr 1fr;
				grid-template-rows: 1fr 1fr;
				grid-column-gap: $spacing-larger;
				grid-row-gap: $spacing-larger;
				padding-top: 2.4rem;
				padding-bottom: 2.4rem;
				border-bottom: 0.1rem solid $grey4;
			}

			@media (min-width: $screen-md) {
				grid-template-columns: repeat(5, auto);
				grid-template-rows: 1fr;
				grid-column-gap: $spacing-larger;
			}

			@media (min-width: $screen-lg) {
				grid-template-columns: repeat(5, 1fr);
			}
		}

		&-title {
			display: block;
			padding: 1.3rem 1.2rem;
			color: $white !important;
			font-weight: 500;
			font-size: 2rem;
			font-family: Poppins;
			position: relative;
			border-top: none;
			@media only screen and (min-width: $screen-sm) {
				padding: 1.4rem 1.2rem 1.4rem 0;
				font-size: 2.4rem;
			}
			@media only screen and (max-width: ($screen-md - 1px)) {
				font-size: 2rem;
				padding: 1.3rem 1.2rem;
			}

			&:before {
				content: '\f078';
				font-family: 'Font Awesome 5 Pro';
				width: 1.8rem;
				height: 2.8rem;
				position: absolute;
				top: 0;
				bottom: 0;
				right: 1.2rem;
				margin: auto;
				text-align: center;
				transition: transform 0.3s ease-in-out;
				@media only screen and (min-width: $screen-md) {
					display: none;
				}
			}

			&:after {
				content: '';
				position: absolute;
				bottom: -0.1rem;
				left: 0;
				right: 0;
				height: 0.1rem;
				background-color: $blue0;
				@media only screen and (min-width: $screen-md) {
					display: none;
				}
			}

			&:hover,
			&:focus,
			&:active {
				text-decoration: none;
				color: $white;
			}

			&[aria-expanded='true'] {
				&:before {
					transform: rotate(180deg);
				}
			}

			&-tablet {
				display: none !important;
				@media only screen and (min-width: $screen-md) {
					display: block !important;
				}
			}

			&-mobile {
				display: none;
				@media (max-width: ($screen-md - 1px)) {
					display: block;
				}
			}
		}

		&-link {
			display: block;
			padding: 8px;
			color: $white !important;
			font-size: 1.6rem;
			width: fit-content;
			height: 45px;
			@media only screen and (max-width: ($screen-md - 1px)) {
				padding-left: 1.2rem;
			}
			@media only screen and (min-width: $screen-md) {
				padding-left: 0px;
			}

			&:hover,
			&:focus {
				text-decoration: none;

				.footer-menu-text {
					&:before {
						background-color: $white;
					}
				}
			}
		}

		&-text {
			position: relative;

			&:before {
				content: '';
				position: absolute;
				left: 0;
				bottom: -0.7rem;
				width: 100%;
				height: 0.1rem;
				transition: background-color 0.3s ease-in-out;
			}
		}

		&-lower {
			max-height: none;
			text-align: center;
			@media only screen and (min-width: $screen-md) {
				text-align: left;
			}

			.footer-menu-item {
				display: inline-block;

				&:last-child {
					.footer-menu-link {
						@media only screen and (min-width: $screen-sm) {
							margin-right: 0;
						}
					}
				}
			}

			.footer-menu-link {
				@media only screen and (min-width: $screen-sm) {
					padding: 1.2rem;
					margin-right: 0.8rem;
				}
				@media only screen and (min-width: $screen-md) {
					padding: 1.2rem 0;
					margin-right: 1.5rem;
				}
			}
		}
	}

	&-secondary-menu {
		background-color: $grey5;
		padding: 0;

		@media (max-width: ($screen-sm - 1px)) {
			> .container {
				padding: 0;
			}
		}

		@media (max-width: ($screen-md - 1px)) {
			background-color: $blue1;
			padding-bottom: 0;
		}

		@media only screen and (min-width: $screen-md) {
			padding: 3rem 0;
		}

		&-link {
			display: block;
			padding: 8px;
			color: $white !important;
			font-size: 1.6rem;
			height: 45px;
			width: fit-content;
			@media only screen and (min-width: $screen-md) {
				padding-left: 0;
				color: $blue1 !important;
			}

			&:hover,
			&:focus {
				text-decoration: none;

				.footer-menu-text {
					&:before {
						background-color: $grey1;
					}
				}
			}
		}

		&-title {
			display: block;
			padding: 1.3rem 1.2rem;
			color: $white !important;
			font-weight: 500;
			font-size: 2rem;
			font-family: Poppins;
			position: relative;
			@media only screen and (min-width: $screen-sm) {
				border-top: none;
				padding: 1.4rem 1.2rem 1.4rem 0;
				color: $text-dark !important;
				font-size: 2.4rem;
			}
			@media only screen and (max-width: ($screen-md - 1px)) {
				color: $white !important;
				font-size: 2rem;
				padding: 1.3rem 1.2rem;
			}

			&:before {
				content: '\f078';
				font-family: 'Font Awesome 5 Pro';
				width: 1.8rem;
				height: 2.8rem;
				position: absolute;
				top: 0;
				bottom: 0;
				right: 1.2rem;
				margin: auto;
				text-align: center;
				transition: transform 0.3s ease-in-out;
				@media only screen and (min-width: $screen-md) {
					display: none;
				}
			}

			&:after {
				content: '';
				position: absolute;
				bottom: -0.1rem;
				left: 0;
				right: 0;
				height: 0.1rem;
				background-color: $blue0;
				@media only screen and (min-width: $screen-md) {
					display: none;
				}
			}

			&:hover,
			&:focus,
			&:active {
				text-decoration: none;
				color: $white;
				@media only screen and (min-width: $screen-sm) {
					color: $grey1;
				}
			}

			&[aria-expanded='true'] {
				&:before {
					transform: rotate(180deg);
				}
			}

			&-tablet {
				display: none !important;
				@media only screen and (min-width: $screen-md) {
					display: block !important;
				}
			}

			&-mobile {
				display: none;
				@media (max-width: ($screen-md - 1px)) {
					display: block;
				}
			}
		}
	}

	&-lower-container {
		margin: 3.2rem 0 3.2rem 0;
		@media only screen and (min-width: $screen-md) {
			margin-top: 3.2rem;
		}
	}

	&-social {
		margin-top: 2rem;
		list-style-type: none;
		text-align: center;
		padding: 24px 0;
		border-top: 1px solid $blue0;
		@media only screen and (min-width: $screen-md) {
			margin: 0;
			text-align: left;
			border-top: none;
			padding: 16px 0 16px 0;
		}

		&-item {
			display: inline-block;
			margin-right: 1.2rem;
			@media only screen and (min-width: $screen-md) {
				margin-right: 0.8rem;
			}
			@media only screen and (min-width: $screen-lg) {
				margin-right: 1.2rem;
			}

			&:last-child {
				margin-right: 0;
			}
		}

		&-link {
			display: block;
			padding: 0.6rem;
			font-size: 1.6rem;

			&:hover,
			&:focus {
				text-decoration: none;
				color: $blue1 !important;

				.fab {
					transform: translateY(-0.2rem);
					box-shadow: 0 5px 12px 0 rgba(25, 34, 43, 0.1);
				}
			}
		}

		.fab {
			display: flex;
			align-items: center;
			justify-content: center;
			width: 3.6rem;
			height: 3.6rem;
			background-color: $white;
			border-radius: 50%;
			transition: box-shadow 0.3s ease-in-out, transform 0.3s ease-in-out;
		}
	}

	&-copy {
		padding: 1.2rem 0;
		font-size: 1.4rem;
		text-align: center;
		color: $white;
		border-top: none;
		@media only screen and (min-width: $screen-md) {
			text-align: right;
		}

		span {
			display: block;
			@media only screen and (min-width: $screen-sm) {
				display: inline;
			}
		}
	}

	&-main {
		background-color: $blue1;
		padding: 0;
		@media only screen and (min-width: $screen-md) {
			padding: 5rem 0;
		}

		@media (max-width: ($screen-sm - 1px)) {
			> .container {
				padding: 0;
			}
		}
	}

	&-content {
		text-align: center;
		color: $white;
		font-weight: 100;
		padding-right: 0;
		@media only screen and (min-width: $screen-md) {
			text-align: left;
			padding-right: 5rem;
		}

		p, p > a {
			color: $white;
			padding: 0;
		}
	}

	&-location {
		font-size: 16px;

		a:hover,
		:focus {
			box-shadow: 0 1px 0 $white;
		}
	}

	&-lower-block {
		border: none;
		margin: 0;
		padding: 12px 0;
		@media only screen and (min-width: $screen-lg) {
			margin-bottom: 0;
		}
		@media only screen and (min-width: $screen-md) {
			border-top: 0.1rem solid $blue0;
			border-bottom: 0.1rem solid $blue0;
			margin-top: 4rem;
		}
	}

	&-brand-logo {
		&:hover {
			box-shadow: none;
		}

		img {
			width: 18rem;
			padding-top: 4rem;
			padding-bottom: 1rem;;
			@media only screen and (min-width: $screen-md) {
				padding: 1rem 0rem;
			}
		}
	}

	&-app-store-badges {
		padding-top: 1rem;

		img {
			padding: 1rem;
			margin-top: 1rem;
		}

		a {
			text-decoration: none;
		}

		@media only screen and (min-width: $screen-md) {
			display: none;
		}
	}

}

.footer-social-link.svg {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 3.6rem;
	height: 3.6rem;
	background-color: #fff;
	border-radius: 50%;
	transition: box-shadow .3s ease-in-out, transform .3s ease-in-out;
	transform: translateY(0.2rem);

	svg {
		fill: currentColor;
	}

	&:hover {
		transform: translateY(0);
		box-shadow: 0 5px 12px #19222b1a;
	}
}
