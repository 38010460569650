@mixin input-card-group {
    display: flex;
    flex-wrap: wrap;
    margin-right: -$spacing-large;
    margin-bottom: -$spacing-large;
}

@mixin input-card-text {
    display: block;
    width: 100%;
    font-size: 1.4rem;
    color: $grey1;
    font-weight: 400;
    margin-top: $spacing-smaller;
}

@mixin input-card-title {
    display: block;
    width: 100%;
    font-size: 1.8rem;
    font-weight: 500;
}

@mixin input-card-content {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
}

@mixin input-card-col {
    float: none;
    flex: 0 1 auto;
    padding-left: 0;
    padding-right: $spacing-large;
    padding-bottom: $spacing-large;
}

@mixin input-card-align {
    display: flex;
    justify-content: space-between;
}

@mixin input-card-label {
    position: relative;
    border: 1px solid $grey4;
    border-radius: $border-radius-normal-size;
    width: 100%;
    height: 100%;
    padding: $spacing-large;
    margin-bottom: 0;
    cursor: pointer;
    transition: all 0.2s;
    background-color: #FFFFFF;

    &::before {
        content: ' ';
        position: absolute;
        top: -1px;
        left: -1px;
        right: -1px;
        bottom: -1px;
        border-radius: $border-radius-normal-size;
        border: 0px solid #FFFFFF;
        transition: border 0.3s ease;
    }

    &:hover {
        box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
        transform: translateY(-2px);
        /*
            * This is because if z-index effects like a popover occur within a radio card the transform breaks the stacking context and will place the popover effect behind adjacent radio cards.
            * See Job Form Budget section as an example.
            */
        z-index: 2;
    }
}