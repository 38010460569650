@import "@bootstrap/scss/tooltip";

.tooltip {
	position: absolute;
	z-index: 1070;
	display: block;
	visibility: visible;
	font-family: Roboto, Helvetica Neue, Helvetica, Arial, sans-serif;
	font-size: 1.44rem;
	font-weight: 400;
	line-height: 1.4;

	&.in {
		opacity: 0.9;
	}

	&:not(.fade-no-slide).fade {
		transition: transform 0.1s linear, opacity 0.1s linear;
	}

	&.fade-no-slide.fade {
		transition: opacity 0.1s linear;
	}

	&.top {
		margin-top: -3px;
		padding: 5px 0;

		.tooltip-arrow {
			border-width: 6px 6px 0 6px;
			border-top-color: $grey0;
			bottom: 0;
			margin-left: -5px;
		}

		&.fade {
			transform: translateY(10px);
			&.in {
				transform: translateY(0);
			}
		}
	}

	&.bottom {
		margin-top: 3px;
		padding: 5px 0;

		.tooltip-arrow {
			border-width: 0 6px 6px 6px;
			border-bottom-color: $grey0;
			top: 0;
			margin-left: -5px;
		}

		&.fade {
			transform: translateY(-10px);
			&.in {
				transform: translateY(0);
			}
		}
	}

	&.left {
		margin-left: -3px;
		padding: 0 5px;

		.tooltip-arrow {
			border-left-color: $grey0;
			right: 0;
			margin-top: -5px;
		}

		&.fade {
			transform: translateX(10px);

			&.in {
				transform: translateX(0);
			}
		}
	}

	&.right {
		margin-left: 3px;
		padding: 0 5px;

		.tooltip-arrow {
			border-right-color: $grey0;
			left: 0;
			margin-top: -5px;
		}

		&.fade {
			transform: translateX(-10px);

			&.in {
				transform: translateX(0);
			}
		}
	}
}

.tooltip-inner {
	max-width: 240px;
	padding: 8px 12px;
	background-color: $grey0;
	text-shadow: none;
	-webkit-font-smoothing: antialiased;
	font-weight: 400;
	font-size: 14px;
	color: $white;
	text-align: center;
	text-decoration: none;
	border-radius: 4px;
}

.tooltip-arrow {
	position: absolute;
	width: 0;
	height: 0;
	border-color: transparent;
	border-style: solid;
}

[data-role="tooltip"] {
	cursor: pointer;
}