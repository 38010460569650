/* **************************   */
/* 14 - LIST GROUP STRUCTURE    */
/* **************************   */

.list-group { 
    font-size: 1.75rem;

    @media screen and (max-width: ($screen-xs - 1px)) {
        font-size: .8em;
    } // mq
    
    /*  14.a - Row element *********************** */
    > a.list-group-item { 
        // for when a whole row is wrapped in an anchor.
		color: $text;
		&:not(.disabled):hover {
			background-color: $grey5;
		} // hover
	}

	> .list-group-item {
		border-top: 1px solid $grey4;
		border-bottom: 1px solid $grey4;
	}

    /* 14.b - List-group as a menu */
    &.list-group-menu {  
        
        @media screen and (max-width: 1019px) {
            padding-right: 0;
        }

        > .list-group-item {
            padding: .3em 1em; //Copied in form.list-group-item below to replicate buttons for forms in the menu
            color: $primary;
            text-decoration: none;
            
            @media screen and (max-width: 479px) {
                padding: .6em 1em;
            }     

            &.success {
                @extend .bg-success;
                color: $white;
            } // success
            &.warning {
                @extend .bg-warning;
                color: $blue0;
            } // success
            &.danger {
                @extend .bg-danger;
                color: $white;
            } // success
            &.info {
                @extend .bg-info;
                @extend .text-white;
            } // success        
            &.primary {
				@include bg-primary;
                color: $white;
            } // success

            &:not(.disabled):hover {
                background-color: $blue1;
                border: 1px solid $blue0;
                color: $white;
                & + .list-group-item{
                    border-top:1px solid $blue0;
                }
                .fa{
                    color: $white;
                }
            } // hover

			.fa, .fal, .fas, .far {
				padding-right: 1em;
				@media screen and (max-width: 480px) {
					padding-right: 2em;
				}
			}
            &.disabled{
                background:$grey5;
            }
        } // list-group-item
        > form.list-group-item{
            padding:0;
            .btn-link{
                border:none;
                padding: .3em 1em;
                width:100%;
                text-align:left;
                @media (max-width:480px){
                    font-weight:300;
                    padding: .6em 1em;
                }
                &:hover{
                    text-decoration: none;
                    color: $white;
                }
                &:focus{
                    color: $blue1;
                    text-decoration: none;
                    background-color: #f5f5f5;
                }

            }
        }
    } // list-group-menu

    // 14.c - List group items
    > .list-group-item {

        .list-group-item-heading {
            font-weight: 400;
            font-size: 1.8rem;
            line-height: 1.1;
            @media screen and (max-width: 480px) { 
                font-size: 1.6rem;
            }
        }
        
        // 14.e - Row Markers - indicate the status or alerts user to special conditions.
        &.row-marker { 
            // 14.e.i - Row states
            &.row-primary:after { 
				content: "";
				position: absolute;
				top: 0;
				left: 0;
				width: 4px;
				height: 100%;
				background-color: $primary;
            } // row-primary

            &.row-info:after { 
				content: "";
				position: absolute;
				top: 0;
				left: 0;
				width: 4px;
				height: 100%;
				background-color: $info;
            } // row-primary

            &.row-success:after { 
				content: "";
				position: absolute;
				top: 0;
				left: 0;
				width: 4px;
				height: 100%;
				background-color: $success;
            } // row-primary

            &.row-warning:after { 
				content: "";
				position: absolute;
				top: 0;
				left: 0;
				width: 4px;
				height: 100%;
				background-color: $warning;
            } // row-primary

            &.row-danger:after { 
				content: "";
				position: absolute;
				top: 0;
				left: 0;
				width: 4px;
				height: 100%;
                background-color: $danger;
            } // row-primary

            &.row-grey:after {
              content: "";
              position: absolute;
              top: 0;
              left: 0;
              width: 4px;
              height: 100%;
              background-color: $grey4;
            }

            // 14.e.ii - Row Status - 2017 Status Markers

            &.row-draft:after,
            &.row-am-draft:after { 
				content: "";
				position: absolute;
				top: 0;
				left: 0;
				width: 4px;
				height: 100%;
                background-color: $warning;
            } // row-primary

            &.row-review:after,
            &.row-in_review:after,
            &.row-am-review:after { 
				content: "";
				position: absolute;
				top: 0;
				left: 0;
				width: 4px;
				height: 100%;
                background-color: $warning;
            } // row-primary

            &.row-hiring:after { 
				content: "";
				position: absolute;
				top: 0;
				left: 0;
				width: 4px;
				height: 100%;
                background-color: $green1;
            } // row-primary

            &.row-deciding:after,
            &.row-is_deciding:after { 
				content: "";
				position: absolute;
				top: 0;
				left: 0;
				width: 4px;
				height: 100%;
                background-color: $green1;
            } // row-primary

            &.row-offering:after { 
				content: "";
				position: absolute;
				top: 0;
				left: 0;
				width: 4px;
				height: 100%;
                background-color: $blue1;
            } // row-primary

            &.row-completed:after,
            &.row-fs-completed:after { 
				content: "";
				position: absolute;
				top: 0;
				left: 0;
				width: 4px;
				height: 100%;
                background-color: $blue1;
            } // row-primary

            &.row-finalizing:after { 
				content: "";
				position: absolute;
				top: 0;
				left: 0;
				width: 4px;
				height: 100%;
                background-color: $green1;
            } // row-primary

            &.row-working:after { 
				content: "";
				position: absolute;
				top: 0;
				left: 0;
				width: 4px;
				height: 100%;
                background-color: $blue1;
            } // row-primary

            &.row-done:after { 
				content: "";
				position: absolute;
				top: 0;
				left: 0;
				width: 4px;
				height: 100%;
                background-color: $blue1;
            } // row-primary

            &.row-cancelled:after { 
				content: "";
				position: absolute;
				top: 0;
				left: 0;
				width: 4px;
				height: 100%;
                background-color: $accent1;
            } // row-primary

            &.row-denied:after { 
				content: "";
				position: absolute;
				top: 0;
				left: 0;
				width: 4px;
				height: 100%;
                background-color: $accent1;
            } // row-primary

        } // add primary role marker

        /* 14.f - Vertical block */
        .vert-center-block { 
            position:relative;
            text-align: center;
            padding: 0 1rem;

            .vert-center-item { 
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
            } // .vert-center-item
        } // .vert-center-block

        /*  14.g - User Actions - Cell inside a row  */

        .user-actions { 
            font-size: 1.5rem;
            background-color: $grey5;
            position: relative;
            min-height: 1px;
            height: 100%;
            position: absolute;
            right: 0%;
            top: 0%;
            display: flex;
            justify-content: center;
            align-items: center;
            color: $grey2;
            text-align: center;  

            @media screen and (max-width: 480px) { 
                position: relative;
                height: 2.25em;
                transition: all .2s linear;
				margin-top: 1rem;
				margin-bottom: -10px;
                width: 100%;
                -webkit-order: 99;
                -ms-flex-order: 99;
				order: 99;
				z-index: 1;
            }

            &:hover { 
                background-color: $grey4;
                transition: all .2s linear;
                color: $white;
                text-decoration: none;
                font-weight: 500;
            }

            .more-icon {
                -moz-osx-font-smoothing: grayscale;
                -webkit-font-smoothing: antialiased;
                display: inline-block;
                font-style: normal;
                font-variant: normal;
                text-rendering: auto;
                line-height: 1;
                font-family: 'Font Awesome 5 Pro';
                font-weight: 900;
                &:before{
                    content: "\f068";
                }
            }
        } // user-action

        a[aria-expanded="false"] .user-actions .more-icon:before{
            content: "\f067";
        }

        /* 14.h - v-icon Block in row */
        .icon-block { // Handle the v-icon stuff as a distinct block. Expects one of the category icons to be placed in this block.
            i.v-icon { 
                width: 5rem;
                height: 5rem;
                margin: 0;
                margin-right: auto;
                margin-left: auto;

                @media screen and (max-width: 479px) { 
                    width: 3rem;
                    height: 3rem;
                } // mq
            } // v-icon
        } // icon-block
    } // list-group-item
    
    //// 14.i - FontAwesome in row
    //.fa, .fal, .far, .fas, .more-icon {
    //    @media screen and (max-width: 479px) {
    //        padding: 0 .75rem;
    //    }
    //}

	p {
		margin-bottom: 0;
		~ p {
			margin-top: 0;
		}
	}
} // list-group

// TODO : Move into more logical LESS file, but due to some styling issues from the CSS waterfall, keep it here for now.
.label-pairs {
	p ~ p {
		margin-top: 0;
	}
}


// 14.d - More drawer
.job-information{
    padding-top: 0;
    padding-bottom: 0;
    @extend .text-light;

    @media screen and (max-width: 480px) {
        background-color: #efefef;
        font-weight: 300;
        clear:left;
        @include text-color($grey1);
        .more-block-inner{
            padding:1em 0;
            &:after, &:before{
                clear:both;
                display:table;
                content:'';
            }
        }
    } // mq

    .icon-list-block { // Extend the default icon-list-block
		@extend .margin-top-smaller;

        @media screen and (max-width: 480px) {

        } // mq
        // @include text-sm;

        > .fa, .fal, .fas, .fab {
            font-size: .8em;
            @media screen and (max-width: 480px) {
                padding-left: 0;
            } // mq
        } // fontAwesome

        &.label-pairs {
            > b {
                min-width: 5.5em; // Space allotted to label.
                display: inline-block;
            }
        } // .label-pairs
    } // .icon-list-block

} //  more-block