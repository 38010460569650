.wrapper { // used for full-width colour blocks with the content centered by the container block.

    .container { 
		background-color: transparent;

        &.container-intro {
			@extend .margin-bottom-largest;
        } // container-intro

        // 12.c 
        &.xxs-full-width, .xxs-no-padding { 
            @media screen and (max-width: 479px) { 
                padding-left: 0;
                padding-right: 0;
            } // mq
        } // sm-full-width
        &.xs-full-width, .xs-no-padding { 
            @media screen and (min-width:480px)  and (max-width: 766px) { 
                padding-left: 0;
                padding-right: 0;
            } // mq
        } // sm-full-width

        &.sm-full-width, .sm-no-padding { 
            @media screen and (min-width:768px) and (max-width: 992px) { 
                padding-left: 0;
                padding-right: 0;
            } // mq
        } // sm-full-width

    } // container

} // section wrappers


/* ****************************** */
/* 13 - CONTENT BLOCKS */
/* ****************************** */

// 13.a - Content Panels *********************************************
.content-panel { 
    padding: 0; 
    border: none;
	@extend .margin-bottom-larger;
	@extend .margin-top-larger;
    
    .content-label { 
        display: inline-block;
        position: absolute;
        @extend .bg-blue4;
        padding: .15rem 1rem;
        font-size: 1.2rem;
        font-weight: 500;
        color: #565d66;
		@include set-paddings(bottom, $spacing-large);
    } // v-content-label
    
    .content-body {
		@extend .padding-top-larger;
		@extend .padding-bottom-larger;
    } // content-body
} // v-content-panel

// 13.c - Remove Gutters from row
.row.no-gutters {
  margin-right: 0;
  margin-left: 0;
}
.row.no-gutters > [class^="col-"],
.row.no-gutters > [class*=" col-"] {
  padding-right: 0;
  padding-left: 0;
}

/* ***************************************** */
/*  ## -  Bootstrap Panel Overrides          */
/* ***************************************** */

.panel {
    @include shadow-1;
    @include border-grey5;
    .panel-heading {
        &.panel-heading-blue2 {
            .panel-title {
                font-size:  1rem;
            } // panel-title
        }
    } // .panel-heading

    &.panel-default {
        .panel-heading {
			background-image: none;
            &.panel-heading-blue2 {
                @extend .bg-blue2;
                color: $white;
            }
        } // .panel-heading
    } // panel-default
    
    &.panel-primary {
        > .panel-heading {
            background-color:   $primary;
            border-color:       $primary;
            .panel-title {}
            .panel-body {}
        }
    }
    
    &.panel-body {
        @extend .border-left;
        @extend .border-right;
        @extend .border-bottom;
        @include shadow-1;
        @include border-grey5;
        border: 1px solid $grey3;
        border-top-left-radius:0px;
        border-top-right-radius:0px;
        margin-bottom: 0px;
    } // panel-body
    
} // panel

.no-gutter {
	padding-left: 0;
	padding-right: 0;
}
.pull-left {
	float: left;
}
.pull-right {
	float: right;
}