@import "@bootstrap/scss/accordion";

$accordion-border-radius: 8px;

.accordion {
	&-item {
		margin-bottom: $spacing-medium;
		background-color: $white;
		border: 1px solid transparent;
		border-radius: $accordion-border-radius;
		box-shadow: 0 1px 1px rgba(0, 0, 0, .05);
	}

	&-body {
		padding: $spacing-large;
		@include clearfix;
	}

	&-header {
		padding: $spacing-small $spacing-medium;
		border-bottom: 1px solid transparent;
		@include border-top-radius(($accordion-border-radius - 1));

		> .dropdown .dropdown-toggle {
			color: inherit;
		}

		& + .list-group {
			.list-group-item:first-child {
				border-top-width: 0;
			}
		}
	}

	&-title {
		margin-top: 0;
		margin-bottom: 0;
		font-size: ceil(($font-size-base * 1.125));
		color: inherit;

		> a,
		> small,
		> .small,
		> small > a,
		> .small > a {
			color: inherit;
		}
	}

	&-footer {
		padding: $spacing-large;
		background-color: $white;
		border-top: 1px solid $grey5;
		@include border-bottom-radius(($accordion-border-radius - 1));
	}

	> .list-group,
	> .accordion-collapse > .list-group {
		margin-bottom: 0;

		.list-group-item {
			border-width: 1px 0;
			border-radius: 0;
		}

		// Add border top radius for first one
		&:first-child {
			.list-group-item:first-child {
				border-top: 0;
				@include border-top-radius(($accordion-border-radius - 1));
			}
		}
		// Add border bottom radius for last one
		&:last-child {
			.list-group-item:last-child {
				border-bottom: 0;
				@include border-bottom-radius(($accordion-border-radius - 1));
			}
		}
	}

	> .table,
	> .table-responsive > .table,
	> .accordion-collapse > .table {
		margin-bottom: 0;

		caption {
			padding-left: $spacing-large;
			padding-right: $spacing-large;
		}
	}
	// Add border top radius for first one
	> .table:first-child,
	> .table-responsive:first-child > .table:first-child {
		@include border-top-radius(($accordion-border-radius - 1));

		> thead:first-child,
		> tbody:first-child {
			> tr:first-child {
				border-top-left-radius: ($accordion-border-radius - 1);
				border-top-right-radius: ($accordion-border-radius - 1);

				td:first-child,
				th:first-child {
					border-top-left-radius: ($accordion-border-radius - 1);
				}
				td:last-child,
				th:last-child {
					border-top-right-radius: ($accordion-border-radius - 1);
				}
			}
		}
	}
	// Add border bottom radius for last one
	> .table:last-child,
	> .table-responsive:last-child > .table:last-child {
		@include border-bottom-radius(($accordion-border-radius - 1));

		> tbody:last-child,
		> tfoot:last-child {
			> tr:last-child {
				border-bottom-left-radius: ($accordion-border-radius - 1);
				border-bottom-right-radius: ($accordion-border-radius - 1);

				td:first-child,
				th:first-child {
					border-bottom-left-radius: ($accordion-border-radius - 1);
				}
				td:last-child,
				th:last-child {
					border-bottom-right-radius: ($accordion-border-radius - 1);
				}
			}
		}
	}
	> .accordion-body + .table,
	> .accordion-body + .table-responsive,
	> .table + .accordion-body,
	> .table-responsive + .accordion-body {
		border-top: 1px solid $grey4;
	}
	> .table > tbody:first-child > tr:first-child th,
	> .table > tbody:first-child > tr:first-child td {
		border-top: 0;
	}
	> .table-bordered,
	> .table-responsive > .table-bordered {
		border: 0;
		> thead,
		> tbody,
		> tfoot {
			> tr {
				> th:first-child,
				> td:first-child {
					border-left: 0;
				}
				> th:last-child,
				> td:last-child {
					border-right: 0;
				}
			}
		}
		> thead,
		> tbody {
			> tr:first-child {
				> td,
				> th {
					border-bottom: 0;
				}
			}
		}
		> tbody,
		> tfoot {
			> tr:last-child {
				> td,
				> th {
					border-bottom: 0;
				}
			}
		}
	}
	> .table-responsive {
		border: 0;
		margin-bottom: 0;
	}
}

.list-group + .accordion-footer {
	border-top-width: 0;
}


// Collapsable accordions
//
// Wrap a series of accordions in `.accordion-group` to turn them into an accordion with
// the help of our collapse JavaScript plugin.

.accordion-group {
	margin-bottom: $spacing-medium;

	// Tighten up margin so it's only between accordions
	.accordion {
		margin-bottom: 0;
		border-radius: $accordion-border-radius;

		+ .accordion {
			margin-top: 5px;
		}
	}

	.accordion-header {
		border-bottom: 0;

		+ .accordion-collapse > .accordion-body,
		+ .accordion-collapse > .list-group {
			border-top: 1px solid $grey4;
		}
	}

	.accordion-footer {
		border-top: 0;
		+ .accordion-collapse .accordion-body {
			border-bottom: 1px solid $grey4;
		}
	}
}
