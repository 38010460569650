@import "@styles/variables/color-variables";

@mixin generate-background-classes {
	@each $key, $color in $colors {
		.bg-#{"" + $key}.bg-#{"" + $key} {
			background-color: $color !important;
		}
	}
}

@include generate-background-classes;

@mixin bg-white {
	background-color: map-get($colors, white) !important;
}

@mixin bg-primary {
	background-color: $primary !important;
}

.bg-primary.bg-primary {
	@include bg-primary;
}

.no-box-shadow {
    box-shadow: none !important;
}