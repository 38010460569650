.talent-selection-offcanvas.offcanvas {

    @include talent-selection-styles;

    margin-top: 150px; // Header Height + Title
    border-radius: 12px 12px 0 0;
    @media (min-width: $screen-sm) {
        margin-top: 176px; // Header Height + Title
    }
    @media (min-width: $screen-md) {
        margin-top: 0;
        border-radius: 0;
    }

    #results-loader {
        display: block;
    }

    // Override widths to match #split-gradient-div layout on Job Form
    &.offcanvas-start,
    &.offcanvas-top,
    &.offcanvas-end,
    &.offcanvas-bottom {
        width: 100%;
        @media (min-width: $screen-md) {
            width: calc((100% - 970px) / 2 + 566px); // 970px=max container size for md screens
        }
        @media (min-width: $screen-lg) {
            width: calc((100% - 1170px) / 2 + 684px); // 1170px=max container size for lg screens
        }
    }

    .offcanvas-inner-wrapper {
        max-width: 100%;
        @media (min-width: $screen-lg) {
            max-width: 560px;
        }
    }

    .select-all-btn,
    .back-btn,
    #load-more-talents-btn {
        background: none;
        border: none;
        outline: none !important;
        font-weight: 500;
    }

    #load-more-talents-btn {
        color: $blue1;
        font-size: 16px;
        display: inline-flex;
        align-items: center;
        margin: 2rem auto 0;

        &:hover {
            color: $blue0;
        }

        i {
            margin-right: 0.8rem;
        }
    }

    .offcanvas-body {
        overflow-y: hidden;
    }

    .offcanvas-scrollable-container {
        overflow-y: auto;
    }

    .checkbox-row {
        ~ .checkbox-row {
            @media (min-width: $screen-md) {
                margin-top: 1rem;
            }
        }

        &:hover {
            background-color: $grey5;
            border-radius: 4px;
        }
    }

}