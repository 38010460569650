.tag {
    display: inline-block;
    margin-right: $spacing-smallest;
    margin-bottom: $spacing-smallest;
    padding: $spacing-smallest $spacing-smaller;
    background-color: $grey5;
    border-radius: 0.4rem;
    font-size: 1.2rem;
    font-weight: 500;
    line-height: 1;
    color: $text-dark;

    &-large {
        font-size: 1.4rem;
        padding: $spacing-smaller;
        margin-right: $spacing-smaller;
        margin-bottom: $spacing-smaller;
    }

    &.danger,
    &-danger {
        background-color: $red4;
    }

    &-blue {
        background-color: $blue1;
        color: $white;
    }
}
