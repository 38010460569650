// TODO : Verify these styles are needed. These were moved out of the [voicesTheme/siteHeader/siteHeader.scss] file once the header was rebuilt...

html {
	min-width: 320px;
}

#main-page {
	padding-bottom: 0;
	box-sizing: border-box;
	background: $grey;
}

.position-center {
	margin-left: auto !important;
	margin-right: auto !important;
}