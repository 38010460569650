@import "@bootstrap/scss/mixins/caret";
@import "@bootstrap/scss/dropdown";

/*
 * Dropdown Select React Component
*/
.select-dropdown {

    &__placeholder{
		color: $grey2;
    }

    &__menu {
        margin-top: $spacing-smallest !important;

        &-list {
            padding: 0 !important;
            border-radius: 4px !important;
        }
    }

    &__option {
        &--is-focused {
            background-color: $blue5 !important;
            color: $grey1 !important;
        }

        &--is-selected {
            background-color: $blue1 !important;
            color: $white !important;
        }
    }

    &__clear-indicator {
        padding: 0 !important;
    }

    &__control {
        min-width: auto;
        width: auto;
        font-size: 16px;
        min-height: 42px !important;
        color: $grey1;
    }

    &__menu {
        font-size: 16px;
        color: $grey1;
    }

    &__indicator-separator {
        display: none;
    }

    &__multi-value {
        background-color: $blue1 !important;
        color: $white !important;
        border-radius: 4px !important;

        &__remove {
            &:hover {
                background-color: $blue0 !important;
                color: $white !important;
                border-top-right-radius: 4px !important;
                border-bottom-right-radius: 4px !important;
            }
        }

        &__label {
            color: $white !important;
        }
    }
}
