@import '@styles/variables/global-variables';

#block-header{
	background-color:$blue1;
	#header-logo{
		margin:1.6rem 0;		
		display:inline-block;
    	width: 40px;
    	@media (min-width:769px){
    		width: 109px;
    	}
	}

	#welcome-block{
		text-align:center;
		color: $white;
	}
	#svg-circle{
		margin:0 auto;
		background-color: $white;
		border-radius:50%;
		display:flex;
		align-items:center;
		justify-content: center;
		height:63px;
		width:63px;
		margin-top:4.7rem;
		i{
			line-height:1;
			font-size:1rem;
		}
		@media (min-width:768px){
			height:84px;
			width:84px;
			margin-top:4.7rem;
		}
		@media (min-width:992px){
			margin-top:5.6rem;
		}
	}
	#welcome-name{
		margin-top:2.4rem;
		color: $white;
		@media (min-width:992px){
			margin-top:5.6rem;
		}
	}
	#get-started-options{
		margin: 1.2rem auto;
		width:80%;
		padding-bottom:151px;
		font-size:1.6rem;
		@media (min-width: 768px){
			padding-bottom:148px;
			font-size:1.8rem;
		}
		@media (min-width:992px){
			padding-bottom:140px;
		}
	}
}

#hello-options-wrapper{
	position:relative;
	top:-76px;

	@media (min-width:992px){
		display:flex;
		& > div{
			display:flex;
			flex-direction:column;
		}
	}

	.hello-option{
		background-color: $white;
		border-radius: 8px;
		box-shadow: 0 5px 12px 0 rgba(25, 34, 43, 0.1);
		border: solid 1px $grey5;
		overflow:hidden;
		margin-bottom:2.3rem;
		text-align:center;
		padding:2.4rem 1.6rem;
		h2{
			font-size:1.8rem;
			@media (min-width:768px){
				font-size:2rem;
			}
		}
		p{
			color:$grey1;
			font-size:1.4rem;
			@media (min-width:768px){
				font-size:1.6rem;
			}
			@media (min-width:992px){
				flex: 1 0 auto;
			}
		}
		a{
			@media (min-width:992px){
		    	align-self: center;
		    }
		}
		&.promoted-option{
			border-bottom:8px solid $blue1;
			@media(min-width:992px){
				margin-top:-2.4rem;
			}
		}
		@media(min-width:768px){
			margin-bottom:3.1rem;
		}
		@media(min-width:992px){
			margin-bottom:0;
			display: flex;
		    flex-direction: column;
		    flex: 1 0 auto;
		}
		@media (min-width:1200px){
			padding:3.2rem 3.8rem;
		}
	}
	#home-link-wrapper{
		text-align:center;
		@media (max-width:991px){
			position:absolute;
			top:-8.5rem;
			left:0;
			right:0;
		}
		@media (max-width:768px){
			top:-7.5rem;
		}
	}
	#home-link{
		font-size:1.2rem;
		font-weight:500;
		@media(max-width:991px){
			color: $white;
		}
		@media(min-width:992px){
			margin-top:1.3rem;
		}
	}
}