$card-margin-bottom: 4rem; //TODO: Reference the $spacing-largest variable once merged in
$card-padding: 3rem; // TODO: Reference the $spacing-larger variable once merged in
$card-border-color: $grey3;

@mixin card-styles {
	border: 1px solid $card-border-color;
	border-radius: 4px;
	padding: $card-padding;
	margin-bottom: $card-margin-bottom;
}

.card {
	@include card-styles;

	&.card-lg {
		padding: ($card-padding*2); // TODO: Reference $spacing-huge variable once merged in
		margin-bottom: ($card-margin-bottom*2); // TODO: Reference $spacing-gigantic variable once merged in
	}

	p {
		color: $grey1;
		margin-bottom: 2rem;
		+ p {
			margin-top: -1rem;
		}
	}

	h3, .h3 {
		font-size: 2.8rem;
		margin-bottom: 2rem;
		margin-top: 0;
	}

	h4, .h4 {
		font-size: 2rem;
		margin-bottom: 1.2rem;
		margin-top: 0;
		font-weight: 500;
	}
}

// Cards used as links
a.card {
	display: block;
	color: #000;
	margin-bottom: calc($card-margin-bottom/2); // TODO: Reference $spacing-large variable once merged in
	&:hover {
		color: $primary;
		i {
			transform: scale(1.12);
		}
		span {
			transform: translateX(.25em);
		}
	}
	i {
		margin-right: .5em;
		transition: transform 0.3s ease;
	}
	span {
		display:inline-block;
		transition: transform 0.3s ease;
	}
}

.page-status-card {
	&-icon {
		max-width: 48px;
	}

	.page-status-subheader {
		font-weight: 700;
	}
}