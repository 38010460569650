@use "sass:map";
@import '@styles/variables/buttons-variables';

@mixin btn-styles(
	$button-class-prefix: $button-class-prefix-default
) {
	font-weight: $button-font-weight;
	border-radius: 3px;
	transition: .1s all linear; // Return to default state faster than onHover transition.
	text-decoration: none;
	white-space: normal;
	margin-right: $button-spacing-between;

	// Inherit default button size styles into all buttons
	& {
		@include btn-size-styles(md);
	}

	// Hover Effect
	&:hover {
		text-decoration: none;
		transition: .2s all linear;
		box-shadow: none;
	}

	// Remove spacing between next button on last element
	&:last-child {
		margin-right: 0;
	}

	/**
	 * "disabled" Button State
	 */
	&.disabled, &[disabled] {
		opacity: 1; //overwrite bootstrap default styles of opacity: .65;
		box-shadow: none !important;
		cursor: not-allowed;

		> * {
			cursor: not-allowed;
		}
	}

	/**
	 * "active" Button State
	 */
	&:active {
		box-shadow: none;
	}

	// Outline Styles
	&.#{$button-class-prefix}-outline,
	&[class*="#{$button-class-prefix}-outline-"] {
		border-width: 1px;
		border-style: solid;
	}

	// Buttons with Icons
	i {
		margin-right: .4rem;
	}
}

/**
 * Styles for buttons in a :focus state
 */
@mixin btn-outline-styles($color: $primary, $border-width: 2px) {
	outline: none;
	box-shadow: 0 0 0 $border-width $color;
}

/**
 * Button Sizes
 *
 * Iterate through all button sizes and render out classes to the CSS by using our custom @include automate-btn-size-styles() mixin
 * Expected Result: .btn-xs, .btn-sm, .btn-md, .btn-lg, .btn-xl
 *
 */
@mixin automate-btn-size-styles(
	$button-class-prefix : $button-class-prefix-default,
	$button-sizes: $button-sizetags,
	$i : 1
) {
	@each $size in $button-sizes {
		// Button Size Styles
		&.#{$button-class-prefix}-#{$size} {
			@include btn-size-styles($size);
		}
	}
}

/**
 * Set Button Size Styles
 */
@mixin btn-size-styles($size) {
	padding: map.get($button-sizes-map, "button-padding-#{$size}-vertical") map.get($button-sizes-map, "button-padding-#{$size}-horizontal");
	line-height: map.get($button-sizes-map, "button-line-height-#{$size}");
	font-size: map.get($button-sizes-map, "button-font-size-#{$size}");
}

/**
 * Button Colours
 *
 * Iterate through all button colours and render out classes to the CSS by using our custom @include automate-btn-color-styles() mixin
 * Expected Result: .btn-default, .btn-primary, .btn-success, .btn-info, .btn-warning, .btn-danger
 *
 */
@mixin automate-btn-color-styles(
	$button-class-prefix : $button-class-prefix-default,
	$button-colors: $button-color-types,
	$i : 1
) {
	@each $color in $button-colors {
		$color-type: $color;

		&.#{$button-class-prefix}-#{"" + $color-type} {
			background-color: map.get($button-colors-map, "button-#{$color-type}-solid-bg-color-normal");
			border-color: transparent;

			&, a {
				color: map.get($button-colors-map, "button-#{$color-type}-solid-font-color-normal");
			}

			&:hover {
				background-color: map.get($button-colors-map, "button-#{$color-type}-solid-bg-color-hover");
				border-color: transparent;

				&, a {
					color: map.get($button-colors-map, "button-#{$color-type}-solid-font-color-hover");
				}
			}

			&:active {
				background-color: map.get($button-colors-map, "button-#{$color-type}-solid-bg-color-active");
				border-color: transparent;

				&, a {
					color: map.get($button-colors-map, "button-#{$color-type}-solid-font-color-active");
				}
			}

			&:focus {
				background-color: map.get($button-colors-map, "button-#{$color-type}-solid-bg-color-focus");
				border-color: transparent;
				@include btn-outline-styles(map.get($button-colors-map, "button-#{$color-type}-outline-color"));

				&, a {
					color: map.get($button-colors-map, "button-#{$color-type}-solid-font-color-focus");
				}
			}

			// TODO - Need to identify "Selected" button state, and style accordingly. See Zeplin file for details.

			&.disabled,
			&[disabled] {
				background-color: map.get($button-colors-map, "button-#{$color-type}-solid-bg-color-disabled") !important;
				border-color: transparent !important;

				&, a {
					color: map.get($button-colors-map, "button-#{$color-type}-solid-font-color-disabled") !important;
				}
			}
		}

		&.#{$button-class-prefix}-outline-#{"" + $color-type},
		&.#{$button-class-prefix}-#{"" + $color-type}.#{$button-class-prefix}-outline {
			background-color: rgba(
							red(map.get($button-colors-map, "button-#{$color-type}-outline-bg-color-normal")),
							green(map.get($button-colors-map, "button-#{$color-type}-outline-bg-color-normal")),
							blue(map.get($button-colors-map, "button-#{$color-type}-outline-bg-color-normal")),
							map.get($button-colors-map, "button-#{$color-type}-outline-bg-alpha-normal")
			);
			border-color: map.get($button-colors-map, "button-#{$color-type}-outline-color-normal");

			&, a {
				color: map.get($button-colors-map, "button-#{$color-type}-outline-font-color-normal");
			}

			&:hover {
				background-color: rgba(
								red(map.get($button-colors-map, "button-#{$color-type}-outline-bg-color-hover")),
								green(map.get($button-colors-map, "button-#{$color-type}-outline-bg-color-hover")),
								blue(map.get($button-colors-map, "button-#{$color-type}-outline-bg-color-hover")),
								map.get($button-colors-map, "button-#{$color-type}-outline-bg-alpha-hover")
				);
				border-color: map.get($button-colors-map, "button-#{$color-type}-outline-color-hover");

				&, a {
					color: map.get($button-colors-map, "button-#{$color-type}-outline-font-color-hover");
				}
			}

			&:active {
				background-color: rgba(
								red(map.get($button-colors-map, "button-#{$color-type}-outline-bg-color-active")),
								green(map.get($button-colors-map, "button-#{$color-type}-outline-bg-color-active")),
								blue(map.get($button-colors-map, "button-#{$color-type}-outline-bg-color-active")),
								map.get($button-colors-map, "button-#{$color-type}-outline-bg-alpha-active")
				);
				border-color: map.get($button-colors-map, "button-#{$color-type}-outline-color-active");

				&, a {
					color: map.get($button-colors-map, "button-#{$color-type}-outline-font-color-active");
				}
			}

			&:focus {
				background-color: rgba(
								red(map.get($button-colors-map, "button-#{$color-type}-outline-bg-color-focus")),
								green(map.get($button-colors-map, "button-#{$color-type}-outline-bg-color-focus")),
								blue(map.get($button-colors-map, "button-#{$color-type}-outline-bg-color-focus")),
								map.get($button-colors-map, "button-#{$color-type}-outline-bg-alpha-focus")
				) !important;
				border-color: map.get($button-colors-map, "button-#{$color-type}-solid-bg-color-focus");
				@include btn-outline-styles(map.get($button-colors-map, "button-#{$color-type}-outline-color"));

				&, a {
					color: map.get($button-colors-map, "button-#{$color-type}-outline-font-color-focus");
				}
			}

			&.disabled,
			&[disabled] {
				background-color: rgba(
								red(map.get($button-colors-map, "button-#{$color-type}-outline-bg-color-disabled")),
								green(map.get($button-colors-map, "button-#{$color-type}-outline-bg-color-disabled")),
								blue(map.get($button-colors-map, "button-#{$color-type}-outline-bg-color-disabled")),
								map.get($button-colors-map, "button-#{$color-type}-outline-bg-alpha-focus")
				) !important;
				border-color: map.get($button-colors-map, "button-#{$color-type}-outline-color-disabled") !important;

				&, a {
					color: map.get($button-colors-map, "button-#{$color-type}-outline-font-color-disabled") !important;
				}
			}
		}
	}
}

/**
 * Button Colours displayed on each Background Colour class
 */
@mixin automate-btn-color-adjustments-foreach-bg-styles(
	$button-class-prefix : $button-class-prefix-default,
	$button-colors : $button-color-types,
	$i : 1
) {
	@each $color in $button-colors {
		$color-type: $color;

		.bg-#{"" + $color-type} .#{$button-class-prefix} {
			/**
			 * Button (Solid)
			 */
			&.#{$button-class-prefix}-#{"" + $color-type} {
				background-color: darken(map.get($button-colors-map, "button-#{$color-type}-solid-bg-color-normal"), 8%);

				&:hover {
					background-color: darken(map.get($button-colors-map, "button-#{$color-type}-solid-bg-color-hover"), 8%);
				}

				&:active {
					background-color: darken(map.get($button-colors-map, "button-#{$color-type}-solid-bg-color-active"), 8%);
				}

				&:focus {
					background-color: darken(map.get($button-colors-map, "button-#{$color-type}-solid-bg-color-focus"), 8%);
				}

				&.disabled,
				&[disabled] {
					background-color: rgba(
									red(darken(map.get($button-colors-map, "button-#{$color-type}-solid-bg-color-disabled"), 5%)),
									green(darken(map.get($button-colors-map, "button-#{$color-type}-solid-bg-color-disabled"), 5%)),
									blue(darken(map.get($button-colors-map, "button-#{$color-type}-solid-bg-color-disabled"), 5%)),
									map.get($button-colors-map, "button-#{$color-type}-outline-bg-alpha-focus")
					) !important;
				}
			}

			&.#{$button-class-prefix}-outline-#{"" + $color-type},
			&.#{$button-class-prefix}-#{"" + $color-type}.#{$button-class-prefix}-outline {
				border-color: darken(map.get($button-colors-map, "button-#{$color-type}-solid-bg-color-normal"), 12%);

				&, a {
					color: darken(map.get($button-colors-map, "button-#{$color-type}-solid-bg-color-normal"), 12%);
				}

				&:hover {
					border-color: darken(map.get($button-colors-map, "button-#{$color-type}-solid-bg-color-normal"), 12%); //intentionally using the "normal" colour
					&, a {
						color: darken(map.get($button-colors-map, "button-#{$color-type}-solid-bg-color-normal"), 12%); //intentionally using the "normal" colour
					}
				}

				&:active {
					border-color: lighten(map.get($button-colors-map, "button-#{$color-type}-solid-bg-color-active"), 12%);

					&, a {
						color: lighten(map.get($button-colors-map, "button-#{$color-type}-solid-bg-color-active"), 12%);
					}
				}

				&:focus {
					border-color: darken(map.get($button-colors-map, "button-#{$color-type}-solid-bg-color-focus"), 12%);

					&, a {
						color: darken(map.get($button-colors-map, "button-#{$color-type}-solid-bg-color-focus"), 12%);
					}
				}
			}
		}
	}
}