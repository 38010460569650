
.job-forbidden {
    box-shadow: 0 0.5rem 1.2rem 0 rgba(25, 34, 43, 0.1);
    border: none;
    .deco-default-support {
        width: 8rem;
        height: 8rem;
        flex-grow: 0;
        margin: auto;
        padding: 1.6rem;
        background-color: #ecf1f5;
        border-radius: 50%;
        margin-bottom: 3.2rem;
    
        & .deco-icon-default-support {
            width: 4.8rem;
            height: 4.8rem;
            align-self: center;
            flex-grow: 0;
            object-fit: contain;
        }
    }
    .job-title {
        font-size: 3.6rem;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.25;
        letter-spacing: normal;
        text-align: left;
        margin-bottom: 2.4rem;
    }
    & .job-not-available {
        height: 2.7rem;
        font-family: Roboto;
        font-size: 1.8rem;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: normal;
        text-align: center;
        margin-bottom: 3.2rem;
    }
}


