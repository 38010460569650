@mixin automate-placeholder-styles($color: $placeholder) {
	/*
	 * Placeholder color.
	 * Do not group these rules. If one is invalid, the whole block fails.
	 */
	&::-webkit-input-placeholder {
		/* WebKit, Blink, Edge */
		color: $color;
	}
	&:-moz-placeholder {
		/* FF 4-18 */
		color: $color;
		opacity: 1; /* Firefox adds opacity so we reset it */
	}
	&::-moz-placeholder {
		/* FF 19+ */
		color: $color;
		opacity: 1; /* Firefox adds opacity so we reset it */
	}
	&:-ms-input-placeholder {
		/* IE 10+ */
		color: $color;
	}
	&::-ms-input-placeholder {
		/* Microsoft Edge */
		color: $color;
	}
	&::placeholder {
		/* modern browsers */
		color: $color;
	}
}