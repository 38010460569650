.radio {
    padding-left: 20px;

    label {
        display: inline-block;
        vertical-align: middle;
        position: relative;
        padding-left: 8px;

        &::before {
            content: "";
            display: inline-block;
            position: absolute;
            width: 17px;
            height: 17px;
            left: 0;
            margin-left: -17px;
            border: 1px solid #CCCCCC;
            border-radius: 50%;
            background-color: $white;
            -webkit-transition: border 0.15s ease-in-out;
            -o-transition: border 0.15s ease-in-out;
            transition: border 0.15s ease-in-out;
        }

        &::after {
            display: inline-block;
            position: absolute;
            content: " ";
            width: 11px;
            height: 11px;
            left: 3px;
            top: 7px;
            margin-left: -17px;
            border-radius: 50%;
            background-color: #555555;
            -webkit-transform: scale(0, 0);
            -ms-transform: scale(0, 0);
            -o-transform: scale(0, 0);
            transform: scale(0, 0);
            -webkit-transition: -webkit-transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
            -moz-transition: -moz-transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
            -o-transition: -o-transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
            transition: transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
        }
    }

    input[type="radio"] {
        opacity: 0;
        z-index: 1;
        cursor: pointer;

        &:focus {
            & + label {
                &::before {
                    outline: thin dotted;
                    outline: 5px auto -webkit-focus-ring-color;
                    outline-offset: -2px;
                }
            }
        }

        &:checked {
            & + label {
                &::after {
                    -webkit-transform: scale(1, 1);
                    -ms-transform: scale(1, 1);
                    -o-transform: scale(1, 1);
                    transform: scale(1, 1);
                }
            }
        }

        &:disabled {
            cursor: not-allowed;

            & + label {
                opacity: 0.65;

                &::before {
                    cursor: not-allowed;
                }
            }
        }
    }

    &-primary {
        input[type="radio"] {
            & + label {
                &::after {
                    background-color: #337AB7;
                }
            }

            &:checked {
                & + label {
                    &::before {
                        border-color: #337AB7;
                    }

                    &::after {
                        background-color: #337AB7;
                    }
                }
            }
        }
    }
}

@mixin radio-checkbox-input-group-styles($inputType) {
    display: block;
    margin-left: -0.4rem;
    margin-right: -0.4rem;
    margin-top: -0.8rem;

    // Fixes float:left on inner elements
    &::after {
        display: block;
        clear: both;
        content: "";
    }

    .#{$inputType}-input {
        display: inline-flex;
        position: relative;
        float: left;
        margin-left: 0.4rem;
        margin-right: 0.4rem;
        margin-top: 0.8rem;
    }
}

// ---------------------------------
// Input Type Styles
//
// Note: input is hidden. The
// customized styles are all
// displayed through the <label>
//
// input[type="radio"]
// input[type="checkbox"]
// ---------------------------------
@mixin radio-checkbox-input-styles() {
    position: relative;

    &[class$="input-no-label"] input + label {
        &::before {
            position: relative;
        }
    }

    input {
        height: 0;
        width: 0;
        position: absolute;
        opacity: 0;

        // ---------------------------------
        // -- Radio / Checkbox
        // ---------------------------------

        // Radio Styles
        &[type="radio"] + label {
            &::before {
                border-radius: 100%;
                cursor: pointer;
            }

            &::after {
                content: ' ';
                width: .8rem;
                height: .8rem;
                border-radius: 100%;
                padding-right: 0;
                margin-left: 0;
                top: 1.2rem;
                left: 1.6rem;
            }
        }

        // Checkbox Styles
        &[type="checkbox"] + label {
            &::before {
                border-radius: 0.4rem;
                cursor: pointer;
            }

            &::after {
                content: $fa-var-check;
                font-size: 1.2rem;
                top: 0.3rem;
                left: 1rem;
            }
        }

        // Radio & Checkbox (Generic Styles)
        + label {
            display: block;
            font-size: 1.4rem !important;
            font-weight: 500;
            margin: 0;
            cursor: pointer;
            line-height: 1.5 !important;
            padding: .5rem 1.2rem .4rem 3.6rem;
            border-radius: .4rem;
            border-width: 1px;
            border-style: solid;

            // Fixed Width Variants
            &.voice-age-label {
                width: 18rem;
            }

            &.gender-label {
                width: 10.5rem;
            }

            // Outline
            &::before {
                content: ' ';
                position: absolute;
                display: block;
                width: 1.6rem;
                height: 1.6rem;
                border-width: 1px;
                border-style: solid;
                cursor: pointer;
                top: 0.8rem;
                left: 1.2rem;
            }

            // Checked
            &::after {
                @extend .fa;
                content: $fa-var-circle;
                position: absolute;
                display: block;
            }
        }

        // Display the icon...
        & + label:hover,
        & + label:active,
        &:checked + label {
            &::after {
                opacity: 1;
            }
        }

        &:disabled:not(:checked),
        &.disabled:not(:checked) {
            + label:after {
                opacity: 0;
            }
        }

        // ---------------------------------
        // -- Disabled State
        // ---------------------------------
        &:disabled,
        &.disabled {
            opacity: 0.4;
            cursor: default;

            + label {
                cursor: not-allowed;
            }
        }

        // ---------------------------------
        // -- Colours
        // ---------------------------------

        // Default
        & {
            + label {
                border-color: $grey4;
                color: $grey1;

                &::before {
                    border-color: $grey4;
                }

                &::after {
                    opacity: 0;
                }
            }

            &[type="radio"] + label::after {
                background-color: $grey4;
            }

            &[type="checkbox"] + label::after {
                color: $grey4;
            }
        }

        // Hover State
        & {
            + label:hover {
                background-color: $grey5;
                border-color: $grey5;
                color: $text-dark;

                &::before {
                    border-color: $grey3;
                }
            }

            &[type="radio"] + label:hover::after {
                background-color: $grey3;
            }

            &[type="checkbox"] + label:hover::after {
                color: $grey3;
            }
        }

        // Active (on click) State
        & {
            + label:active {
                background-color: $grey5;
                border-color: $grey5;
                color: $text-dark;

                &::before {
                    border-color: $grey3;
                }
            }

            &[type="radio"] + label:active::after {
                background-color: $grey3;
            }

            &[type="checkbox"] + label:active::after {
                color: $grey3;
            }
        }

        // Selected/Checked State
        &:checked + label {
            background-color: $blue1;
            border-color: $blue1;
            color: $white;
            background-blend-mode: screen;
        }

        // Selected Radio State
        &[type="radio"]:checked + label {
            &::before {
                border-color: $white;
            }

            &::after {
                background-color: $white;
            }
        }

        // Selected Checkbox State
        &[type="checkbox"]:checked + label {
            &::before {
                border-color: $white;
                background-color: $white;
            }

            &::after {
                color: $blue1;
            }
        }

        &[type="radio"],
        &[type="checkbox"] {
            &:focus,
            &:focus-visible {
                + label {
                    border-color: $blue1;
                    outline: $blue1 auto 1px;
                }
            }
        }
    }

    &-round {
        input {
            + label {
                border-radius: 2.4rem;
                padding: 0.5rem 1.2rem;
                border: 1px solid $blue1;

                &::before,
                &::after {
                    display: none !important;
                }
            }

            &:checked + label {
                background-color: $blue1;
                border-color: $blue1;
                color: $white;
            }
        }
    }
}

.radio-input-group {
    @include radio-checkbox-input-group-styles(radio);
}

.checkbox-input-group {
    @include radio-checkbox-input-group-styles(checkbox);
}

input[type="radio"] {
    height: 0;
    width: 0;
    position: absolute;
    opacity: 0;
}

.radio-input,
.checkbox-input {
    @include radio-checkbox-input-styles();
}
