@import "@bootstrap/scss/list-group";

/**
 * List Group Items not inside a layout-body should have different border color
 */
.list-group {
	@include shadow-1;
	border-radius: 8px;
	margin-bottom: 20px;

	> .list-group-item,
	> .list-group-item-with-arrow {
		margin-bottom: -1px;
		background-color: $white;
		@include border-grey5;
		@extend .border-bottom-grey4;
		@extend .border-top-grey4;

		&:first-child {
			@extend .border-top-radius-2;
			border-top-color: $grey5;
		}

		&:last-child {
			@extend .border-bottom-radius;
			@extend .border-bottom-grey5;
		}

		&.layout-body {
			@include border-grey5;
		}
	}
}

.list-group-item.list-group-item-selected {
	background-color: $grey5;
}

/**
 * Clickable List Group Item
 */
.list-group-item-with-arrow {
	display: block;
	padding-top: $spacing-medium;
	padding-right: ($spacing-medium + .75rem + $spacing-medium);
	padding-bottom: $spacing-medium;
	padding-left: $spacing-medium;
	position: relative;
	transition: 0.3s ease background-color;
	@extend .border-top-grey4;

	@media (min-width:$screen-sm) {
		padding-top: $spacing-large;
		padding-right: ($spacing-large + .75rem + $spacing-large);
		padding-bottom: $spacing-large;
		padding-left: $spacing-large;
	}

	&:last-child {
		@extend .border-bottom-grey4;
	}

	&:hover {
		cursor: pointer;
		background-color: $grey5;
	}

	// Other inner clickable elements positioned above the arrow-link
	[href],
	[data-bs-toggle],
	.btn,
	button {
		position: relative;
		z-index: 2; // ensure all other inner elements are above the arrow-link
		pointer-events: all;
		cursor: pointer;
	}

	// Links without an href (ie - perhaps an avatar without a link?) should not take over the clickevent of the arrow-link
	a:not([href]) {
		pointer-events: none;
	}

	.list-group-item-arrow-link {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;

		&::after {
			@extend .far;
			position: absolute;
			top: 50%;
			right: $spacing-medium;
			transform: translateY(-50%);
			font-size: 2rem;
			content: $fa-var-angle-right;
			color: $grey1;
			@media (min-width:$screen-sm) {
				right: $spacing-large;
			}
		}
	}
}