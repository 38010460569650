@use "sass:map";

$header-dropdown-padding: 12px;
$header-spacing: 16px;
$hamburger-line-width: 24px;
$hamburger-padding: 5px;
$hamburger-line-height: 3px;

@mixin hamburger-button-line-styles($hamburger-line-color:$white) {
	position: absolute;
	top: 50%;
	left: 50%;
	width: $hamburger-line-width;
	height: $hamburger-line-height;
	background-color: $hamburger-line-color;
	transition: transform 0.3s ease, opacity 0.3s ease;
}

@mixin hamburger-line-1 {
	transform: translate(-50%, -50%) rotate(-45deg);
	-webkit-transform: translate(-50%, -50%) rotate(-45deg);
	-moz-transform: translate(-50%, -50%) rotate(-45deg);
	-ms-transform: translate(-50%, -50%) rotate(-45deg);
	-o-transform: translate(-50%, -50%) rotate(-45deg);
}

@mixin hamburger-line-2 {
	opacity: 0;
}

@mixin hamburger-line-3 {
	transform: translate(-50%, -50%) rotate(45deg);
	-webkit-transform: translate(-50%, -50%) rotate(45deg);
	-moz-transform: translate(-50%, -50%) rotate(45deg);
	-ms-transform: translate(-50%, -50%) rotate(45deg);
	-o-transform: translate(-50%, -50%) rotate(45deg);
}

@mixin hamburger-x-lines {
	.hamburger-line-1 {
		@include hamburger-line-1;
	}
	.hamburger-line-2 {
		@include hamburger-line-2;
	}
	.hamburger-line-3 {
		@include hamburger-line-3;
	}
}

@mixin header-dropdown-link-active-styles() {
	color: $text-dark;
	background: $grey5;
	border-radius: 4px;
	text-decoration: none;
}

@mixin header-dropdown-link-styles(
	$font-size:14px,
	$hoverable:true
) {
	font-size: $font-size;
	line-height: 1.5;
	padding: 8px $header-dropdown-padding;
	display: block;
	color: $grey1;
	&:hover {
		@if $hoverable {
			@include header-dropdown-link-active-styles();
		}
	}
	&.active {
		@include header-dropdown-link-active-styles();
	}
}

@mixin hamburger-button-styles($hamburger-state:'default') {
	background: none;
	border: none;
	position: relative;
	z-index: 2;
	width: ($hamburger-line-width + ($hamburger-padding*2));
	height: ($hamburger-line-width + ($hamburger-padding*2));
	padding: 0;
	& {
		//@if ($hamburger-state == 'default') {
		//	@extend #hamburger-default-lines;
		//}

		@if ($hamburger-state == 'active') {
			@include hamburger-x-lines;
		}
	}
	&:focus {
		outline: none;
	}
	[class^='hamburger-line'] {
		@include hamburger-button-line-styles();
	}
}

.action-list-container {
	display: flex;
}

.action-list {
	position: relative;
	display: inline-block;

	~ .action-list {
		margin-left: $spacing-smaller;
	}
}

// Button - displayed as status pill, with caret
button.action-list-status-btn {
	display: inline-block;
	font-size: 1.2rem;
	font-weight: 500;
	line-height: 1;
	text-align: center;
	border-radius: 3.75rem;
	border: none;
	padding: .4rem .8rem;

	&.status-grey5 {
		background-color: $grey5;
	}
	&.status-grey4 {
		background-color: $grey4;
	}
	&.status-ice5 {
		background-color: $ice5;
	}
	&.status-ice4 {
		background-color: $ice4;
	}
}

// Button - with text & icon
button.action-list-btn {
	@extend .btn;
	@extend .btn-outline-default;

	// btn overrides
	margin-right: 0;
	box-shadow: none !important;
	outline: none !important;

	// style overrides to look more like a Bootstrap .form-control element
	border-color: $grey4;

	// Align icon and inner-text using flex
	display: inline-flex;
	align-items: center;
	justify-content: space-between;

	&:focus {
		background-color: $grey4 !important;
	}

	i {
		float: none !important;
		line-height: 1 !important;
		padding-right: 0 !important;
		&.fa-exchange-alt {
			transform: rotate(90deg);
		}
	}

	& > i {
		margin-right: 1rem !important;
	}

	span ~ i {
		margin-left: 1rem !important;
		margin-right: 0 !important;
	}
}

.action-list.open button.action-list-btn {
	background-color: map.get($button-colors-map, "button-default-solid-bg-color-hover");
	border-color: transparent;
	color: map.get($button-colors-map, "button-default-solid-font-color-hover");
	box-shadow: none;
}

// Button (disabled)
button.action-list-btn-disabled {
	@extend .form-control;
	text-align: left;
}

// 3 Dotted Icon (Triggers the dropdown like a regular button)
.context-menu-action-list-btn {
	border-radius: 100%;
	background-color: $white;
	border: 1px solid $grey3;
	width: 4rem;
	height: 4rem;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	transition: background-color 0.3s ease-in-out;
	i {
		color: $grey1;
	}
	&:hover {
		background-color: $grey4;
	}
}

i.action-list-btn,
.context-menu-action-list-btn i {
	position: relative;
	cursor: pointer;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	width: 3.8rem; // dotted-icon needs to be big enough to touch/interact with while on smaller devices.
	height: 3.8rem;

	&:before {
		position: relative;
		z-index: 1;
	}

	&:after {
		position: absolute;
		top: 0;
		left: 0;
		content: ' ';
		width: 100%;
		height: 100%;
		border-radius: 100%;
		transform: scale(0);
		opacity: 0;
		transition: transform 0.15s ease-in-out, opacity 0.3s ease, background-color 0.3s ease;
	}
}

// Hover Events for on Desktop (ignore Tablet on Landscape mode)
body.ua-desktop i.action-list-btn:hover {
	&:after {
		background-color: $grey5;
		transform: scale(1);
		opacity: 1;
	}
}

.action-list.open i.action-list-btn {
	&:after {
		background-color: $grey4 !important;
		transform: scale(1);
		opacity: 1;
	}
}

// Dropdown Element
// NOTE: built without React, so component structure is slightly different)
.action-list {
	&.open .action-list-dropdown {
		display: block;
	}
	&.action-list-right .action-list-dropdown {
		right: 0;
	}
	.btn.active {
		box-shadow: none;
	}
}

.action-list-dropdown {
	display: none; // will become visible with .show
	position: absolute;
	z-index: 4;
	margin-top: 0.7rem;
	border: 1px solid $grey5;
	border-radius: $border-radius-normal-size;
	background-color: $white;
	width: 26rem;
	@include shadow-1;

	// In case .action-list-dropdown is rendered via React, using the Bootstrap Popover component, we need to override some styles here
	&.popover {
		margin-top: 0 !important;
		.popover-arrow {
			display: none !important;
		}
	}

	&.show {
		display: block;
	}

	ul {
		padding: $spacing-small;
		margin-bottom: 0;
	}

	li {
		display: block;
		position: relative;

		// -- Action Button Styles
		a, button {
			display: block;
			padding: $spacing-smaller $spacing-small;
			background-color: $white;
			border-radius: $border-radius-normal-size;
			text-align: left;

			&.active {
				background-color: $grey5;
			}

			&:not(.link) {
				color: $grey1;
				@include header-dropdown-link-styles($font-size: 1.6rem);
			}

			transition: background-color 0.3s ease;

			&.link {
				font-size: 1.4rem;

				&:hover {
					border-bottom: none;
					background: none;
				}
			}

			&:focus {
				text-decoration: none;
			}

			&[disabled] {
				color: $grey3 !important;
				cursor: not-allowed !important;

				&:hover {
					background: transparent;
				}
			}
		}

		button {
			width: 100%;
			background: none;
			border: none;
			outline: none;
		}

		// -- List item styles with description text
		span {
			&.title {
				display: block;
				font-weight: $font-weight-medium;
				font-size: 1.6rem;
				color: $text-dark;
			}

			&.description {
				display: block;
				font-weight: $font-weight-normal;
				font-size: 1.4rem;
				color: $grey1;
			}
		}

		// -- Selectable ListItem Styles
		// -- ie: to replicate a custom <select> dropdown
		input[type="radio"] + label:hover,
		input[type="radio"]:checked + label {
			background-color: $grey5;
		}

		input[type="radio"] {
			opacity: 0;
			width: 0;
			height: 0;
			overflow: hidden;
			position: absolute;

			&:not(:checked) + label:hover {
				cursor: pointer;
			}

			+ label {
				display: block;
				margin-bottom: $spacing-small;
				padding: $spacing-smaller $spacing-small;
				background-color: $white;
				border-radius: $border-radius-normal-size;
				transition: background-color 0.3s ease;
			}
		}

		// Remove bottom margin on last option selector
		&:last-child input[type="radio"] + label {
			margin-bottom: 0;
		}
	}
}

#job-header-action-list.action-list {
	.action-list-dropdown {
		z-index: 3;
	}
}


/**********************************************
 ****          Custom Action Lists         ****
 **********************************************/
.action-list.feedbacks-action-list {
	// Mobile Fixes, so action list is left aligned
	&.action-list-right {
		@media (max-width: $screen-xs-max) {
			float: none !important;
			padding-bottom: $spacing-large;
		}

		.action-list-dropdown {
			@media (max-width: $screen-xs-max) {
				left: 0;
				right: auto;
			}
		}
	}
}

.action-list.member-role-selector .action-list-dropdown,
.action-list-dropdown.member-role-selector-dropdown {
	width: 100%;
	@media (min-width: $screen-sm) {
		max-width: 34rem;
	}
}

.action-list {
	&.favorites-action-list,
	&.logged-out-favorites-action-list {
		position: relative;

		// Heart Button
		.action-list-btn {
			width: 3.2rem;
			height: 3.2rem;
			color: $grey4;
		}

		// Solid Heart
		.fas.fa-heart {
			color: $red1;

			&:hover {
				color: $red0;
			}
		}

		// Outlined Heart
		.far.fa-heart {
			&:hover {
				font-weight: 900; // becomes solid heart
				color: $grey4;
			}
		}

		&.open .action-list-btn {
			&.fas::after {
				background-color: $red4 !important;
				transform: scale(1.2);
			}

			&.far::after {
				opacity: 0;
			}
		}

		// Bootstrap tooltip
		.tooltip-inner {
			white-space: nowrap;
			max-width: none;
		}

		// Hide/Show content displays
		.favorites-action-list-dynamic-content-container,
		.favorites-action-list-dynamic-form-container {
			display: none;

			&.active {
				display: block;
			}
		}

		// Dropdown
		.action-list-dropdown {
			@media (min-width: $screen-xs) {
				&::before {
					position: absolute;
					bottom: 100%;
					right: 10px;
					border: solid transparent;
					content: " ";
					height: 0;
					width: 0;
					pointer-events: none;
					border-bottom-color: $white;
					border-width: 4px;
				}
			}

			ul {
				padding-top: 0;
				padding-right: 0;
				padding-bottom: 0; // no longer need padding bottom, since there is a max height on the scrollable container
				margin-top: 1.2rem;
				margin-right: 1.2rem; // to allow for scrollbar to be pushed over
				margin-bottom: 1.2rem;
				max-height: (.2rem + 3.2rem + 4rem + 4rem);
				overflow: auto;

				&.list-with-1-item {
					max-height: (.2rem + 3.2rem);
				}

				&.list-with-2-items {
					max-height: (.2rem + 3.2rem + 4rem);
				}

				&::-webkit-scrollbar {
					-webkit-appearance: none;
					width: 7px;
				}

				&::-webkit-scrollbar-thumb {
					border-radius: 4px;
					background-color: rgba(0, 0, 0, .5);
					-webkit-box-shadow: 0 0 1px rgba(255, 255, 255, .5);
				}
			}

			li {
				~ li {
					margin-top: 0.8rem;
				}

				button[data-action] {
					position: relative;
					display: flex;
					align-items: center;
					padding: .4rem;
					// Checkbox is processing request. Disable it.
					&[disabled] {
						color: $text-dark !important; // override (other) important styles
						&.processing::after {
							@extend .far;
							content: $fa-var-spinner;
							position: absolute;
							top: 10px;
							right: 10px;
							font-size: 1.4rem;
							animation: fa-spin 2s linear infinite;
						}
					}
				}

				.checkbox {
					position: relative;
					width: 20px;
					height: 20px;
					border-radius: $border-radius-normal-size;
					border: 1px solid $grey3;
					margin: 0 .8rem 0 0;

					&::before {
						@extend .far;
						position: absolute;
						top: 50%;
						left: 50%;
						transform: translate(-50%, -50%);
						font-size: 1.4rem;
					}

					&.checked {
						border-color: $blue1;
						background-color: $blue1;

						&::before {
							color: $white;
							content: $fa-var-check;
						}

						+ span {
							font-weight: bold;
						}
					}
				}
			}

			// Loader
			.loader-container {
				display: flex;
				align-items: center;
				justify-content: center;
				@media (max-width: ($screen-xs - 1px)) {
					position: fixed;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
				}
				@media (min-width: $screen-xs) {
					height: 13.8rem; // match height for max-height of ul
					&.loader-container-with-1-item {
						height: (13.8rem - 8rem); // match height for max-height of ul
					}
					&.loader-container-with-2-items {
						height: (13.8rem - 4rem); // match height for max-height of ul
					}
				}
			}

			.loader {
				font-size: 1.6rem;
				color: $grey1;
			}
		}

		// Mobile Dropdown UI
		.action-list-dropdown {
			@media (max-width: ($screen-xs - 1px)) {
				position: fixed;
				z-index: ($header-zindex + 100); // i dont even know why this needs to be so high... but it do :(
				top: 0;
				right: 0;
				width: 100%;
				height: 100%;
				margin-top: 0;
				border-radius: 0;
				border: none;
				transform: translateX(100%);
				transition: 0.3s ease transform;
			}

			ul {
				@media (max-width: ($screen-xs - 1px)) {
					position: absolute;
					max-height: 100%;
					top: 97px; // height of header container
					bottom: 149px; // height of footer contianer with the sticky buttons
					left: 0;
					right: 0;
				}
			}
		}

		&.open.animate .action-list-dropdown {
			@media (max-width: ($screen-xs - 1px)) {
				transform: translateX(0);
			}
		}

		.favorites-action-list-mobile-dropdown-header {
			position: relative;
			z-index: 2;
			margin-top: 4rem;
			display: flex;
			align-items: center;
			padding-left: 1.8rem;
			padding-right: 1.8rem;

			@media (min-width: $screen-xs) {
				display: none;
			}

			button.close {
				margin-left: auto;
				border-radius: 100%;
				padding: 0;
				width: 3.6rem;
				height: 3.6rem;
				flex-shrink: 0;
				margin-right: 0;
			}
		}

		.favorites-action-list-mobile-dropdown-footer {
			position: absolute;
			bottom: 0;
			left: 0;
			width: 100%;
			padding: 1.8rem;
			border-top: 1px solid $grey5;

			@media (min-width: $screen-xs) {
				display: none;
			}

			.btn {
				width: 100%;

				~ .btn {
					margin-top: 1.2rem;
				}
			}
		}

		// "Create New Favorite List" Button
		button.create-new-favorite-list-btn {
			font-weight: bold;
			color: $blue1 !important;
			width: 100%;
			padding: 1.5rem;
			background: none;
			box-shadow: none;
			outline: none;
			border: none;
			border-top: 1px solid $grey5;
			@media (max-width: ($screen-xs - 1px)) {
				display: none;
			}

			&[disabled] {
				color: $grey2 !important;
				background-color: $grey5;
				opacity: .5;
			}

			i {
				margin-right: .4rem;
			}
		}

		// Form Styles
		form[name="favorite_list_form"] {
			padding: 1.8rem;
			@media (min-width: $screen-xs) {
				padding: 1.2rem;
			}

			.form-group {
				text-align: left;
				position: relative;
				padding-bottom: 15px; // enough height for error message
				margin-bottom: 0;
			}

			textarea {
				max-width: 100%;
				height: 4.8rem;
				resize: none;
				overflow-y: hidden;
			}

			.btns {
				margin-top: 1.2rem;
				display: flex;

				&.btns-align-left {
					justify-content: flex-start;
				}

				&.btns-align-right {
					justify-content: flex-end;
				}

				@media (max-width: ($screen-xs - 1px)) {
					position: fixed;
					width: 100%;
					bottom: 0;
					left: 0;
					padding: 1.8rem;
					z-index: 2; // will cover up the "Done" button
					display: flex;
					flex-direction: column-reverse;
				}

				.btn {
					@media (max-width: ($screen-xs - 1px)) {
						padding: 1.2rem 2.4rem;
						line-height: 2.4rem;
						font-size: 1.8rem;
						margin-right: 0;
						margin-top: 1.2rem;
					}
					@media (min-width: $screen-xs) {
						padding: .4rem 1.2rem;
						line-height: 1.6rem;
						font-size: 1.4rem;
					}
				}
			}

			.form-input-message-error {
				position: absolute;
			}
		}
	}
}

@mixin loading-skeleton-background-animation {
	transition: 0.4s background-color;
	animation: 1s ease infinite alternate loading-skeleton-background;
}

@keyframes loading-skeleton-background {
	from {
		background-color: mix($white, $grey4);
	}
	to {
		background-color: $grey4;
	}
}