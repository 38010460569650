/*********************************************/
/*           Default Status Labels           */
/*********************************************/

.status-primary {
	@extend .status-offering;
}

.status-success {
	color: $success;
}

.status-warning {
	color: $warning;
}

.status-info {
	color: $info;
}

.status-error {
	color: $danger;
}

.status-alert {
	color: $warning;
}

// Grey / Default Label
.status-default {
	padding: 2px 1em;
	border-radius: 3.75em;
	border: 1px solid $grey5;
	color: $text-dark;
	background-color: $grey5;
	text-transform: uppercase;
	letter-spacing: 1px;
	font-size: 75%;
	-webkit-font-smoothing: antialiased;
	font-weight: 700;
	-moz-osx-font-smoothing: grayscale;

	&.bg-grey1 {
		@extend .bg-grey1;
		border: 2px solid $grey1;
		color: $white;
	}

	&.bg-grey2 {
		@extend .bg-grey2;
		border: 2px solid $grey2;
		color: $white;
	}
}

/*********************************************/
/*             Job Status Labels             */
/*********************************************/

// yellow
.status-draft {
	padding: 2px 1em;
	border-radius: 3.75em;
	border: 1px solid $warning;
	color: $warning;
	background-color: transparent;
	text-transform: uppercase;
	//font-family: 'Roboto Condensed', 'Helvetica Nueue', arial, sans-serif; // TODO : Update styling on this component. The only font that should be getting used now is just "Roboto".
	letter-spacing: 1px;
	font-size: 75%;
	-webkit-font-smoothing: antialiased;
	font-weight: 700;
	-moz-osx-font-smoothing: grayscale;
}

// New yellow Client AM
.status-fs-draft {
	padding: 2px 1em;
	border-radius: 3.75em;
	border: 1px solid $warning;
	color: $warning;
	background-color: transparent;
	text-transform: uppercase;
	//font-family: 'Roboto Condensed', 'Helvetica Nueue', arial, sans-serif; // TODO : Update styling on this component. The only font that should be getting used now is just "Roboto".
	font-size: 75%;
	-webkit-font-smoothing: antialiased;
	font-weight: 700;
	-moz-osx-font-smoothing: grayscale;
}

// yellow
.status-am-draft {
	padding: 2px 1em;
	border-radius: 3.75em;
	border: 1px solid $warning;
	color: $warning;
	background-color: transparent;
	text-transform: uppercase;
	//font-family: 'Roboto Condensed', 'Helvetica Nueue', arial, sans-serif; // TODO : Update styling on this component. The only font that should be getting used now is just "Roboto".
	font-size: 75%;
	-webkit-font-smoothing: antialiased;
	font-weight: 700;
	-moz-osx-font-smoothing: grayscale;
}

// New yellow
.status-in_review {
	padding: 2px 1em;
	border-radius: 3.75em;
	border: 1px solid $warning;
	color: $warning;
	background-color: transparent;
	text-transform: uppercase;
	//font-family: 'Roboto Condensed', 'Helvetica Nueue', arial, sans-serif; // TODO : Update styling on this component. The only font that should be getting used now is just "Roboto".
	font-size: 75%;
	-webkit-font-smoothing: antialiased;
	font-weight: 700;
	-moz-osx-font-smoothing: grayscale;
}

// Support Review Yellow
.status-am-review {
	padding: 2px 1em;
	border-radius: 3.75em;
	border: 1px solid $warning;
	color: $warning;
	background-color: transparent;
	text-transform: uppercase;
	//font-family: 'Roboto Condensed', 'Helvetica Nueue', arial, sans-serif; // TODO : Update styling on this component. The only font that should be getting used now is just "Roboto".
	font-size: 75%;
	-webkit-font-smoothing: antialiased;
	font-weight: 700;
	-moz-osx-font-smoothing: grayscale;
}

// yellow
.status-support-review {
	padding: 2px 1em;
	border-radius: 3.75em;
	border: 1px solid $warning;
	color: $warning;
	background-color: transparent;
	text-transform: uppercase;
	//font-family: 'Roboto Condensed', 'Helvetica Nueue', arial, sans-serif; // TODO : Update styling on this component. The only font that should be getting used now is just "Roboto".
	font-size: 75%;
	-webkit-font-smoothing: antialiased;
	font-weight: 700;
	-moz-osx-font-smoothing: grayscale;
}

// yellow
.status-pending {
	padding: 2px 1em;
	border-radius: 3.75em;
	border: 1px solid $warning;
	color: $warning;
	background-color: transparent;
	//font-family: 'Roboto Condensed', 'Helvetica Nueue', arial, sans-serif; // TODO : Update styling on this component. The only font that should be getting used now is just "Roboto".
	text-align: center;
	text-transform: uppercase;
	font-size: 75%;
	letter-spacing: 1px;
	-webkit-font-smoothing: antialiased;
	font-weight: 700;
	-moz-osx-font-smoothing: grayscale;
}

// New Green Client
.status-denied {
	padding: 2px 1em;
	border-radius: 3.75em;
	border: 1px solid $danger;
	color: $danger;
	background-color: transparent;
	text-transform: uppercase;
	font-size: 75%;
	//font-family: 'Roboto Condensed', 'Helvetica Nueue', arial, sans-serif; // TODO : Update styling on this component. The only font that should be getting used now is just "Roboto".
	text-align: center;
	letter-spacing: 1px;
	-webkit-font-smoothing: antialiased;
	font-weight: 700;
	-moz-osx-font-smoothing: grayscale;
}

// New green Open - Hiring Talent
.status-hiring {
	padding: 2px 1em;
	border-radius: 3.75em;
	border: 1px solid $success;
	color: $white;
	background-color: $success;
	text-transform: uppercase;
	//font-family: 'Roboto Condensed', 'Helvetica Nueue', arial, sans-serif; // TODO : Update styling on this component. The only font that should be getting used now is just "Roboto".
	font-size: 75%;
	letter-spacing: 1px;
	-webkit-font-smoothing: antialiased;
	font-weight: 700;
	-moz-osx-font-smoothing: grayscale;
}

// New Blue
.status-fs-awaiting-auditions {
	padding: 2px 1em;
	border-radius: 3.75em;
	border: 1px solid $success;
	color: $white;
	background-color: $success;
	text-transform: uppercase;
	//font-family: 'Roboto Condensed', 'Helvetica Nueue', arial, sans-serif; // TODO : Update styling on this component. The only font that should be getting used now is just "Roboto".
	font-size: 75%;
	letter-spacing: 1px;
	-webkit-font-smoothing: antialiased;
	font-weight: 700;
	-moz-osx-font-smoothing: grayscale;
}

// New Blue
.status-offering {
	padding: 2px 1em;
	border-radius: 3.57em;
	border: 1px solid $primary;
	color: $white;
	background-color: $primary;
	text-transform: uppercase;
	//font-family: 'Roboto Condensed', 'Helvetica Nueue', arial, sans-serif; // TODO : Update styling on this component. The only font that should be getting used now is just "Roboto".
	font-size: 75%;
	letter-spacing: 1px;
	-webkit-font-smoothing: antialiased;
	font-weight: 700;
	-moz-osx-font-smoothing: grayscale;
}

// New Blue - Talent
.status-fs-finalizing-agreement {
	padding: 2px 1em;
	border-radius: 3.57em;
	border: 1px solid $primary;
	color: $white;
	background-color: $primary;
	text-transform: uppercase;
	//font-family: 'Roboto Condensed', 'Helvetica Nueue', arial, sans-serif; // TODO : Update styling on this component. The only font that should be getting used now is just "Roboto".
	font-size: 75%;
	letter-spacing: 1px;
	-webkit-font-smoothing: antialiased;
	font-weight: 700;
	-moz-osx-font-smoothing: grayscale;
}

// new Blue- Client
.status-working {
	padding: 2px 1em;
	border-radius: 3.75em;
	border: 1px solid $primary;
	color: $primary;
	background-color: $blue5;
	text-transform: uppercase;
	//font-family: 'Roboto Condensed', 'Helvetica Nueue', arial, sans-serif; // TODO : Update styling on this component. The only font that should be getting used now is just "Roboto".
	font-size: 75%;
	letter-spacing: 1px;
	-webkit-font-smoothing: antialiased;
	font-weight: 700;
	-moz-osx-font-smoothing: grayscale;
}

// New green
.status-fs-working {
	padding: 2px 1em;
	border-radius: 3.75em;
	border: 1px solid $primary;
	color: $primary;
	background-color: $blue5;
	text-transform: uppercase;
	//font-family: 'Roboto Condensed', 'Helvetica Nueue', arial, sans-serif; // TODO : Update styling on this component. The only font that should be getting used now is just "Roboto".
	font-size: 75%;
	letter-spacing: 1px;
	-webkit-font-smoothing: antialiased;
	font-weight: 700;
	-moz-osx-font-smoothing: grayscale;
}

// New Blue - Talent
.status-is_deciding {
	padding: 2px 1em;
	border-radius: 3.75em;
	border: 1px solid $success;
	color: $success;
	background-color: transparent;
	text-transform: uppercase;
	//font-family: 'Roboto Condensed', 'Helvetica Nueue', arial, sans-serif; // TODO : Update styling on this component. The only font that should be getting used now is just "Roboto".
	font-size: 75%;
	letter-spacing: 1px;
	-webkit-font-smoothing: antialiased;
	font-weight: 700;
	-moz-osx-font-smoothing: grayscale;
}

// New Blue - Client
.status-done {
	padding: 2px 1em;
	border-radius: 3.75em;
	border: 1px solid $ice0;
	color: $ice0;
	background-color: transparent;
	text-transform: uppercase;
	//font-family: 'Roboto Condensed', 'Helvetica Nueue', arial, sans-serif; // TODO : Update styling on this component. The only font that should be getting used now is just "Roboto".
	font-size: 75%;
	letter-spacing: 1px;
	-webkit-font-smoothing: antialiased;
	font-weight: 700;
	-moz-osx-font-smoothing: grayscale;
}

// New Blue
.status-fs-completed {
	padding: 2px 1em;
	border-radius: 3.75em;
	border: 1px solid $primary;
	color: $primary;
	background-color: transparent;
	text-transform: uppercase;
	//font-family: 'Roboto Condensed','Helvetica Nueue', arial,sans-serif; // TODO : Update styling on this component. The only font that should be getting used now is just "Roboto".
	font-size: 75%;
	letter-spacing: 1px;
	-webkit-font-smoothing: antialiased;
	font-weight: 700;
	-moz-osx-font-smoothing: grayscale;
}

// Cancelled New red
.status-cancelled {
	padding: 2px 1em;
	border-radius: 3.75em;
	border: 1px solid $danger;
	color: $danger;
	background-color: transparent;
	text-transform: uppercase;
	//font-family: 'Roboto Condensed','Helvetica Nueue', arial,sans-serif; // TODO : Update styling on this component. The only font that should be getting used now is just "Roboto".
	font-size: 75%;
	letter-spacing: 1px;
	-webkit-font-smoothing: antialiased;
	font-weight: 700;
	-moz-osx-font-smoothing: grayscale;
}

// TODO: Remove? Are these legacy???
/* job process pills */
.status-deleted {
	padding: 3px 5px;
	border-radius: 3px;
	color: $white;
	background-color: $danger;
	text-transform: uppercase;
	font-size: 12px;
	-webkit-font-smoothing: antialiased;
	font-weight: bold;
	-moz-osx-font-smoothing: grayscale;
}

/* red */
.status-awarded {
	padding: 3px 5px;
	border-radius: 3px;
	color: $white;
	background-color: $success;
	text-transform: uppercase;
	font-size: 12px;
	-webkit-font-smoothing: antialiased;
	font-weight: bold;
	-moz-osx-font-smoothing: grayscale;
}

/* green */
.status-answered {
	padding: 3px 5px;
	border-radius: 3px;
	color: $white;
	background-color: $success;
	text-transform: uppercase;
	font-size: 12px;
	-webkit-font-smoothing: antialiased;
	font-weight: bold;
	-moz-osx-font-smoothing: grayscale;
}

/* green */

/***
* DS-43 Status labels
***/
.status {
	// common styles for both statuses
	display: inline-block;
	// padding minus 2px for borders
	padding: 0.2rem 0.6rem;
	font-size: 1.2rem;
	font-weight: 500;
	line-height: 1;
	text-align: center;
	border-radius: 3.75rem;
	border-width: 0.2rem;
	border-style: solid;

	&-blue {
		color: $white;
		border-color: $blue1;
		background-color: $blue1;

		&-outline {
			color: $blue1;
			border-color: $blue1;
		}
	}

	&-default {
		color: $text-dark;
		border-color: $grey5;
		background-color: $grey5;
		text-transform: none;
		letter-spacing: normal;

		&-outline {
			color: $grey2;
			border-color: $grey2;
		}
	}

	&-green {
		color: $white;
		border-color: $green0;
		background-color: $green0;

		&-outline {
			color: $green0;
			border-color: $green0;
		}
	}

	&-grey {
		color: $white;
		border-color: $grey1;
		background-color: $grey1;
	}

	&-red {
		color: $white;
		border-color: $red1;
		background-color: $red1;

		&-outline {
			color: $red1;
			border-color: $red1;
		}
	}

	&-yellow {
		&-outline {
			color: $grey1;
			border-color: $yellow2;
		}
	}

	&-purple {
		color: $purple1;
		border-color: $purple5;
		background-color: $purple5;
	}

	&-icon {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 2rem;
		height: 2rem;
		border-radius: 50%;
		font-size: 1rem;

		img {
			display: block;

			&:not(.voices-fsj-icon) {
				width: 1.2rem;
				height: 1.2rem;
			}
		}

		&-blue {
			color: $white;
			background-color: $blue1;
		}

		&-green {
			color: $white;
			background-color: $green0;
		}

		&-purple {
			color: $white;
			background-color: $purple1;
		}

		&-lightblue {
			color: $blue1;
			background-color: $blue5;
		}
	}

	&-envelope {
		color: $green0;
		font-size: 1.6rem;
	}
}