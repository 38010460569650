// ---------------------- Widget Modals, not used for Service Notifications --------------------- //
// Example: the messaging modal on the client responses page

.modal.widget-modal {

	.modal-dialog {
		background: $white;
		border-radius: 8px;
		@media (max-width:($screen-sm - 1)) {
			margin-left: 15px;
			margin-right: 15px;
		}
	}

	.modal-header {
		position: relative;
		background: none;
		padding-top: 4rem;
		padding-bottom: 2rem;
		border-bottom: none;
		.modal-header-content {
			text-align: center;
			padding-left: 1.6rem;
			padding-right: 1.6rem;
		}
		strong {
			color: $text-dark;
		}
		p {
			color: $grey1;
		}
	}

	button.close {
		position: absolute;
		top: 0;
		right: 0;
		color: $text-dark;
		font-size: 2rem;
		width: 50px; // big enough for mobile devices, so people can touch the close button easily
		height: 50px; // big enough for mobile devices, so people can touch the close button easily
	}

	.modal-body {
		padding-top: 2rem;
		padding-bottom: 2rem;
	}

	.modal-footer {
		padding-top: 0;
		padding-bottom: 3.2rem;
		border-top: none;
	}

	textarea {
		resize: vertical;
		min-height: 15rem;
	}

}