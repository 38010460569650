@import "@bootstrap/scss/modal";
@import "@bootstrap/scss/close";
@import "@styles/components/toggle-input";

// ===============================================================
// Voices.com Modals
// ===============================================================

.modal {
	&-dialog {
		pointer-events: all;
		margin: 8rem auto 3rem; //Adjust margins to account for our fixed header.
	}

	&-content {
		width: auto;
	}

	&-header {
		flex-direction: column;
		align-items: flex-start;
		padding: $spacing-large calc(2 * $spacing-large) $spacing-large $spacing-large;
		border-bottom: none;

		@media (min-width: $screen-xs) {
			padding: $spacing-larger calc(2 * $spacing-larger) $spacing-medium $spacing-larger;
		}

		// TODO - move to clientJobResponses?
		.close {
			font-size: 2.2rem;
		}
		.btn-close {
			font-size: 1.3rem;
		}
		.close, & .btn-close {
			position: absolute;
			top: 2.4rem;
			right: 2.4rem;
			z-index: 1;
			color: $grey1;
			opacity: 1;
			background-color: transparent;
			border: none;
			line-height: 1;

			@media (min-width: $screen-xs) {
				top: 3.2rem;
				right: 3.2rem;
			}
		}

		.modal-title {
			margin-top: 0;
			line-height: 1.25;
			overflow-wrap: break-word;

			span {
				font-size: inherit;
				color: inherit;
			}
		}

		.modal-subtitle {
			margin: 0;
		}
	}

	&-content {
		border: none;
		margin: 0 30px;
		background-color: $white;
		border-radius: 8px;

		@media (min-width: $screen-sm) {
			box-shadow: 0 5px 15px rgba(0, 0, 0, .5);
			margin-left: 0;
			margin-right: 0;
		}

		.modal-body {
			font-size: 1.6rem;
			padding: 0 $spacing-large;
			color: $grey1;

			@media (min-width: $screen-xs) {
				padding: 0 $spacing-larger;
			}

			p {
				margin: 0 0 10px; //Re-instate this margin here because this is sometimes being overriden by a list-group
			}

			.btns {
				display: flex;
				flex-direction: column-reverse;
				margin-top: $spacing-large;

				@media (min-width: $screen-xs) {
					flex-direction: row;
				}

				.btn {
					margin-right: 0;
					margin-top: $spacing-smaller;
					width: 100%;

					@media (min-width: $screen-xs) {
						width: auto;
						margin-right: $spacing-medium;
						margin-top: 0;
					}

					&:last-child {
						margin: 0;
					}
				}
			}

			small {
				color: $grey1;
				font-size: 1.2rem;
				line-height: 1.8rem;
			}
		}

		.modal-footer {
			padding: $spacing-large;
			display: flex;
			flex-direction: column-reverse;

			@media (min-width: $screen-xs) {
				margin-top: $spacing-large;
				flex-direction: row;
				justify-content: flex-end;
			}

			button {
				margin-right: 0;
				margin-top: $spacing-smaller;
				width: 100%;

				@media (min-width: $screen-xs) {
					width: auto;
					margin-right: $spacing-medium;
					margin-top: 0;
				}

				&:last-child {
					margin: 0;
				}
			}

			@media (min-width: $screen-sm) {
				padding: $spacing-large $spacing-larger;
			}
		}

		.form-group label {
			font-size: 1.6rem;
		}
	}

	.modal-footer {
		border-top: 1px solid $grey4;
		margin-top: $spacing-large;
	}

	&.show,
	&.fade.in {
		opacity: 1;
	}

	.close,
    .btn-close {
		font-size: 2.2rem;
		position: absolute;
		top: 2.4rem;
		right: 2.4rem;
		z-index: 1;
		color: $grey1;
		opacity: 1;
		background-color: transparent;
		border: none;
		line-height: 1;

		@media (min-width: $screen-xs) {
			top: 3.2rem;
			right: 3.2rem;
		}
	}

    .btn-close {
        font-size: 16px;
        --bs-btn-close-color: #4f5963;
    }
}

.modal-backdrop {
	position: fixed;
	bottom: 0;

	&.show,
	&.fade.in {
		opacity: .5;
	}
}

.modal-floating-close-btn {
	position: absolute;
	z-index: 2;
	top: 2.4rem;
	right: 2.4rem;
	font-size: 2.2rem;
	color: $grey1;
	opacity: 1;
	background-color: transparent;
	border: none;
	line-height: 1;

	@media (min-width: $screen-xs) {
		top: 3.2rem;
		right: 3.2rem;
	}
}

// ---------------------- Multi-screen Modal ----------------------- //
.multi-step-modal {
	.modal-body {
		position: relative;
	}

	.multi-step-modal-prev-content-btn,
	.multi-step-modal-next-content-btn {
		position: absolute;
		top: 50%;
		background: none;
		outline: none;
		border: none;

		&:disabled {
			cursor: default;
			opacity: 0.25;
		}
	}

	.multi-step-modal-prev-content-btn {
		left: 3.2rem;
	}

	.multi-step-modal-next-content-btn {
		right: 3.2rem;
	}

	.multi-step-modal-content {
		padding-left: 3.2rem;
		padding-right: 3.2rem;
	}

	.multi-step-modal-section {
		padding: 1.6rem;
	}
}

// ---------------------- Fix z-indexing --------------------- //
.modal-backdrop.in,
.modal-backdrop.show {
	z-index: ($header-zindex + 1) !important; // higher than everything, including the header which is at 99999, and the cookie-banner which is at 9999
}

.modal,
#modal-request-quote-success.in {
	z-index: ($header-zindex + 2); // higher than the modal backdrop
}

body.modal-open .select2-dropdown {
	z-index: ($header-zindex + 3);
}


// ---------------------- Preview Portfolio Sample Modal CSS ---------------------- //

#preview-sample-modal {

	.modal-dialog {
		width: 100%;
		@media(max-width: $screen-sm - 1px) {
			margin: 0;
		}
		@media(min-width: $screen-sm) {
			width: 650px;
		}
		@media(min-width: $screen-md) {
			width: 850px;
		}
		@media(min-width: $screen-lg) {
			width: 1145px;
		}
	}

	.modal-header {
		padding-bottom: 1.6rem;
		flex-direction: column;
		align-items: flex-start;

		@media(min-width: $screen-sm) {
			padding-bottom: 2.4rem;
		}
	}

	#preview-sample-header-divider {
		margin: 0 2.4rem 1.6rem;
		width: calc(100% - 4.8rem);
		height: 1px;
		background: $grey4;
		@media(min-width: $screen-sm) {
			margin: 0 3.2rem 2.4rem;
			width: calc(100% - 6.4rem);
		}
	}

	.modal-content {
		margin: 0;

		@media(max-width: $screen-sm - 1px) {
			min-height: 100vh;
		}
	}

	.talent-details {
		display: flex;
		align-items: center;

		.talent-avatar {
			margin-right: $spacing-smaller;
		}

		.talent-name-wrap {
			display: flex;
			align-items: center;

			.name {
				margin-top: 0;
				margin-bottom: .4rem;
			}
		}

		.talent-ratings .stars {
			display: flex;
			font-size: 1.4rem;

			i {
				line-height: 1.5;
			}
		}
	}

	#preview-sample-download {
		position: absolute;
		display: flex;
		align-items: center;
		color: #6e7681;
		bottom: $spacing-medium;
		right: 0;
		margin: 0 2.4rem;
		color: $text-dark;
		padding: $spacing-small 0;
		justify-content: center;
		width: calc(100% - 4.8rem);
		@media(min-width: $screen-xs) {
			margin: 0 3.2rem;
			width: calc(100% - 6.4rem);
		}
		@media(min-width: $screen-sm) {
			top: 3.2rem;
			color: $grey1;
			right: 10.1rem;
			width: auto;
			bottom: auto;
			border: none !important;
			background: none;
			padding: 0;
		}
		@media(min-width: $screen-sm) {
			flex-direction: column;
			margin: 0;
			&:after {
				content: '';
				position: absolute;
				top: 0;
				right: -2.4rem;
				height: 100%;
				width: 1px;
				background: $grey4;
			}
		}

		i {
			font-size: 2rem;
			margin-right: $spacing-smaller;
			@media(min-width: $screen-sm) {
				margin-right: 0;
			}
		}

		span {
			font-size: 1.8rem;
			@media(min-width: $screen-sm) {
				font-size: 1.4rem;
			}
		}
	}

	#pdf_sample {
		width: 100%;
		height: 51rem;
		@media (min-width: $screen-sm) {
			height: 76rem;
		}
		@media (min-width: $screen-lg) {
			height: 82rem;
		}
	}

	&.preview-sample-response {
		#preview-sample-content {
			height: calc(100% - 16.8rem);
		}
	}

	#preview-sample-content {
		img {
			max-width: 100%;
		}
	}

	#preview-sample-metadata {
		padding-left: 2.4rem;
		padding-right: 2.4rem;
		@media(min-width: $screen-xs) {
			padding-left: 3.2rem;
			padding-right: 3.2rem;
		}

		.details-row {
			margin-bottom: $spacing-medium;

			p {
				margin-bottom: 0;

				& + p {
					margin-top: $spacing-smallest;
				}
			}
		}

		a.expand-content-btn {
			font-size: 1.6rem;
		}
	}
}

@media (max-width: 768px) {
	.titlename {
		display: none;
	}
}

@media (min-width: 992px) {
	#previouslyWorkedModal .modal-dialog {
		width: 850px;
	}
}
