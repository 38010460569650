@import "react-datepicker/dist/react-datepicker.css";

.react-datepicker {
    font-family: 'Roboto', sans-serif;
    width: 300px;
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
    border: none;
    border-radius: 4px;

    > * {
        font-size: 14px;
        color: $grey1;
    }

    &__navigation {
        top: $spacing-small;
    }

    &__day-name,
    &__day,
    &__time-name {
        color: $grey1;
        width: 12%;
        line-height: 3.6rem;

        &--disabled {
            opacity: 0.5 !important;
        }

        &--keyboard-selected,
        &--selected {
            border-radius: 100%;

            &:hover {
                color: $white;
            }
        }

        &--selected {
            color: $white;
        }


        &:hover,
        &:focus {
            border-radius: 100%;
        }
    }

    &__header {
        background-color: white;
        border: none;
    }

    &__current-month {
        font-size: 16px;
        color: $blue1;
        padding: $spacing-smaller $spacing-medium !important;
    }

    &__month-container {
        width: 100%;
    }

    .react-datepicker__view-calendar-icon {
        input {
            padding: $spacing-small $spacing-small $spacing-small 4.5rem;
        }
    }
}

