.layout-body {
	display: block;
	@include bg-white;
	@include shadow-1;
	@include border-grey5;
	@include border-radius-2;
	padding: $spacing-medium;
	margin-bottom: $spacing-large;

	@media print {
		box-shadow: none;
	}

	@media (min-width: $screen-xs) {
		padding: $spacing-large;
		margin-bottom: $spacing-larger;
	}

	h2:first-of-type {
		margin-top: 0;
	}

	&:last-child .form-actions {
		@include set-margins(bottom, 0);
	}

	&.top-border-blue1 {
		border-top: 0.8rem solid $blue1;
	}

	&.left-border-blue1 {
		border-left: 0.8rem solid $blue1;
	}

	// Layout Body with List Group Items
	&.layout-body-group-item {
		padding-right: 0;
		padding-left: 0;

		.list-group-item.list-group-item,
		.list-group-item-with-arrow.list-group-item-with-arrow {
			border-top: 1px solid $grey4;

			&:last-child {
				border-bottom: 1px solid $grey4;
			}
		}

		[class^="list-group-item"],
		.payment-list-group-item, // Top Level Payments
		.talent-list-group-item, // Hired Talents
		.message-group-item, // Top Level Messages
		.portfolio-list-item { // Search
			border-radius: 0 !important;
			border-left: none !important;
			border-right: none !important;

			&:hover {
				border-top-color: $grey4;
				border-bottom-color: $grey4;
			}
		}

		#action-bar-container {
			padding: 0 $spacing-medium;

			@media (min-width: $screen-xs) {
				padding: 0 $spacing-large;
			}
		}
	}

	// Nested Elements Shouldn't Have box-shadow
	.layout-body {
		@include border-grey4;
		box-shadow: none;
	}

	.panel.panel-body,
	.list-group {
		box-shadow: none !important;

		.list-group-item.list-group-item,
		.list-group-item-with-arrow.list-group-item-with-arrow {
			border: 1px solid $grey4;
		}
	}

	.accordion-section {
		box-shadow: none;
	}
}

.layout-body-top {
	@extend .border-top-grey4;
	@extend .border-left-grey4;
	@extend .border-right-grey4;
	padding: $spacing-large;
	@extend .border-top-radius-2;

	&:last-child .form-actions {
		margin-bottom: 0;
	}

	// xxs down
	@media (max-width: ($screen-xs - 1px)) {
		@include set-paddings(all, $spacing-medium);
	}

	// Grey Background Layout Body
	&.bg-grey5 {
		border-color: $grey5;
	}
}

.layout-body-middle {
	@extend .border-top-grey4;
	@extend .border-left-grey4;
	@extend .border-right-grey4;
	padding: $spacing-large;

	&:last-child .form-actions {
		margin-bottom: 0;
	}

	// xxs down
	@media (max-width: ($screen-xs - 1px)) {
		@include set-paddings(all, $spacing-medium);
	}

	// Grey Background Layout Body
	&.bg-grey5 {
		border-color: $grey5;
	}
}

.layout-body-bottom {
	@include border-grey4;
	@extend .border-bottom-radius-2;
	padding: $spacing-large;
	@extend .margin-bottom-larger;

	&:last-child .form-actions {
		margin-bottom: 0;
	}

	// xxs down
	@media (max-width: ($screen-xs - 1px)) {
		@include set-paddings(all, $spacing-medium);
		@include set-paddings(bottom, $spacing-large);
	}

	// Grey Background Layout Body
	&.bg-grey5 {
		border-color: $grey5;
	}
}

// Split Layout (Payment Form, Submit Agreements)
#split-main-div {
	position: relative;
	margin-top: $header-height;
	padding-top: $spacing-large;

	@media (min-width: $screen-md) {
		padding-top: $spacing-largest;
		display: flex;
		align-items: flex-start;
		padding-bottom: $spacing-largest;

		> .row {
			width: 100%;
		}
	}

	#right-column {
		position: relative;

		&::before {
			position: absolute;
			background-color: $white;
			content: '';
			z-index: -1;
			height: 100%;
			top: 0;
			left: calc((100% - 100vw) / 2);
			right: calc((100% - 100vw) / 2);

			@media screen and (min-width: $screen-md) {
				position: fixed;
				width: calc((100% - 970px) / 2 + 404px); // Page not found!
				bottom: 0;
				right: 0;
				left: auto;
			}

			@media screen and (min-width: $screen-lg) {
				width: calc((100% - 1170px) / 2 + 487px); // 486px + 1px.
			}
		}

		.sticky-container {
			position: relative;

			@media screen and (min-width: $screen-md) {
				position: sticky;
				top: 11rem; // header-spacing (70px) + 4rem
			}
		}
	}
}

.grey-container {
    background-color: $grey5;
    border-radius: 4px;
    padding: $spacing-smallest $spacing-large;
}

hr {
	border-top: 1px solid $grey4;
}