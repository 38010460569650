// Mixin for auto margins
@mixin auto-margins($directions: all) {
	@if $directions == all {
		margin: auto !important;
	} @else {
		@each $dir in $directions {
			@if $dir == top {
				margin-top: auto !important;
			} @else if $dir == right {
				margin-right: auto !important;
			} @else if $dir == bottom {
				margin-bottom: auto !important;
			} @else if $dir == left {
				margin-left: auto !important;
			}
		}
	}
}

// Mixin for removing margins
@mixin no-margins($directions: all) {
	@if $directions == all {
		margin: 0 !important;
	} @else {
		@each $dir in $directions {
			@if $dir == top {
				margin-top: 0 !important;
			} @else if $dir == right {
				margin-right: 0 !important;
			} @else if $dir == bottom {
				margin-bottom: 0 !important;
			} @else if $dir == left {
				margin-left: 0 !important;
			}
		}
	}
}

// Mixin for setting margins based on variable
@mixin set-margins($direction, $spacing) {
	@if $direction == all {
		margin: $spacing !important;
	} @else {
		@if $direction == top {
			margin-top: $spacing !important;
		} @else if $direction == right {
			margin-right: $spacing !important;
		} @else if $direction == bottom {
			margin-bottom: $spacing !important;
		} @else if $direction == left {
			margin-left: $spacing !important;
		}
	}
}

// Mixin for setting padding based on variable
@mixin set-paddings($direction, $spacing) {
	@if $direction == all {
		padding: $spacing !important;
	} @else {
		@if $direction == top {
			padding-top: $spacing !important;
		} @else if $direction == right {
			padding-right: $spacing !important;
		} @else if $direction == bottom {
			padding-bottom: $spacing !important;
		} @else if $direction == left {
			padding-left: $spacing !important;
		}
	}
}

.margin-right-auto {
	@include auto-margins(right);
}

.margin-left-auto {
	@include auto-margins(left);
}

.margin-left-right-auto {
	@include auto-margins(left right);
}

.margin-auto {
	@include auto-margins();
}

.margin-top-auto {
	@include auto-margins(top);
}

.margin-bottom-auto {
	@include auto-margins(bottom);
}

.margin-left-right-zero {
	@include no-margins(left right);
}

.margin-top-zero {
	@include set-margins(top, 0);
}

.margin-top-smallest {
	@include set-margins(top, $spacing-smallest);
}

.margin-top-smaller {
	@include set-margins(top, $spacing-smaller);
}

.margin-top-small {
	@include set-margins(top, $spacing-small);
}

.margin-top-medium {
	@include set-margins(top, $spacing-medium);
}

.margin-top-large {
	@include set-margins(top, $spacing-large);
}

.margin-top-larger {
	@include set-margins(top, $spacing-larger);
}

.margin-top-largest {
	@include set-margins(top, $spacing-largest);
}

.margin-top-huge {
	@include set-margins(top, $spacing-huge);
}

.margin-top-gigantic {
	@include set-margins(top, $spacing-gigantic);
}

.margin-zero {
	@include set-margins(all, 0);
}

.margin-bottom-zero {
	@include set-margins(bottom, 0);
}

.margin-bottom-smallest {
	@include set-margins(bottom, $spacing-smallest);
}

.margin-bottom-smaller {
	@include set-margins(bottom, $spacing-smaller);
}

.margin-bottom-small {
	@include set-margins(bottom, $spacing-small);
}

.margin-bottom-medium {
	@include set-margins(bottom, $spacing-medium);
}

.margin-bottom-large {
	@include set-margins(bottom, $spacing-large);
}

.margin-bottom-larger {
	@include set-margins(bottom, $spacing-larger);
}

.margin-bottom-largest {
	@include set-margins(bottom, $spacing-largest);
}

.margin-bottom-huge {
	@include set-margins(bottom, $spacing-huge);
}

.margin-bottom-gigantic {
	@include set-margins(bottom, $spacing-gigantic);
}

.margin-left-zero {
	@include set-margins(left, 0);
}

.margin-left-smallest {
	@include set-margins(left, $spacing-smallest);
}

.margin-left-smaller {
	@include set-margins(left, $spacing-smaller);
}

.margin-left-small {
	@include set-margins(left, $spacing-small);
}

.margin-left-medium {
	@include set-margins(left, $spacing-medium);
}

.margin-left-large {
	@include set-margins(left, $spacing-large);
}

.margin-left-larger {
	@include set-margins(left, $spacing-larger);
}

.margin-left-largest {
	@include set-margins(left, $spacing-largest);
}

.margin-left-huge {
	@include set-margins(left, $spacing-huge);
}

.margin-left-gigantic {
	@include set-margins(left, $spacing-gigantic);
}

.margin-right-zero {
	@include set-margins(right, 0);
}

.margin-right-smallest {
	@include set-margins(right, $spacing-smallest);
}

.margin-right-smaller {
	@include set-margins(right, $spacing-smaller);
}

.margin-right-small {
	@include set-margins(right, $spacing-small);
}

.margin-right-medium {
	@include set-margins(right, $spacing-medium);
}

.margin-right-large {
	@include set-margins(right, $spacing-large);
}

.margin-right-larger {
	@include set-margins(right, $spacing-larger);
}

.margin-right-largest {
	@include set-margins(right, $spacing-largest);
}

.margin-right-huge {
	@include set-margins(right, $spacing-huge);
}

.margin-right-gigantic {
	@include set-margins(right, $spacing-gigantic);
}

.padding-zero {
	@include set-paddings(all, 0);
}

.padding-smallest {
	@include set-paddings(all, $spacing-smallest);
}

.padding-smaller {
	@include set-paddings(all, $spacing-smaller);
}

.padding-small {
	@include set-paddings(all, $spacing-small);
}

.padding-medium {
	@include set-paddings(all, $spacing-medium);
}

.padding-large {
	@include set-paddings(all, $spacing-large);
}

.padding-larger {
	@include set-paddings(all, $spacing-larger);
}

.padding-largest {
	@include set-paddings(all, $spacing-largest);
}

.padding-huge {
	@include set-paddings(all, $spacing-huge);
}

.padding-gigantic {
	@include set-paddings(all, $spacing-gigantic);
}

.padding-top-zero {
	@include set-paddings(top, 0);
}

.padding-top-smallest {
	@include set-paddings(top, $spacing-smallest);
}

.padding-top-smaller {
	@include set-paddings(top, $spacing-smaller);
}

.padding-top-small {
	@include set-paddings(top, $spacing-small);
}

.padding-top-medium {
	@include set-paddings(top, $spacing-medium);
}

.padding-top-large {
	@include set-paddings(top, $spacing-large);
}

.padding-top-larger {
	@include set-paddings(top, $spacing-larger);
}

.padding-top-largest {
	@include set-paddings(top, $spacing-largest);
}

.padding-top-huge {
	@include set-paddings(top, $spacing-huge);
}

.padding-top-gigantic {
	@include set-paddings(top, $spacing-gigantic);
}

.padding-bottom-zero {
	@include set-paddings(bottom, 0);
}

.padding-bottom-smallest {
	@include set-paddings(bottom, $spacing-smallest);
}

.padding-bottom-smaller {
	@include set-paddings(bottom, $spacing-smaller);
}

.padding-bottom-small {
	@include set-paddings(bottom, $spacing-small);
}

.padding-bottom-medium {
	@include set-paddings(bottom, $spacing-medium);
}

.padding-bottom-large {
	@include set-paddings(bottom, $spacing-large);
}

.padding-bottom-larger {
	@include set-paddings(bottom, $spacing-larger);
}

.padding-bottom-largest {
	@include set-paddings(bottom, $spacing-largest);
}

.padding-bottom-huge {
	@include set-paddings(bottom, $spacing-huge);
}

.padding-bottom-gigantic {
	@include set-paddings(bottom, $spacing-gigantic);
}

.padding-left-zero {
	@include set-paddings(left, 0);
}

.padding-left-smallest {
	@include set-paddings(left, $spacing-smallest);
}

.padding-left-smaller {
	@include set-paddings(left, $spacing-smaller);
}

.padding-left-small {
	@include set-paddings(left, $spacing-small);
}

.padding-left-medium {
	@include set-paddings(left, $spacing-medium);
}

.padding-left-large {
	@include set-paddings(left, $spacing-large);
}

.padding-left-larger {
	@include set-paddings(left, $spacing-larger);
}

.padding-left-largest {
	@include set-paddings(left, $spacing-largest);
}

.padding-left-huge {
	@include set-paddings(left, $spacing-huge);
}

.padding-left-gigantic {
	@include set-paddings(left, $spacing-gigantic);
}

.padding-right-zero {
	@include set-paddings(right, 0);
}

.padding-right-smallest {
	@include set-paddings(right, $spacing-smallest);
}

.padding-right-smaller {
	@include set-paddings(right, $spacing-smaller);
}

.padding-right-small {
	@include set-paddings(right, $spacing-small);
}

.padding-right-medium {
	@include set-paddings(right, $spacing-medium);
}

.padding-right-large {
	@include set-paddings(right, $spacing-large);
}

.padding-right-larger {
	@include set-paddings(right, $spacing-larger);
}

.padding-right-largest {
	@include set-paddings(right, $spacing-largest);
}

.padding-right-huge {
	@include set-paddings(right, $spacing-huge);
}

.padding-right-gigantic {
	@include set-paddings(right, $spacing-gigantic);
}
