// Override Bootstrap Variables
$popover-border-color: $grey4;

// Import Bootstrap
@import "@bootstrap/scss/popover";

// -------------------------------------------------------------
// Custom Popover Styles
// -------------------------------------------------------------

.popover {
	border-radius: 4px;
	@include shadow-2;
	position: absolute;
	top: 0;
	left: 0;
	z-index: 1060;
	display: none;
	max-width: 276px;
	padding: 1px;
	font-size: 14px;
	text-align: left;
	text-decoration: none;
	text-shadow: none;
	text-transform: none;
	background-color: $white;
	background-clip: padding-box;
	border: 1px solid $popover-border-color;
	line-break: auto;

	&.open {
		display: block;
	}

	&.top {
		margin-top: -10px;

		> .arrow {
			bottom: -11px;
			left: 50%;
			margin-left: -11px;
			border-top-color: $grey2;
			border-bottom-width: 0;

			&:after {
				bottom: 1px;
				margin-left: -10px;
				content: " ";
				border-top-color: $white;
				border-bottom-width: 0;
			}
		}
	}

	&.bottom {
		margin-top: 10px;

		> .arrow {
			top: -11px;
			left: 50%;
			margin-left: -11px;
			border-top-width: 0;
			border-bottom-color: $grey2;

			&::after {
				top: 1px;
				margin-left: -10px;
				content: " ";
				border-top-width: 0;
				border-bottom-color: $white;
			}
		}
	}

	&.right {
		margin-left: 10px;

		> .arrow {
			top: 50%;
			left: -11px;
			margin-top: -11px;
			border-right-color: $grey2;
			border-left-width: 0;

			&::after {
				bottom: -10px;
				left: 1px;
				content: " ";
				border-right-color: $white;
				border-left-width: 0;
			}
		}
	}

	&.left {
		margin-left: -10px;

		> .arrow {
			top: 50%;
			right: -11px;
			margin-top: -11px;
			border-right-width: 0;
			border-left-color: $grey2;

			&::after {
				right: 1px;
				bottom: -10px;
				content: " ";
				border-right-width: 0;
				border-left-color: $white;
			}
		}
	}

	> .arrow,
	> .arrow::after {
		position: absolute;
		display: block;
		width: 0;
		height: 0;
		border-color: transparent;
		border-style: solid;
	}

	> .arrow {
		border-width: 11px;
	}

	> .arrow::after {
		content: "";
		border-width: 10px;
	}
}

.popover-title {
	padding: 8px 14px;
	margin: 0;
	font-size: 14px;
	background-color: $grey5;
	border-bottom: 1px solid $grey5;
	border-radius: 5px 5px 0 0;
}

.popover-content {
	padding: 9px 14px;
}

// Implementation: Number Picker
// Areas this component exists are...
// - Job Form (Finished Minutes, Other Time Period)
.number-picker {
	position: relative;
	> .input-group > p {
		color: $grey1;
		margin-bottom: 0.4rem;
		font-size: 1.4rem;
		white-space: nowrap;
	}
}
.popover.number-picker-popover {
	width: 230px;
	top: auto; // Override Bootstrap's top:0px styles
	pointer-events: none;
	opacity: 0;
	transform: translateY(-0.8rem);
	transition: transform 0.3s ease, opacity 0.3s ease;

	&.open {
		display: block;
		pointer-events: auto;
		&.animate {
			opacity: 1;
			transform: translateY(0);
		}
	}

	// "Clear", "Done" and Plus & Minus buttons
	button {
		font-size: 1.6rem;
		padding: 1.5rem 1rem;
	}

	.number-picker-row {
		display: flex;
		align-items: center;
		justify-content: space-between;
		~ .number-picker-row {
			margin-top: 1rem;
		}
		> label {
			width: 33.33333%;
		}
		input {
			min-width: 0;
			margin-left: 0 !important;
		}
	}

	.input-group {
		display: flex;
		flex: 1;
		margin-left: 1rem;
		input {
			margin: 0;
			padding-left: 0;
			padding-right: 0;
			text-align: center;
			border-radius: 0 !important;
		}
		button {
			margin: 0;
			&[data-action="minus"] {
				border-top-right-radius: 0;
				border-bottom-right-radius: 0;
				color: $white;
				background-color: $grey4;
			}
			&[data-action="plus"] {
				border-top-left-radius: 0;
				border-bottom-left-radius: 0;
			}
		}
	}

	.panel-body {
		padding: $spacing-medium;
	}

	.panel-footer {
		background: none;
		display: flex;
		justify-content:space-between;
		padding: $spacing-medium;
	}
}