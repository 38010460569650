.show,
.visible {
	display: block !important;
}

.hidden {
	display: none !important;
}

@each $key, $value in $grid-breakpoints {
	$hidden-class-prefix: unquote("hidden-#{$key}");
	$hidden-up-class-prefix: unquote("hidden-above-#{$key}");
	$hidden-down-class-prefix: unquote("hidden-below-#{$key}");
	$visible-class-prefix: unquote("visible-#{$key}");
	$visible-up-class-prefix: unquote("visible-above-#{$key}");
	$visible-down-class-prefix: unquote("visible-below-#{$key}");
	$keys: map-keys($grid-breakpoints);
	$nextIndex: index($keys, $key) + 1;
	$nextKey: null;

	@if $nextIndex <= length($keys) {
		$nextKey: nth($keys, $nextIndex);
	}

	@if $key == "xxs" {
		.#{$visible-class-prefix} {
			@media (min-width: map-get($grid-breakpoints, $nextKey)) {
				display: none !important;
			}
		}
	}

	@if $key != "xxs" {
		@if $key == "xs" {
			.#{$hidden-class-prefix} {
				@media (max-width: map-get($grid-breakpoints, $nextKey) - 1px) {
					display: none !important;
				}
			}

			.#{$visible-class-prefix} {
				@media (max-width: map-get($grid-breakpoints, $key) - 1px) {
					display: none !important;
				}

				@media (min-width: map-get($grid-breakpoints, $nextKey)) {
					display: none !important;
				}
			}
		} @else if $key == "lg" {
			.#{$hidden-class-prefix} {
				@media (min-width: map-get($grid-breakpoints, $key)) {
					display: none !important;
				}
			}

			.#{$visible-class-prefix} {
				@media (max-width: map-get($grid-breakpoints, $key) - 1px) {
					display: none !important;
				}
			}
		} @else {
			.#{$hidden-class-prefix} {
				@media (min-width: map-get($grid-breakpoints, $key)) and (max-width: map-get($grid-breakpoints, $nextKey) - 1px) {
					display: none !important;
				}
			}

			.#{$visible-class-prefix} {
				@media (max-width: map-get($grid-breakpoints, $key) - 1px) {
					display: none !important;
				}

				@media (min-width: map-get($grid-breakpoints, $nextKey)) {
					display: none !important;
				}
			}
		}

		.#{$hidden-up-class-prefix} {
			@media (min-width: map-get($grid-breakpoints, $key)) {
				display: none !important;
			}
		}

		.#{$hidden-down-class-prefix} {
			@media (max-width: map-get($grid-breakpoints, $key) - 1px) {
				display: none !important;
			}
		}

		.#{$visible-up-class-prefix} {
			display: none !important;
			@media (min-width: map-get($grid-breakpoints, $key)) {
				display: block !important;
			}
		}

		.#{$visible-down-class-prefix} {
			display: none !important;
			@media (max-width: map-get($grid-breakpoints, $key) - 1px) {
				display: block !important;
			}
		}
	}
}

