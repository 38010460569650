$flyout-arrow-width: .6rem;
$flyout-arrow-outer-width: .7rem;

.flyout-popover-parent {
	position: relative;
}

.flyout-popover {
	display: none;
	position: absolute;
	border: 1px solid $grey5;
	padding: $spacing-medium;
	background-color: $white;
	max-width: 30rem;
	min-width: 28rem;
	top: 0;
	left: 0;
	border-radius: 4px;
	z-index: 999999;
	background-clip: padding-box;
	white-space: normal;
	box-shadow: 0 5px 12px 0 rgba(25, 34, 43, 0.1);

	// Offset the popover to account for the popover arrow
	&.top,
	&[data-popper-placement="top"] {
		margin-top: - calc($flyout-arrow-width + $spacing-smaller);
	}

	&.right,
	&[data-popper-placement="right"] {
		top: 0 !important;
		margin-left: calc($flyout-arrow-width + $spacing-smaller);
	}

	&.bottom,
	&[data-popper-placement="bottom"] {
		margin-top: calc($flyout-arrow-width + $spacing-smaller);
	}

	&.left,
	&[data-popper-placement="left"] {
		top: 0 !important;
		margin-left: - calc($flyout-arrow-width + $spacing-smaller);
	}

	.popover-title {
		margin-bottom: 1rem !important;
		padding: 0;
		font-size: 1.6rem;
		background-color: $white;
		border-bottom: none;
		border-radius: 0;
		color: $text-dark;
	}

	.popover-content {
		padding: 0;
		font-size: 1.4rem;
	}

	.close-btn {
		position: absolute;
		right: $spacing-medium;
		top: $spacing-smaller;
		color: $grey1;
		font-size: 1.6rem;
	}

	&.fade.in,
	&.show {
		opacity: 1 !important;
	}
}

//Arrow
.flyout-popover > .arrow {
	&,
	&:after {
		position: absolute;
		display: block;
		width: 0;
		height: 0;
		border-color: transparent;
		border-style: solid;
	}
}

.flyout-popover > .arrow {
	border-width: $flyout-arrow-outer-width;
}

.flyout-popover > .arrow:after {
	border-width: $flyout-arrow-width;
	content: "";
}

.flyout-popover {
	&.top > .arrow,
	&[data-popper-placement="top"] > .arrow {
		left: 50%;
		margin-left: -$flyout-arrow-outer-width;
		border-bottom-width: 0;
		border-top-color: $grey5;
		bottom: -$flyout-arrow-outer-width;

		&:after {
			content: " ";
			bottom: 1px;
			margin-left: -$flyout-arrow-width;
			border-bottom-width: 0;
			border-top-color: $white;
		}
	}

	&.right > .arrow,
	&[data-popper-placement="right"] > .arrow {
		top: 15% !important;
		left: -$flyout-arrow-outer-width;
		margin-top: -$flyout-arrow-outer-width;
		border-left-width: 0;
		border-right-color: $grey5;

		&:after {
			content: " ";
			left: 1px;
			bottom: -$flyout-arrow-width;
			border-left-width: 0;
			border-right-color: $white;
		}
	}

	&.bottom > .arrow,
	&[data-popper-placement="bottom"] > .arrow {
		left: 50%;
		margin-left: -$flyout-arrow-outer-width;
		border-top-width: 0;
		border-bottom-color: $grey5;
		top: -$flyout-arrow-outer-width;

		&:after {
			content: " ";
			top: 1px;
			margin-left: -$flyout-arrow-width;
			border-top-width: 0;
			border-bottom-color: $white;
		}
	}

	&.left > .arrow,
	&[data-popper-placement="left"] > .arrow {
		top: 15% !important;
		right: -$flyout-arrow-outer-width;
		margin-top: -$flyout-arrow-outer-width;
		border-right-width: 0;
		border-left-color: $grey5;

		&:after {
			content: " ";
			right: 1px;
			border-right-width: 0;
			border-left-color: $white;
			bottom: -$flyout-arrow-width;
		}
	}
}

.flyout-icon {
	cursor: pointer;
}

.popover.popover {
	.popover-arrow.popover-arrow {
		&:after {
			border-top-color: $white;
		}
	}
}
