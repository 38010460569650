/* ****************************** */
/* 25 - Labels, Badges and Markers */
/* ****************************** */

// 25.a - Labels
.label { 
    text-transform: uppercase;
    font-weight: 400;
    border-radius: 3.75em;
    padding: 2px 1em;
    letter-spacing:1px;

    &.label-pending {
        color: grey!important;
        text-transform: capitalize;
        font-family: Roboto;
        font-size: 12px;
        font-weight: bold;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.5;
        letter-spacing: 0.2px;
        text-align: center;
        background-color: #fff!important;
        border: 2px solid #ffbc33!important;
    }
}

// 25.b - Job Status Labels
// Extends the .label class.

.label-status-danger { 
    color: $white;
    background-color: $danger;
    border: 1px solid $danger;
    &.label-reverse { 
        color: $danger;
        background-color: $white;
    }
}

.label-status-product-order {
    color: $blue1;
    background-color: $blue5;
    border: 2px solid $blue5; 
}


.label-status-ai-job {
    align-items: center;
    background-color: $blue5;
    border: 2px solid $blue5;
    color: $blue1;
    display: inline-flex;
    justify-content: center;

    // SVG icon for AI for status labels
    .ai-icon {
        align-self: center;
        margin-right: 3px;
        img {
            height: 1.35em;
            width: 1.25em;
            fill: currentColor;
        }
    }
}

.label-accept{
    text-transform: capitalize;
    font-family: Roboto;
    font-size: 12px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.5;
    letter-spacing: 0.2px;
    text-align: center;
    background-color: #238551!important;
	color: $white !important;
}
.label-decline{
    color: #d1462a!important;
    text-transform: capitalize;
    font-family: Roboto;
    font-size: 12px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.5;
    letter-spacing: 0.2px;
    text-align: center;
    background-color: #fff!important;
    border: 2px solid #d1462a!important;
}

.label-status-denied { 
    color: $accent1;
    background-color: transparent;
    border: 1px solid $accent3;
    
    &.label-reverse { 
        color: $accent1;
        background-color: $white;
    } // label-reverse
} // .label-status-denied

.label-status-cancelled { 
    color: $accent1;
    background-color: transparent;
    border: 1px solid $accent3;
    
    &.label-reverse { 
        color: $accent1;
        background-color: $white;
    } // label-reverse
} // .label-status-cancelled

.label-status-draft, .label-warning { 
    color: $warning;
    background-color: transparent;
    border: 1px solid $warning;  

    &.label-reverse { 
        color: $white;
        background-color: $warning;
    } // label-reverse
} // .label-warning

.label-status-review { 
    color: $warning;
    background-color: $white;
    border: 1px solid $warning;

    &.label-reverse { 
        color: $warning;
        background-color: $white;
    } // label-reverse
} // .label-status-review 

.label-status-approved { 
    color: $blue2;
    background-color: transparent;
    border: 1px solid $blue2;
    
    &.label-reverse { 
        color: $warning;
        background-color: $white;
    } // label-reverse
} // .label-status-pending

.label-status-expired { 
    color: $blue1;
    background-color: $blue5;
    border: 1px solid $blue1;

    &.label-reverse { 
        color: $blue2;
        background-color: $white;
    } // label-reverse
    
} // .label-status-expired

.label-status-offering, .label-status-approved { 
    color: $white;  // $primary;
    background-color: $blue1;
    border: 1px solid $blue1;
    
    &.label-reverse { 
        color: $primary;
        background-color: $white;
    } // label-reverse
} // .label-status-awarded

.label-status-hired { 
    color: $primary;
    background-color: $white;
    border: 1px solid $primary;  

    &.label-reverse { 
        color: $primary;
        background-color: $white;
    } // label-reverse
} // .label-status-hired

.label-status-deciding { 
    color: $green1;
    background-color: $white;
    border: 1px solid $green1;

    &.label-reverse { 
        color: $primary;
        background-color: $white;
    } // label-reverse
} // .label-status-deciding

.label-status-working, .label-status-hired { 
    color: $blue1;
    background-color: $blue5;
    border: 1px solid $blue1; 

    &.label-reverse { 
        color: $primary;
        background-color: $white;
    } // label-reverse
} // .label-status-offering

.label-status-done { 
    color: $blue1;
    background-color: $white;
    border: 1px solid $blue1;
    
    &.label-reverse { 
        color: $blue1;
        background-color: $white;
    } // label-reverse
} // .label-status-done

.label-status-hiring, .label-success { 
    color: $white;
    background-color: $success;
    border: 1px solid $success;

    &.label-reverse { 
        color: $success;
        background-color: $white;
    } // label-reverse
} // .label-status-completed

.label-status-completed { 
    color: $blue2;
    background-color: transparent;
    border: 1px solid $blue2;
    
    &.label-reverse { 
        color: $blue1;
        background-color: $white;
    } // label-reverse
} // .label-status-completed

.label-status-finalizing { 
    color: $blue0;
    background-color: $white;
    border: 1px solid $blue0;
    
    &.label-reverse { 
        color: $blue1;
        background-color: $white;
    } // label-reverse
} // .label-finalizing

.label-primary {
    border:1px solid $blue1;

    &.label-reverse {
        color: $blue1;
        background-color: $white;
    }
}

.label-danger {
    border:1px solid $accent0;

    &.label-reverse {
        color: $accent0;
        background-color: $white;
    }
}

[att^=label-status-] { // look for any status label with a link within it.
    a { display: inline-block; color: inherit; // set the colour of the linked text to match the status label default colour.
        &:hover { font-weight: 500; }
    } // a
} // label.label-status-*

/************************
    Bootstrap Label Overrides
************************/
.label{
    display:inline-block;
    white-space:normal;
}
