ul#responses-container {

  // Job Response
  > li.list-item {

    // Proposal section
    .response-proposal {
      margin-bottom: 0;
      padding-left: 0;
      &, + .response-info-buttons {
        margin-top: 2rem;
      }
      h3 {
        font-size: 1.8rem;
        color: $grey0;
        font-weight: 800;
        margin-top:0;
      }
      p {
        border-left: 3px solid #eee;
        padding-left: 10px;
        font-size: 1.5rem;
      }
    }

    // Info Buttons section
    .response-info-buttons {
      padding-right: 0;
      margin-bottom: 0;
    }
  }
}

#shortlistShareModal {
	form {

		.mb-2 {
			text-align: left;
			width: 100%;
			label, input {
				font-size: 1.6rem;
			}
			label {
				margin-bottom: 0;
				font-weight: 600;
			}
			input {
				width: 100%;
				padding: 1rem;
			}
			button {
				width: auto;
				padding: 1rem;

                @media (min-width: $screen-sm) {
                    min-width: 20%;
                }
			}
		}
	}
}
