@import "@bootstrap/scss/badge";

// TODO: convert all sizing related styles to rems if we no longer need to support alert styles for global & globalClassic

@mixin badge-styles() {
	font-size: 12px;
	line-height: 12px;
	font-weight: 500;
	padding: 4px 8px;
	border-radius: 20px;
}

.badge {
	&-primary {
		@include badge-styles();
		color: $white !important;
		background-color: $blue1!important;
	}
	&-grey {
		@include badge-styles();
		color: $white !important;
		background-color: $grey1;
	}
	&-default {
		@include badge-styles();
		color: $text-dark !important;
		background-color: $grey5;
	}
}