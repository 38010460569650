#multi-step-navigation {
    display: none;

    @media (min-width: $screen-sm) {
        display: block;
        position: relative;
    }

    ul#progress-steps {
        list-style-type: none;
        padding: 0;
        margin-bottom: 0;

        li {
            font-size: 1.4rem;
            line-height: 1.14;
            color: $grey1;
            font-weight: 500;

            a {
                &:hover {
                    text-decoration: underline;
                }
            }

            &:before {
                content: '';
                width: 16px;
                display: inline-block;
                height: 16px;
                border: 2px solid $grey4;
                background-color: $grey4;
                border-radius: 50%;
                margin-right: $spacing-smaller;
                vertical-align: bottom;
            }

            &.active {
                &:before {
                    border-color: $blue1;
                    background-color: $blue1;
                }
            }

            &.checked {
                &:before {
                    content: '\f00c';
                    font-family: 'Font Awesome 5 Pro';
                    background-color: $blue5;
                    border-color: $blue1;
                    color: $blue1;
                    font-size: 8px;
                    line-height: 14px;
                    text-align: center;
                    border-width: 1px;
                }
            }

            @media (min-width: $screen-sm) {
                &.active {
                    color: $blue1;
                }

                &:not(:last-child):after {
                    content: '';
                    display: block;
                    height: 24px;
                    width: 2px;
                    margin-top: 4px;
                    margin-left: 7px;
                    margin-bottom: 4px;
                    border-radius: 2px;
                    background-color: $grey4;
                }
            }
        }
    }
}