@mixin talent-selection-styles {

  #searchbar {
    position: relative;
    width: 100%;

    input[type="search"] {
      background-color: $grey5;
      border-radius: 33px;
      border: none;
      outline: none;
      height: 40px;
    }

    input[type="search"]::-webkit-search-decoration,
    input[type="search"]::-webkit-search-cancel-button,
    input[type="search"]::-webkit-search-results-button,
    input[type="search"]::-webkit-search-results-decoration {
      -webkit-appearance:none;
    }
  }

  #scrollable-container {
    margin-top: 10px;
    max-height: 310px;
    overflow-y: scroll;
  }

  button.clear-talent-search-btn {
    display: none; // hide on default.
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 0;
    padding: 0.8rem 1.2rem;
    background: none;
    border: none;
    outline: none;
    &:hover svg path {
      fill: $grey3;
    }
    svg {
      width: 2rem;
      height: 2rem;
      path {
        fill: $grey4;
      }
    }
  }

  .loading-spinner {
    display: none; // hide on default.
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 1.4rem;
    line-height: 1;
  }

  .checkbox-row {
    ~ .checkbox-row {
      margin-top: 2rem;
    }

    label {
      margin-bottom: 0;
      &::before {
        top: 2rem;
      }
      &::after {
        top: 2.4rem;
      }
      &::before,
      &::after {
        transform: translateY(0);
        @media (min-width: $screen-md) {
          top: 50%;
          transform: translateY(-50%);
        }
      }
    }
    .talent-details-container {
      display: flex;
      width: 100%;
    }
    .talent-details {
      margin-left: 0.8rem;
      font-weight: 400;
      display: flex;
      flex-direction: column;
    }
    .talent-name {
      font-size: 1.6rem;
    }
    .talent-invite-source {
      font-size: 1.2rem;
    }

    .audio-player-container {
      width: 100%;
      margin-top: 1rem;
      padding: 1rem;
      @media (min-width:$screen-md) {
        position: absolute;
        margin-top: 0;
        padding: 0;
        right: 4.6rem;
        top: 50%;
        transform: translateY(-50%);
        width: 40%;
      }
    }

    .audio.voices-audio-player {
      background: none;
      .play-pause-btn {
        width: 30px;
        height: 30px;
        svg {
          width: 100%;
          height: 100%;
        }
      }
      .loading {
        width: 30px;
        height: 30px;
      }
    }
  }

  #selected-talents-container {
    border-bottom: 1px solid $grey4;
    margin-bottom: 0.4rem;
    padding-bottom: 0.4rem;
  }

}