.toast {
	max-width: 500px;
	margin-left: auto;
	margin-right: auto;
	position: fixed;
	justify-content: flex-end;
	padding: $spacing-small;
	border-radius: $border-radius-normal-size;
	font-size: 1.4rem;
	bottom: 2.4rem;
	left: 2.4rem;
	right: 2.4rem;
	z-index: 100090;
	display: flex;
	align-items: center;

	// Animation styles
	opacity: 1;
	transition: 0.3s ease transform, 0.3s ease opacity;
	transform: translateY(100%); // for browsers that dont support calc()
	transform: translateY(calc(100% + 2.4rem));
	&.animate-out {
		opacity: 0;
	}

	// Toast Styles
	&.toast-default {
		background-color: $grey0;
		color: $white;
	}
	&.toast-error {
		background-color: $red5;
		border: 1px solid $red1;
		color:$text-dark;
		.toast-icon{
			color:$red1;
			margin-right:0.4rem;
		}
	}
	&.toast-success {
		background-color: $green5;
		border: 1px solid $green1;
		color:$text-dark;
		.toast-icon{
			color:$green1;
			margin-right:0.4rem;
		}
	}

	// Message Text
	> span {
		margin-right: auto; // this will push to the very left
	}

	.btn {
		// .btn-sm styles
		padding: .4rem 1.2rem;
		line-height: 1.6rem;
		font-size: 1.4rem;
	}
}

.toast-action-btn {
	+ .toast-close-btn {
		margin-left: .8rem;
	}
}

.toast-close-btn {
	border: none !important;
	background: none !important;
	outline: none !important;
	&:hover i {
		color: $grey2;
	}
	i {
		transition: color 0.2s ease;
		color: $grey1;
	}
}