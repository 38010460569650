@keyframes pulsate-hotspot {
  0% {
    box-shadow: 0 0 0px 0px rgba($purple4, 0.8); // x y blur offset color
  }
  100% {
    box-shadow: 0 0 0px 5px rgba($purple4, 0.8); // x y blur offset color
  }
}

.hotspot {
  position: absolute;
  z-index: 100;
  @media (max-width: ($screen-md - 1px)) {
    display: none;
  }
  &:hover {
    .hotspot-dot {
      animation: pulsate-hotspot 1s forwards; // pause at end of animation state
    }
  }

  .flyout-popover {
    background-color: $purple1;
    top: -1.4rem !important;
    .popover-title, .popover-content {
      color: $white;
      background-color: transparent;
    }
    > .arrow {
      &, &::after {
        border-right-color: $purple1;
      }
    }
  }

  .flyout-popover-btn {
    background-color: #fff !important;
    color: $text-dark !important;
  }
}

.hotspot-dot {
  display: block;
  width: 1.6rem;
  height: 1.6rem;
  border-radius: 100%;
  background-color: $purple1;
  cursor: pointer;
  animation: pulsate-hotspot 1s infinite alternate-reverse;
}