.nav-main.introjs-fixParent {
	position: absolute;
}

.introjs-fixParent {
	position: absolute !important;
	transform: none !important;
	z-index: auto !important;
}

.introjs-showElement {
	display: inherit;
	height: fit-content;
}

.introjs-helperLayer {
	background-color: $white !important;
	box-shadow: 0 2px 15px #0006 !important;
	border: 1px solid rgba(0,0,0,.5) !important;
}

.introjs-overlay {
	opacity: 0.3 !important;
	background-color: #000;
}

.introjs-arrow.top {
	top: -9px;
}

.spotlight-element-wrapper {
	display: inline-flex;
}

.introjs-tooltipReferenceLayer * {
	font-family: 'Roboto', 'Font Awesome 5 Pro';
}

.spotlight-modal {
	border-radius: 4px;
	box-shadow: 0 12px 40px 0 rgba(197, 205, 214, 0.7);
	background-color: $white;

	&.introjs-tooltip {
		max-width: 225px;
	}

	.btn {
		border-radius: 3px;
	}

	.introjs-nextbutton {
		@extend .btn-primary;
	}

	.introjs-donebutton {
		@extend .btn-primary;

		& ~ .btn {
			display: none;
		}
	}

	p,
	.introjs-tooltiptext {
		@extend .text-xs;
	}

	.spotlight-svgs {
		margin: 3.3rem auto 2.5rem;
		width: 75%;
		max-width: 200px;
		display: block;
	}

	.spotlight-example {
		font-style: italic;
		color: $grey2;
	}

	.introjs-tooltip-header {
		padding-top: 12px;
	}

	.introjs-tooltip-title {
		@extend .text-xs;
		@extend .text-dark;
		font-weight: bold;
	}

	.introjs-skipbutton {
		&:not(.introjs-donebutton) {
			font-size: 1.6rem;
			@extend .text-dark;

			&:hover {
				color: $grey1;
			}
		}
	}

	.introjs-prevbutton {
		display: none;
	}

	//Needs the specificity to override their styles.
	.introjs-bullets {
		position: absolute;
		left: 10px;

		ul li a.active {
			&, &:hover, &:focus {
				background: $blue1;
			}
		}
	}
}
