.loading-skeleton {
	&.heading {
		width: 100%;
		height: 24px;
		border-radius: 4px;
		background-color: $grey5;
	}

	&.subheading {
		width: 100%;
		height: 17px;
		border-radius: 4px;
		background-color: $grey5;
	}

	&.paragraph {
		width: 100%;
		height: 12px;
		border-radius: 6px;
		background-color: $grey5;

        &.small {
            width: 50%;
        }

        &.medium {
            width: 75%;
        }
    }

	&.circle {
		width: 60px;
		height: 60px;
		background-color: $grey5;
		border-radius: 50%;
		
		&-sm {
			width: 35px;
			height: 35px;
		}
	}

	&.button {
		width: 104px;
		height: 36px;
		border-radius: 4px;
		background-color: $grey5;
	}

	&.icon {
		width: 25px;
		height: 25px;
		background-color: $grey5;
		border-radius: 50%;
	}

	&.round {
		border-radius: 2rem;
	}

	&.darker {
		background-color: $grey4;
	}
}

.loading-container {
    position: relative;
}

#results-loader {
    background: rgba(200, 200, 200, 0.7);
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 999;
    display: none;
    align-items: flex-end;
    justify-content: center;
    border-radius: 8px;

	&.show {
		display: block;
	}

    #results-loader-spinner,
	.voices-loader {
        position: absolute;
        z-index: 99999;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        background-color: $white;
        padding: 1rem;
        font-size: 1.6rem;
        border-radius: 4px;
    }
}
