.image-scaled-to-fit-container {
	position: relative;
	display: inline-block;
}

img.image-scaled-to-fit {
	max-width: 100%;

	// This will inherit the size of the img itself
	+ .image-scaled-to-fit-fallback {
		display: inline-block;
		position: absolute;
		z-index: 2; // to cover up the <img>
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-repeat:no-repeat;
		background-size:cover;
		background-position:center;
	}
}

// Feature detection from the modernizer.js dependency
// If object-fit is enabled, then hide the fallback "background image" element
html.object-fit img.image-scaled-to-fit {
	object-fit: cover;
	+ .image-scaled-to-fit-fallback {
		display: none;
	}
}