/* FROM:
 *
 * https://www.cssscript.com/simple-5-star-rating-system-with-css-and-html-radios/
*/

.stars {
	display: inline-flex;
	align-items: center;

	&,
	label.star,
	.single-star-rating i,
	.stars-not-yet-rated i {
		font-size: 1.2rem;
		line-height:1;
	}

	// Bigger Stars
	// Note: .not-yet-rated will always be displayed as small version.
	&.stars-bigger {
		&,
		label.star,
		.single-star-rating i {
			font-size: 2rem;
			line-height:1;
		}
		.stars-text {
			font-size: 1.6rem;
		}
	}

	// Big Stars
	// Note: .not-yet-rated will always be displayed as small version.
	&.stars-big{
		&,
		label.star,
		.single-star-rating i {
			font-size: 2rem;
			line-height:1;
		}
		.stars-text {
			font-size: 1.4rem;
		}
	}


	// Sibling Text
	~ [class*="text-"] {
		margin-left: 0.4rem;
	}
}

// Single Star Variant
.single-star-rating {
	color:$grey1;
	.fa-star{
		color: #ffb11f;
		margin-right: 3px;
	}
	~ .stars-text {
		margin-left:0;
	}
}

// 5 Star Variant
.five-star-rating {
	display: inline-flex;
	flex-direction: row-reverse;

	input.star {
		display: none;
		&:checked {
			~ label.star {
				&:before {
					color: #ffb11f;
					transition: all .25s;
					font-weight: 900;
				}
				&.star-full:before {
					content: '\f005';
				}
				&.star-half:before {
					content: "\f089";
				}
				&.star-empty:before {
					display: none;
				}
			}
		}

		&:disabled ~ label.star {
			transform: none;
			cursor: default;
		}
	}

	label.star {
		position: relative;
		color: #C5CDD6;
		cursor:pointer;
		transition: all .2s;
		margin-bottom: 0;
		~ label.star {
			margin-right: .2rem;
		}
		&:before {
			position: absolute;
			z-index:2;
			font-family: 'Font Awesome 5 Pro';
		}
		&:after {
			font-family: 'Font Awesome 5 Pro';
			content: '\f005';
			font-weight: 400;
		}
		&:hover {
			transform: rotate(-10deg) scale(1.2);
		}
	}

	 ~ .stars-text {
		margin-left:.8rem;
	 }
}

// Stars Text
// Used in: "Single Star" or "5 Star" Variants
.stars-text {
	color: $grey1;
	.stars-not-yet-rated {
		color: $grey1;
		display: inline-flex;
		align-items: center;
		i {
			color: $grey4;
			margin-right: .4rem;
		}
	}
	.stars-text-dot {
		&::before {
			color: $grey1;
			display: inline-block;
			content: "\2022";
		}
	}
}

// Aggregated Stars Rating
@mixin aggregated-star-rating-styles($background-color, $star-color) {
	display: flex;
	align-items: center;
	background: $background-color;
	border-radius: 1.4rem;
	color: $text-dark;
	font-size: 1.6rem;
	font-weight: 500;
	line-height: 1.25;
	padding: 0.2rem 0.8rem;
	.fa-star {
		color: $star-color;
		font-size:1.2rem;
		margin-right: 0.4rem;
		line-height: 1.5;
	}
}
.aggregated-star-rating {
	@include aggregated-star-rating-styles(
		$background-color: $yellow5,
		$star-color: #ffb11f
	);
}
.aggregated-star-rating-zero {
	@include aggregated-star-rating-styles(
		$background-color: $grey5,
		$star-color: $grey1
	);
}

// Star Rating Selector - Leave a Review!
.star-rating-selector {
	display: inline-flex;
	flex-direction: row-reverse;
	margin-bottom: 1.2rem;

	input.star {
		display: none;
		&:checked {
			// Fill in all checked stars
			~ label.star {
				&:before {
					font-family: 'Font Awesome 5 Pro';
					color: #ffb11f;
					content: '\f005';
					transition: all .25s;
					font-weight: 900; // will be solid, and not outlined
				}
				&.star-full:before {
					content: '\f005';
				}
				&.star-half:before {
					content: "\f089";
				}
				&.star-empty:before {
					display: none;
				}
			}
		}

		&:disabled ~ label.star {
			transform: none;
			cursor: default;
		}

		// Highlight all stars proceeding the one that was clicked on
		&.star-1:checked ~ label.star:before {
			color: #ffb11f;
		}
	}

	label.star {
		position: relative;
		font-size: 2.5rem;
		color: #C5CDD6;
		cursor:pointer;
		transition: all .2s;
		margin-bottom: 0;
		~ label.star {
			margin-right:.6rem;
		}
		&:before {
			position: absolute;
			z-index:2;
			font-family: 'Font Awesome 5 Pro';
		}
		&:after {
			font-family: 'Font Awesome 5 Pro';
			content: '\f005';
			font-weight: 400;
		}
		&:hover {
			font-family: 'Font Awesome 5 Pro';
			color: #ffb11f;
			font-weight: 900;
			content: '\f005';
			transform: scale(1.3);
		}

		&:hover::after{
			font-weight: 900;
		}

		&:hover ~ label.star:before {
			font-family: 'Font Awesome 5 Pro';
			color: #ffb11f;
			font-weight: 900;
			content: '\f005';
		}
	}

	~ .stars-text {
		margin-left: .8rem;
	}
}