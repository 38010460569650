$avatar-size: 100px;
$avatar-border-width: 1px;

.custom-thumbnail {
	height: 165px;
	width: 165px;
	min-width: 165px;
	display: flex;
	align-items: center;
	justify-content: center;

	.circle-avatar-container {
		width: 100%;
		height: 100%;
	}

	@media (max-width: ($screen-sm - 1px)) {
		margin: 0 auto;
	}
}

.profile-ratings-container {
	width: 165px;
	margin-top: $spacing-smaller;
	text-align: center;

	@media (max-width: ($screen-sm - 1px)) {
		margin-left: auto;
		margin-right: auto;
	}
}

.custom-thumbnail-action-btns {
	text-align: center;
	@extend .margin-top-smaller;
	@media (max-width: ($screen-sm - 1px)) {
		margin-left: auto;
		margin-right: auto;
	}
	@media (min-width: $screen-sm) {
		width: 165px;
	}

	.btn {
		margin-right: 0;
	}

	> * {
		~ * {
			@extend .margin-top-smaller;
		}
	}
}

// Mixin to use to copy these avatar properties on each .circle-avatar class
@mixin circle-avatar-styles() {
	background-repeat: no-repeat;
	background-position: 50%;
	background-size: cover;
	border-radius: 50%;
}

// Circle Avatars
.circle-avatar-container {
	border: 1px solid $grey3;
	border-radius: 100%;
	overflow: hidden;

	.circle-avatar-online-indicator {
		position: absolute;
		z-index: 3;
		right: 8px;
		bottom: 8px;
		margin: 0;
		width: 2rem;
		min-width: 2rem;
		height: 2rem;
		background-color: $green1;
		border: 2px solid $white;
		border-radius: 100%;
	}

    .circle-avatar-ai-badge {
        position: absolute;
        z-index: 2;
        right: -5px;
        bottom: -1px;
        margin: 0;
        width: 2.5rem;
        min-width: 2.5rem;
        height: 2rem;
        border-radius: 100%;
    }

	&-xs {
		@include circle-avatar-styles();
		width: 2.4rem;
		min-width: 2.4rem;
		height: 2.4rem;

		.circle-avatar-online-indicator {
			right: -2px;
			bottom: -2px;
			width: 1rem;
			min-width: 1rem;
			height: 1rem;
		}

        .circle-avatar-ai-badge {
            width: 1.5rem;
            min-width: 1.5rem;
        }
	}

	&-sm {
		@include circle-avatar-styles();
		width: 3.6rem;
		min-width: 3.6rem;
		height: 3.6rem;

		.circle-avatar-online-indicator {
			right: 0px;
			bottom: 0px;
			width: 1.2rem;
			min-width: 1.2rem;
			height: 1.2rem;
		}

        .circle-avatar-ai-badge {
            width: 2rem;
            min-width: 2rem;
        }
	}

	&-md {
		@include circle-avatar-styles();
		width: 4.8rem;
		min-width: 4.8rem;
		height: 4.8rem;

		.circle-avatar-online-indicator {
			right: 0px;
			bottom: 0px;
			width: 1.4rem;
			min-width: 1.4rem;
			height: 1.4rem;
		}
	}

	&-lg {
		@include circle-avatar-styles();
		width: 6rem;
		min-width: 6rem;
		height: 6rem;

		.circle-avatar-online-indicator {
			right: 0px;
			bottom: 0px;
			width: 1.6rem;
			min-width: 1.6rem;
			height: 1.6rem;
		}

        .circle-avatar-ai-badge {
            width: 2rem;
            min-width: 2rem;
        }
	}

	&-xl {
		@include circle-avatar-styles();
		width: 10rem;
		min-width: 10rem;
		height: 10rem;

		.circle-avatar-online-indicator {
			right: 6px;
			bottom: 6px;
			width: 1.8rem;
			min-width: 1.8rem;
			height: 1.8rem;
		}
	}

	&-xxl {
		@include circle-avatar-styles();
		width: 13rem;
		min-width: 13rem;
		height: 13rem;

		.circle-avatar-online-indicator {
			right: 8px;
			bottom: 8px;
			width: 2rem;
			min-width: 2rem;
			height: 2rem;
		}
	}

	&-lg-2 {
        @include circle-avatar-styles();
        width: 4.8rem;
        min-width: 4.8rem;
        height: 4.8rem;
        @media (min-width: $screen-sm) and (max-width:($screen-md - 1px)) {
           width: 6rem;
           min-width: 6rem;
           height: 6rem;
		}
    }
}

span.circle-avatar-container, span.circle-avatar-container span {
	display: inline-block;
}

.circle-avatar {
	border-radius: 100%;
	clip-path: circle(50%);
	overflow: hidden;
	width: 100%;
	height: 100%;
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
	transition: transform 0.3s ease;

	&-default {
		background-position: center;
		background-repeat: no-repeat;
	}

	&-border {
		position: absolute;
		top: 0;
		left: 0;
		background-color: $grey3;
		border-radius: 100%;
		clip-path: circle(50%);
		width: $avatar-size;
		min-width: $avatar-size;
		height: $avatar-size;
		transition: background-color 0.3s ease;
	}

	&-link {
		border-radius: 100%;
		line-height: 0;

		&:hover {
			.circle-avatar {
				transform: scale(1.1);
			}
		}
	}
}

.circle-avatar-outer-container {
	position: relative;
	height: fit-content;
}

.file-upload-container {
	.circle-avatar-upload {
		position: absolute;
		z-index: 2;
		top: $avatar-border-width;
		left: $avatar-border-width;
		width: ($avatar-size - ($avatar-border-width*2));
		min-width: ($avatar-size - ($avatar-border-width*2));
		height: ($avatar-size - ($avatar-border-width*2));
	}
}

//Status Icons
.invited-talent-status-icons {
	position: absolute;
	right: 0;
	bottom: 0;
	line-height: 1rem;
	background: #fff;
	border-radius: 100%;

	i {
		font-size: 1.6rem;
	}

	.fa-check-circle {
		color: $green0;
	}

	.fa-times-circle {
		color: $red1;
	}

	.fa-scrubber {
		color: $grey1;
	}
}

.objectFitAvatar-container {
    display: flex;
}

.object-fit-avatar {
    object-fit: cover;
    object-position: center;
    aspect-ratio: 1;
	overflow-clip-margin: unset;
	overflow: visible;
	transform: translate3d(0px, 0px, 0.1px);
}

.member-avatar-group{
	display: flex;
	flex-direction: row;
	//width: 91.66%;
	flex: 0 0 auto;

	& >ul{
		padding-left:0px;
		margin:0;

		& > .member-avatar-group-item{
			padding-left: 0;
			list-style: none;
			margin-left: -15px;
		}

		& > .member-avatar-group-item:first-child{
			margin-left: 0;
		}
	}
}
