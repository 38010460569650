.expandable-content {
	max-height: 70px;
	overflow: hidden;

	&-large {
		max-height: 85px;
	}

	&-tags {
		max-height: 110px;
	}

	&.expanded {
		max-height: none;
	}
}