/**
 * 2. Foundation
 * Similar to "atoms" in atomic design. A single element that creates a style. (Defining a single style for an element)
 * Such as: typography styles, grid system, utility classes
 */
@import '@styles/utilities/normalize';
@import '@styles/utilities/typography';
@import '@styles/utilities/grid';
@import './utilities';
@import '@styles/utilities/print';

body.scroll-lock {
  overflow: hidden;
  height: 100%;
}