.project-public-list-item{
	display:flex !important;
	flex-direction:column;
    padding: 2.6rem 1.6rem;

    & + .project-public-list-item{
	    border-top:solid 1px $grey4;
        padding-top:2.6rem;
    }

	@media(min-width:$screen-sm){
		flex-direction:row;
	}
    
    .project-public-list-item-banner {
        height: 30rem;
        width: 100%;
        min-width: 100%;
        border-radius: 0.4rem;
        overflow: hidden;
        margin: 0 auto $spacing-small;

        img {
            object-fit: cover;
            height: 100%;
            width: 100%;
        }

        @media (min-width: $screen-sm) {
            margin: 0 $spacing-large 0 0;
            width: 18.6rem;
            min-width: 18.6rem;
            height: 12.9rem;
        }
    }

	.project-public-list-item-info{
		.project-public-list-item-title {
			font-size:1.6rem;
			font-weight:500;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
		}
		@media(min-width:$screen-sm){
			flex-grow:1;
            white-space: normal;
		}
	}

    a.project-public-list-item-title.project-public-list-item-info-title-search {
        overflow-wrap: break-word;
        word-wrap: break-word;
        white-space: normal;
    }

    .portfolio-list-item-portfolio-sample {
        margin-bottom: $spacing-small;

        .controls {
            margin-right: 0;
        }

        a.portfolio-list-item-translation-portfolio-preview-btn {
            margin-bottom: 0;
        }

        @media (min-width: $screen-sm) {
            max-width: 36.6rem;

            a.portfolio-list-item-translation-portfolio-preview-btn {
                max-width: 35.2rem;
            }
        }

        .view-details-link {
            width: 100%;

            @media (min-width: $screen-sm) {
                width: auto;
            }
        }
    }

	.project-public-talent-info{
		display:flex;
		align-items:center;
		.circle-avatar-outer-container{
			margin-right:$spacing-smallest;
			@media(min-width:$screen-sm){
				margin-right:$spacing-smaller;
			}
		}
		.project-public-item-talent-name{
			font-size:1.4rem;
			margin-top:0;
			margin-bottom:$spacing-smallest;
			font-weight:bold;
			@media(min-width:$screen-sm){
				margin-bottom:0;
			}
		}
		.stars{
			display:flex;
			@media(min-width:$screen-sm){
				margin-left:$spacing-smaller;
			}
		}
	}

    .project-public-talent-price {
        display: flex;
        justify-content: space-between;

        .project-public-talent-name-and-rating {
            @media (min-width: $screen-sm) {
                display: flex;
            }
        }

        .project-public-from {
            align-self: flex-end;
            line-height: 1;
            font-size: 1.4rem;
            color: $grey1;

            &:hover {
                .project-public-from-price {
                    color: $text-dark;
                    font-size: 2rem;
                    font-weight: 500;
                    text-decoration: none;
                }
            }

            .project-public-from-price {
                color: $text-dark;
                font-size: 2rem;
                font-weight: 500;
            }
        }
    }

    sup.project-public-from-price-decimal{
        font-weight: 400;
        font-size:1.2rem;
    }
}