// Customize SASS Variables for Bootstrap's Offcanvas Component.
$offcanvas-bg-color: $white;
$offcanvas-color: $text-dark;

// Import Bootstrap's Offcanvas Styles.
@import "@bootstrap/scss/offcanvas";

.offcanvas {

    // Fix .show class so display:block doesn't ruin inner layout of .offcanvas element
    &.show {
        display: flex !important;
        z-index: ($header-zindex + 2) !important;
    }

    // Override widths
    &.offcanvas-start,
    &.offcanvas-top,
    &.offcanvas-end,
    &.offcanvas-bottom {
        width: 100%;
        @media (min-width: $screen-md) {
            width: 400px;
        }
        @media (min-width: $screen-lg) {
            width: 560px;
        }
    }

    .close-btn {
        background: none;
        outline: none;
        border: none;
        color: $grey1;
    }
}

.offcanvas-inner-wrapper {
    display: flex;
    width: 100%;
    margin: 0 auto;
}

.offcanvas-header {
    display: flex;

    .offcanvas-inner-wrapper {
        flex-direction: column;
    }
}

.offcanvas-body {
    display: flex;

    .offcanvas-inner-wrapper {
        flex-direction: column;
    }

    &-action-bar {
        display: flex;
        flex-direction: column;

        &-btns {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 2.4rem;
        }

        .searchbar-container {
            width: 100%;
            margin-bottom: 2.4rem;
        }
    }
}

.offcanvas-footer {
    padding: $spacing-medium;
    border-top: 1px solid $grey5;

    .offcanvas-inner-wrapper {
        flex-direction: row;
        justify-content: space-between;
    }
}

.offcanvas-backdrop {
    z-index: ($header-zindex + 1);
}