#results-refinement-slideout-body {
  margin-top: 3.6rem;
}

.results-refinement-slideout-body-heading {
  font-weight: 500;
  display: block;
  margin-bottom: 0.8rem;
}

#results-refinement-slideout-scrollable-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 2.4rem;
  overflow-y: auto;
}

#results-refinement-slideout {
  position: fixed;
  z-index: 999999;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-color: #fff;
  transition: transform 0.3s ease;
  transform: translateX(100%);
  &.opened {
    transform: translateX(0);
  }

  &-close-btn {
    background: none;
    outline: none;
    border: none;
    position: absolute;
    top: 1.6rem;
    right: 1.6rem;
    width: 4rem;
    height: 4rem;
    color: $grey1;
  }

  .btns-container {
    display: flex;
    flex-direction: column;
    margin-top: 3.6rem;
    .btn {
      margin-right: 0;
      ~ .btn {
        margin-top: 1.6rem;
      }
    }
  }

  .radio-group-container {
    ~ .radio-group-container {
      padding-top: 1.6rem;
      margin-top: 1.6rem;
      border-top: 1px solid $grey4;
    }
  }

  .radio {
    label {
      color: $text-dark;
    }
  }
}