.empty-state {
	display: flex;
	align-items: center;
	flex-direction: column;
	margin-top: $spacing-largest;
	margin-bottom: $spacing-largest;
	@media (min-width:$screen-xs) {
        margin-top: $spacing-huge;
		margin-bottom: $spacing-huge;
	}

	// ------ Horizontal Layout (Modifier Class) ------
	&.empty-state--horizontal {
		@media (min-width:$screen-md) {
			flex-direction: row;
			padding-left: $spacing-huge;
			padding-right: $spacing-huge;
		}

		.empty-state-graphic {
			@media (min-width:$screen-md) {
				width: 50%;
				max-width: 100%;
				margin-bottom: 0;
				margin-right: $spacing-huge;
			}

			+ .empty-state-content {
				@media (min-width:$screen-md) {
					margin-left: $spacing-huge;
				}
			}
		}

		.empty-state-content {
			@media (min-width:$screen-md) {
				width: 50%;
				max-width: 100%;
				text-align: left;
			}
		}
	}

	.empty-state-graphic {
		width: 100%;
		max-width: 32rem;
		margin-bottom: $spacing-large;
		@media (min-width:$screen-sm) {
			margin-bottom: $spacing-larger;
		}

		img {
			display: block;
			margin-left: auto;
			margin-right: auto;
			width: 100%;
			// Fix these icons widths, since other icons needed extra artboard space to allow for the shadow
			// to be displayed okay... and now all icons will be scaled to the same size
			&[src$="empty-shortlist.svg"],
			&[src$="empty-jobs.svg"] {
				width: 93%;
			}
			&[src$="empty-reviews.svg"] {
				width: 80%;
			}
		}
	}

	.empty-state-content {
		width: 100%;
		max-width: 38rem;
		text-align: center;

		&.empty-state-content--lg {
			max-width: 48rem;
		}

		&.empty-state-content--xl {
			max-width: 58rem;
		}

		&.empty-state-content--xxl {
			max-width: 68rem;
		}

		h2, h3, h4, h5, h6 {
			font-size: 2.8rem;
			font-weight: $font-weight-medium;
			color: $grey1;
			margin-top: 0;
			margin-bottom: $spacing-smaller;
			span {
				font-size: .5em;
			}
		}

		p {
			font-size: 1.8rem;
			color: $grey1;
			max-width: 90%;
			margin-left: auto;
			margin-right: auto;
			margin-bottom: 0;
			@media (min-width: $screen-md) {
				max-width: 100%;
			}
		}

		a, button {
			margin-top: $spacing-larger;
			margin-right: 0;
			width: 100%;
			@media (min-width:$screen-xs) {
				width: auto;
			}
			~ a, ~ button {
				@media (max-width:($screen-xs - 1px)) {
					margin-top: $spacing-medium;
				}
				@media (min-width:$screen-xs) {
					margin-left: $spacing-medium;
				}
			}
		}
	}
}

// Empty States Inside Layout Body with List Items (that normally have filters)
.layout-body.layout-body-group-item {
	.empty-state {
		border-top: 1px solid $grey4;
		padding-top: $spacing-largest;
		padding-bottom: $spacing-largest;
		margin: 0;
		@media (min-width: $screen-xs) {
			padding-top: $spacing-huge;
			padding-bottom: $spacing-huge;
		}
	}
}