@import '@styles/variables/global-variables';

/* ****************************** */
/* 17 - JOBS PAGE */
/* ****************************** */

// 17.a - VoiceMatch circle
.vm-score-block {
	.circle {
		margin: .25em auto 0;
	}

	// circle
}

// vm-score-block

//Talent jobs list only. Header checkbox.
#select-all-talent-job .checkbox {
	margin-top: 6px;
}

#editShortlistContainer .alert-success {
	margin-top: 1.5rem;
	display: none;
}

.proserv-info-wrap {
	padding: 2rem;
	text-align: right;

	.proserv-info-item {
		display: inline;
		margin-right: 2rem;
	}
}


#sticky-footer {
	position: fixed;
	bottom: 0;
	width: 100%;
	z-index: 999;
	background: $white;
	margin-right: 15px;
}

// Action List for job details 

i.action-list-btn.jdetails, i.action-list-btn.jlist {
	border-radius: 100%;
	background-color: $white;
	border: 1px solid $grey3;
	transition: all 0.3s ease-in-out;

	&:hover {
		border: 1px solid $grey3 !important;
		background-color: $grey4 !important;
	}

	&:active {
		border: 1px solid $grey3 !important;
		background-color: $grey4 !important;
	}
}

i.action-list-btn.jdetails {
	width: 5rem;
	height: 5rem;
}

.file-details-box {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	width: 100%;
	max-width: 35.6rem;
	background-color: $grey5;
	padding: $spacing-large;
	margin-bottom: $spacing-medium;
	font-size: 1.6rem;
	border-radius: 4px;
	word-break: break-all;

	&-links {
		a {
			font-weight: bold;
			font-size: 1.6rem;
		}
	}
}

.project-code {
	font-size: 1.4rem;
	margin-top: 0.4rem;
}

.status {
	vertical-align: middle;
	margin-top: -2px;
}

.job-details-header {
	font-size: 1.8rem;
	font-weight: normal;
}

.proserve-head .status {
	margin-left: 1.2rem;
}

.job-header {
	h1 {
		overflow-wrap: break-word;
	}
}

.job-header span.status {
	margin-right: $spacing-smallest;
}

.label-status-workedtogether {
	color: $grey0;
	border: 2px solid $yellow3;
}