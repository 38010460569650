/**
 * BIG TODO: Go through WebApp & Marketing Applications, and determine what Bootstrap dependencies can be removed.
 */

/**
 * Base Styles
 */
@import './base/styles';

/**
 * Foundational Styles
 */
@import './foundation/styles';

/**
 * MIXINS
 */
@import './mixins/container-styles'; // @include container-styles;
@import './mixins/talent-selection-styles'; // @include talent-selection-styles;

/**
 * Components
 * Similar to "molecules" in atomic design. An element that is created by multiple atoms to create a new element.
 * Such as: buttons, inputs, alert boxes, etc
 */

// Shared Folder

@import '@styles/components/avatars';
@import '@styles/components/button-groups';
@import '@styles/components/buttons';
@import '@styles/components/badges';
@import '@styles/components/decorative-icons';
@import '@styles/components/forms';
@import '@styles/components/checkbox';
@import '@styles/components/input-groups';
@import '@styles/components/navs';
@import '@styles/components/tables';
@import '@styles/components/labels';
@import '@styles/components/footer';
@import '@styles/components/header';
@import '@styles/components/header-bare';
@import '@styles/components/datepicker';
@import '@styles/components/layout';
@import '@styles/components/modals';
@import '@styles/components/pagination';
@import '@styles/components/accordions';
@import '@styles/components/radio-input';
@import '@styles/components/stars';
@import '@styles/components/status-labels';
@import '@styles/components/toggle-input';
@import '@styles/components/voices-audio-player';
@import '@styles/components/offcanvas';
@import '@styles/components/results-refinement-mobile-slideout';
@import '@styles/components/dropdowns';
@import '@styles/components/react-component-styles';
@import '@styles/components/sortable';

// Main App Specific Components
@import './components/shared/alerts';
@import './components/shared/breadcrumbs';
@import './components/shared/icons';
@import './components/shared/login-form';
@import './components/shared/signup-form';
@import './components/webapp/accordions';
@import './components/webapp/action-lists';
@import './components/webapp/bootstrap-cards';
@import './components/webapp/cards';
@import './components/webapp/carousel';
@import './components/webapp/checkbox-row';
@import './components/webapp/compliments';
@import './components/webapp/connected-container';
@import './components/webapp/credit-card';
@import './components/webapp/dl'; /* html5 datalist */
@import './components/webapp/empty-states';
@import './components/webapp/expandable-content';
@import './components/webapp/file-upload';
@import './components/webapp/image-scaled-to-fit';
@import './components/webapp/lazyloader';
@import './components/webapp/lists';
@import './components/webapp/list-group';
@import './components/webapp/loading-skeleton';
@import './components/webapp/modals-onboarding-modals';
@import './components/webapp/modals-widget-modals';
@import './components/webapp/modals-request-a-quote-modal';
@import './components/webapp/modals-talent-selection-modal';
@import './components/webapp/multi-step-navigation';
@import './components/webapp/page-header-container';
@import './components/webapp/panels';
@import './components/webapp/popovers';
@import './components/webapp/portfolio-list-item';
@import './components/webapp/progress-bars';
@import './components/webapp/radio-card';
@import './components/webapp/checkbox-card';
@import './components/webapp/radio-toggle';
@import './components/webapp/readmore';
@import './components/webapp/sidebar-nav';
@import './components/webapp/spotlights';
@import './components/webapp/sub-navigation';
@import './components/webapp/tags';
@import './components/webapp/tags-input';
@import './components/webapp/toast';
@import './components/webapp/tooltip';
@import './components/webapp/flyout';
@import './components/webapp/hotspot';
@import './components/webapp/talent-selection-offcanvas';
