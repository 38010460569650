@import '@styles/variables/global-variables';

#password-validation-pattern{
    list-style-type:none;
    padding-left:0;
    margin-bottom:0;
    margin-top:0.8rem;
    .password-validation-item{
        position:relative;
        padding-left:2.2rem;
        font-size:1.4rem;
        color: $grey1;

        & + .password-validation-item {
            margin-top:0.4rem;
        }

        &::before{
            height:18px;
            width:18px;
            border:1px solid $grey2;
            border-radius:50%;
            content:'';
            position:absolute;
            left:0;
            top:1px;
        }

        &.checked,
        &.error{
            &::before{
                font-family:'Font Awesome 5 Pro';
                font-weight: bold;
                line-height:1;
                border:none;
                font-size:1.8rem;
            }
        }

        &.checked{
            &::before{
                color:$green1;
                content: '\f058';
            }
        }
        &.error{
            color:$red1;
            font-weight:bold;
            &::before{
                content: '\f06a';
            }
        }
        
    }
}