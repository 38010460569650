// Fix Page Content Position from Fixed Header
#bare3-template #header-bare {
	+ #main-page {
		margin-top: $header-height;
		padding-bottom: $header-height !important;
		min-height: calc(100vh - $header-height) !important;
	}

	&.align-center {
		+ #main-page {
			display: flex;
			align-items: center;
		}
	}


	&.mobile-bottom-container {
		+ #split-main-div {
			@media (max-width: $screen-sm - 1px) {
				margin-top: calc($header-height + 35px);
				min-height: calc(100vh - $header-height + 35px) !important;
			}
		}
	}
}

// Simple Header Menu
#header-bare {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	z-index: 4;
	margin-bottom: $spacing-huge;

	// Previewing a page which uses Header Bare template
	// e.g - Public Shared Favorite List
	&.header-bare-preview {
		position: relative;
		margin-bottom: 0;
		.container {
			width: 100%;
			padding: 1.6rem;
		}
	}

	&.blue-theme {
		background-color: $primary;
		#header-title {
			color: $white;
		}
		#header-bare-content-container {
			color: $white;
			a:not(.btn) {
				color: $white;
			}
		}
		#header-logo {
			margin-right: calc($spacing-medium / 2);
		}
	}

	&.white-theme {
		background-color: $white;
		box-shadow: 0 2px 15px 0 rgba(#000, calc(5/100)), 0 2px 5px 0 rgba($text-dark, calc(10/100)); // .shadow-1
		#header-title {
			color: $grey1;
		}
		#header-bare-content-container {
			color: $grey1;
			a:not(.btn) {
				color: $blue1;
			}
		}
	}

	.container {
		padding: $spacing-medium;
		display: flex;
		justify-content: space-between;
		align-items: center;
		min-height: 7.2rem;
		height: 7.2rem;

		>.row {
			width: 100%;
			margin: - $spacing-medium;
		}
	}

	.bottom-container {
		border-top: 1px solid $grey4;
		padding: $spacing-smaller $spacing-medium;
	}

	.inner-container {
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	#header-bare-logo-container {
		display: flex;
		align-items: center;
	}

	#header-logo {
		display: inline-block;
		max-width: 4rem;
		width: 4rem;
		@media (min-width: $screen-sm) {
			max-width: 12rem;
			width: 12rem;
			margin-right: $spacing-large;
		}

		img {
			min-width: 2.5rem;
			width: 2.5rem;

			@media (min-width: $screen-sm) {
				min-width: 4rem;
				width: auto;
			}
		}
	}

	#header-custom-logo {
        max-width: 10rem;
        width: auto;
		height: auto;
		max-height: 3rem;
        @media (min-width: $screen-xs) {
            max-width: 16rem;
            max-height: 5rem;
            margin-right: $spacing-small;
        }
	}

	#header-title {
		border-left: 1px solid $grey4;
		padding-left: 1.2rem;
		@media (min-width: $screen-sm) {
			padding-left: $spacing-large;
		}
	}

	#header-bare-content-container {
		display: flex;
		align-items: center;
		font-size: $font-size-small;
		color: $white;
		margin: 0;
		#header-bare-custom-content + .btn {
			margin-left: 2rem;
		}
	}

	#header-bare-custom-content {
		display: flex;
		flex: 1 0 auto;
		font-weight: $font-weight-medium;
		p {
			margin-bottom: 0;
		}
	}

	&.header-border {
		.container {
			border-bottom: 1px solid rgba($white, 0.2);
		}
	}

	// --------------------------------------------------------
	// Custom Header Bare Styles
	// --------------------------------------------------------

	// Public Shared Favorite List or Public Shared Shortlist
	&.public-shared-list-header {
		@media (max-width: ($screen-xs - 1px)) {
			position: relative;
			margin-bottom: 0;
			+ #main-page {
				margin-top: 0 !important;
			}
		}
		.container {
			@media (max-width: ($screen-xs - 1px)) {
				height: auto;
				min-height: auto;
			}
		}
		#header-bare-custom-content > * ~ * {
			@media (min-width: $screen-xs) {
				margin-left: 10px;
				padding-left: 10px;
				border-left: 1px solid $grey3;
			}
		}
		.btn-default {
			@media (max-width: ($screen-sm - 1px)) {
				display: none;
			}
		}
	}
}