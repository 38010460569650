#request-a-quote-modal {
  ul#circle-avatars-list {
    margin: 0;
    padding: 0;
    display: flex;
    margin-right: $spacing-small;
    li {
      list-style: none;
    }
  }

  // Only display for multi-invites.
  #circle-avatar-list-item2,
  #circle-avatar-list-item3,
  #circle-avatar-list-item4,
  #circle-avatar-list-item5 {
    display: none;
    margin-left: -2.4rem;
  }

  #avatar-and-toggle {
    @media (max-width: ($screen-sm - 1px)) {
      flex-direction: column;
      align-items: flex-start;

      .toggle-container {
        flex-direction: row;
        gap: 1rem;
        margin-left: 0px;
      }
    }
  }

  .toggle-input-container {
    display: flex;
    align-items: flex-start;
    margin-top: $spacing-small;
    flex-direction: row;

    @media screen and (min-width: $screen-sm) {
      flex-direction: column;
      align-items: flex-end;
      margin-top: 0;
    }

    .toggle-input-label-text {
      margin-left: 4px;
      margin-top: 0;

      @media screen and (min-width: $screen-sm) {
        margin-left: $spacing-smaller;
      }
    }
  }

}