@import "@bootstrap/scss/forms";

// ===============================================================
// Voices.com Forms
// ===============================================================
@import '@styles/mixins/forms-mixins';

form {
	h2 {
		margin-top: 3rem;
	}

	h3 {
		margin-top: 3rem;
	}

	h4 {
		margin-top: 3rem;
	}

	h5 {
		margin-top: 2.6rem;
	}

	h6 {
		margin-top: 2rem;
	}

	.ruled-b {
		border-bottom: 1px solid $blue4;
		margin-bottom: 1.6rem;
	}

	// 21.a - Fieldsets
	fieldset {
		border: 1px solid $grey4;
		padding: 1rem;

		> h2 {
			margin-top: 1rem;
		}

		> h3 {
			margin-top: 1rem;
		}
	}

	// 21.b - Multi-column rows
	@media screen and (max-width: 480px) {
		.row.multicol {
			// Class applied to parent .row of multi-column checkbox or radio buttons.
			> div + div {
				// remove margin from top of second column .radio element
				.radio:first-of-type,
				.checkbox:first-of-type {
					margin-top: 0.6rem;
				}
			}

			> div {
				// remove margin from bottom of all column .radio elements
				.radio:last-of-type,
				.checkbox:last-of-type {
					margin-bottom: 0;
				}
			}
		}
	}
}

.form-horizontal {
	.checkbox {
		padding-top: 7px;
		margin-top: 0;
		margin-bottom: 0;
		min-height: 27px;
	}

	.checkbox-inline {
		padding-top: 7px;
		margin-top: 0;
		margin-bottom: 0;
	}

	.radio {
		padding-top: 7px;
		margin-top: 0;
		margin-bottom: 0;
		min-height: 27px;
	}

	.radio-inline {
		padding-top: 7px;
		margin-top: 0;
		margin-bottom: 0;
	}

	.form-group {
		margin-right: -15px;
		margin-left: -15px;

		&:after {
			display: table;
			content: " ";
			clear: both;
		}

		&:before {
			display: table;
			content: " ";
		}
	}

	.has-feedback {
		.form-control-feedback {
			right: 15px;
		}
	}
}

/* *********************************************    */
// 21.c - Form Groups **************************
/* *********************************************    */

// Form Labels
.form-group {
	label,
	.form-label {
		font-size: 1.6rem;
		font-weight: 500;
		color: $text-dark;
		margin-bottom: $spacing-smaller;

		+ .form-label-description {
			margin-top: -1rem;
		}
	}

	.form-label {
		display: block;
	}

	.form-label-description {
		font-size: 1.4rem;
		color: #4f5963;
	}
}

// Form Group
.form-group {
	margin-bottom: 2.4rem; // Override Bootstrap's 15px
	&::-webkit-input-placeholder::before {
		font-family: fontAwesome;
		content: '\f002  ';
		color: $blue3;
	}

	&::-moz-placeholder::before {
		font-family: fontAwesome;
		content: '\f002  ';
		color: $blue3;
	}

	&:-ms-input-placeholder::before {
		font-family: fontAwesome;
		content: '\f002  ';
		color: $blue3;
	}

	input::placeholder::before {
		font-family: fontAwesome;
		content: '\f002  ';
		color: $blue3;
	}

	// Disabled
	.form-control[disabled],
	fieldset[disabled] .form-control {
		background-color: $grey5;
		border: 1px solid $grey4;
		color: $grey2;
	}

	// Readonly
	.form-control[readonly] {
		background-color: $grey5;
		border: 1px solid $grey4;
		color: $text-dark;
	}

	.fa {
		float: left;
		padding-right: 1em;
		margin-left: 0.4em;
		line-height: 2.25em;
	}

	.fa + select.form-control {
		display: inline-block;
		width: auto;
		float: left;
	}

	.date-field {
		.fa {
			line-height: 1.66em;
		}
	}
}

.form-control {
	color: $grey1;
	padding: 1.2rem;
	font-size: 1.6rem;
	line-height: $line-height-base;
	border-radius: 4px;
	box-shadow: none;
	-webkit-box-shadow: none;
	-o-box-shadow: none;
	height: auto;
	-webkit-transition: border-color ease-in-out 0.15s;
	-moz-transition: border-color ease-in-out 0.15s;
	-o-transition: border-color ease-in-out 0.15s;
	transition: border-color ease-in-out 0.15s;
	background: $white;
	border: 1px solid $grey4;

	&:focus {
		color: $grey0;
	}

	&.form-control-feedback {
		margin-top: 0.25rem;
	}
}

.form-control.form-control-sm {
	font-size: 1.2rem;
}

.form-control.form-control-lg {
	font-size: 1.8rem;
}

.form-control.form-control-xl {
	font-size: 2.6rem;
}

.form-help {
	color: $grey2;
	font-size: 1.6rem;
}

.form-control-feedback {
	display: block;
	float: none;
	position: relative;
	width: auto;
	text-align: left;
}

/* *********************************************    */
// 21.d - Form Field Styling *******************
/* *********************************************    */
input,
text,
textarea {
	border-radius: 4px;
	border: 1px solid $grey4;
	padding: 6px 10px;
}

/*
 * Input :Focus Styling
 */
select.form-control:focus {
	border-bottom-right-radius: 0; // Remove bottom radius on drop-down field
	border-bottom-left-radius: 0; // Remove bottom radius on drop-down field
}

input:focus,
text:focus,
textarea:focus {
	-webkit-box-shadow: 0 0 8px 0 rgba(102, 175, 233, 0.6) !important; // for Firefox!
	-moz-box-shadow: 0 0 8px 0 rgba(102, 175, 233, 0.6) !important;
	box-shadow: 0 0 8px 0 rgba(102, 175, 233, 0.6) !important;
	border: 1px solid #66afe9 !important;
	outline: none;
}

// Bootstrap Checkbox
.checkbox {
	label {
		font-size: 1.6rem;
		font-weight: $font-weight-normal;
		color: $text-dark;

		&::after {
			text-align: center; // checkbox
		}

		&.sr-only {
			padding: 0;
		}
	}
}

/* In voicesTheme.js we use this class to
 * add focus highlighting to Select2 Multi-selects when tabbing through form inputs
 */
.focus-highlight {
	-webkit-box-shadow: 0 0 8px 0 rgba(102, 175, 233, 0.6) !important; // for Firefox!
	-moz-box-shadow: 0 0 8px 0 rgba(102, 175, 233, 0.6) !important;
	box-shadow: 0 0 8px 0 rgba(102, 175, 233, 0.6) !important;
	border: 1px solid #66afe9 !important;
	outline: none;
}

// 21.h - Form status rules (probably not used)
.has-success .form-control {
	border-color: $success;
}

.has-danger .form-control {
	border-color: $danger;
}

.has-warning .form-control {
	border-color: $warning;
}

.form-control-feedback {
	display: block;
	float: none;
	margin-bottom: 0.5rem;
}

.has-success .col-form-label,
.has-success .custom-control,
.has-success .form-check-label,
.has-success .form-control-feedback,
.has-success .form-control-label {
	color: $success;
	margin-top: 0.8rem;
}

.has-warning .col-form-label,
.has-warning .custom-control,
.has-warning .form-check-label,
.has-warning .form-control-feedback,
.has-warning .form-control-label {
	color: $warning;
	margin-top: 0.8rem;
}

.has-danger .col-form-label,
.has-danger .custom-control,
.has-danger .form-check-label,
.has-danger .form-control-feedback,
.has-danger .form-control-label {
	color: $danger;
	margin-top: 0.8rem;
}

/* **********************************************/
// Form Actions (Buttons at bottom of Forms) ****
/* **********************************************/
.form-actions {
	margin-top: 3rem;
	margin-bottom: 3rem;

	button,
	a {
		margin-bottom: 1rem;
	}

	&.form-actions-inline {
		display: flex;
		flex-wrap: wrap;
		@media (min-width: 480px) {
			flex-wrap: nowrap;
		}

		> * {
			width: 100%;
			@media (min-width: 480px) {
				width: auto;
			}

			~ * {
				@media (min-width: 480px) {
					margin-left: 10px;
				}
			}

			input,
			button,
			a {
				width: 100%;
				@media (min-width: 480px) {
					width: auto;
				}
			}
		}
	}
}

// TODO : Verify that this is needed
/* For Select2 placeholders with allowClear */
.placeholder {
	color: $grey1;
}

// Automate all inputs placeholder color styles
* {
	@include automate-placeholder-styles();
}

/* -------------------------------
   Calendar popup link
   ------------------------------- */

.calendar-input {
	padding: 6px 0;
	margin-left: -33px;
	cursor: pointer;
	height: 38px;
	display: inline-block;
	vertical-align: top;
}

/* --------------------------------
 * Error Styling
 * -------------------------------- */

.form-input-error,
input[type="radio"].form-input-error + label {
	border: 1px solid $red1 !important;
	background-color: $red5 !important;

	&:focus {
		border: 1px solid $red1 !important;
		box-shadow: 0 0 8px 0 rgba($red, 0.6) !important;
	}
}

.form-input-message-error {
	display: flex;
	width: 100%;
	align-items: center;
	position: relative;
	margin-top: .4rem;
	font-size: 14px;
	line-height: 1;
	color: #d1462a;

	&::before {
		content: $fa-var-exclamation-circle;
		font-family: 'Font Awesome 5 Pro';
		font-size: 14px;
		font-weight: 300;
		color: $red1;
		font-weight: 600;
		font-size: 1.8rem;
		margin-right: 0.4rem;
	}
}

// Choices.js Custom Error Styles
.form-input-error.choices {
	border: none !important;
	background: none !important;
	box-shadow: none !important;

	.choices__inner {
		border: 1px solid $red1;
		background-color: $red5;
	}

	&.is-focused {
		.choices__inner {
			border: 1px solid $red1 !important;
			box-shadow: 0 0 0 1px $red1 !important;
		}
	}
}

// ---------------------- Specific Fields --------------------- //

.inline-fields {
	display: flex;
	gap: $spacing-medium;
	flex-wrap: wrap;
}

.price-field,
.date-field,
.link-field {
	position: relative;

	&:before {
		font-family: 'Font Awesome 5 Pro';
		font-weight: 500;
		position: absolute;
		top: 12px;
		color: $grey1;
		font-size: 16px;
		left: 0;
		padding: 0 1.8rem;
	}

	input {
		padding-left: 4.5rem;
	}
}

.price-field {
	&:before {
		content: '\f155';
	}
}

.date-field {
	&:before {
		content: '\f073';
		z-index: 1;
	}

	.datepicker-clear-btn {
		position: absolute;
		top: 15px;
		right: 10px;
		padding: 0;
		background: none;
		border: none;
		outline: none;
		transition: 0.3s ease color;

		&:hover {
			color: $grey2;
		}
	}
}

.link-field {
	&:before {
		content: '\f0c1';
	}
}

// ---------------------- Textarea --------------------- //

textarea {
	resize: vertical;
}

// ---------------------- Character Counter --------------------- //

.character-counter {
	font-size: 1.4rem;
	color: $grey1;
	font-weight: $font-weight-normal;
}

.checkbox.checkbox-primary {
	label {
		&::after {
			font-size: 14px;
		}
	}
}

.form-control-plaintext {
	border: 1px $grey4 solid;
}

// ---------------------- Form Row --------------------- //

.form-row {
	display: flex;
	flex-direction: column;
}