.file-upload-block {
    border: 5px dotted $ice5;
    padding: .75em 2em .5em;
}

.drop-zone {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: center;
    border-radius: 4px;
    border: dashed 1px $grey3;
    padding: $spacing-larger;
    margin-bottom: $spacing-larger;
    background-color: $white;

    @media (max-width: ($screen-xs-max)) {
        padding: $spacing-large;
    }

    @media (min-width: $screen-xs) {
        flex-direction: row;
    }

    p {
        margin: 0;
    }

    button {
        margin-right: 0;
        margin-top: $spacing-medium;

        @media (min-width: $screen-xs) {
            margin-top: 0;
        }
    }
}