@import '@styles/mixins/input-mixins';

.checkbox-card-group {
   @include input-card-group;
}

.checkbox-card {
    &[class*="col-"] {
        @include input-card-col;
    }

    // Left Aligned Card Content
    &.checkbox-card-left-align label{
       @include input-card-align;
        .checkbox-card-content {
            margin-left: $spacing-medium;
        }
    }

    // Center Aligned Card Content
    &.checkbox-card-center-align label {
        @include input-card-align;
        .checkbox-card-content {
            margin-left: $spacing-medium;
        }
        @media(min-width:$screen-md) {
            flex-wrap: wrap;
            justify-content: center;
            .checkbox-card-content {
                text-align: center;
                justify-content: center;
                margin-left: 0;
            }
        }
    }

    & .checkbox-content-layout {
        display: flex;
        flex-wrap: nowrap;
        @media(max-width:$screen-sm){
            flex-wrap: wrap;
            text-align: center;
            justify-content: center;
            margin-left: 0;
        }
    }

    // Checkbox Interactions
    input[type="checkbox"] {
        // Hide the actual input element
        // the "checkbox" styles will be built off the pseudo ::after element, so we can customize it.
        height: 0;
        width: 0;
        position: absolute;
        opacity: 0;

        // Checkbox Styles
        + label {
            display: block;
            border-radius: .4rem;
            font-size: 1.4rem !important;
            font-weight: 500;
            padding: 2rem;
            margin: 0;
            cursor: pointer;
            border-width: 1px;
            border-style: solid;
            line-height: 1.5 !important;
            border-color: $grey4;
            color: $grey1;

            @media (min-width: $screen-md) {
                padding-right: 6rem;
            }

            // Outline
            &::before {
                content: ' ';
                position: absolute;
                top: 2.4rem;
                right: 2.4rem;
                left: auto;
                display: block;
                width: 2rem;
                height: 2rem;
                border-width: 1px;
                border-style: solid;
                border-radius: 0.4rem;
                border-color: $grey4;
                cursor: pointer;
                cursor: pointer;
            }

            // Checked
            &::after {
                @extend .fa;
                content: $fa-var-check;
                position: absolute;
                top: 2.4rem;
                right: 2.4rem;
                left: auto;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 1.2rem;
                color: $grey4;
                opacity: 0;
                padding-right: 0 !important;
                margin-left: 0 !important;
                width: 2rem;
                height: 2rem;
                border-radius: 0.4rem;
            }
        }

        // ---------------------------------
        // -- Selected State
        // ---------------------------------
        &:checked + label {
            box-shadow: 0 0 0 2px $primary;
            &:hover {
                box-shadow: 0 5px 10px #0003, 0 0 0 2px $primary;
            }
            &::after {
                opacity: 1;
                background-color: $primary;
                color: $white;
            }
        }

        // ---------------------------------
        // -- Disabled State
        // ---------------------------------
        &:disabled {
            opacity: 0.4;
            cursor: default;
            + label {
                cursor: not-allowed;
            }
            &:not(:checked) + label:after {
                opacity: 0;
            }
        }
    }

    // The Card Display... which is built by the <label> element
    label {
        @include input-card-label;

        .checkbox-card-content {
            @include input-card-content;
        }

        .checkbox-card-title {
            @include input-card-title;
        }

        .checkbox-card-text {
            @include input-card-text;
        }
    }
}
