// Text Alignment *********************************************
// Add !important to override for ex. .btn defaults
.text-left {
	text-align: left !important;
}

.text-right {
	text-align: right !important;
}

.text-center {
	text-align: center !important;
}

/**
 * Text Scaling Classes
 *
 * Note: Moving forward, these scaling classes should never be used on headings!
 *  If any instances of <h1> to <h6> tags are found with these classes applied, please remove them!
 */
@mixin text-xxs {
	font-size: ($font-size-base - 0.6) !important; // 1.2rem
}

@mixin text-xs {
	font-size: ($font-size-base - 0.4) !important; // 1.4rem
}

@mixin text-sm {
	font-size: ($font-size-base - 0.2) !important; // 1.6rem
}

@mixin text-lg {
	font-size: ($font-size-base + 0.2) !important; // 1.6rem
}

.text-xxs {
	@include text-xxs;
}

.text-xs {
	@include text-xs;
}

.text-sm {
	@include text-sm;
}

small {
	font-size: ($font-size-base - 0.2);
}

.text-md {
	font-size: $font-size-base !important; // 1.8rem
}

.text-lg {
	@include text-lg;
}

.text-xl {
	font-size: ($font-size-base + 0.6) !important; // 2.4rem
}

.text-xxl {
	font-size: ($font-size-base + 1) !important; // 2.8rem
}

// Word Wrapping *********************************************
.no-overflow,
.text-ellipsis {
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
}

/* Break words to prevent overflow */
.overflow-break {
	/* These are technically the same, but use both */
	overflow-wrap: break-word;
	word-wrap: break-word;
	-ms-word-break: break-all;
	/* This is the dangerous one in WebKit, as it breaks things wherever */
	word-break: break-all;
	/* Instead use this non-standard one: */
	word-break: break-word;

	/* Adds a hyphen where the word breaks, if supported (No Blink) */
	-ms-hyphens: auto;
	-moz-hyphens: auto;
	-webkit-hyphens: auto;
	hyphens: auto;
}

.break-word {
	word-break: break-all;
}

.break-word-nowrap {
	word-break: break-word;
	overflow-wrap: break-word;
}

.white-space-no-wrap {
	white-space: nowrap !important;
}

// Font Weights *********************************************
.font-regular {
	font-weight: $font-weight-normal;
}

.font-medium {
	font-weight: $font-weight-medium;
}

.font-bold {
	font-weight: $font-weight-bold;
}

// Smooth Rendering Fonts?? *********************************************
.font-clear {
	-webkit-font-smoothing: antialiased;
	font-weight: 500;
}

@mixin text-color($color) {
	color: $color !important;
}

.text-blue0 {
	@include text-color($blue0);
}

.text-blue1 {
	@include text-color($blue1);
}

.text-green0 {
	@include text-color($green0);
}

.text-red1 {
	@include text-color($accent1);
}

.text-grey0 {
	@include text-color($grey0);
}

.text-grey1 {
	@include text-color($grey1);
}

.text-grey2 {
	@include text-color($grey2);
}

.text-grey3 {
	@include text-color($grey3);
}

.text-primary {
	@include text-color($primary);
}

.text-info {
	@include text-color($info);
}

.text-success {
	@include text-color($success);
}

.text-warning {
	@include text-color($warning);
}

.text-danger {
	@include text-color($danger);
}

.text-white.text-white {
	@include text-color(#fff);
}

.text-inverse {
	@include text-color($text-inverse);
}

.text-light {
	@include text-color($text-light);
}

.text-dark {
	@include text-color($text-dark);
}

.text-purple1 {
	@include text-color($purple1);
}

.text-yellow0 {
	@include text-color($yellow0);
}

.user-select-none {
	user-select: none;
}

.white-space-no-wrap {
	white-space: nowrap;
}
