.vertical-border {
	position: absolute;
	width: 0.8rem;
	height: 100%;
	background: $grey5;
	@extend .border-radius;
}

#remove-gutter {
	@media screen and (min-width: $screen-sm) and (max-width: $screen-md - 1px) {
		h1 {
			font-size: 3.6rem;
		}
	}
	@media screen and (max-width: $screen-sm - 1px) {
		padding-left: 0;
		padding-right: 0;

		h1 {
			font-size: 2.8rem;
		}
	}
}

@media screen and (max-width: $screen-sm - 1px) {
	#submit-review-btn, #cancel-btn {
		display: block;
		width: 100%;
	}
}

.break-word-nowrap {
	word-break: break-word;
	overflow-wrap: break-word;
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
	.break-word-nowrap {
		word-break: break-all;
		overflow-wrap: break-word;
	}
}

.feedback-icon {
	@extend .padding-left-smaller;

	ul {

		list-style: none;
		@extend .padding-left-zero;
		@extend .padding-top-small;

		li {

			display: inline-block;
			text-align: center;

			label {
				cursor: pointer;
			}

			input[type="radio"] {
				display: none;
			}

			.icon-list-categories {
				@extend .border;
				border-radius: 50%;
				text-align: center;
				padding: 1.6rem;
				@media screen and (max-width: 480px) {
					padding: 1rem;
				}
				display: inline-flex;

				i.deco-icon {
					display: inline-flex;
					vertical-align: middle;
				}

				&:hover {
					background-color: $grey5;
					cursor: pointer;
				}
			}

			@media screen and (max-width: 480px) {
				p {
					@include text-xxs;
				}
			}

			&:hover {
				cursor: pointer;

				.icon-list-categories {
					background-color: $grey5;
				}

				p {
					@extend .text-dark;
				}
			}
		}

		.disable {

			opacity: 0.5;

			&:hover {
				opacity: 1;

				.icon-list-categories {
					background-color: $grey5;
				}
			}
		}

		.active {

			opacity: 1;

			.icon-list-categories {
				background-color: $blue5;
				border-color: $blue5;

				&:hover {
					background-color: $blue5;
					border-color: $blue5;
				}
			}

			p {
				@extend .font-medium;
			}

			&:hover {
				.icon-list-categories {
					background-color: $blue5;
					border-color: $blue5;
				}

				p {
					@extend .text-grey1;
				}
			}
		}
	}
}