/* Sizing */
.scale-h-50             { transform: scale(0.50, 1); }
.scale-h-75             { transform: scale(0.75, 1); }
.scale-h-90             { transform: scale(0.90, 1); }
.scale-h-95             { transform: scale(0.95, 1); }
.scale-h-105            { transform: scale(1.05, 1); }
.scale-h-110            { transform: scale(1.10, 1); }
.scale-h-115            { transform: scale(1.15, 1); }
.scale-h-120            { transform: scale(1.20, 1); }
.scale-h-150            { transform: scale(1.50, 1); }
.scale-h-200            { transform: scale(2.00, 1); }