.page-header {
	padding-bottom: 0;
	margin: 0;
	border-bottom: none;
}

.page-header-container {
	margin-top: 2.8rem;
	margin-bottom: $spacing-large;
	@media (min-width:$screen-sm) {
		margin-top: 4.4rem;
		margin-bottom: 4.4rem;
	}

	&.page-header-container-row-layout {
		h1 {
			margin-bottom: 0;
		}
		.page-header-action-btns .btn {
			margin-top: 0;
			margin-bottom: 0;
		}
		.page-header-with-action-btns {
			flex-direction: row;
			justify-content: space-between;
			align-items: flex-start; // to prevent stretching...
		}
	}

	h1 {
		margin-top: 0;
		span {
			font-size: 1.8rem;
			font-weight: $font-weight-normal;
			color: $grey1;
		}
	}
}

.page-header-back-btn {
	display: inline-block;
	font-size: 1.4rem;
	font-weight: $font-weight-medium;
	border-radius: 4px;
	padding: 0 $spacing-smallest;
	transition: all 0.3s ease-in-out;
	text-decoration: none;
	&:hover {
		background-color: $grey4;
		color: $text-dark;
		text-decoration: none;
	}

	&, a & {
		color: $grey1;
	}

	i {
		margin-right: .4rem;
	}

	+ .page-header {
		h1 {
			margin-top: $spacing-small;
		}
	}

	+ .page-header-with-action-btns {
		margin-top: $spacing-medium;
	}
}

.page-header-with-action-btns {
	display: flex;
	flex-direction: column;
	margin-top: ($font-size-h1 * 1.1); // (h1 font-size) * 1.1 .... the 1.1 comes from the h1 margin-top

	@media (min-width:$screen-sm) {
		flex-direction: row;
		justify-content:space-between;
	}

		h1 {
		margin-top: 0;
		margin-bottom: 0;
		word-break: break-all;
		@media (max-width:($screen-sm - 1px)) {
			margin-bottom: $spacing-medium;
		}
	}
}

.page-header-action-btns {
	@media (max-width:($screen-sm - 1px)) { // (min-width:$screen-xs) and
		display: flex;
	}
	.btn {
		float: left; // will keep margins true to size...
		width: 100%;
		margin-right: $spacing-smaller;
		margin-bottom: 0;
		@media (max-width:($screen-sm - 1px)) {
			display: flex;
			align-items: center;
			justify-content: center;
		}
		@media (min-width:$screen-sm) {
			width: auto;
			margin-right: $spacing-medium;
			@media (max-width:($screen-md - 1px)) {
				flex: 1;
			}
			// Force btn-lg styles...
			&:not(.btn-lg) {
				font-size: 1.8rem;
				line-height: 2.4rem;
				padding: 1.2rem 2.4rem;
			}
		}
		&:last-child {
			margin-right: 0;
		}
		&.btn-lg {
			@media (max-width:($screen-sm - 1px)) {
				// Revert .btn-lg styles...
				font-size: 1.6rem; // remove .btn-lg font-size
				line-height: 2rem;
				padding: .8rem 1.6rem; // remove .btn-lg padding
			}
		}
		i {
			@media (max-width:($screen-xs - 1px)) {
				display: none;
			}
		}
	}
}
