// Agreement List Item <li>

.agreementListItem {
	> .row {
		display: flex;
	}

	.agreementJobDetails {
		@media (min-width: 992px) {
			display: flex;
			align-items: center;
		}
	}

	.agreementJobPricing {
		display: flex;
		align-items: center;
	}
}

.vertical-border-agreement {
	position: absolute;
	width: 0.2rem;
	height: 100%;
	background: $grey4;
	@extend .border-radius;
}