// ========================== Portfolio List Items =====================================
// Components are re-used in multiple areas where portfolio list items are paginated:
// - Search
// - Favorites
// - Hired Voice Actors
// - etc...
// =====================================================================================

$portfolio-list-item-padding: 1.6rem;

.portfolio-list-item {
	@extend .list-group-item;
	padding: (1rem + $portfolio-list-item-padding) $portfolio-list-item-padding;
	display: flex;
	justify-content: space-between;
	flex-direction: column;

	// tablet+
	@media (min-width: $screen-md) {
		flex-direction: row;
	}

	// tablet only
	@media (min-width: $screen-md) and (max-width: ($screen-lg - 1px)) {
		flex-wrap: wrap;
	}

	.portfolio-list-item-flex-col {
		~ .portfolio-list-item-flex-col {
			margin-top: $portfolio-list-item-padding;

			&.portfolio-list-item-invite-to-job {
				// tablet+
				@media (min-width: $screen-md) {
					margin-top: 0;
					margin-left: 2rem;
				}
			}

			// desktop+
			@media (min-width: $screen-lg) {
				margin-top: 0;
				margin-left: ($portfolio-list-item-padding*2);
			}
		}
	}
}

// Talent Container - Avatar, Talent Details
.portfolio-list-item-talent-container {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	text-align: center;
	align-items: flex-start;

	// tablet+
	@media (min-width: $screen-md) {
		flex-wrap: nowrap; // reset mobile-first styles
		justify-content: flex-start; // reset mobile-first styles
		text-align: left; // reset mobile-first styles
	}

	// desktop+
	@media (min-width: $screen-lg) {
		flex: auto;
	}
}

// Talent Container: Talent Details - Name, Star Ratings
.portfolio-list-item-talent-details {
	margin-top: $portfolio-list-item-padding;
	width: 100%;

	// tablet+
	@media (min-width: $screen-md) {
		margin-left: $portfolio-list-item-padding;
		margin-top: 0;
		width: auto; // reset mobile-first styles
	}

	// desktop+
	@media (min-width: $screen-lg) {
		width: 160px;
		flex: 1 0 auto;
	}

	// h3
	.portfolio-list-item-talent-name {
		@include responsive-heading-styles(6);
		margin-top: 0;
		margin-bottom: 0.4rem;
		display: block;
	}
}

// Talent Details: Star Ratings
.portfolio-list-item .stars {
	display: flex;
	justify-content: center;
	@media (min-width: $screen-md) {
		justify-content: flex-start;
	}
}

// Talent Details: Info ("Member Since", "Completed Jobs", "Location"...)
.portfolio-list-item-talent-info {
	color: $grey1;
	margin-top: 0.5rem;
	line-height: 1.2;
	font-size: 1.2rem;
	display: flex;
	justify-content: center;
	@media (min-width: $screen-md) {
		justify-content: flex-start;
	}

	i {
		margin-right: .4rem;
	}
}

// ----------------- Demo/Portfolio Container - Audio Player, Heart, Tags -------------------
.portfolio-list-item-portfolio-container {
	display: flex;
	justify-content: center;

	// tablet only
	@media (min-width: $screen-md) and (max-width: ($screen-lg - 1px)) {
		order: 4; // re-order the flex-col elements from of the natural order
		width: 100%;
	}
}

.portfolio-list-item-portfolio-details {
	width: 100%;
	max-width: 400px;

	// tablet+
	@media (min-width: $screen-md) and (max-width: ($screen-lg - 1px)) {
		max-width: 520px;
	}

	// desktop+
	@media (min-width: $screen-lg) {
		max-width: none; // reset
		width: 358px; // with latest changes to Favorites heart sizing, needed to shrink this player down slightly
	}

	.voices-audio-player {
		margin-bottom: 1.2rem;
	}
}

.portfolio-list-item-portfolio-title {
	@include responsive-heading-styles(5);
	margin-top: 0;
	margin-bottom: 1rem;
	color: $text-dark;
	text-align: center;
	@media (min-width: $screen-md) {
		text-align: left;
	}
}

.portfolio-list-item-portfolio-sample {
	display: flex;
}

.portfolio-list-item-portfolio-tags {
	font-size: 1.4rem;
	color: $grey2;
	text-align: center;
	@media (max-width: ($screen-md - 1px)) {
		justify-content: center;
	}
	@media (min-width: $screen-md) {
		text-align: left;
	}

	.portfolio-tag ~ .portfolio-tag {
		margin-left: .4rem;

		&::before {
			content: '\2022';
			font-size: 1.4rem;
			margin-right: .4rem;
		}
	}

	.portfolio-service-category-tag {
		font-weight: $font-weight-medium;
	}

	.circle-icon-blue {
		margin-right: 0.4rem;
	}
}

a.portfolio-list-item-translation-portfolio-preview-btn {
	@extend .btn;
	@extend .btn-default;
	padding: .8rem;
	width: 100%;
	margin-bottom: 1.2rem;
	color: $text-dark;
	text-align: center;
}

// ------------------------- Invite to Job Button ---------------------------
.portfolio-list-item-invite-to-job {
	text-align: center;

	.downmenu {
		position: relative;

		.headbtn {
			min-width: auto;
			margin-right: 0;
		}

		.dropdown-content {
			min-width: 100%;
		}
	}


	// tablet+
	@media (min-width: $screen-md) {
		text-align: left; // reset mobile-first styles
	}

	// tablet only
	@media (min-width: $screen-md) and (max-width: ($screen-lg - 1px)) {
		order: 3; // re-order the flex-col elements from of the natural order
	}
}

// ========================== Pagination Skeleton ========================== \\

.loading {
	.portfolio-list-item-talent-container {
		flex-direction: column;
		align-items: center;
		order: 1;

		> div:last-of-type {
			display: flex;
			flex-direction: column;
			align-items: center;
			width: 100%;
		}

		.loading-skeleton {
			&.circle {
				margin-bottom: 1rem;
			}

			&.medium {
				margin-bottom: 1rem;
				width: 40%;
			}

			&.small {
				width: 25%;
			}
		}
	}

	.portfolio-list-item-portfolio-container {
		flex-wrap: wrap;
		justify-content: flex-start;
		order: 2;

		.loading-skeleton:nth-child(1),
		.loading-skeleton:nth-child(2) {
			margin-bottom: 1rem;
		}

		.take-space {
			flex-basis: 100%;

			> .loading-skeleton {
				width: 75%;
			}
		}

		.loading-skeleton:nth-child(3) {
			margin-right: 1rem;
		}

		.loading-skeleton:nth-child(3),
		.loading-skeleton:nth-child(4) {
			flex-basis: 25%;
		}
	}

	.portfolio-list-item-invite-to-job {
		order: 3;
	}

	@media screen and (min-width: $screen-sm) {
		&.portfolio-list-item {
			flex-direction: row;
			flex-wrap: nowrap;
		}

		.portfolio-list-item-talent-container {
			flex-direction: row;
			flex-wrap: nowrap;
			flex-basis: 30%;
			flex-grow: 0;

			> div:last-of-type {
				margin-left: 1rem;
				align-items: flex-start;
			}

			.loading-skeleton {
				&.circle {
					flex-shrink: 0;
				}

				&.medium {
					width: 90%;
					margin-bottom: 0.8rem;
				}

				&.small {
					width: 45%;
				}
			}
		}

		.portfolio-list-item-portfolio-container {
			margin-left: 3.2rem;
			flex-grow: 1;
			width: unset;
		}

		.portfolio-list-item-invite-to-job {
			margin-left: 3.2rem;
		}
	}
}

.layout-body-group-item {
	.portfolio-list-item {
		&:first-child {
			border-top: none;
			border-top-right-radius: 8px;
			border-top-left-radius: 8px;
		}
	}
}