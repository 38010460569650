/* Image Presets */
// Mimic Bootstrap default for internal use.
.img-responsive {
  width:100%;
  height: auto;
}

// TODO: Deprecate this class. HOW does this even have to do with images whatsoever? -_-
.full-width {
  width:100%;
}
