// Define FontAwesome Icons (only the icons we need)
.#{$fa-css-prefix}-search:before {content: $fa-var-search;}
.#{$fa-css-prefix}-spinner:before {content: $fa-var-spinner;}
.#{$fa-css-prefix}-chevron-down:before {content: $fa-var-chevron-down;}
.#{$fa-css-prefix}-caret-down:before {content: $fa-var-caret-down;}
.#{$fa-css-prefix}-circle-notch:before {content: $fa-var-circle-notch;}
.#{$fa-css-prefix}-times-circle:before {content: $fa-var-times-circle;}
.#{$fa-css-prefix}-gem:before { content: $fa-var-gem; }

html,
body {
	&.expanded {
		@media only screen and (max-width: 991px) {
			overflow: hidden;
		}
	}
}

// Styles for transition for secondary nav on scroll
body {
	&.scroll {
		&-down {
			.nav-secondary-container,.subNav {
				@media only screen and (min-width: $screen-md) {
					transform: translateY(-100%);
				}
			}
			.header.header-with-subnav {
				.nav-main {
					@media only screen and (min-width: $screen-md) {
						height: 7.2rem;
					}
				}
			}
		}
		&-up {
			.nav-secondary-container,.subNav {
				@media only screen and (min-width: $screen-md) {
					transform: translateY(0);
				}
			}
			.header.header-with-subnav {
				.nav-main {
					@media only screen and (min-width: $screen-md) {
						height: 14rem;
					}
				}
			}
		}
	}
}

// Website Header
.header {
	position: relative;
	height: 7.2rem;
	.sr-only-focusable {
		&:active,
		&:focus {
			position: absolute;
			top: 7.2rem;
			background-color: #fff;
		}
	}
	&.header-with-subnav {
		height: 7.2rem;
		@media only screen and (min-width: $screen-md) {
			height: 14rem;
		}
		.sr-only-focusable {
			&:active,
			&:focus {
				@media only screen and (min-width: $screen-md) {
					top: 14rem;
				}
			}
		}
		.nav-main {
			@media only screen and (min-width: $screen-md) {
				transition: height 0.5s ease-in-out;
			}
		}
	}

	// ---------------------------------------------------------
	// NOT LOGGED IN - Website Header Style Overrides.
	// ---------------------------------------------------------
	&.header-not-loggedin {
		.nav-main:not(.expanded-search) {
			.nav-main-form-container {
				@media only screen and (max-width: ($screen-sm - 1px)) {
					flex: 1 1 auto;
					margin-left: 4rem;
					margin-right: 2rem;
					.nav-main-form {
						width: 100%;
					}
				}
			}
			.nav-main-form {
				width:42rem;
				@media only screen and (min-width:$screen-md){
					width:55rem;
				}
				@media only screen and (min-width:$screen-lg){
					width:47rem;
				}
				@media only screen and (max-width: ($screen-xs - 1px)) {
					padding: 0;
				}
			}
			.nav-main-form-input {
				@media only screen and (max-width: ($screen-xs - 1px)) {
					font-size: 1.4rem;
					&::placeholder {
						// Chrome, Firefox, Opera, Safari 10.1+
						font-size: 1.4rem;
					}
					&:-ms-input-placeholder {
						// IE 10-11
						font-size: 1.4rem;
					}
					&::-ms-input-placeholder {
						// Microsoft Edge
						font-size: 1.4rem;
					}
				}
			}
		}
		.nav-main-form-container {
			@media only screen and (min-width: $screen-sm){
				margin-right:auto;
			}
			.nav-main-form  {
				width: 47rem;
			}
		}
		.nav-main-logo-link {
			display: block !important; // since this is swapped out on mobile screens for non-logged in users.
		}
		.nav-main-submenu-list-item-logo {
			display: none !important; // since we have the main logo being displayed.
		}
		.nav-main-searchbar {
			@media only screen and (max-width: ($screen-md - 1px)) {
				justify-content: flex-end;
			}
			@media only screen and (min-width: $screen-md) and (max-width: ($screen-lg - 1px)) {
				.nav-main-submenu-not-loggedin-btn-group {
					margin-left: auto;
				}
			}
		}
		.nav-main-logo-link {
			@media only screen and (max-width: ($screen-sm - 1px)) {
				position: absolute;
				top: 14px;
				left: 0;
			}
		}
		.nav-main-submenu-not-loggedin-btn-group {
			@media only screen and (min-width: $screen-sm) and (max-width: ($screen-lg - 1px)) {
				display: flex;
				align-items: center;
			}
		}
		.nav-main-submenu {
            top: 0;
            right: 0;
            left: auto;
            width: 100%;
            max-height: none !important;
            height: 100%;
            z-index: 10;
            transform: translateX(100%);
            transition: transform 0.3s ease-in-out;

            @media (min-width: $screen-sm) {
                width: 36rem;
            }

			@media only screen and (min-width: $screen-lg) {
				width: auto;
				height: auto;
				background-color: transparent;
				transform: translateX(0);
				transition: none;
			}
			&.expanded {
                transform: translateX(0);
			}
		}
		.nav-main-submenu-header {
			align-items: center;
			justify-content: space-between;
			height: 7.2rem;
			position: relative;
			padding: 2rem 1.5rem;
			&::before {
				content: '';
				position: absolute;
				bottom: -0.1rem;
				left: 0;
				right: 0;
				height: 0.1rem;
				background-color: $grey4;
				margin: 0 1.5rem;
				z-index: 1;
			}
            @media (min-width: $screen-lg) {
                display: none;
            }
			@media only screen and (max-width: ($screen-lg - 1px)) {
				display: flex;
			}
			&-logo {
				@media only screen and (min-width: $screen-sm) {
					width: 10.9rem;
					height: 2.4rem;
				}
			}
		}
		.nav-main-submenu-list {
			padding: 2.4rem 1.6rem;
			@media only screen and (min-width: $screen-lg) {
				padding: 0;
			}
			@media only screen and (max-width: ($screen-lg - 1px)) {
				max-height: calc(100vh - 72px);
				overflow-y: scroll;
			}
		}
	}
}
.nav {
	&-main {
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		background-color: #fff;
		z-index: 99999;
		transition: background-color 0.3s ease-in-out;
		// main-nav bottom border
		&::before {
			content: '';
			position: absolute;
			bottom: 0;
			left: 0;
			right: 0;
			height: 1px;
			background-color: $grey4;
			z-index: 1;
		}

		li {
			line-height: 1.5;
		}

		a {
			&:hover,
			&:focus {
				text-decoration: none !important;
			}
		}

		// nav-main-container - so secondary nav can hide behind it
		&-container {
			@media only screen and (min-width: $screen-sm) {
				background-color: $white;
				position: relative;
				z-index: 10;
				border-bottom: solid 1px $grey4;
			}
		}

		// .nav-main-logo
		&-logo {

			&-v {
				fill: $blue1;
				transition: all 0.3s ease-in-out;
			}

			&-full {
				fill: $blue1;
			}

			&-rest {
				visibility: hidden;
				opacity: 0;
				transform: translateX(-2.2rem);
				transition: all 0.3s ease-in-out;
				@media only screen and (min-width: $screen-lg) {
					transition: none;
					visibility: visible;
					opacity: 1;
					transform: translateX(0);
				}
			}

			// @extend .nav-main-logo-link(logo container);
			// Note: Hide on mobile screens and swap for .nav-main-submenu-list-item-logo
			&-link {
				display: none;
				@media only screen and (min-width: $screen-sm) {
					display: block;
					margin-right: 0;
					flex: 0 0 3.5rem;
					overflow: hidden;
					width: 3.5rem;
					height: 2.4rem;
					overflow: hidden;
					transition: all 0.3s ease-in-out, opacity 0s, visibility 0s;
				}
				@media only screen and (min-width: $screen-lg) { // full "Voices" logo.
					margin-right: 1rem;
					flex: 0 0 10.9rem;
					width: auto;
				}
				@media print {
					display: none;
				}
			}
		}

		&-menu {
			&-btn {
				background: none;
				border: none;
				position: relative;
				width: 3.2rem;
				height: 3.2rem;
				padding: 0;
				flex-shrink: 0;

				&:focus {
					outline: none;
				}

				@media only screen and (min-width: $screen-lg) {
					display: none;
				}
			}

			&-line {
				position: absolute;
				top: 0.7rem;
				left: 0;
				right: 0;
				margin: auto;
				width: 2.4rem;
				height: 0.3rem;
				background-color: $grey1;
				transition: transform 0.3s ease, opacity 0.3s ease;
				&:nth-child(2) {
					top: 1.5rem;
				}
				&:nth-child(3) {
					top: 2.3rem;
				}
			}

			&-close-tablet {
				background: none;
				border: none;
				position: relative;
				width: 3.2rem;
				height: 3.2rem;
				padding: 0;
				margin-right: 0.9rem;
				.tablet-menu-line {
					position: absolute;
					top: 0.7rem;
					left: 0;
					right: 0;
					margin: auto;
					width: 2.4rem;
					height: 0.3rem;
					background-color: #fff;
					&:nth-child(1) {
						transform: translateY(0.8rem) rotate(45deg);
						background-color: $grey1;
					}
					&:nth-child(2) {
						top: 2.3rem;
						transform: translateY(-0.8rem) rotate(-45deg);
						background-color: $grey1;
					}
				}
			}
		}

		// .nav-main-submenu
		&-submenu {
			position: fixed;
			width: 100%;
			background-color: #fff;
			z-index: 1;
			@media only screen and (min-width: $screen-lg) {
				position: static;
				max-height: none;
				width: auto;
				z-index: 1;
				overflow: visible;
			}

			&-secondary {
				display: flex;
				margin-top: 0.8rem;
				margin-bottom: 1.6rem;
				padding-left: 2.4rem;
				position: relative;
				@media only screen and (min-width: $screen-lg) {
					display: block;
					margin: 0;
					padding: 1.6rem;
				}

				&::before {
					content: '';
					width: 0.8rem;
					height: 100%;
					background-color: $grey5;
					border-radius: 0.8rem;
					position: absolute;
					top: 0;
					left: 1.1rem;
					@media only screen and (min-width: $screen-lg) {
						display: none;
					}
				}

				&-container {
					max-height: 0;
					overflow: hidden;
					transition: max-height 0.3s ease-in-out;
					@media only screen and (min-width: $screen-lg) {
						max-height: none !important;
						visibility: hidden;
						overflow: visible;
						position: fixed;
						top: 4.4rem;
						left: -3rem;
						width: 23.2rem;
						border-radius: 0.4rem;
						box-shadow: 0 5px 12px 0 rgba(25, 34, 43, 0.1), inset 0px 0px 0px 1px $grey5;
						background-color: #fff;
						z-index: 2;
					}
					@media only screen and (min-width: $screen-lg) and (-ms-high-contrast: active),
					(-ms-high-contrast: none) {
						left: 50%;
						top: 6.5rem;
					}

					.nav-main-submenu-secondary {
						@media only screen and (min-width: $screen-lg) {
							transform: translateY(1rem);
							opacity: 0;
							transition: transform 0.3s ease-in-out 0.15s,
							opacity 0.3s ease-in-out 0.15s;
						}
					}

					&.expanded {
						@media only screen and (min-width: $screen-lg) {
							visibility: visible;
						}

						.nav-main-submenu-secondary {
							@media only screen and (min-width: $screen-lg) {
								transform: translateY(0);
								opacity: 1;
							}
						}
					}
				}

				.nav-main-submenu-list-item {
					@media only screen and (min-width: $screen-lg) {
						margin: 0;
					}
				}

				a.nav-main-submenu-link {
					&:hover,
					&:focus {
						color: $text-dark !important;
					}

					@media only screen and (min-width: $screen-lg) {
						color: $grey1;
						padding: 0.8rem 1.2rem;
						&:hover,
						&:focus {
							background-color: $grey5;
						}
					}
				}

				&-overlay {
					display: none;
					@media only screen and (min-width: $screen-lg) {
						position: fixed;
						left: 0;
						top: 0;
						width: 100%;
						height: 100%;
						z-index: 10;
					}

					&.expanded {
						@media only screen and (min-width: $screen-lg) {
							display: block;
						}
					}
				}
			}

			// .nav-main-submenu-list
			&-list {
				list-style-type: none;
				padding: 0;
				text-align: left;
				margin: 0;
				@media only screen and (min-width: $screen-lg) {
					display: flex;
					align-items: center;
				}

				// .nav-main-submenu-list-secondary
				&-secondary {
					margin-left: 0;
					margin-bottom: 0;
					padding-left: 0;
					flex: 1;
					list-style-type: none;

					&:first-child {
						&::after {
							@media only screen and (min-width: $screen-lg) {
								content: '';
								display: block;
								height: 0.1rem;
								background-color: $grey4;
								margin: 1.6rem -1.6rem;
							}
						}
					}
				}

				// .nav-main-submenu-list-item
				&-item {
					margin: 0;

					&:last-child {
						@media only screen and (min-width: $screen-sm) {
							margin: 0;
						}
					}

					@media only screen and (min-width: $screen-lg) {
						margin: 0 2.4rem 0 0;
					}
					@media print {
						display: none;
					}

					&-desktop {
						@media only screen and (min-width: $screen-lg) {
							display: none;
						}
					}

					// @extend .nav-main-submenu-list-item-logo(mobile screens only!);
					&-logo {
						@media only screen and (min-width: $screen-sm) {
							display: none !important;
						}
						a {
							display: flex; // maintains svg viewBox sizing.
						}
					}
				}

				// .nav-main-submenu-list-line
				&-line {
					@media only screen and (min-width: $screen-lg) {
						//48px + 1px for line between
						position: relative;
						margin-right: 4.9rem;
					}

					&::before {
						@media only screen and (min-width: $screen-lg) {
							content: '';
							width: 0.1rem;
							height: 2.4rem;
							position: absolute;
							top: 0;
							right: -2.5rem;
							background-color: $grey4;
						}
					}
				}
			}

			&-link {
				display: block;
				margin: 0 auto;
				font-size: 1.6rem;
				position: relative;
				padding: 0.8rem 1.2rem;
				border-radius: 0.4rem;
				transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out;
				@media only screen and (min-width: $screen-lg) {
					// display: inline;
					width: auto;
					padding: 0;
				}

				&:hover,
				&:focus {
					background-color: $grey5;
					@media only screen and (min-width: $screen-lg) {
						background-color: transparent;
					}
				}
				&-line {
					@media only screen and (min-width: $screen-lg) {
						font-weight: 500;
					}

					&::before {
						@media only screen and (min-width: $screen-lg) {
							content: '';
							position: absolute;
							left: 0;
							bottom: -0.4rem;
							width: 0;
							height: 0.2rem;
							background-color: $blue1;
							opacity: 0;
							transition: opacity 0.3s ease-in-out, width 0.3s ease-in-out;
						}
					}

					&:hover,
					&:focus {
						&::before {
							@media only screen and (min-width: $screen-lg) {
								opacity: 1;
								width: 100%;
							}
						}
					}
				}
				&[aria-expanded='true'] {
					.fa-caret-down {
						transform: rotate(180deg);
						@media only screen and (min-width: $screen-lg) {
							transform: none;
						}
					}
				}

				.fa-caret-down {
					position: absolute;
					top: 0;
					bottom: 0;
					right: 1.6rem;
					width: 1rem;
					height: 1.6rem;
					margin: auto;
					transition: transform 0.3s ease-in-out;
					@media only screen and (min-width: $screen-lg) {
						position: static;
					}
				}
			}

			&-category {
				margin: 0 0 0.8rem 1.2rem;
				font-size: 1.4rem;
				color: $grey2;
			}

			a:not(.header-btn), button:not(.header-btn) {
				color: $grey1;

				&:focus {
					text-decoration: none;
				}
			}

			a.nav-main-submenu-link {

				@media (min-width: $screen-lg) {
					color: $text-dark;
				}

				&:hover,
				&:focus {
					color: $text-dark;
				}
			}

			a.nav-main-submenu-category-link {
				display: inline-block;
				margin-top: 0.8rem;
				margin-left: 1.2rem;
				color: $blue1;
				font-size: 1.4rem;
				font-weight: 500;
				border-bottom: 0.1rem solid transparent;

				&:hover,
				&:focus {
					border-bottom: 0.1rem solid $blue0;
					color: $blue0;
				}
			}

			a.nav-main-submenu-btn {
				display: block;
				&.header-btn-primary {
					background-color: $blue1;
					border-color: transparent;
					color: $white !important;
					font-weight: 500;
					border-radius: 3px;
					font-size: 1.6rem;
					padding: $spacing-smaller $spacing-medium;
					line-height: 2rem;
					margin-top: 2.4rem;
					text-align: center;
					transition: .1s all linear;
					@media only screen and (min-width: $screen-lg) {
						margin-top: 0;
					}

					&:hover {
						background-color: $blue0;
					}
				}
				&-tablet {
					display: none;
					@media only screen and (min-width: $screen-sm) {
						display: block;
					}
					@media only screen and (min-width: $screen-lg) {
						display: none;
					}
				}
			}
		}

		&-overlay {
			position: fixed;
			width: 100%;
			height: 100%;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			z-index: 9;
			background-color: $grey0;
			opacity: 0;
			visibility: hidden;
			transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out;
			@media only screen and (min-width: $screen-sm) {
				z-index: 9;
			}
			@media only screen and (min-width: $screen-lg) {
				display: none;
			}

			&.expanded {
				opacity: 0.4;
				visibility: visible;
			}

			&-loggedin.expanded {
				@media only screen and (max-width: $screen-md - 1px) {
					display: none;
				}

				@media only screen and (min-width: $screen-lg) {
					display: none;
				}
			}
		}

		&.expanded {
			@media only screen and (max-width: ($screen-sm - 1px)) {
				&::before {
					background-color: $grey4;
					margin: 0 1.5rem;
				}

				.nav-main {
					&-searchbar-container {
						background-color: #fff;
					}

					&-logo {
						&-dot {
							visibility: hidden;
						}

						&-rest {
							visibility: visible;
							opacity: 1;
							fill: $blue1;
							transform: translateX(0);
						}

						&-link {
							flex: 0 0 10.9rem;
						}
					}

					&-form {
						visibility: hidden;
						opacity: 0;
					}

					&-menu-btn {
						.nav-main-menu-line {
							&:nth-child(1) {
								transform: translateY(0.8rem) rotate(45deg);
								background-color: $grey1;
							}
							&:nth-child(2) {
								opacity: 0;
							}
							&:nth-child(3) {
								transform: translateY(-0.8rem) rotate(-45deg);
								background-color: $grey1;
							}
						}
					}
				}
			}
			@media only screen and (max-width: 1199px) {
				.nav-main {
					&-submenu {
						background-color: #fff;
						box-shadow: 0 5px 32px 0 rgba(25, 34, 43, 0.11);
					}
				}
			}
		}
	}
}

// ---------------------------------------------------------
// NOT LOGGED IN - Nav Buttons
// ---------------------------------------------------------
a.nav-main-submenu-btn-tablet {
	display: none;
	@media only screen and (min-width: $screen-sm) {
		&.btn{
			display: block;
		}
		margin-bottom: 0;
		margin-right: 1.6rem;
	}
	@media only screen and (min-width: $screen-md) {
		&.link{
			display:inline;
		}
	}
	@media only screen and (min-width: $screen-lg) {
		&.btn, &.link{
			display: none;
		}
	}
	&.btn {
		color: #fff;
		background-color: $blue1;
		height: 3.6rem;
		transform: translateY(0);
		transition: all 0.3s ease-in-out;
		border-radius: 0.4rem;
		//globalCSS overrides
		font-weight: 500;
		padding: 0.8rem 1.6rem;
		font-size: 1.6rem;
		line-height: 2rem;
		//globalClassic overrides
		text-shadow: none;
		box-shadow: none;
		background-image: none;
		border: none;
		&:hover {
			background-color: $blue0;
		}
	}
	&.link {
		color: $text-dark;
		border-bottom: 0;
		position: relative;
		font-size: 16px;
		font-weight: 500;
		&::before {
			@media only screen and (min-width: $screen-sm) {
				content: '';
				position: absolute;
				left: 0;
				bottom: -0.4rem;
				width: 0;
				height: 0.2rem;
				background-color: $blue1;
				opacity: 0;
				transition: opacity 0.3s ease-in-out, width 0.3s ease-in-out;
			}
		}

		&:hover,
		&:focus {
			color: $text-dark;
			text-decoration: none;
			box-shadow: none;

			&::before {
				@media only screen and (min-width: $screen-sm) {
					opacity: 1;
					width: 100%;
				}
			}
		}
	}
}

.header-btn-postjob {
	margin-right: 0.8rem !important;
	@media (min-width: $screen-lg) {
		margin-right: 1.2rem !important;
	}
}

// ============================= Nav Searchbar & Popups =============================

.nav-main-searchbar-container {
	display: flex;
	align-items: center;
	padding: 0 1.5rem;
	height: 7.2rem;
	margin: 0 auto;
	transition: background-color 0.3s ease-in-out;
	@media only screen and (min-width: $screen-sm) {
		padding: 0 1.5rem;
		max-width: 750px;
	}
	@media only screen and (min-width: $screen-md) {
		max-width: 970px;
	}
	@media only screen and (min-width: $screen-lg) {
		max-width: 1170px;
	}
}

// Main Searchbar contains: Logo, Searchbar, Main/Top Links.
.nav-main-searchbar {
	position: relative;
	display: flex;
	align-items: center;
	flex: 1 1 auto;
	max-width: 100%;
	z-index: 1;
	background-color: $white;
	@media only screen and (min-width: $screen-sm) {
		margin-right: 0;
	}

	&-cancel {
		flex: 0;
		overflow: hidden;
		visibility: hidden;
		opacity: 0;
		background: none;
		outline: none;
		border: none;
		padding: 0;
		color: $grey1;
		font-weight: 500;
		transform: translateY(0.5rem);
		transition: visibility 0s ease-in-out, opacity 0s ease-in-out,
		transform 0s ease-in-out, flex 0.3s ease-in-out;
		font-size: 1.6rem;
		@media only screen and (min-width: $screen-sm) {
			display: none;
		}
	}
}

// Note: for Non-Logged-In Users, the searchbar will be displayed on ALL screen sizes.
// However, on mobile devices (when loggedin), the search-icon will toggle the searchbar display
.nav-main-form-container {
    width: 50%;
    justify-content: center;
    @media print {
        display: none;
    }
}

// Main Nav - Expanded Search Container (Mobile)
.nav-main.expanded-search {
	@media only screen and (max-width: ($screen-sm - 1px)) {
		.nav-main-form-container {
			display: block;
			background-color: $white;
			position: fixed;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background-color: #fff;
			z-index: 2;
			.nav-main-form {
				width: 100%;
			}
		}

		// Wraps <form> and mobile menu close button.
		.nav-main-form-wrapper {
			display: flex;
			align-items: center;
		}

		.nav-main-form {
			transform: translateY(0);
			opacity: 1;
		}

		.nav-main-form-input {
			transition: all 0.3s ease-in-out;
		}

		.nav-main-logo-link {
			flex: 0 0 2.8rem;
			visibility: visible;
			opacity: 1;
			margin-right: 1.6rem;
		}

		.nav-search {
			top: 14.4rem;
			height: calc(100% - 14.4rem);
		}

		.nav-search-mobile-menu-scrollable-area {
			position: absolute;
			width: 100%;
			height: 100%;
			padding: 16px;
			overflow: scroll;
		}

		.nav-search-mobile-menu-wrapper {
			display: block;
		}

		.nav-search-mobile-menu-close-btn {
			display: flex;
			align-items: center;
			justify-content: center;
		}

		&:not(.has-recent-search-items) {
			.nav-search-mobile-menu-section-recent-searches {
				display: none;
			}
		}
	}
}

// Has Recent Searches...
.nav-main.has-recent-search-items {
	// Display "Recent Searches" dropdown
	.nav-main-form-recent-searches.show {
		@media (min-width: $screen-sm) {
			height: auto;
			overflow: visible;
			.nav-main-form-recent-searches-dropdown {
				opacity: 1;
				transform: translateY(0);
			}
		}
	}
}

.nav-main-form {
	position: relative;
	z-index: 3;
	margin: 0;
	max-width: 100%;
	width: 47rem;
	display: flex;
	align-items: center;
	padding: 0.6rem 0.8rem;
	border-radius: 2.5rem;
	border: 0.2rem solid $grey5;
	background-color: $grey5;
	background-clip: padding-box; //match border and background alpha color
	transition: border-color 0.3s ease-in-out, background-color 0.3s ease-in-out;

	@media only screen and (min-width: $screen-sm) {
		width: 100%;
	}

	&.active {
		background-color: #fff;
		border-color: $blue1;
		.nav-main-form-btn-search:hover {
			background-color: $grey5;
		}
		.nav-main-form-btn-search {
			border: none;
		}
	}

	// .nav-main-form-selector
	&-selector {
		position: relative;
		flex: 1 0 auto; // allows for dynamic button widths, based on current selection-text.
		@media only screen and (max-width: ($screen-sm - 1px)) {
			display: none; // On smaller screens, Service Category is selected when the mobile-menu is opened and user has focused on input field.
		}
		&.show {
			.nav-main-form-selector-dropdown {
				height: auto;
				overflow: visible;
				padding-top: 12px;
			}
			ul {
				opacity: 1;
				transform: translateY(0px);
			}
		}

		// .nav-main-form-selector-btn
		&-btn {
			border-radius: 2rem;
			outline: none;
			background-color: #fff;
			border: 1px solid $grey5;
			font-size: 1.6rem;
			font-weight: bold;
			padding: 0.6rem 1rem;
			&-text {
				margin-right: 0.6rem;
				color: $grey1;
			}
		}

		// .nav-main-form-selector-dropdown
		&-dropdown {
			position: absolute;
			z-index: 3; // to go above the recent-searches container
			height: 0;
			overflow: hidden;
			padding: 0;
			ul {
				list-style: none;
				padding: 0.8rem;
				margin: 0;
				width: 17rem;
				background-color: #fff;
				border-radius: 4px;
				box-shadow: 0 2px 15px 0 rgba(#000, calc(5/100)), 0 2px 5px 0 rgba($text-dark, calc(10/100)); // .shadow-1
				opacity: 0;
				transform: translateY(4px);
				transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
				li {
					input[type="radio"]:checked + label {
						background-color: $grey5;
						color: $text-dark;
						cursor: default;
					}
					label {
						width: 100%;
						font-size: 1.4rem;
						font-weight: 500;
						margin-bottom: 0;
						padding: 0.8rem;
						border-radius: 4px;
						background-color: #fff;
						user-select: none; // prevent user selection text-highlighting
						transition: background-color 0.3s ease;
						&:hover:not(:checked) {
							cursor: pointer;
							background-color: $grey5;
							color: $text-dark;
						}
					}
				}
			}
		}
	}

	// .nav-main-form-input-wrapper
	// contains: .nav-main-form-input, and .nav-main-form-recent-searches
	&-input-wrapper {
		position: relative;
		width: 100%;
	}

	// .nav-main-form-input
	&-input {
		width: 96%;
		height: 3.6rem;
		background: none;
		font-weight: 400;
		font-size: 1.6rem;
		color: $grey1;
		padding: 0;
		margin: 0 0 0 1.2rem;
		border: none;
		box-shadow: none;
		&::placeholder {
			// Chrome, Firefox, Opera, Safari 10.1+
			color: $grey1;
			opacity: 1; // Firefox
			font-weight: 400;
			font-size: 1.6rem;
			transition: color 0.3s ease-in-out;
		}
		&:-ms-input-placeholder {
			// IE 10-11
			color: $grey1;
			font-weight: 400;
			font-size: 1.6rem;
			transition: color 0.3s ease-in-out;
		}
		&::-ms-input-placeholder {
			// Microsoft Edge
			color: $grey1;
			font-weight: 400;
			font-size: 1.6rem;
			transition: color 0.3s ease-in-out;
		}
		&:focus {
			box-shadow: none !important;
			color: $text-dark;
			outline: 0;
			border: none !important;
			&::placeholder {
				// Chrome, Firefox, Opera, Safari 10.1+
				color: $grey2;
			}
			&:-ms-input-placeholder {
				// IE 10-11
				color: $grey2;
			}
			&::-ms-input-placeholder {
				// Microsoft Edge
				color: $grey2;
			}
		}
	}

	// .nav-main-form-recent-searches
	&-recent-searches {
		position: absolute;
		top: 100%;
		left: 0;
		height: 0;
		overflow: hidden;
		@media (max-width: ($screen-md - 1px)) {
			display: none; // Note: On smaller screens, "Recent Searches" is displayed in the mobile menu
		}
		&-dropdown {
			margin-top: 12px;
			width: 36rem;
			background-color: #fff;
			border-radius: 4px;
			padding: 1.6rem;
			box-shadow: 0 2px 15px 0 rgba(#000, calc(5/100)), 0 2px 5px 0 rgba($text-dark, calc(10/100)); // .shadow-1
			opacity: 0;
			transform: translateY(4px);
			transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
		}
		.nav-search-list-item {
			position: relative;
			&:hover {
				.nav-search-list-item-remove-btn {
					opacity: 1;
					visibility: visible;
				}
			}
			a {
				padding: 0.6rem;
				border-radius: 4px;
				transition: background-color 0.3s ease;
				&:hover {
					background-color: $grey5;
					padding-right: 2.6rem;
				}
			}
			~ .nav-search-list-item {
				margin-top: 0.6rem;
			}
		}
		.nav-search-list-item-link {
			display: flex;
			transition: padding 0.3s ease;
			.search-query {
				text-overflow: ellipsis;
				overflow: hidden;
				white-space: nowrap;
				margin-right: 4px; // automatic spacing between this word, and "in"
			}
			strong {
				flex: 1 0 auto;
				margin-left: 4px; // automatic spacing between this word, and "in"
			}
		}
		.nav-search-list-item-remove-btn {
			position: absolute;
			top: 0.8rem;
			right: 0.6rem;
			display: block;
			opacity: 0;
			visibility: hidden;
			outline: none;
			border: none;
			display: flex;
			align-items: center;
			justify-content: center;
			border-radius: 100%;
			width: 2rem;
			height: 2rem;
			background-color: transparent;
			transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out, opacity 0.3s ease-in-out;
			&::before {
				content: '\f00d';
				font-family: 'Font Awesome 5 Pro';
				font-size: 1.6rem;
				font-weight: 300;
				color: $grey2;
			}
			&:hover {
				background-color: $grey4;
				color: $text-dark;
			}
		}
	}

	// .nav-main-form-btn
	&-btn {
		position: relative;
		padding: 0.8rem;
		background-color: transparent;
		outline: none;
		border: none;
		font-size: 18px;
		z-index: 3;
		line-height: 1;
		&-clear {
			opacity: 0;
			visibility: hidden;
			transition: opacity 0.3s ease-in-out;
			&.show {
				visibility: visible;
				opacity: 1;
				margin-right: .6rem;
			}
			&:not(.show) {
				padding: 0;
				width: 0;
				overflow: hidden;
			}
			&::before {
				content: '';
				position: absolute;
				width: 0.1rem;
				height: 2rem;
				right: 0rem;
				top: 0.8rem;
				background-color: $grey4;
			}
			&:hover,
			&:focus {
				.fa-times-circle {
					color: $text-dark;
				}
			}
			.fa-times-circle {
				color: $grey2;
				transition: color 0.3s ease-in-out;
			}
		}

		// .nav-main-form-btn-search
		&-search {
			display: flex;
			align-items: center;
			justify-content: center;
			outline: none;
			font-size: 18px;
			border-radius: 100%;
			border: 1px solid $grey5;
			background-color: #fff;
			min-width: 34px;
			height: 34px;
			padding: 0;
			transition: background-color 0.3s ease;
			i {
				color: $grey1;
			}
			.fa-circle-notch {
				font-size: 15px;
				font-weight: 500;
			}
		}


	}
}

// Recent Searches, Browse By...
.nav-search-list {
	list-style: none;
	padding: 0;
	margin:0;

	// .nav-search-list-header
	&-header {
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-bottom: 1.6rem;
	}

	// .nav-search-list-heading
	&-heading {
		font-size: 1.4rem;
		color: $grey2;
	}

	// .nav-search-list-clear-btn
	&-clear-btn {
		font-size: 1.4rem;
		color: $blue1;
		transition: color 0.3s ease;
		background: none;
		outline: none !important;
		border: none !important;
		&:hover {
			color: $blue0;
		}
	}

	// .nav-search-list-item
	&-item {
		display: block;
		color: $grey1;
		font-size: 1.6rem;
		~ .nav-search-list-item {
			margin-top: 2.4rem;
		}
		a {
			color: $grey1;
		}

		// .nav-search-list-item-remove-btn
		&-remove-btn {
			display: none; // by default, hide remove buttons. On mobile screens, these are not displayed.
		}

		// .nav-search-list-item-heading
		&-heading {
			display: block;
			margin-bottom: 0.4rem;
			font-size: 1.6rem;
			font-weight: bold;
			color: $text-dark;
		}

		// .nav-search-list-item-subheading
		&-subheading {
			font-size: 1.4rem;
			color: $grey2;
		}
	}
}

.nav-search-mobile-menu-close-btn {
	display: none; // Note: only display when .expanded-search is applied
	border: none;
	outline: none;
	background-color: transparent;
	width: 3.6rem;
	height: 3.6rem;
	border-radius: 100%;
	margin-left: 1rem;
	&:hover {
		background-color: $grey5;
	}
	i {
		font-size: 30px;
		font-weight: 300;
	}
	@media (min-width: $screen-md) {
		display: none !important; // Note: Only display on mobile screens.
	}
}

.nav-search-mobile-menu {
	&-wrapper {
		display: none; // Note: only display when .expanded-search is applied
		@media (min-width: $screen-md) {
			display: none !important; // Note: Only display on mobile screens.
		}
	}

	&-search-type-suggestion-tool {
		overflow: hidden;
		height: 0;
		opacity: 0;
		visibility: hidden;
		transform: translateY(4px);
		transition: opacity 0.3s ease, transform 0.3s ease;
		margin-left: 22px;
		&.show {
			opacity: 1;
			visibility: visible;
			height: auto;
			~ .nav-search-mobile-menu-section {
				display: none;
			}
		}
		a.nav-search-mobile-menu-search-type-suggestion {
			color: $text-dark;
			display: flex;
			align-items: center;
			padding-top: 1.2rem;
			padding-bottom: 1.2rem;
			border-bottom: 1px solid #ecf1f5;
			i {
				margin-right: 8px;
				color: $grey1;
			}
			.search-query {
				text-overflow: ellipsis;
				overflow: hidden;
				white-space: nowrap;
				margin-right: 4px; // automatic spacing between this word, and "in"
			}
			strong {
				flex: 1 0 auto;
				margin-left: 4px; // automatic spacing between this word, and "in"
			}
		}
	}

	&-section {
		margin-top: 1.6rem;
		padding-top: 1.6rem;
		border-top: 2px solid $grey5;

		// .nav-search-mobile-menu-section-browse-by
		&-browse-by {
			// arrow links.
			.nav-search-list-item-link {
				display: block;
				position: relative;
				&::after {
					content: "\f054"; // .fa-chevron-right
					width: 1rem;
					height: 2rem;
					position: absolute;
					top: 0;
					bottom: 0;
					right: 0;
					margin: auto;
					font-family: 'Font Awesome 5 Pro';
					font-size: 1.4rem;
					color: $grey2;
				}
			}
		}
	}
}

// ============================= Nav Sub Menu ============================= \\
.subnav-view-all {
	width:  100%;
	font-weight: 500;
	font-size: 1.8rem;

	&:hover {
		background-color: $white;
		text-decoration: underline;
		text-decoration-color: $blue0;
	}

	a {
		color: $blue1 !important;
	}

	a:link {
		background-color: $white !important;
	}
}

.subnav-dropdown {
	border-bottom-left-radius: 4px;
	border-bottom-right-radius: 4px;
	display: none;
	position: absolute;
	background-color: $white;
	width: 47rem;
	height: auto;
	box-shadow: 0 5px 12px 0 rgba(25, 34, 43, 0.1);
	z-index: 1;
}

.subnav-ctas{
	display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: flex-start;
}

.subnav-items{
	display:inline-flex;
	flex-wrap: wrap;
}

.subnav-links{
	width:50%;
	color: $grey1 !important;
}

li.nav-secondary-item {
	display: inline-block;
}

li.nav-secondary-item:hover > a.nav-secondary-link::before {
    border-top: 0.4rem solid #1171bb;
}

.subnav-dropdown a {
	padding: 12px 16px;
	text-decoration: none;
	display: block;
}

.subnav-items a:hover{
	background-color: $grey5;
	border-radius: 4px;
}

.subnav-view-all:hover{
	background-color: $white;
	text-decoration: underline !important;
	text-decoration-color: $blue0 !important;
}

.nav-secondary-item:hover .subnav-dropdown {
	display: block;
}

.header.header.header-with-subnav {
	.nav-secondary {
		@media only screen and (min-width: $screen-md) {
			display: flex;
			justify-content: center;
			align-items: center;
			margin: 0 auto;
			padding: 0 1.5rem;
			list-style-type: none;
			max-width: 1170px;
			font-size: 16px;
			position: relative;

			> * {
				font-size: 16px;
			}

			h4 {
				font-weight: bold;
				font-size: 16px;
			}

			& .h4 {
				font-weight: 700;
				font-size: 16px;
			}
		}
	}

	.nav-secondary-container {
		display: none;
		@media only screen and (min-width: $screen-md) {
			display: block;
			transition: transform 0.5s ease-in-out;
			background-color: #fff;
		}
	}

	.nav-secondary-item {
		~ .nav-secondary-item {
			margin-left: 5rem;
		}
	}

	.nav-secondary-link {
		display: block;
		color: $grey1 !important;
		padding: 1.5rem 0;
		position: relative;
		transition: color 0.3s ease-in-out;
		&:before {
			@media only screen and (min-width: $screen-md) {
				content: '';
				position: absolute;
				left: 0;
				bottom: 0;
				border-top: 0.4rem solid transparent;
				border-radius: 0.8rem 0.8rem 0 0;
				width: 100%;
				transition: border-color 0.3s ease-in-out;
			}
		}
		&:hover,
		&:focus {
			color: $text-dark !important;
			&:before {
				@media only screen and (min-width: $screen-md) {
					border-top: 0.4rem solid $blue1;
				}
			}
		}
	}
}

.nav-main-menu-btn{
	@media only screen and (min-width: $screen-md) {
		display: none;
	}
}

//header ribbon subnav
.subNav{
    background: $white;
	transition: transform 0.5s ease-in-out;
	display: flex;
	max-width: 1180px;
	align-items: center;
	margin: 0 auto;
	padding: 0 1.4rem;
	position: relative;
	.left {
		align-self: center;
		padding: 0 10px;
		&:hover{
			border-radius: 50%;
			height: 30px;
			display: flex;
			justify-content: center;
			align-items: center;
			background: $grey4;
		}
	}
	.right {
		align-self: center;
		padding: 0 10px;
		&:hover{
			border-radius: 50%;
			height: 30px;
			display: flex;
			justify-content: center;
			align-items: center;
			background: $grey4;
		}
	}
}
.side-scroll-container {
	display: flex;
	max-width: 1100px;
	align-items: center;
	margin: 0 auto;
	padding: 0 1.4rem;
	position: relative;
	overflow: hidden;
	.side-scroller {
		height: auto;
		display: flex;
		flex-wrap: nowrap;
		overflow-x: auto;
		overflow-y:hidden;
		animation-delay:3s;
		scroll-behavior: smooth;
		-webkit-overflow-scrolling: touch;
		scrollbar-width: none; /* hide & remove space scroll-bar occupies - firefox */
	}
	/* remove space that scrollbar occupies - Chrome + Edge + Safari */
	.side-scroller::-webkit-scrollbar {
		-webkit-appearance: none;
		height: 0px;
	}
}

.scroll-link {
	flex-shrink: 0;
	width: 114px;
	height: 65px;
	margin: 1px 10px;
	overflow: hidden;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: 1.6rem;
	color:$grey1 !important;
	&:hover{
		border-bottom: 0.4rem solid $blue1 ;
		color:$grey0 !important;
	}
	@media only screen and (max-width: $screen-sm) {
		font-size: 1.4rem;
		width: 106px;
	}
	@media only screen and (max-width: $screen-xs) {
		font-size: 1.2rem;
		width: 85px;
		height: 50px;
		margin: 1px 5px;
	}
}
.hidden-arrow{
	opacity: 0;
	pointer-events: none;
}
// Styles for transition for secondary nav on scroll
body {
	&.scroll {
		&-down {
			.nav-secondary-container,.subNav {
					transform: translateY(-100%);
			}
			.header.header-with-subnav {
				.nav-main {
					@media only screen and (max-width: $screen-md) {
						height: 7.2rem;
					}
				}
			}
		}
		&-up {
			.nav-secondary-container,.subNav {
					transform: translateY(0);
			}
			.header.header-with-subnav {
				.nav-main {
					@media only screen and (max-width: $screen-md) {
						height: 14rem;
					}
					@media only screen and (max-width: $screen-xs) {
						height: 14rem;
					}
				}
			}
		}
	}
}
.header-with-subnav + #main-page{
	@media only screen and (max-width: ($screen-md - 1)) {
		padding-top: 5.2rem;
	}
}
.nav-main-container{
	@media only screen and (max-width: ($screen-sm - 1)) {
		border-bottom: solid 1px #c9d0d9;
	}
}
.how-it-works hr{
	border-top: 1px solid $grey4 !important;
}

.solutions-footer a{
	padding: 0 !important;
    display: inline !important;
	color: $blue1 !important;
}

@media only screen and (min-width: $screen-md) {
    .solutions-meganav-dropdown{
        height: 90vh;          
        overflow-y: auto;
    }
}
