/**
 * Bootstrap Grid
 */
@import "@bootstrap/scss/variables";
@import "@bootstrap/scss/variables-dark";
@import "@bootstrap/scss/maps";
@import "@bootstrap/scss/grid";
@import "@bootstrap/scss/containers";

.row {
	> .wrapper {
		padding-left: 0;
		padding-right: 0;
	}
}

.container,
.container-fluid,
.container-xxl,
.container-xl,
.container-lg,
.container-md,
.container-sm,
.row {
	--bs-gutter-x: 3rem;
}

