@import "@bootstrap/scss/utilities/api";

// =============================================================== //
// ============= [Voices.com custom print.css styles] ============ //
// =============================================================== //

// Bootstrap Overrides!!!
// -------------------------

/*! Source: https://github.com/h5bp/html5-boilerplate/blob/master/src/css/main.css */
@media print {
	*,
	*:before,
	*:after {
		color: #000 !important;
		text-shadow: none !important;
		background: transparent !important;
		-webkit-box-shadow: none !important;
		box-shadow: none !important;
	}
	a,
	a:visited {
		text-decoration: underline;
	}

	pre,
	blockquote {
		border: 1px solid #999;

		page-break-inside: avoid;
	}
	thead {
		display: table-header-group;
	}
	tr,
	img {
		page-break-inside: avoid;
	}
	img {
		max-width: 100% !important;
	}
	p,
	h2,
	h3 {
		orphans: 3;
		widows: 3;
	}
	h2,
	h3 {
		page-break-after: avoid;
	}
	.navbar {
		display: none;
	}
	.btn > .caret,
	.dropup > .btn > .caret {
		border-top-color: #000 !important;
	}
	.label {
		border: 1px solid #000;
	}
	.table {
		border-collapse: collapse !important;
	}
	.table td,
	.table th {
		background-color: #fff !important;
	}
	.table-bordered th,
	.table-bordered td {
		border: 1px solid #ddd !important;
	}

	// Reset Grid Styles, and set every container to be at full-width
	.container, .row {
		margin: 0 !important;
		padding: 0 !important;
		float: none;
		display: block;
	}
	[class^="col-"], [class*=" col-"] {
		width: 100% !important;
		float: none !important;
		padding-left: 0 !important;
		padding-right: 0 !important;
		text-align: left !important;
	}

	.row {
		display: flex;
	}

	dd {
		margin-bottom: 0;
	}

	// Reset Offset And Pulls
	[class*="-pull-"], [class*="-offset-"] {
		left: 0 !important;
		right: auto !important;
	}
}

.visible-print {
	display: none !important;
}

@media print {
	.visible-print {
		display: block !important;
	}
	table.visible-print {
		display: table !important;
	}
	tr.visible-print {
		display: table-row !important;
	}
	th.visible-print,
	td.visible-print {
		display: table-cell !important;
	}
}

.visible-print-block {
	display: none !important;
}

@media print {
	.visible-print-block {
		display: block !important;
	}
}

.visible-print-inline {
	display: none !important;
}

@media print {
	.visible-print-inline {
		display: inline !important;
	}
}

.visible-print-inline-block {
	display: none !important;
}

@media print {
	.visible-print-inline-block {
		display: inline-block !important;
	}
}

@media print {
	.hidden-print {
		display: none !important;
	}
}


// Custom Voices.com Print Styles!!!!
// ------------------------------------

@media print {
	/**
	 * Basics
	 *
	 * 72pt = 1 inch tall
	 * 36pt = 1/2 inch tall
	 * 18pt = 1/4 inch tall
	 * 12pt = standard body text
	 */

	@page {
		size: auto;
		margin: 1in;
	}

	body {
		width: 100%;
		margin: 0;
		font-size: 12pt;
		color: #000;
		background: #FFF;
	}

	h1,h2,h3,h4,h5,h6 { page-break-after:avoid; }
	h1{ font-size:18pt; }
	h2{ font-size:16pt; }
	h3{ font-size:14pt; }
	h4,h5,h6{ font-size:13pt; }
	p, span, div { font-size: 12pt; }

	table, figure, p, ul, ol, img { page-break-inside: avoid;}

	blockquote { padding: 0.25in 0.334in; }
	img { max-width: 100% !important; }

	a:link[href^="http"]::after,
	a[href^="http://"]:visited::after {
		content: " <" attr(href) "> ";
		font-size: 80%;
	}

	a[href^="#"]:after,
	a[href^="javascript:"]:after,
	a[href^="tel:"]:after {
		content: "";
	}

	/* Please note that things like logos will need to have this turned off otherwise you see text over the image */


	// -------------------------- Layout Adjustments --------------------------- //

	// Display Voices.com logo
	body::before {
		content: url('#{$themePath}/logos/voices-logo-brand-sm-blue.png');
		display: block;
		width: 140px;
	}

	// Add line between Voices.com logo and the page content
	#main-page {
		padding-top: 1rem;
		margin-top: 2rem;
		border-top: 3px solid #ccc;
	}

	// Hide all of these components on print
	#header,
	.nav-sub,
	footer.footer,
	a[rel="print"]
	{
		display: none !important;
	}

	.d-flex {
		display: block;
	}

	// -------------------------- Completely Random Elements --------------------------- //

	.stat-figure,
	.stat-title {
		font-size: 12pt !important;
		display: inline-block;
	}

}