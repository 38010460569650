$deco-icon-cdn-path    : 'https://static.voices.com/assets/images/decorative-icons';
$deco-icon-local-path  : '/assets/images/decorative-icons';
$deco-icon-asset-path  : $deco-icon-local-path; // TODO update path once .svg assets get pushed onto CDN

// List of Selectors for decorative icons
// NOTE: Icon Names MUST match the .svg file name, and they MUST be added in alphabetical order!
$deco-icon-selectors:
    additional-payment,
    agency,
    article,
    article-eye,
    audio-file,
    bar-chart,
    briefcase,
    broadcast,
    cake,
    calendar,
    car,
    category,
    category-x,
    character-announcer,
    character-custom,
    character-instructor,
    character-narrator,
    character-real_person,
    character-spokesperson,
    chat,
    child,
    city1,
    city2,
    city3,
    clipboard,
    cloud,
    computer-support,
    custom-branding,
    data-lock,
    default-support,
    direction,
    direction-no-mark,
    elderly,
    exclamation,
    file,
    file-add,
    file-secure,
    file-stack,
    film,
    firewall,
    first-aid-kit,
    flying-money,
    global,
    ghost,
    headphones,
    help,
    headset,
    heart,
    heart-hand,
    internet-video,
    job-posting,
    job-posting-x,
    job-response,
    keychain,
    language,
    language-icon,
	license,
    lightbulb-file,
    lightbulb-head,
    location,
    lock,
    mail-check,
    mail-exclamation,
    mail-x,
    mail,
    measure,
    measure-icon,
    megaphone,
    mic-check,
    mic,
    middle-aged,
    mobile,
    music,
    partners,
    payment,
    plan,
    podcast,
    price,
    price-tag,
    projector-screen,
    profile,
    project-inquiry,
    project-purchased,
    rating,
    receipt,
    rehire,
    response-share,
    responses-share,
    revisions,
    ribbon-star,
    ribbon-star-white,
    role,
    scale,
    scholar,
    search,
    secure,
    signed-agreement,
    speaker,
    stopwatch,
    stopwatch-icon,
	subscription,
    support,
    support-priority,
    talent-hired,
    teenager,
    team-care,
    team-permission,
    team,
    thumbs-up,
    travel-suitcase,
    trophy-star,
    tv,
    umbrella,
    unlock,
    wallet,
    young-adult;

// Deco Icons - Sizing (mixin)
@mixin deco-icon-sizing($sm: 4rem, $md: 4.8rem) {
	width: $md;
	height: $md;
	@media screen and (max-width : $screen-sm - 1px) {
		width: $sm;
		height: $sm;
	}
}

// Deco Icons - Base Styling
.deco-icon {
	&:before {
		content: " ";
		display: inline-block;
		background-size: contain;
		background-repeat: no-repeat;
		background-color: transparent;
		background-position: bottom;
		@include deco-icon-sizing();
	}

	&__sm {
		&:before {
			@include deco-icon-sizing(1.4rem, 1.4rem);
		}
	}

	&__md {
		&:before {
			@include deco-icon-sizing(1.6rem, 2.4rem);
		}
	}
}

// Decorative icons picker from selector list
@each $value in $deco-icon-selectors {
    .deco-icon-#{$value}:before {
        background-image: url('#{$deco-icon-asset-path}/#{$value}.svg');
    }
};

// Deco Icons - Circle Modifier - DS-116

@mixin deco-circle-styles($bg-color) {
    margin: 0 auto;
    background-color: $bg-color;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 72px;
    width: 72px;

    @media screen and (min-width: $screen-sm) {
        height: 80px;
        width: 80px;
    }

	&__sm {
		height: 24px;
		width: 24px;
	}

	&__md {
		height: 32px;
		width: 32px;

		@media screen and (min-width: $screen-sm) {
			height: 48px;
			width: 48px;
		}
	}
}

.deco-white-circle {
	@include deco-circle-styles(#fff);
}

.deco-grey-circle {
    @include deco-circle-styles($grey5);
}

.deco-blue-circle {
	@include deco-circle-styles($blue5);
}

// Specific Override Styles
.deco-icon.deco-icon-category-x:before {
    background-position: center;
    width: 6rem;
    @media screen and (max-width : $screen-sm - 1px) {
        width: 5.2rem;
    }
}
