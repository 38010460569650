@import '@node_modules/vanillajs-datepicker/sass/datepicker-bs5';

.datepicker.datepicker-dropdown {
    width: 300px;

    .datepicker-header {
        color: $blue1;

        .datepicker-controls {
            button {
                &:hover {
                    color: $blue0;
                }
            }
        }
    }

    .datepicker-main {
        .datepicker-view {
            width: 100%;

            .datepicker-cell {
                &.selected {
                    background-color: $blue0;
                    color: $white;
                }
            }

            .month,
            .year {
                font-size: 16px;
            }

            .days {
                .day,
                .dow {
                    font-size: 14px;
                    height: 4rem;
                }

                .dow {
                    font-weight: 300;
                }

                .day {
                    border-radius: 100%;
                }
            }
        }
    }

    .datepicker-footer {
        .clear-button {
            color: $blue1;

            &:hover {
                color: $blue0;
            }
        }
    }
}

.datepicker-input {
    position: relative;
    display: inline-block;
    cursor: pointer;
    min-width: 220px;
    background-color: transparent;

    input {
        width: 100%;
        padding-right: (1.6rem * 3);
    }

    .fa-calendar-check,
    .fa-calendar-alt {
        position: absolute;
        top: 50%;
        right: 1.6rem;
        transform: translateY(-50%);
        font-size: 1.8rem;
    }
}

// -------------------------------------------------
// Variations of Datepicker Components
// -------------------------------------------------


// Vacation Status
.vacation-status-input-group {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    @media (min-width: $screen-md) {
        display: inline-flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }

    > * ~ * {
        margin-top: .4rem;
        @media (min-width: $screen-md) {
            margin-top: 0;
            margin-left: 1.2rem;
        }
    }

    .form-group {
        margin-bottom: 0;

        label {
            font-size: 1.6rem;
        }

        .fa-calendar-check {
            font-size: 1.6rem;
            color: $grey1;
        }
    }

    #clear-vacation-status-btn {
        font-size: 1.6rem;
        color: $grey1;
        border: none;
        background: none;
        outline: none;

        @media (min-width: $screen-md) {
            margin-top: 3rem;
        }
    }
}