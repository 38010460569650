ul {
	&.list-check {
		padding-left: $spacing-large;
		list-style-type: none;

		li {
			position: relative;
			margin-top: $spacing-smallest;

			&::before {
				content: '\f00c';
				position: absolute;
				left: -$spacing-large;
				font-family: 'Font Awesome 5 Pro';
				font-size: 1.4rem;
			}
		}
	}
}

.list-unstyled {
	padding-left: 0;
	list-style: none;
}