// @import "react-multi-email/dist/style.css"; <--- NOTE: Commented out, due to the base component class being changed to 'react-multi-tags'

.react-multi-tags {
  margin: 0;
  max-width: 100%;
  -webkit-box-flex: 1;
  -ms-flex: 1 0 auto;
  flex: 1 0 auto;
  outline: 0;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  text-align: left;
  line-height: 1.21428571em;
  padding: 0;
  background: #fff;
  border: 1px solid rgba(34, 36, 38, 0.15);
  color: rgba(0, 0, 0, 0.87);
  border-radius: 0.28571429rem;
  -webkit-transition: box-shadow 0.1s ease, border-color 0.1s ease;
  transition: box-shadow 0.1s ease, border-color 0.1s ease;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  align-content: flex-start;
}
.react-multi-tags > span[data-placeholder] {
  display: none;
  position: absolute;
  left: 0.5em;
  top: 0.4em;
  padding: 0.4em;
  line-height: 1.21428571em;
}
.react-multi-tags.focused {
  border-color: #85b7d9;
  background: #fff;
}

.react-multi-tags.empty > span[data-placeholder] {
  display: inline;
  color: #ccc;
}
.react-multi-tags.fill > span[data-placeholder] {
  display: none;
}

.react-multi-tags > input {
  flex: 1;
  width: auto !important;
  outline: none !important;
  border: 0 none !important;
  display: inline-block !important;
  vertical-align: baseline !important;
  padding: 1.2rem !important;
  line-height: 1.5;
}

.react-multi-tags,
.react-multi-tags-list-items {
  [data-tag] {
    line-height: 1;
    vertical-align: baseline;
    margin: 0.14285714em;
    background-color: #f3f3f3;
    background-image: none;
    padding: 0.4em 0.8em;
    color: rgba(0, 0, 0, 0.6);
    text-transform: none;
    font-weight: 600;
    font-size: 1.6rem;
    border: 0 solid transparent;
    border-radius: 0.28571429rem;
    -webkit-transition: background 0.1s ease;
    -o-transition: background 0.1s ease;
    transition: background 0.1s ease;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    max-width: 100%;

    &:first-child {
      margin-left: 0;
    }

    &.invalid {
      border: 1px solid $red1;
      background-color: $red5;
      color: $red1;
    }

    [data-tag-item] {
      max-width: 100%;
      overflow: hidden;
    }

    [data-tag-handle] {
      margin-left: 0.833em;
      cursor: pointer;
    }
  }
}

// ----------------------- Custom styles -----------------------

.react-multi-tags-list-items {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  align-content: flex-start;
}

.react-multi-tags-helper-text {
  color: $grey1;
  font-size: 1.4rem;
  margin: 0.8rem 0;
}

.react-multi-tags {
  input[type="text"] {
    &:focus {
      box-shadow: none !important;
      border: none !important;
      border-radius: 0;
    }
  }

  // Has Error Class Applied
  &.form-input-error {
    border: 1px solid $red1 !important;
    background-color: $red5 !important;
    input {
      background-color: $red5 !important;
    }
  }
}
