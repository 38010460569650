.checkbox-row {
  position: relative;

  ~ .checkbox-row {
    margin-top: 1px;
  }

  input {
    height: 0;
    width: 0;
    position: absolute;
    opacity: 0;

    // DEFAULT STYLES.
    & + label {
      font-size: 1.4rem;
      font-weight: 500;
      display: flex;
      align-items: center;
      padding: 1rem 3.6rem 1rem 1rem;
      border: none;
      color: $grey1;
      cursor: pointer;
      border-radius: 4px;
      // Checkbox square outline
      &::before {
        content: ' ';
        position: absolute;
        display: block;
        width: 2rem;
        height: 2rem;
        border: 1px solid $grey4;
        border-radius: 0.4rem;
        left: auto;
        right: 1.2rem;
        top: 0;
        transform: translateY(-50%);
      }
      // Checkbox checkmark
      &::after {
        @extend .fa;
        content: $fa-var-check;
        font-weight: 500;
        position: absolute;
        display: block;
        font-size: 1.2rem;
        left: auto;
        right: 1.6rem;
        top: 0;
        transform: translateY(-50%);
        opacity: 0;
        color: $white;
      }
    }

    // CHECKED
    &:checked + label {
      &::before {
        background-color: $blue1;
      }
      &::after {
        opacity: 1;
      }
    }

    // DISABLED
    &:disabled + label {
      background-color: $grey5;
      cursor: default;
      opacity: 50%;
      &::before {
        background-color: $grey3;
      }
    }

    // HOVER STATE
    & + label:hover,
    & + label:active {
      background-color: $grey5;
    }
  }
}

.select-all-btn{
  display: flex;
  justify-content: flex-end;
  color: $blue1;
  font-size: 16px;
}