$button-class-prefix-default: btn;

/**
 * Button Sizes mapping! Build out btn size classes by iterating through @include automate-btn-size-styles() mixin
 */
$button-sizetags : xs, sm, md, lg, xl;

$button-sizes-map: (
	// Padding
	'button-padding-xs-vertical' : 0.4rem,
	'button-padding-xs-horizontal' : 0.8rem,
	'button-padding-sm-vertical' : 0.4rem,
	'button-padding-sm-horizontal' : 1.2rem,
	'button-padding-md-vertical' : 0.8rem,
	'button-padding-md-horizontal' : 1.6rem,
	'button-padding-lg-vertical' : 1.2rem,
	'button-padding-lg-horizontal' : 2.4rem,
	'button-padding-xl-vertical' : 1.6rem,
	'button-padding-xl-horizontal' : 2.8rem,
	// Line-Height
	'button-line-height-xs' : 1.2rem,
	'button-line-height-sm' : 1.6rem,
	'button-line-height-md' : 2rem,
	'button-line-height-lg' : 2.4rem,
	'button-line-height-xl' : 2.4rem,
	// Font-Size
	'button-font-size-xs' : 1rem,
	'button-font-size-sm' : 1.4rem,
	'button-font-size-md' : 1.6rem,
	'button-font-size-lg' : 1.8rem,
	'button-font-size-xl' : 1.8rem
);

// Spacing between
$button-spacing-between : .6rem;

// Font Weight
$button-font-weight           : 500;

/**
 * Button Colours mapping! Build out btn colour classes by iterating through @include automate-btn-color-styles() mixin
 * Notes: TODO : Add in "Selected" colour styling. See Zeplin file for details.
 */
$button-color-types : default, secondary, white, primary, success, info, warning, danger;

/**
 * Default Button Colours
 * Note: When using the alternative .btn-outline-default class, this can ONLY be used on DARK backgrounds (because of AODA compliancies).
 */
// [SOLID BUTTON] Font Colour
$button-colors-map: (
	'button-default-solid-font-color-normal'     : $text-dark,
	'button-default-solid-font-color-hover'      : $text-dark,
	'button-default-solid-font-color-active'     : $text-dark,
	'button-default-solid-font-color-focus'      : $text-dark,
	'button-default-solid-font-color-disabled'   : $grey3,

  // [SOLID BUTTON] BG Colour
	'button-default-solid-bg-color-normal'       : $grey5,
	'button-default-solid-bg-color-hover'        : $grey4,
	'button-default-solid-bg-color-active'       : $grey3,
	'button-default-solid-bg-color-focus'        : $grey5,
	'button-default-solid-bg-color-disabled'     : $grey5,

  // [OUTLINE BUTTON] Font Colour
	'button-default-outline-font-color-normal'   : $text-dark,
	'button-default-outline-font-color-hover'    : $text-dark,
	'button-default-outline-font-color-active'   : $text-dark,
	'button-default-outline-font-color-focus'    : $text-dark,
	'button-default-outline-font-color-disabled' : $grey3,

  // [OUTLINE BUTTON] Border Colour
	'button-default-outline-color-normal'        : $grey3,
	'button-default-outline-color-hover'         : $grey2,
	'button-default-outline-color-active'        : $grey2,
	'button-default-outline-color-focus'         : $grey3,
	'button-default-outline-color-disabled'      : $grey3,

  // [OUTLINE BUTTON] BG Colour
	'button-default-outline-bg-color-normal'       : $white,
	'button-default-outline-bg-color-hover'        : $grey4,
	'button-default-outline-bg-color-active'       : $grey4,
	'button-default-outline-bg-color-focus'        : $white,
	'button-default-outline-bg-color-disabled'     : $white,

  // [OUTLINE BUTTON] BG Transparency levels
	'button-default-outline-bg-alpha-normal'     : 1,
	'button-default-outline-bg-alpha-hover'      : 1,
	'button-default-outline-bg-alpha-active'     : 1,
	'button-default-outline-bg-alpha-focus'      : 1,
	'button-default-outline-bg-alpha-disabled'   : 0.4,

  // [OUTLINE] Focus Colour
	'button-default-outline-color'               : $ice1,

	/**
	* Secondary Button Colours (Same as Default Button - To Override Bootstrap Defaults)
	* Note: When using the alternative .btn-outline-white class, this can ONLY be used on DARK backgrounds (because of AODA compliancies).
	*/
	'button-secondary-solid-font-color-normal'     : $text-dark,
	'button-secondary-solid-font-color-hover'      : $text-dark,
	'button-secondary-solid-font-color-active'     : $text-dark,
	'button-secondary-solid-font-color-focus'      : $text-dark,
	'button-secondary-solid-font-color-disabled'   : $grey3,

	'button-secondary-solid-bg-color-normal'       : $grey5,
	'button-secondary-solid-bg-color-hover'        : $grey4,
	'button-secondary-solid-bg-color-active'       : $grey3,
	'button-secondary-solid-bg-color-focus'        : $grey5,
	'button-secondary-solid-bg-color-disabled'     : $grey5,

	'button-secondary-outline-font-color-normal'   : $text-dark,
	'button-secondary-outline-font-color-hover'    : $text-dark,
	'button-secondary-outline-font-color-active'   : $text-dark,
	'button-secondary-outline-font-color-focus'    : $text-dark,
	'button-secondary-outline-font-color-disabled' : $grey3,

	'button-secondary-outline-color-normal'        : $grey3,
	'button-secondary-outline-color-hover'         : $grey2,
	'button-secondary-outline-color-active'        : $grey2,
	'button-secondary-outline-color-focus'         : $grey3,
	'button-secondary-outline-color-disabled'      : $grey3,

	'button-secondary-outline-bg-color-normal'       : $white,
	'button-secondary-outline-bg-color-hover'        : $grey4,
	'button-secondary-outline-bg-color-active'       : $grey4,
	'button-secondary-outline-bg-color-focus'        : $white,
	'button-secondary-outline-bg-color-disabled'     : $white,

	'button-secondary-outline-bg-alpha-normal'     : 1,
	'button-secondary-outline-bg-alpha-hover'      : 1,
	'button-secondary-outline-bg-alpha-active'     : 1,
	'button-secondary-outline-bg-alpha-focus'      : 1,
	'button-secondary-outline-bg-alpha-disabled'   : 0.4,

	'button-secondary-outline-color'             : $ice1,

	/**
	* White Button Colours
	* Note: When using the alternative .btn-outline-white class, this can ONLY be used on DARK backgrounds (because of AODA compliancies).
	*/
	// [SOLID BUTTON] Font Colour
	'button-white-solid-font-color-normal'     : $text-dark,
	'button-white-solid-font-color-hover'      : $text-dark,
	'button-white-solid-font-color-active'     : $text-dark,
	'button-white-solid-font-color-focus'      : $text-dark,
	'button-white-solid-font-color-disabled'   : $grey3,
	// [SOLID BUTTON] BG Colour
	'button-white-solid-bg-color-normal'       : $white,
	'button-white-solid-bg-color-hover'        : $grey5,
	'button-white-solid-bg-color-active'       : $grey4,
	'button-white-solid-bg-color-focus'        : $white,
	'button-white-solid-bg-color-disabled'     : $white,
	// [OUTLINE BUTTON] Font Colour
	'button-white-outline-font-color-normal'   : $white,
	'button-white-outline-font-color-hover'    : $grey5,
	'button-white-outline-font-color-active'   : $grey4,
	'button-white-outline-font-color-focus'    : $white,
	'button-white-outline-font-color-disabled' : $white,
	// [OUTLINE BUTTON] BG Colour
	'button-white-outline-bg-color-normal'       : transparent,
	'button-white-outline-bg-color-hover'        : $grey5,
	'button-white-outline-bg-color-active'       : $grey4,
	'button-white-outline-bg-color-focus'        : $white,
	'button-white-outline-bg-color-disabled'     : $white,
	// [OUTLINE BUTTON] Border Colour
	'button-white-outline-color-normal'        : $white,
	'button-white-outline-color-hover'         : $grey5,
	'button-white-outline-color-active'        : $grey4,
	'button-white-outline-color-focus'         : $white,
	'button-white-outline-color-disabled'      : $white,
	// [OUTLINE BUTTON] BG Transparency levels
	'button-white-outline-bg-alpha-normal'     : 0,
	'button-white-outline-bg-alpha-hover'      : 0.2,
	'button-white-outline-bg-alpha-active'     : 0.1,
	'button-white-outline-bg-alpha-focus'      : 0.3,
	'button-white-outline-bg-alpha-disabled'   : 0.3,
	// [OUTLINE] Focus Colour
	'button-white-outline-color'               : $ice1,

	/**
	* Primary Button Colours
	* Note: When using the alternative .btn-outline-primary class, this can ONLY be used on LIGHT backgrounds (because of AODA compliancies).
	*/
	// [SOLID BUTTON] Font Colour
	'button-primary-solid-font-color-normal'     : $white,
	'button-primary-solid-font-color-hover'      : $white,
	'button-primary-solid-font-color-active'     : $white,
	'button-primary-solid-font-color-focus'      : $white,
	'button-primary-solid-font-color-disabled'   : $white,
	// [SOLID BUTTON] BG Colour
	'button-primary-solid-bg-color-normal'       : $blue1,
	'button-primary-solid-bg-color-hover'        : $blue0,
	'button-primary-solid-bg-color-active'       : $blue0,
	'button-primary-solid-bg-color-focus'        : $blue1,
	'button-primary-solid-bg-color-disabled'     : $blueDisabled,
	// [OUTLINE BUTTON] Font Colour
	'button-primary-outline-font-color-normal'   : $blue1,
	'button-primary-outline-font-color-hover'    : $blue0,
	'button-primary-outline-font-color-active'   : $blue0,
	'button-primary-outline-font-color-focus'    : $blue1,
	'button-primary-outline-font-color-disabled' : $blueDisabled,
	// [OUTLINE BUTTON] BG Colour
	'button-primary-outline-bg-color-normal'       : transparent,
	'button-primary-outline-bg-color-hover'        : $blue0,
	'button-primary-outline-bg-color-active'       : $blue0,
	'button-primary-outline-bg-color-focus'        : $blue1,
	'button-primary-outline-bg-color-disabled'     : $blueDisabled,
	// [OUTLINE BUTTON] Border Colour
	'button-primary-outline-color-normal'        : $blue1,
	'button-primary-outline-color-hover'         : $blue0,
	'button-primary-outline-color-active'        : $blue0,
	'button-primary-outline-color-focus'         : $blue1,
	'button-primary-outline-color-disabled'      : $blueDisabled,
	// [OUTLINE BUTTON] BG Transparency levels
	'button-primary-outline-bg-alpha-normal'     : 0,
	'button-primary-outline-bg-alpha-hover'      : 0.2,
	'button-primary-outline-bg-alpha-active'     : 0.2,
	'button-primary-outline-bg-alpha-focus'      : 0.3,
	'button-primary-outline-bg-alpha-disabled'   : 0.3,
	// [OUTLINE] Focus Colour
	'button-primary-outline-color'               : $ice1,

	/**
	* Success Button Colours
	* Note: When using the alternative .btn-outline-success class, this can ONLY be used on LIGHT backgrounds (because of AODA compliancies).
	*/
	// [SOLID BUTTON] Font Colour
	'button-success-solid-font-color-normal'     : $white,
	'button-success-solid-font-color-hover'      : $white,
	'button-success-solid-font-color-active'     : $white,
	'button-success-solid-font-color-focus'      : $white,
	'button-success-solid-font-color-disabled'   : $white,
	// [SOLID BUTTON] BG Colour
	'button-success-solid-bg-color-normal'       : $green0,
	'button-success-solid-bg-color-hover'        : $green1,
	'button-success-solid-bg-color-active'       : $green0,
	'button-success-solid-bg-color-focus'        : $green0,
	'button-success-solid-bg-color-disabled'     : #B6E0C7,
	// [OUTLINE BUTTON] Font Colour
	'button-success-outline-font-color-normal'   : $green0,
	'button-success-outline-font-color-hover'    : $green1,
	'button-success-outline-font-color-active'   : $green0,
	'button-success-outline-font-color-focus'    : $green0,
	'button-success-outline-font-color-disabled' : #B6E0C7,
	// [OUTLINE BUTTON] BG Colour
	'button-success-outline-bg-color-normal'       : transparent,
	'button-success-outline-bg-color-hover'        : $green1,
	'button-success-outline-bg-color-active'       : $green0,
	'button-success-outline-bg-color-focus'        : $green0,
	'button-success-outline-bg-color-disabled'     : #B6E0C7,
	// [OUTLINE BUTTON] Border Colour
	'button-success-outline-color-normal'        : $green0,
	'button-success-outline-color-hover'         : $green1,
	'button-success-outline-color-active'        : $green0,
	'button-success-outline-color-focus'         : $green0,
	'button-success-outline-color-disabled'      : #B6E0C7,
	// [OUTLINE BUTTON] BG Transparency levels
	'button-success-outline-bg-alpha-normal'     : 0,
	'button-success-outline-bg-alpha-hover'      : 0.2,
	'button-success-outline-bg-alpha-active'     : 0.1,
	'button-success-outline-bg-alpha-focus'      : 0.3,
	'button-success-outline-bg-alpha-disabled'   : 0.3,
	// [OUTLINE] Focus Colour
	'button-success-outline-color'               : $green4,

	/**
	* Info Button Colours
	* Note: When using the alternative .btn-outline-info class, this can ONLY be used on LIGHT backgrounds (because of AODA compliancies).
	*/
	// [SOLID BUTTON] Font Colour
	'button-info-solid-font-color-normal'     : $white,
	'button-info-solid-font-color-hover'      : $white,
	'button-info-solid-font-color-active'     : $white,
	'button-info-solid-font-color-focus'      : $white,
	'button-info-solid-font-color-disabled'   : $white,
	// [SOLID BUTTON] BG Colour
	'button-info-solid-bg-color-normal'       : $ice1,
	'button-info-solid-bg-color-hover'        : $ice0,
	'button-info-solid-bg-color-active'       : $ice2,
	'button-info-solid-bg-color-focus'        : $ice1,
	'button-info-solid-bg-color-disabled'     : #B4E4FD,
	// [OUTLINE BUTTON] Font Colour
	'button-info-outline-font-color-normal'   : $ice1,
	'button-info-outline-font-color-hover'    : $ice0,
	'button-info-outline-font-color-active'   : $ice2,
	'button-info-outline-font-color-focus'    : $ice1,
	'button-info-outline-font-color-disabled' : #B4E4FD,
	// [OUTLINE BUTTON] BG Colour
	'button-info-outline-bg-color-normal'       : transparent,
	'button-info-outline-bg-color-hover'        : $ice0,
	'button-info-outline-bg-color-active'       : $ice2,
	'button-info-outline-bg-color-focus'        : $ice1,
	'button-info-outline-bg-color-disabled'     : #B4E4FD,
	// [OUTLINE BUTTON] Border Colour
	'button-info-outline-color-normal'        : $ice1,
	'button-info-outline-color-hover'         : $ice0,
	'button-info-outline-color-active'        : $ice2,
	'button-info-outline-color-focus'         : $ice1,
	'button-info-outline-color-disabled'      : #B4E4FD,
	// [OUTLINE BUTTON] BG Transparency levels
	'button-info-outline-bg-alpha-normal'     : 0,
	'button-info-outline-bg-alpha-hover'      : 0.2,
	'button-info-outline-bg-alpha-active'     : 0.1,
	'button-info-outline-bg-alpha-focus'      : 0.3,
	'button-info-outline-bg-alpha-disabled'   : 0.3,
	// [OUTLINE] Focus Colour
	'button-info-outline-color'               : $ice4,

	/**
	* Warning Button Colours
	* Note: When using the alternative .btn-outline-warning class, this can be used on both DARK & LIGHT backgrounds (because of AODA compliancies).
	*/
	// [SOLID BUTTON] Font Colour
	'button-warning-solid-font-color-normal'     : $text-dark,
	'button-warning-solid-font-color-hover'      : $text-dark,
	'button-warning-solid-font-color-active'     : $text-dark,
	'button-warning-solid-font-color-focus'      : $text-dark,
	'button-warning-solid-font-color-disabled'   : $grey3,
	// [SOLID BUTTON] BG Colour
	'button-warning-solid-bg-color-normal'       : $yellow1,
	'button-warning-solid-bg-color-hover'        : $yellow0,
	'button-warning-solid-bg-color-active'       : $yellow2,
	'button-warning-solid-bg-color-focus'        : $yellow1,
	'button-warning-solid-bg-color-disabled'     : #FFE4B4,
	// [OUTLINE BUTTON] Font Colour
	'button-warning-outline-font-color-normal'   : $yellow1,
	'button-warning-outline-font-color-hover'    : $yellow0,
	'button-warning-outline-font-color-active'   : $yellow2,
	'button-warning-outline-font-color-focus'    : $yellow1,
	'button-warning-outline-font-color-disabled' : #FFE4B4,
	// [OUTLINE BUTTON] BG Colour
	'button-warning-outline-bg-color-normal'       : transparent,
	'button-warning-outline-bg-color-hover'        : $yellow0,
	'button-warning-outline-bg-color-active'       : $yellow2,
	'button-warning-outline-bg-color-focus'        : $yellow1,
	'button-warning-outline-bg-color-disabled'     : #FFE4B4,
	// [OUTLINE BUTTON] Border Colour
	'button-warning-outline-color-normal'        : $yellow1,
	'button-warning-outline-color-hover'         : $yellow0,
	'button-warning-outline-color-active'        : $yellow2,
	'button-warning-outline-color-focus'         : $yellow1,
	'button-warning-outline-color-disabled'      : #FFE4B4,
	// [OUTLINE BUTTON] BG Transparency levels (Note: normal' does not need to be defined, since the default state is always transparent)
	'button-warning-outline-bg-alpha-normal'     : 0,
	'button-warning-outline-bg-alpha-hover'      : 0.2,
	'button-warning-outline-bg-alpha-active'     : 0.1,
	'button-warning-outline-bg-alpha-focus'      : 0.3,
	'button-warning-outline-bg-alpha-disabled'   : 0.3,
	// [OUTLINE] Focus Colour
	'button-warning-outline-color'               : $yellow4,

	'button-danger-solid-font-color-normal'     : $white,
	'button-danger-solid-font-color-hover'      : $white,
	'button-danger-solid-font-color-active'     : $white,
	'button-danger-solid-font-color-focus'      : $white,
	'button-danger-solid-font-color-disabled'   : $white,

	'button-danger-solid-bg-color-normal'       : $red1,
	'button-danger-solid-bg-color-hover'        : $red0,
	'button-danger-solid-bg-color-active'       : $red2,
	'button-danger-solid-bg-color-focus'        : $red1,
	'button-danger-solid-bg-color-disabled'     : #F0B7AC,

	'button-danger-outline-font-color-normal'   : $red1,
	'button-danger-outline-font-color-hover'    : $red0,
	'button-danger-outline-font-color-active'   : $red2,
	'button-danger-outline-font-color-focus'    : $red1,
	'button-danger-outline-font-color-disabled' : #F0B7AC,

	'button-danger-outline-bg-color-normal'       : transparent,
	'button-danger-outline-bg-color-hover'        : $red0,
	'button-danger-outline-bg-color-active'       : $red2,
	'button-danger-outline-bg-color-focus'        : $red1,
	'button-danger-outline-bg-color-disabled'     : #F0B7AC,

	'button-danger-outline-color-normal'        : $red1,
	'button-danger-outline-color-hover'         : $red0,
	'button-danger-outline-color-active'        : $red2,
	'button-danger-outline-color-focus'         : $red1,
	'button-danger-outline-color-disabled'      : #F0B7AC,

	'button-danger-outline-bg-alpha-normal'     : 0,
	'button-danger-outline-bg-alpha-hover'      : 0.2,
	'button-danger-outline-bg-alpha-active'     : 0.1,
	'button-danger-outline-bg-alpha-focus'      : 0.3,
	'button-danger-outline-bg-alpha-disabled'   : 0.3,

	'button-danger-outline-color'               : $red4,
)