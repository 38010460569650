.modal.onboarding-modal {
	// Custom Onboarding Modal Styles
	z-index: 3147483640 !important; // to go over the Hotjar feedback widget...

	&.fade.in .modal-dialog,
	&.show .modal-dialog {
		transform: translate(-50%, -50%);
	}

	button.close {
		position: absolute;
		top: 1.6rem;
		right: 1.6rem;
		z-index: 1;
	}

	.modal-dialog {
		// Disable default Bootstrap modal styles
		transform: translate(-50%, -60%);
		margin: 0;

		// Custom Onboarding Modal Styles
		position: absolute;
		top: 50%;
		left: 50%;
		border-radius: 8px;
		background-color: $white;
		@include shadow-2;
		width: 80%;
		max-width: 480px;
		@media (min-width: $screen-sm) {
			width: 65%;
			max-width: 500px;
		}
		@media (min-width: $screen-md) {
			width: 40%;
			max-width: 650px;
		}

		// ------ Content Styles ----------------------
		.onboarding-modal-dialog-content {
			text-align: center;

			strong.title {
				color: $text-dark;
				display: block;
				font-size: 2rem;
				font-weight: $font-weight-medium;
				line-height: 1.25;
				margin-bottom: 1.6rem;
				@media (min-width: $screen-sm) {
					font-size: 2.4rem;
				}
			}

			p {
				font-size: 1.6rem;

				+ button {
					margin-top: 3.2rem;
				}

				&:last-of-type {
					margin-bottom: 0;
				}
			}
		}

		@media (max-width: $screen-xs - 1px) {
			.btns-container {
				display: flex;
				flex-direction: column-reverse;

				.btn {
					margin: 0;

					&:last-child { //Add Margin to Last Child since the Buttons are Reversed on Mobile
						margin-bottom: $spacing-medium;
					}
				}
			}
			#learn-more {
				margin-top: 1.6rem;
				margin-right: 0;
			}
		}

		.onboarding-modal-media-block {
			margin-bottom: 4.4rem;
		}

		.onboarding-modal-icon-block {
			margin-bottom: 2.4rem;
		}

		// ------ Static Modal ----------------------
		&.onboarding-modal-static-dialog {
			.onboarding-modal-dialog-content {
				padding: $spacing-large;
				text-align: center;
				@media (min-width: $screen-sm) {
					padding: $spacing-largest;
				}
			}
		}

		// ------ Slider Modal ----------------------
		$max-slider-limit: 3;
		@mixin automate-onboarding-modal-slider-styles($max-slider-limit) {
			@for $i from 1 through $max-slider-limit {
				// Total number of slides
				&[data-slider-limit="#{$i}"] {
					.onboarding-modal-dialog-content {
						width: #{calc($i * 100%)};
					}

					.onboarding-modal-slider-section {
						width: calc(100% / $i);
					}
				}

				// Step number
				&[data-slider-step="#{$i}"] {
					.onboarding-modal-dialog-content {
						left: - calc(calc($i - 1) * 100%);
					}
				}
			}
		}

		&.onboarding-modal-slider-dialog {
			overflow: hidden;

			// Automate widths of Sliders
			@include automate-onboarding-modal-slider-styles($max-slider-limit); // If we need to increase this number in the future, for more lengthy onboarding modals, then feel free! For now we only need sliders with 3 screens at a time...

			// More Custom Slider Styles...
			.onboarding-modal-dialog-content {
				position: relative;
				left: 0;
				display: flex;
				align-items: flex-end;
				transition: 0.3s ease left;
			}

			.onboarding-modal-slider-section {
				padding: $spacing-largest;
			}

		}

		// ------ Pagination Dots ----------------------
		ul.onboarding-modal-pagination-dots {
			list-style: none;
			padding: 0 0 4rem 0;
			margin-bottom: 0;
			display: flex;
			align-items: center;
			justify-content: center;

			li {
				display: block;
				width: .8rem;
				height: .8rem;
				border-radius: 100%;
				background-color: $grey4;

				&[data-slider-step] {
					cursor: pointer;
				}

				&.active {
					background-color: $blue1;
				}

				~ li {
					margin-left: .8rem;
				}
			}
		}
	}
}