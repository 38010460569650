@import '@styles/mixins/input-mixins';

.radio-card-group {
   @include input-card-group;
}

.radio-card {
    &[class*="col-"] {
        @include input-card-col;
    }

    // Left Aligned Card Content
    &.radio-card-left-align label {
       @include input-card-align;
        .radio-card-content {
            margin-left: $spacing-medium;
        }
    }

    // Center Aligned Card Content
    &.radio-card-center-align label {
        @include input-card-align;
        .radio-card-content {
            margin-left: $spacing-medium;
        }
        @media(min-width:$screen-md){
            flex-wrap: wrap;
            justify-content: center;
            .radio-card-content {
                text-align: center;
                justify-content: center;
                margin-left: 0;
            }
        }
    }

    // Radio Button (interactions)
    input[type="radio"] {
        &:checked + label {
            box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
            &::before {
                border: 3px solid $blue1;
            }
        }

        &:disabled {
            opacity: 0.4;
            cursor: default;

            & + label {
                background-color: $grey5;
                color: $grey2;
                cursor: default;
                &:hover {
                    color: $grey2;
                    box-shadow: none;
                    transform: none;
                }
            }

            &:checked + label {
                box-shadow: none;
            }
        }
    }

    // The Card Display... which is built by the <label> element
    label {
        @include input-card-label;

        .radio-card-content {
            @include input-card-content;
        }

        .radio-card-title {
            @include input-card-title;
        }

        .radio-card-text {
            @include input-card-text;
        }
    }
}
