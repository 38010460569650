
#connected-services-container {
    padding-bottom: 1.1rem;
}

.connected-services-alert {
    height: auto;
    border-radius: 4px;
    background-color: $green5;

    .alert {
        border: none;
    }
}

#modal-disconnect {
    label {
        width: 56.9rem;
        height: 2.4rem;
        font-size: 1.6rem;
        font-weight: 500;
        line-height: 1.5;
        color: $text-dark;
        margin: 0;
    }

    .password-sub-text {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
}

.help-text {
    height: 2.1rem;
    margin: 2.4rem 0 0;
    font-size: 1.4rem;
    line-height: 1.5;
    color: $grey1;
}

.google-social-content {
    .icon {
        background: url('#{$assetPath}/images/account/google.svg') transparent 0 0 no-repeat;
    }
}

.apple-social-content {
    .icon {
        background: url('#{$assetPath}/images/account/apple.png') transparent 0 0 no-repeat;
    }
}

.social-sign-in-header {
    display: flex;
    align-items: center;

    .icon {
        display: inline-block;
        vertical-align: middle;
        width: 26px;
        height: 26px;
        background-size:25px;
    }
}

.connected-services-container {
    display: flex;
    flex-direction: row;
    align-items: center;

    .icon-block {
        font-size: 2.4rem;

        .fa-check-circle:before {
            color: $green1;
        }
    }

    .connected-services-description {
        font-size: 1.6rem;
        padding-left: $spacing-smaller;

        span {
            font-weight:bold;
            word-break: break-all;
        }
    }
}

.connected-services-container {
    border-radius: 8px;
    border: 1px solid $grey4;
    display: flex;
    justify-content: space-between;

    @media (min-width: $screen-sm) and (max-width: ($screen-md - 1px)) {
        justify-content: center;
        align-items: center;
    }
    @media (max-width: $screen-sm) {
        flex-direction: column;
    }
    .disconnect-btn-wrapper {
        display: flex;
        justify-content: center;
        margin-top: $spacing-medium;

        @media (min-width: $screen-sm) {
            margin-top: 0;
            display: inline-block;
        }
    }

    .icon-block {
        display: inline-block;
        font-size: 2.4rem;
        margin-right:0.8rem;

        .fa-times-circle:before {
            color: $red1;
        }
    }

    .social-sign-in-container {
        border-radius: 4px;
        white-space: nowrap;
        width: 20rem;
        height: 4rem;
        display: flex;
        padding: 1.2rem 2rem 1.2rem 1.8rem;
        border: thin solid $grey4;
        transition: all .2s linear;

        @media (min-width: $screen-xs) and (max-width: ($screen-md - 1px)) {
            margin-top: 1.2rem;
        }
        &:hover {
            cursor: pointer;
            background-color: $grey4;
        }

        &:focus {
            background-color: $white;
            border: solid 2px $ice2;
        }
        .icon {
            display: inline-block;
            vertical-align: middle;
            height: 19px;
            background-size: 16.6px;
        }

        .text {
            font-size: 1.4rem;
            font-weight: 500;
            line-height: normal;
            letter-spacing: 0.22px;
            color: $text-dark;
            margin: 0 0 0 1.1rem;

            @media (max-width: $screen-sm) {
                margin: 0 auto;
            }
        }
    }
}

.disconnected-container {
    @media (min-width: $screen-sm) {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
    }
}


.disconnect-note {
    margin: 1.6rem 0 0;
    font-size: 1.5rem;
    line-height: 1.5;
    color: $grey1;

    span {
        font-weight: bold;
    }
}
