/* ****************************** */
/* Progress Indicators - Vert & Horiz */
/* ****************************** */

/* Draft proposal only.
 * From: https://www.cssscript.com/responsive-step-progress-indicator-with-pure-css/ */

.progress-indicator {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
}

.progress-indicator.stacked {
    display: block;
}

.progress-indicator > li {
    -ms-flex: 1;
    -webkit-flex: 1;
    -moz-flex: 1;
    flex: 1;
}

.progress-indicator {
    margin: 0 0 1em;
    padding: 0;
    font-size: 80%;
    text-transform: uppercase;
}

.progress-indicator > li { // each LI is a step/node in the process.
    list-style: none;
    text-align: center;
    width: auto;
    padding: 0;
    margin: 0;
    position: relative;
    text-overflow: ellipsis;
    color: $grey2;
    display: block;

    a:hover {
        text-decoration: none;
    }
}

.progress-indicator > li:hover {
    color: $grey1;
}

.progress-indicator > li.completed,
.progress-indicator > li.completed .bubble {
    color: $success;
}

.progress-indicator > li .bubble {
    border-radius: 1000px;
    width: 2rem;
    height: 2rem;
    background-color: $grey3;
    display: block;
    margin: 0 auto 0.5em;
    border-bottom: 1px solid $grey1;
}

.progress-indicator > li .bubble:after,
.progress-indicator > li .bubble:before {
    display: block;
    position: absolute;
    top: 9px;
    width: 100%;
    height: 3px;
    content: '';
    background-color: $grey3;
}

.progress-indicator > li.completed .bubble,
.progress-indicator > li.completed .bubble:after,
.progress-indicator > li.completed .bubble:before {
    background-color: $green1; // primary element colour
    border-color: $green0; // apply suble shadow-like effect
}

.progress-indicator > li .bubble:before {
    left: 0;
}

.progress-indicator > li .bubble:after {
    right: 0;
}

.progress-indicator > li:first-child .bubble:after,
.progress-indicator > li:first-child .bubble:before {
    width: 50%;
    margin-left: 50%;
}

.progress-indicator > li:last-child .bubble:after,
.progress-indicator > li:last-child .bubble:before {
    width: 50%;
    margin-right: 50%;
}

.progress-indicator > li.active,
.progress-indicator > li.active .bubble {
    color: $blue1;
}

.progress-indicator > li.active .bubble,
.progress-indicator > li.active .bubble:after,
.progress-indicator > li.active .bubble:before {
    background-color: $blue2;
    border-color: $blue0;
    text-decoration: none;
}

.progress-indicator > li a:hover .bubble,
.progress-indicator > li a:hover .bubble:after,
.progress-indicator > li a:hover .bubble:before {
    background-color: $ice2;
    border-color: $ice1;
    text-decoration: none;
}

.progress-indicator > li a:hover .bubble {
    color: $primary;
    text-decoration: none;
}

.progress-indicator > li.danger .bubble,
.progress-indicator > li.danger .bubble:after,
.progress-indicator > li.danger .bubble:before {
    background-color: $accent1;
    border-color: $accent0;
}

.progress-indicator > li.danger .bubble {
    color: $accent1;
}

.progress-indicator > li.warning .bubble,
.progress-indicator > li.warning .bubble:after,
.progress-indicator > li.warning .bubble:before {
    background-color: $warning;
    border-color: darken($warning, 30%);
}

.progress-indicator > li.warning .bubble {
    color: $warning;
}

.progress-indicator > li.info .bubble,
.progress-indicator > li.info .bubble:after,
.progress-indicator > li.info .bubble:before {
    background-color: $info;
    border-color: $ice0;
}

.progress-indicator > li.info .bubble {
    color: $info;
}

.progress-indicator.stacked > li {
    text-indent: -10px;
    text-align: center;
    display: block;
}

.progress-indicator.stacked > li .bubble:after,
.progress-indicator.stacked > li .bubble:before {
    left: 50%;
    margin-left: -1.5px;
    width: 3px;
    height: 100%;
}

.progress-indicator.stacked .stacked-text {
    position: relative;
    z-index: 10;
    top: 0;
    margin-left: 60% !important;
    width: 45% !important;
    display: inline-block;
    text-align: left;
    line-height: 1.2em;
}

.progress-indicator.stacked > li a {
    border: none;
}

.progress-indicator.stacked.nocenter > li .bubble {
    margin-left: 0;
    margin-right: 0;
}

.progress-indicator.stacked.nocenter > li .bubble:after,
.progress-indicator.stacked.nocenter > li .bubble:before {
    left: 10px;
}

.progress-indicator.stacked.nocenter .stacked-text {
    width: auto !important;
    display: block;
    margin-left: 40px !important;
}

/* Panel version*/

.progress-indicator.progress-panel {
    background-color: $grey5;
    padding: 10px 5px;
    border: 1px solid #DDDDDD;
    border-radius: 4px;
}

.progress-indicator.progress-panel > li .bubble {
    height: 12px;
    width: 99%;
    border-radius: 2px;
    box-shadow: inset -5px 0 12px rgba(0, 0, 0, 0.2);
}

.progress-indicator.progress-panel > li .bubble:before,
.progress-indicator.progress-panel > li .bubble:after {
    display: none;
}

.progress-indicator.stepped.stacked {
    // width: 48%;
    display: inline-block;
}

.progress-indicator.stepped.stacked > li {
    height: 150px;
}

.progress-indicator.stepped.stacked > li .bubble {
    padding: 0.1em;
}

.progress-indicator.stepped.stacked > li:first-of-type .bubble {
    padding: 0.5em;
}

.progress-indicator.stepped.stacked > li:last-of-type .bubble {
    padding: 0em;
}

/* Nocenter */

.progress-indicator.nocenter.stacked > li {
    min-height: 100px;
}

.progress-indicator.nocenter.stacked > li span {
    display: block;
}

@media handheld, screen and (max-width: 400px) {
    .progress-indicator {
        font-size: 60%;
    }
}