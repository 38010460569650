@mixin shadow-1 {
    box-shadow: 0 2px 15px 0 rgba(#000, calc(5/100)), 0 2px 5px 0 rgba($text-dark, calc(10/100));
}

@mixin shadow-2 {
    box-shadow: 0 5px 12px 0 rgba($text-dark, calc(10/100));
}

@mixin shadow-3 {
    box-shadow: 0 5px 32px 0 rgba($text-dark, calc(11/100));
}

.shadow-1 {
	@include shadow-1;
}

.shadow-2 {
	@include shadow-2;
}

.shadow-3 {
	@include shadow-3;
}