@import "@bootstrap/scss/reboot";

svg {
	vertical-align: inherit;
}

hr {
	opacity: 1;
	margin: 2rem 0;
}

a {
	text-decoration: none;
}

img {
	max-width: 100%;
}

ul.radio-group {
	padding-left: 0;
	list-style: none;
}