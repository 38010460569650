/* *******************************************************
   27b - Warning Box 

   This is a re-usable <div id="warning"> element 
   that can be called with the function showWarnings() and 
   displayed centered on page or over another element.
   Example: "Are you sure you want to delete this?"
 * ******************************************************* */
#warning-box {
    transform: scale(0);
    z-index: 999999;
    position: absolute;
    left: 0;
    right: 0;
    margin: 10px 10%;
    padding: 20px 25px;
    color: #333;
    box-sizing: border-box;
    border: 2px $blue1 solid;
    border-radius: 6px;
    background: $white;
    box-shadow: 0 14px 24px -16px rgba(0, 0, 0, 0.63);
    transition: all 0.5s;
}
#warning-box h3 {
    margin: 0 25px 0 0;
    font-size: 2rem;
}
#warning-box h3 i {
    color: #f00;
    margin-right: 8px;
}
#warning-box i {
    float: none;
    padding-right: 0;
    margin-left: 0; 
    line-height: 0;
}
#warning-box ul {
    list-style-type: none;
    margin: 0 0 10px 0;
}
#warning-box ul li {
    word-wrap: break-word;
}
#warning-box p {
    margin: 10px 0;
    line-height: 1.5em;
}
#warning-box button {
	border: none;
	background-color: transparent;
	position: absolute;
	right: 12px;
	top: 10px;
}
#warning-box button.action {
    float: right;
    min-width: 73px;
    margin: 15px 0 0 12px;
}

#warning-box.animate-appear {
    transform: scale(1);
}
#warning-box.animate-disappear {
    transform: scale(0);
}
.purple-plus-cta {
    background-color: #e7dcff;
    border-radius: 2rem;
    padding: 0.6rem 1.2rem 0.6rem .4rem;
    font-size: 1.4rem;

    @media (max-width: 992px) {
        position: relative;
        padding-left: 3.8rem;
        border-radius: 5rem;
	}
  
    .grey-circle {
      font-size: 1.4rem;
      background-color: #552ab1;
      height: 3rem;
      width: 3rem;
      border-radius: 2.4rem;
      text-align: center;
      padding: 0.4rem;
      margin-right: 0.8rem;

        @media (max-width: 992px){
            position: absolute;
            left: 0.4rem;
        }
    }

  }


.plus-cta {
    background-color: #f0f4f7;
    border-radius: 2rem;
    padding: 0.4rem 0.8rem 0.4rem 0.4rem;
    display: inline-block;
    font-size: 1.4rem;
  
    .grey-circle {
      font-size: 1.4rem;
      background-color: $grey2;
      height: 3rem;
      width: 3rem;
      border-radius: 2.4rem;
      display: inline-block;
      text-align: center;
      padding: 0.4rem;
      margin-right: 0.4rem;
    }
  }

@keyframes hide-notification{
    from {
        opacity:1;
    }
    to {
        opacity:0;
    }    
}

@keyframes appear {
    from {
        transform: scale(0);
    }
    to {
        transform: scale(1);
    }
}
@keyframes disappear {
    from {
        transform: scale(1);
    }
    to {
        transform: scale(0);
    }
}
