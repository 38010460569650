$checkbox-unit-size: 20px;
$checkbox-unit-size-lg: 24px;

.checkbox {
    padding-left: $checkbox-unit-size;

    label {
        display: inline-block;
        vertical-align: middle;
        position: relative;
        padding-left: 8px;

        &::before {
            content: "";
            display: inline-block;
            position: absolute;
            left: 0;
            border-radius: 3px;
            background-color: $white;
            -webkit-transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
            -o-transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
            transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
            width: $checkbox-unit-size;
            height: $checkbox-unit-size;
            top: 0;
            margin-left: -18px;
            border: 1px solid $grey4;
            cursor: pointer;
        }

        &::after {
            display: inline-block;
            position: absolute;
            width: $checkbox-unit-size;
            height: $checkbox-unit-size;
            left: 0;
            top: 0;
            margin-left: -18px;
            padding-top: 2px;
            color: #555555;
            padding-left: 0;
            line-height: 1.2 !important;
            font-size: 14px;
        }
    }

    input[type="checkbox"] {
        opacity: 0;
        z-index: 1;
        cursor: pointer;
        margin-top: -8px;

        &:focus {
            & + label {
                &::before {
                    outline: none;                    outline-offset: -2px;
                }
            }
        }

        &:checked {
            & + label {
                &::after {
                    font-family: 'Font Awesome 5 Pro';
                    content: "\f00c";
                }
            }
        }

        &:indeterminate {
            & + label {
                &::after {
                    display: block;
                    content: "";
                    width: 10px;
                    height: 3px;
                    background-color: #555555;
                    border-radius: 2px;
                    margin-left: -16.5px;
                    margin-top: 7px;
                }
            }
        }

        &:disabled {
            cursor: not-allowed;

            & + label {
                opacity: 0.65;

                &::before {
                    background-color: #eeeeee;
                    cursor: not-allowed;
                }
            }
        }
    }

    input[type="radio"] {
        opacity: 0;
        z-index: 1;
        cursor: pointer;

        &:focus {
            & + label {
                &::before {
                    outline: thin dotted;
                    outline: 5px auto -webkit-focus-ring-color;
                    outline-offset: -2px;
                }
            }
        }

        &:checked {
            & + label {
                &::after {
                    font-family: 'Font Awesome 5 Pro';
                    content: "\f00c";
                }
            }
        }

        &:indeterminate {
            & + label {
                &::after {
                    display: block;
                    content: "";
                    width: 10px;
                    height: 3px;
                    background-color: #555555;
                    border-radius: 2px;
                    margin-left: -16.5px;
                    margin-top: 7px;
                }
            }
        }

        &:disabled {
            cursor: not-allowed;

            & + label {
                opacity: 0.65;

                &::before {
                    background-color: #eeeeee;
                    cursor: not-allowed;
                }
            }
        }
    }

    &-lg {
        label {
            padding-left: ($checkbox-unit-size-lg + 3); // 3px spacing between checkbox and label text
            height: $checkbox-unit-size-lg;

            &::before {
                width: $checkbox-unit-size-lg;
                height: $checkbox-unit-size-lg;
            }
            &::after {
                width: $checkbox-unit-size-lg;
                height: $checkbox-unit-size-lg;
                font-size: 15px;
                top: 1px;
            }
        }
    }

    &-no-label {
        label {
            padding-left: 0;
            &::before {
                position: relative;
            }
        }
    }

    &-all {
        label {
            &::before {
                top: -10px;
            }

            &::after {
                top: -9px;
                padding-left: .4rem;
                -webkit-font-smoothing: antialiased;
                line-height: 1.2;
            }
        }
    }
}

.checkbox-primary {
    input[type="checkbox"] {
        &:checked {
            &+label {
                &::before {
                    background-color: #337ab7;
                    border-color: #337ab7;
                }
                &::after {
                    color: $white;
                }
            }
        }
        &:indeterminate {
            &+label {
                &::before {
                    background-color: #337ab7;
                    border-color: #337ab7;
                }
                &::after {
                    background-color: $white;
                }
            }
        }
    }
    input[type="radio"] {
        &:checked {
            &+label {
                &::before {
                    background-color: #337ab7;
                    border-color: #337ab7;
                }
                &::after {
                    color: $white;
                }
            }
        }
        &:indeterminate {
            &+label {
                &::before {
                    background-color: #337ab7;
                    border-color: #337ab7;
                }
                &::after {
                    background-color: $white;
                }
            }
        }
    }
}

.form-horizontal .radio,
.form-horizontal .checkbox,
.form-horizontal .radio-inline,
.form-horizontal .checkbox-inline {
    padding-top: 0;
}

.form-group .checkbox label {
    font-family: Roboto;
}

// Correct 'googly eyes' look for radio buttons and normalize alignment and text offsets
.checkbox-inline input[type='checkbox'],
.checkbox input[type='checkbox'],
.radio-inline input[type='radio'],
.radio input[type='radio'] {
    position: absolute;
}

// ---------------- Radio Buttons ----------------
.radio {
    font-size: 1.8rem; // override Bootstrap 3 to avoid off-center dot for radio
    label {
        &::before {
            width: 18px;
            height: 18px;
            border: 1px solid #AAAAAA;
            top: 4px;
        }

        &::after {
            width: 10px;
            height: 10px;
            left: 4px;
            top: 8px;
        }
    }

    input[type='radio']:focus + label::before {
        outline: none;
    }
}

.radio-group {
    display: flex;
    flex-wrap: wrap;

    ul & {
        padding-left: 0;
        list-style: none;
    }

    > .radio {
        width: 50%;
        margin-top: 0;
    }

    + .radio-group {
        margin-top: -1rem;
    }

    .radio label {
        font-size: 1.6rem;

        &::before {
            top: 2px;
        }

        &::after {
            top: 5px;
        }
    }
}

// ---------------- Specific Checkboxes/Radio Buttons ----------------

input[type='radio'] + i.invoice-icon {
    display: inline-block;
    height: 70px;
    width: 90px;
    margin-top: 5px;
    padding-top: 1rem;
    text-align: center;
    border-radius: 8px;
    background: #313E4B;

    &:hover {
        background: $blue1;
    }

    > span {
        padding-top: 13px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #FFFFFF;
        font-size: 2.2rem;
        font-style: normal;
    }
}

input[type='radio']:checked + i.invoice-icon {
    background: $blue1;
}

.checkbox input[type="checkbox"]:disabled,
.checkbox input[type="radio"]:disabled {
    cursor: not-allowed;
}

.checkbox input[type="checkbox"]:disabled + label,
.checkbox input[type="radio"]:disabled + label {
    opacity: 0.65;
}

.checkbox input[type="checkbox"]:disabled + label::before,
.checkbox input[type="radio"]:disabled + label::before {
    background-color: $grey5;
    border-color: $grey4;
    cursor: not-allowed;
}

.checkbox input[type="checkbox"]:disabled + label::after,
.checkbox input[type="radio"]:disabled + label::after {
    color: $grey1;
    cursor: not-allowed;
}
