@import "@styles/fonts/poppins";

html {
	font-size: $html-font-size-base; // this should already be set to 10px in the Bootstrap scaffolding, but just in case...
}

body {
	font-family: $font-family-base;
	font-weight: $font-weight-base;
	font-size: $font-size-base;
	line-height: $line-height-base;
}

// Basic links
a {
	cursor: pointer;
	transition: color 0.15s linear;
	text-decoration: none;

	&, &:link {
		color: $blue1;
	}

	&:hover,
	&:active {
		color: $blue0;
		text-decoration: none;
	}
}

// Content Links
p a,
.link {
	font-weight: 500;
	border-bottom: 1px solid transparent;

	&:hover,
	&:active {
		text-decoration-skip-ink: auto;
		box-shadow: 0 1px 0 $blue0;
	}

	// Override other font weights if these classes are applied on links
	&.font-regular,
	&.font-med,
	&.font-bold,
	&.font-heavy {
		font-weight: 500;
	}

	&[disabled] {
		color: $grey2;
		cursor: default;
		pointer-events: none;
	}
}

button.link {
	border: none;
	background: none;
	outline: none;
	padding: 0;
	color: $blue1 !important;
	transition: color 0.15s linear;

	&:hover,
	&:active {
		color: $blue0 !important;
		text-decoration: none;
	}
}

// Paragraph Text Styles
p {
	margin-bottom: $paragraph-margin-bottom;

	+ p {
		margin-top: $next-paragraph-margin-top; // Add spacing between paragraph text elements
	}
}

// Heading Styles
h1 {
	color: $text-dark;
	margin-top: 1.1em;
	margin-bottom: 1em;
}

h2,
h3,
h4,
h5,
h6 {
	color: $text-dark;
	margin-bottom: 1rem;
}

h1,
.h1,
h2,
.h2,
h3,
.h3 {
	margin-top: 2rem;
	margin-bottom: 1rem;
}

h1 small,
.h1 small,
h2 small,
.h2 small,
h3 small,
.h3 small,
h1 .small,
.h1 .small,
h2 .small,
.h2 .small,
h3 .small,
.h3 .small {
	font-size: 65%;
}

h4,
.h4,
h5,
.h5,
h6,
.h6 {
	margin-top: 1rem;
	margin-bottom: 1rem;
}

h4 small,
.h4 small,
h5 small,
.h5 small,
h6 small,
.h6 small,
h4 .small,
.h4 .small,
h5 .small,
.h5 .small,
h6 .small,
.h6 .small {
	font-size: 75%;
	font-weight: 400;
	line-height: 1;
	color: $grey2;
}

@mixin responsive-heading-styles($level: 1) {
	$font-weight: map-get($font-weights, h#{$level});
	$font-size: map-get($font-sizes, h#{$level});
	$font-size-mobile: map-get($font-sizes-mobile, h#{$level});

	h#{$level},
	.h#{$level} {
		font-size: $font-size-mobile;
		font-weight: $font-weight;

		@media (min-width: $screen-sm) {
			font-size: $font-size;
		}
	}
}

@include responsive-heading-styles(1);
@include responsive-heading-styles(2);
@include responsive-heading-styles(3);
@include responsive-heading-styles(4);
@include responsive-heading-styles(5);
@include responsive-heading-styles(6);

/**
 * Header Sub-text Classes
 */
h1 > span,
h2 > span,
h3 > span,
h4 > span,
h5 > span,
h6 > span {
	@extend .text-grey2;
	font-size: 0.8em;
}

// Header address format
h1 > p {
	// TODO : Remove this eventually, since this is incorrect syntax usage. Block level elements should not be nested within each other.
	color: #989797;
	font-size: 0.5em;
	font-weight: 500;
}

h1 > span {
	display: block;
}

.title > h1 {
	color: $blue0;
}

/* ****************************** */
// 7 - COPY & TYPOGRAPHY ELEMENTS
/* ****************************** */

bold,
strong,
.strong,
.bold {
	font-weight: bold;
}

.em,
em,
.italic,
italics {
	font-style: italic;
}

// TODO : Verify that we need all of these class modifiers
blockquote {
	padding: 1rem 2rem;
	margin-top: 0;
	margin-right: 0;
	margin-bottom: 2rem;
	margin-left: 0;
	border: none;
	font-size: $font-size-base;
	font-weight: $font-weight-normal;

	&,
	&.border-left {
		border-left: 5px solid $ice4;
		text-align: left;
	}

	&.border-right {
		border-left: none;
		border-right: 5px solid $ice4;
		text-align: right;
	}

	&.no-borders {
		border: none;
		text-align: center;
	}

	footer,
	small {
		font-weight: $font-weight-base;
		display: block;
		font-size: 90%;
		color: $grey2;

		&:before {
			content: '\2014 \00A0';
		}
	}
}

ol.numbered-list {
	color: $grey1;
	font-size: 1.6rem;
	padding: 0 0 0 2.2rem;
	margin: 0 0 1.2rem;

	> li {
		line-height: 2.4rem !important;
		padding-bottom: 0 !important;
	}
}