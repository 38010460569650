/* ****************************** */
/* 10 - Traditional UL OL Lists */
/* ****************************** */

li.wrap {
    word-break: break-all;
}

ol:not(.table):not(.nav):not(.dropdown-menu):not(.pagination):not(.list-group):not(.progress-indicator) { 
    padding: 0 0 0 2.2rem;
    margin: 0 0 1.2rem 0;
    
    > li { 
        line-height: 2rem;
        padding-bottom: 1.6rem; 
    }//li

} // basic list positioning

// 10.a - List bullets  *********************************************
ul{
    &.no-bullets{
        list-style:none;
        padding:0;
    }
    &.list-icon { 
    // Apply to UL blocks which don't act as tables.
        list-style: none; // suppress html bullets.
        
        > li { 
            &::before { 
                // font-family: fontAwesome, 'Arial Black', arial;
                font-family:    'Font Awesome 5 Pro';
                font-weight:    900;
                display:        inline-block;
                content:        "\f0c8"; // Square glyph - issue after moving to FAP5.
                text-indent:    -1.6em;
                font-size:      65%;
                vertical-align: text-bottom;
                color:          $ice1; /* or whatever color you prefer */
            } // add bullet. Allows us to colour the bullet and use our preferred glyph.
        } // li
    }
    &.spaced-out-list-items {
        li ~ li {
            margin-top: .5rem;
        }
    }
} // ul

ol.list-inline{ 
// Apply to UL blocks which don't act as tables.
    list-style: none; // suppress html bullets.

    counter-reset: list-numbers;
    
    > li { 
        &::before { 
            display:        inline-block;
            counter-increment: list-numbers;
            content: counter(list-numbers)".";
            text-indent:    -1em;
        } // add number. Setting ol to inline-block breaks number display regardless of list-style type.
    } // li
} // ol


// 10.b - Inline lists  *********************************************
.list-inline { 
    padding-left: 0;
    list-style: none;

    > li { 
        display: inline-block;
        padding-left: 1.6rem;

        &::before { 
            padding-right: 0;
            text-indent: 0;
            content: none;
            display: none;
        }

        &:not(:last-child) { 
            margin-right: 1rem;
        } // not last child
    } // li    
} // list-inline