.collapse.in,
.collapse.show {
	display: block !important;
}

// Accordion Component ****************************
.accordion-section {
	@media screen and (max-width: $screen-md - 1px) {
		@include shadow-1;
		border-radius: 8px;
	}

	~ .accordion-section {
		margin-top: $spacing-medium;
	}

	&-inline {
		.accordion-heading-panel {
			padding-left: 0;
			padding-right: 0;
			border: none;

			> * {
				font-size: 1.4rem;
			}

			&::after {
				color: $blue1;
			}
		}

		.panel-body {
			padding: 0 0 $spacing-medium;
			border: none !important;
		}
	}
}

// Paragraph text
.accordion-heading-panel,
.accordion-content-panel {
	p {
		color: $grey1;
		font-size: 1.4rem;
	}
}

// Accordion Heading ****************************
.accordion-heading-panel {
	position: relative;
	display: block;
	background-color: $white;
	cursor: pointer;
	transition: all 0.3s;
	color: $text-dark;
	border: 1px solid $grey5;
	border-radius: 8px !important;
	padding-right: 30px; // extra padding so the arrow won't get covered

	&:after {
		position: absolute;
		top: 50%;
		right: 1rem;
		transform: translateY(-50%);
		@extend .far; // Font Awesome (regular)
		content: $fa-var-angle-up;
	}

	&:not(.collapsed) {
		border-bottom-left-radius: 0 !important;
		border-bottom-right-radius: 0 !important;
	}

	&.collapsed {
		&:after {
			content: $fa-var-angle-down;
		}
	}

	h2 {
		margin: 0;
		font-size: $font-size-h4;
		font-weight: $font-weight-medium;
	}

	p {
		margin-bottom: 0;
	}
}

// Accordion Content ****************************
.accordion-content-panel {
	.panel.panel-body {
		border-top: none;
		border: 1px solid $grey5;

		@media screen and (max-width: $screen-md - 1px) {
			box-shadow: none;
		}
	}

	&.collapse.in,
	&.collapse.show {
		display: block;
	}
}

// Legacy Stuff ****************************
// remove once we are 100% sure all accordions are using the new model.
.accordion-toggle {
	@extend .accordion-heading-panel;
}

//New Accordion Styles
.voices-accordion.panel{
	border-radius: 0;
	display: block;
	cursor: pointer;
	transition: all 0.3s;
	color: $text-dark;
	border-bottom: 1px solid $grey4;
    box-shadow: none;
	margin-bottom:0;
	.panel-heading{
		position: relative;
		border-radius: 0;
		padding:0;
		span{
			width:100%;
			display:block;
			position: relative;
		    line-height: 1;
		    font-size: 1.6rem;
		    font-weight:500;
    		padding: 1.6rem 0;
			&.collapsed {
				&:after {
					content: $fa-var-angle-down;
				}
			}
			&:after {
				position: absolute;
				top: 50%;
				right: 0;
				transform: translateY(-50%);
				@extend .far; // Font Awesome (regular)
				content: $fa-var-angle-up;
				font-size:2rem;
				color:$blue1;
			}
		}
	}
	.panel-body{
		padding:0 0 1.6rem 0;
	}
}