.tags-input {

	select {
		display: none;
	}

	&__form {
		display: flex;
	}

	&__input {
		@extend .form-control;
		flex: 1 1 auto;
	}

	&__add-button {
		flex: 1 0 auto;
		max-width: 10rem;
		margin-left: $spacing-small;
	}

	&__expand-button {
		display: inline-flex;
		align-items: center;
		margin-top: $spacing-small;
		padding: 0;
		font-weight: 500;
		font-size: 1.8rem;
		background: none;
		border: none;
		outline: none;
		color: $blue1;
		text-decoration: none;
		transition: color 0.15s linear;

		// Hide on larger screens completely
		@media (min-width: $screen-sm) {
			display: none;
		}

		i {
			font-size: 1.4rem;
			margin-right: .6rem;
		}
	}

	&__list {
		display: flex;
		flex-wrap: wrap;
		gap: $spacing-smaller;
		margin-top: $spacing-small;

		// Sortable Placeholder
		.move-item-placeholder {
			box-sizing: border-box;
			background-color: $blue5;
			list-style: none;
			margin: 2px 6px 2px 0;
		}
	}

	&__list-item {
		display: inline-flex;
		word-break: break-all;
		background-color: $blue1;
		align-items: center;
		border: none;
		color: $white;
		font-size: 1.4rem;
		font-weight: 500;
		border-radius: $border-radius-normal-size;
		margin: 2px $spacing-smallest 2px 0;
		padding: $spacing-smallest 5px $spacing-smallest 10px;
		cursor: grab;
		white-space: nowrap;
		&.collapsed {
			@media (max-width: $screen-sm - 1) {
				display: none !important;
			}
		}
	}

	.white__theme__list-item {
		background-color: $white;
		color: $text-dark; 
		border: 1px solid $grey4;
	}
	
	.white__invalid-delete-item{
		color: $text-dark;
		border-left: 1px solid black;
	}

	.invalid-tag-item {
		display: inline-flex;
		word-break: break-all;
		align-items: center;
		border: none;
		color: $text-dark;
		font-size: 1.4rem;
		font-weight: 500;
		border-radius: $border-radius-normal-size;
		margin: 2px 4px 2px 0;
		padding: 4px 5px 4px 10px;
		cursor: grab;
		white-space: nowrap;
		&.collapsed {
			@media (max-width: $screen-sm - 1) {
				display: none !important;
			}
		}
		border-radius: 4px;
		border: 1px solid $red1;
		background: $red5;
	}

	&__delete-button {
		display: inline-flex;
		align-items: center;
		padding-left: 10px;
		line-height: 1;
		height: 14px;
		appearance: none;
		background: none;
		outline: none;
		border: none;
		border-left: 1px solid $white;
		margin-left: 8px;
		cursor: pointer;
		color: $white;
		i {
			font-size: 1.6rem;
		}
	}

}