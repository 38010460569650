.cc-icon {
	width: 3.8rem;
	height: $spacing-large;
	overflow: hidden;
	background-size: 3.8rem $spacing-large;
	margin-right: $spacing-small;

	& img {
		vertical-align: top;
		border: 1px solid $grey4;
		border-radius: 0.4rem;
	}
}
