.audio.voices-audio-player,
.audio.voices-audio-player-playlist {
	height: auto;
	display: flex;
	justify-content: space-between;
	align-items: center;
	border-radius: 4px;
	user-select: none;
	-webkit-user-select: none;
	background-color: #fff;

	.slider {
		flex-grow: 1;
		background-color: $grey4;
		cursor: pointer;
		position: relative;

		.audio-progress {
			background-color: $blue1;
			border-radius: inherit;
			position: absolute;
			pointer-events: none;

			.pin {
				height: 20px;
				width: 20px;
				border-radius: 50%;
				background-color: $grey4;
				position: absolute;
				pointer-events: all;
			}
		}
	}

	&.loaded-audio .audio-progress .pin {
		background-color: $blue1;
	}

	.controls {
		font-family: 'Roboto', sans-serif;
		font-size: 16px;
		line-height: 18px;
		color: $text-dark;
		display: flex;
		flex-grow: 1;
		justify-content: space-between;
		align-items: center;
		margin-left: 8px;
		margin-right: 8px;

		.slider {
			margin-left: 16px;
			margin-right: 16px;
			border-radius: 4px;
			height: 6px;

			.audio-progress {
				width: 0;
				height: 100%;

				.pin {
					right: -10px;
					top: -7px;
				}
			}
		}

		span {
			cursor: default;
			font-size: 1.26rem;
		}
	}

	.volume {
		position: relative;

		.volume-btn {
			cursor: pointer;
			fill: $grey3;

			svg {
				display: block;
			}

			&.open path {
				fill: $blue1;
			}
		}

		.volume-controls {
			width: 30px;
			height: 135px;
			background-color: rgba($grey4, calc(80/100));
			border-radius: 7px;
			position: absolute;
			left: -3px;
			bottom: 52px;
			flex-direction: column;
			align-items: center;
			display: flex;
			z-index: 99;

			&.hidden {
				display: none;
			}

			.slider {
				margin-top: 12px;
				margin-bottom: 12px;
				width: 6px;
				border-radius: 3px;

				.audio-progress {
					bottom: 0;
					height: 100%;
					width: 6px;

					.pin {
						left: -5px;
						top: -8px;
					}
				}
			}
		}
	}

	//Colour customizations
	&.dark-voices-player {
		padding-left: 10px;
		padding-right: 10px;
		@media (min-width: 425px) {
			padding-left: 24px;
			padding-right: 24px;
		}
		background: $grey0;

		.play-pause-btn svg .play-pause-icon {
			fill: $white;
		}

		.slider {
			background-color: $grey4;

			.audio-progress {
				background-color: $blue2;

				.pin {
					background-color: $blue2;
				}
			}
		}

		.controls {
			color: $grey4;
		}

		.volume {
			.volume-controls {
				background-color: rgba($grey0, calc(80/100));
			}

			.volume-btn {
				fill: $grey4;

				&.open path {
					fill: $blue2;
				}
			}
		}
	}

	&.blue-voices-player {
		padding-left: 10px;
		padding-right: 10px;
		@media (min-width: 425px) {
			padding-left: 24px;
			padding-right: 24px;
		}
		background: $blue1;

		.play-pause-btn svg .play-pause-icon,
		.spinner {
			fill: $grey4;
		}

		.slider {
			background-color: $grey2;

			.audio-progress {
				background-color: $grey4;

				.pin {
					background-color: $grey4;
				}
			}
		}

		.controls {
			color: $grey4;
		}

		.volume {
			.volume-controls {
				background-color: rgba($grey0, calc(80/100));
			}

			.volume-btn {
				fill: $grey4;

				&.open path {
					fill: $blue2;
				}
			}
		}
	}
}

.audio.voices-audio-player,
.play-pause-btn-container {
	.play-pause-btn {
		cursor: pointer;
		background: none;
		outline: none;
		border: none;
		padding: 0;

		&:disabled {
			cursor: default;
			svg circle {
				fill: #94badd; // disabled blue
			}
		}

		svg {
			display: block;
			.play-pause-icon {
				fill: $white;
			}
		}
	}

	svg circle {
		fill: $blue1;
	}

	.loading {
		display: none;
		align-items: center;
		justify-content: center;
		width: 36px;
		height: 36px;
		background: $blue1;
		border-radius: 50%;
	}

	.spinner {
		width: auto;
		height: auto;
		color: $white;
	}
}

.play-pause-html-btn{
	svg {
		circle{
			fill: $blue1;
		}
		.play-pause-icon {
			fill: $white;
		}
	}
	.spinner {
		width: 18px;
		height: 18px;
		color: $white;
	}
}

// Inverted Play Pause Button
.play-pause-btn.inverted {
	outline-width: 4px !important;
	svg {
		circle {
			fill: none;
		}
		.play-pause-icon {
			fill: $blue1;
		}
	}
}

.voices-audio-player .play-pause-btn-container {
	.play-pause-btn,
	.loading {
		margin-right: 8px;
	}
}

button.default-play-video-btn {
	display: inline-flex;
	align-items: center;
	svg {
		width: 20px;
		height: 20px;
		path {
			fill: #fff;
		}
		circle {
			fill: transparent;
		}
	}
	span {
		font-size: 1.6rem;
		margin-left: 0.4rem;
		font-weight: 500;
	}
}

.play-video-btn {
	display: flex;
	align-items: start;
	padding: 0;
	margin: 0;
	background: none;
	border: none;
	border-radius: 100%;
	outline: 4px solid $blue1 !important; // override :focus styles
	outline-offset: -2px;
	width: 24px;
	height: 24px;
	cursor: pointer;
	transition: transform 0.3s ease;

	&-container {
		position: relative;
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	&:not(:disabled):hover {
		transform: scale(1.1);
	}

	@media (min-width: $screen-sm) {
		width: 31px;
		height: 31px;
	}

	svg {
		width: 100%;
		height: 100%;
		circle {
			fill: transparent;
		}
		path {
			fill: $blue1;
		}
	}

	// "Video" text
	+ span {
		position: absolute;
		top: 100%;
		margin-top: 0.4rem;
	}
}

.audio.voices-audio-player-playlist {
	display: flex;
	flex-direction: column;

	.playlist-btns {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		justify-content: space-between;

		> * {
			display: flex;
			align-items: center;
			margin-right: 1.6rem;
			&:last-child {
				margin-right: 0;
			}
		}

		.previous-btn,
		.next-btn {
			background: none;
			border: none;
			outline: none;
			transition: transform 0.3s ease;
			&:not(:disabled):hover {
				transform: scale(1.1);
			}
			&:disabled {
				cursor: default;
				svg path {
					fill: #94badd;
				}
			}
		}

		.play-video-btn {
			width: 36px;
			height: 36px;
		}
	}

	.playlist-controls {
		display: flex;
		justify-content: space-between;
		align-items: center;
		width: 100%;
		max-width: 420px;
	}

	.playlist-item-disabled-text {
		font-size: 1.2rem;
		color: $grey1;
		display: flex;
		justify-content: center;
		margin-top: -6px;
	}
}

// Scrubber
.audio .controls {
	/* Input range - chrome and safari */
	input[type="range"] {
		// Focused State
		&:focus,
		&::-moz-range-track:focus // Firefox
		{
			outline :none !important;
		}

		// Track
		--range-progress: 0;
		-webkit-appearance: none;
		position: relative;
		background: $grey4;
		width: 100%;
		height: 3px;
		padding: 3px;
		border-radius: 10px;
		border: none !important;
		cursor: pointer;
		box-shadow: none !important;

		// Played Progress
		&::before {
			content: '';
			height: 100%;
			background: $blue1;
			width: var(--range-progress);
			border-bottom-left-radius: 10px;
			border-top-left-radius: 10px;
			position: absolute;
			top: 0;
			left: 0;
		}

		// Thumb
		&::-webkit-slider-thumb {
			-webkit-appearance: none;
			position: absolute;
			height: 20px;
			width: 20px;
			top: 50%;
			left: var(--range-progress);
			transform: translate(-10px, -50%);
			border-radius: 50%;
			background-color: $blue1;
			pointer-events: all;
		}

		// Disabled Thumb
		&:disabled::-webkit-slider-thumb {
			background-color: #94badd;
		}

		// Disabled Thumb (when rendered for "Video" type files)
		&.video-scrubber:disabled::-webkit-slider-thumb {
			background-color: $grey4;
		}

		+ .current-time {
			margin-left: 1.6rem;
		}
	}

	/* Input range - firefox */
	input[type="range"] {

		// Track
		&::-moz-range-track {
			position: relative;
			background: $grey4;
			width: 100%;
			height: 100%;
			border-radius: 3px;
			padding: 3px;
			cursor: pointer;
		}

		// Played Progress
		&::-moz-range-progress {
			background: $blue1;
			border-bottom-left-radius: 10px;
			border-top-left-radius: 10px;
			height: 100%;
		}

		// Thumb
		&::-moz-range-thumb {
			position: absolute;
			left: var(--range-progress);
			height: 20px;
			width: 20px;
			border-radius: 50%;
			cursor: pointer;
			border: transparent;
			background-color: $blue1;
			pointer-events: all;
		}

		// Disabled Thumb
		&:disabled::-moz-range-thumb {
			background-color: #94badd;
		}

		// Disabled Thumb (when rendered for "Video" type files)
		&.video-scrubber:disabled::-moz-range-thumb {
			background-color: $grey4;
		}
	}
}

@keyframes spin {
	from {
		transform: rotateZ(0);
	}
	to {
		transform: rotateZ(1turn);
	}
}
