/* html5 datalist */

dl {
	margin:0; // bootstrap override
	font-size: 1.6rem;
	color: $grey1;
	dt {
		font-weight: $font-weight-medium;
	}

	// Horizontal List Items (aligned flush to the left-side)
	// TODO: Standardize dl classes .... there seems to already be a .dl-horizontal ...
	&.dl-horizontal-align-left {
		display: flex;
		flex-wrap: wrap;
		dt, dd {
			width: 50%;
		}
	}
}