.compliment-received {
	display: flex;

	& + & {
		margin-top: 2rem;
	}
}

.compliment-icon {
	width: 4.3rem;
	height: 4.3rem;
	background: $blue5;
	border-radius: 100%;
	padding: 0.6rem;
	margin-right: 1.6rem;

	.deco-icon {
		&:before {
			height: 3.2rem;
			width: 3.2rem;
		}
	}
}

.compliment-content {
	.compliment-heading {
		@extend .text-md;
	}

	.compliment-heading {
		font-weight: bold;
		margin-top: 0.2rem;
		margin-bottom: 0.2rem;
	}

	p {
		margin-bottom: 0;
	}
}
