/*********************************************************************
 * BORDERS
 *********************************************************************/

// Remove Border
.no-border {
	border: none !important
}

.no-border-bottom {
	border-bottom: none !important
}

.no-border-top {
	border-top: none !important
}

.no-border-left {
	border-left: none !important
}

.no-border-right {
	border-right: none !important
}

// Border Radius
.no-border-radius {
	border-radius: 0 !important;
}

.no-border-radius-right {
	border-top-right-radius: 0 !important;
	border-bottom-right-radius: 0 !important;
}

.no-border-radius-left {
	border-top-left-radius: 0 !important;
	border-bottom-left-radius: 0 !important;
}

.border-radius {
	border-radius: $border-radius-normal-size !important;
}

@mixin border-radius-2 {
	border-radius: $border-radius-large-size !important;
}

.border-radius-2 {
	@include border-radius-2;
}

.border-top-radius {
	border-top-left-radius: $border-radius-normal-size !important;
	border-top-right-radius: $border-radius-normal-size !important;
}

.border-top-radius-2 {
	border-top-left-radius: $border-radius-large-size;
	border-top-right-radius: $border-radius-large-size;
}

.border-bottom-radius {
	border-bottom-left-radius: $border-radius-normal-size !important;
	border-bottom-right-radius: $border-radius-normal-size !important;
}

.border-bottom-radius-2 {
	border-bottom-left-radius: $border-radius-large-size;
	border-bottom-right-radius: $border-radius-large-size;
}

// ------------------ Border Colours ------------------
@mixin border-grey4 {
	border: 1px solid $grey4;
}

.border-grey4 {
	@include border-grey4;
}

.border-top-grey4 {
	border-top: 1px solid $grey4;
}

.border-right-grey4 {
	border-right: 1px solid $grey4;
}

.border-bottom-grey4 {
	border-bottom: 1px solid $grey4;
}

.border-left-grey4 {
	border-left: 1px solid $grey4;
}

@mixin border-grey5 {
	border: 1px solid $grey5;
}

.border-grey5 {
	@include border-grey5;
}

.border-top-grey5 {
	border-top: 1px solid $grey5;
}

.border-right-grey5 {
	border-right: 1px solid $grey5;
}

.border-bottom-grey5 {
	border-bottom: 1px solid $grey5;
}

.border-left-grey5 {
	border-left: 1px solid $grey5;
}

.border-danger {
	border: 1px solid $red1;
}
