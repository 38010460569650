/* **********************************************   */
/* 19 - TABULAR DATA (TABLES)                       */
/* Supplanted by list panels. May be deprecated     */
/* Tabular data structured using UL tags.           */
/* **********************************************   */


//  *********************************************
//  **** Coyier method using UL elements. 
// UL = table block
// LI = table row
// DIV = table cell
// DATA-LABEL on DIV = Left column with label used on small screens.

// *********************************************

table, thead, tbody, tfoot, tr, td, th {
	border: none;
}

ul.table { 
    width: 100%; 
    border-collapse: collapse; 
    list-style-type: none;
    font-size: 1.5rem;
    padding: 0;
    display: table;    
    margin-top: 2rem;
    margin-bottom: 2rem;
    
    &.voices-default { 
        @extend .row-lines;
        @extend .row-stripes;
        @extend .cell-lines;
    } // voices-default
    
    @media screen and (max-width: 768px) {
		@include set-margins(top, 0);
    }
    
    //  *********************************************
    /* 'Table' Styles - Affects whole table */
    
    &.bordered { 
        border: 1px solid $grey3;
    } // bordered

    &.row-lines { 
        li { 
            border-bottom: 1px solid $grey3; 
        } // underlines on rows
    } 
    &.row-stripes { 
        li:nth-of-type(odd):not(:first-child):not(.breaking-row) { 
            background: $grey5;       
            border-collapse: collapse;
            padding-top: 5px;
        } // zebtra striping rows
	} 
	&.row-stripes-no-header { 
        li:nth-of-type(even):not(.breaking-row) { 
            background: $grey5;       
            border-collapse: collapse;
            padding-top: 5px;
        } // zebtra striping rows
    } 
    &.row-spaced { 
        > li { padding-top: .75rem; padding-bottom: .75rem; }
    } // bordered
    &.row-hover { 
        > li:hover { 
			color: $white;
            background: $blue2 !important;
        }
    } // row-hover
    &.cell-lines { 
        > li > div { 
            border-bottom: 1px solid $grey4;
        }
    } // cell-lines

    // Table Row *********************************************
 
    li { 
        border-bottom: none; 
        text-align: left; 
        display: table-row;

        &.breaking-row { // Interrupt the ul.table with a section header or info block at full width.
            display: block;
            padding-top: 1.5rem;
            border-bottom: none; 
            background: $white;

            h4 { display: block; }
        } // .breaking-row

        .text-right { 
            padding-right: 1rem;
        } // text-right

        .text-left { 
            padding-left: 1rem;
        } // text-right

        .text-center { 
            padding-left: 0.5rem;
            padding-right: 0.5rem;
        } // text-center
        
        // Table Cell  *********************************************
        div {  // Basic table cell.
            padding: .5rem .6rem; 
            display: table-cell;

 
            &.icon-list { 
                text-align: right;
                
                .fa { 
                    padding-left: 1rem;
                    vertical-align: bottom;
                    
                    &:hover { 
                        color: $success;
                    } // default icon hover rule.
                    
                    &.fa-trash { 
                        &:hover { 
                            color: $danger;
                        } // trash hover rule.
                    } // fa-trash
                } // fa
            } // icon-list in table row

            
            &.cell-status-label { 
                text-align: center;
                border-bottom: none;
                white-space: nowrap;

                > .label { 

                    @media screen and (max-width: 480px) { 
                        display: block;
                    } // mq

                } // label
            } // col-status-label

            .text-right { 
                padding-right: 1rem;
            } // text-right

            .text-left { 
                padding-left: 1rem;
            } // text-right

            .text-center { 
                padding-left: 0.5rem;
                padding-right: 0.5rem;
            } // text-center


        } // table.cc-table
            
        /*} // div*/
        
        // Add to li to create header row  *********************************************
        &.header-row { 
            background-color: transparent; 
            padding-top: 0;

            div { 
                background: $white;
                color: $grey0; 
                font-weight: bold; 
                border-bottom: 2px solid $blue2;
                display: table-cell;
            } // header li div
        } // header-row
    } // li
} // ul

    
/* 
Max width before this the table gets nasty
This query will take effect for any screen smaller than 760px
and also iPads specifically.
*/
@media screen and (max-width: 760px) { 

	/* Force table to not be like tables anymore */
	ul.table, ul.table li { 
		display: block; 
	}
	
    ul.table { 
        
        border-top: 1px solid $grey4; 
        border-bottom: 1px solid $grey4; 

        // <760 Header row *********************************************
        > li.header-row {  
	       /* Hide table headers (but not display: none;, for accessibility) */
            position: absolute;
            top: -9999px;
            left: -9999px;
        }

        // <760 Table Row  *********************************************
        > li { 
            border-left: 1px solid $grey4; 
            border-right: 1px solid $grey4; 
            border-bottom: none; 
            border-top: none;
            padding-bottom: 0;

            // <760 Table Row  *********************************************
            div { 
                /* Behave  like a "row" */
                border: none;
                border-bottom: none; 
                position: relative;
                padding-left: 40%; 
                display: block;

                &.cell-collapse { 
                    padding-left: 1rem;

                    &:before { 
                        display: none;
                    } // cell-collapse before
                } // cell-collapse

                &.icon-list { 
                    text-align: right;
                    
                    .fa { 
                        text-align: right;
                       // transform: scale(1.25); 
                        font-size: 1.8rem;
                        padding-right: 1.5rem;
                        padding-left: 1.5rem;
                    }
                } // icon-list in table row
    
            } // div
 
            // <760 Table column header *********************************************
            div:before { 
                /* Now like a table header */
                position: absolute;
                /* Top/left values mimic padding */
                top: 0.5rem;
                left: 1rem;
                width: 40%; 
                white-space: nowrap;
                color: $grey0;
                text-align: left;
                /* Pull column header text from the row. */
                content: attr(data-label);
            }
        } // li
    } // .table.cc-table
    
} // mq

/* 
 Rules for tables on ridiculously small screens. Looking at you Apple!
*/
@media only screen and (max-width: 320px) { 

    // <320 Table cells  *********************************************
    ul.table li div { 
        border-bottom: none;
        line-height: 1.618rem;

        &:before { 
            font-size: 1.3rem;
        }
        &.icon-list { 
            .fa { 
                font-size: 1.2rem;
            } // fa
        } // icon-list in table row
    } // div
} // mq for ridiculously small screens.