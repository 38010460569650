@import "@bootstrap/scss/tables";

.table {
	thead {
		th {
			strong {
				font-size: 1.8rem;
			}
			span {
				font-weight: $font-weight-normal;
				color: $grey2;
				font-size: 1.4rem;
			}
		}
	}

	th, td {
		font-size: 1.6rem;
	}
}