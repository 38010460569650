// Properties
$avatar-size: 100px;
$avatar-border-width: 1px;

.header-row-with-nav {
    @media (max-width: $screen-sm - 1px) {
        background-color: $white;
    }
}

.sidebar-nav {
    text-align: center;
    background-color: $white;

    @media (min-width: $screen-sm) {
        margin-top: $spacing-largest;
        background-color: transparent;
    }

    .layout-body {
        margin-bottom: 0 !important; // important because default .layout-body is set as !important already... -__-
        padding: 0;
        @media (max-width:($screen-sm - 1px)) {
            border: none;
            box-shadow: none;
        }
        &.remove-bottom-rounded-corners {
            border-bottom-left-radius: 0 !important;
            border-bottom-right-radius: 0 !important;
        }
    }

    .file-upload-container {
        padding-top: $spacing-small;
        padding-left: $spacing-small;
        padding-right: $spacing-small;
        @media (min-width:$screen-sm) {
            padding-top: $spacing-large;
            padding-left: $spacing-large;
            padding-right: $spacing-large;
        }
    }

    .member-info-container {
        padding-top: $spacing-large;
        padding-left: $spacing-large;
        padding-right: $spacing-large;
    }

    .h4, .h5 {
        margin-top: 0;
        margin-bottom: 0;
    }

    .file-upload-container {
        display: flex;
        justify-content: center;
    }

    .member-name,
    .member-username,
    .stars {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .stars .stars-text {
        font-size: 1.4rem;
    }

    .member-name {
        color: $text-dark;
        .flyout-popover {
            &.right {
                top: -15px !important;
            }
        }
    }

    .member-username {
        font-size:1.4rem;
    }

    .voices-badge {
        width: 1.2rem;
        height: 1.2rem;
        margin-left: $spacing-smallest;
    }

    // "View All (7)" Button
    button[data-bs-toggle="collapse"] {
        color: $blue1;
        font-size: 1.6rem;
        font-weight: 500;
        border: none;
        background: none;
        outline: none;
        padding: 0;
        &::before {
            font-family: 'Font Awesome 5 Pro';
            font-weight: 500; //.far
            content: $fa-var-angle-down;
            margin-right: .8rem;
            padding-left: .3rem;
        }
        &[aria-expanded="true"]::before {
            content: $fa-var-angle-up;
        }
    }

    button[data-bs-toggle="collapse"] {
        display: none;
        @media (min-width:$screen-sm) {
            display: block;
        }
    }

    &-member-section {
        .sidebar-nav-items-container {
            @media(min-width:$screen-sm){
                margin-top: $spacing-large;
                border-top: 1px solid $grey4;
            }
        }
    }
}

// Editable Profile Sections
.sidebar-nav-items-container {
    padding: $spacing-medium;

    @media (min-width: $screen-lg) {
        padding: $spacing-large;
    }

    .btn {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        &.btn-circular {
            margin-bottom: $spacing-smaller;
            padding: $spacing-smaller $spacing-medium;

            &:last-child {
                margin-bottom: 0;
            }

            @media (min-width: $screen-md) {
                padding: $spacing-smaller $spacing-small;
            }

            @media (min-width: $screen-lg) {
                padding: $spacing-smaller $spacing-medium;
            }

            i {
                margin-right: 0;
            }

            &:focus {
                background-color: $grey4;
            }
        }
    }
}


// Avatar Uploader Button
.file-upload-button {
    position:relative;
    width: $avatar-size;
    height: $avatar-size;
    padding: 0;
    border: none;
    cursor: pointer;
    &:hover {
        .circle-avatar-upload {
            transform: scale(0.96);
        }
        .circle-avatar-border {
            background-color: $primary;
        }
    }

    .circle-avatar-plus {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        z-index: 3;
        right: 5px;
        bottom: 5px;
        margin: 0;
        cursor: pointer;
        width: 2rem;
        height: 2rem;
        background-color: $white;
        border-radius: 100%;
        i  {
            font-size: 2rem;
            border: 1px solid $white;
            border-radius: 100%;
        }
    }
}