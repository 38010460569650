.vcenter {
	display: inline-block;
	vertical-align: middle;
	float: none;
	text-align: left;
}

// TODO : Rename this class (so it makes sense as to what style is being applied)
.center-xs-down {
	@media (max-width: 767px) {
		margin: 0 auto;
	}
}

// Text alignment classes by screen size ***********************
@media screen and (max-width: 479px) {
	.text-xxs-right {
		text-align: right;
	}
	.text-xxs-left {
		text-align: left;
	}
	.text-xxs-center {
		text-align: center;
	}
}

@media screen and (min-width: 480px) and (max-width: 767px) {
	.text-xs-right {
		text-align: right;
	}
	.text-xs-left {
		text-align: left;
	}
	.text-xs-center {
		text-align: center;
	}
}

@media screen and (min-width: 768px) and (max-width: 991px) {
	.text-sm-right {
		text-align: right;
	}
	.text-sm-left {
		text-align: left;
	}
	.text-sm-center {
		text-align: center;
	}
}

@media screen and (min-width: 992px) and (max-width: 1199px) {
	.text-md-right {
		text-align: right;
	}
	.text-md-left {
		text-align: left;
	}
	.text-md-center {
		text-align: center;
	}
}

@media screen and (min-width: 1200px) {
	.text-lg-right {
		text-align: right;
	}
	.text-lg-left {
		text-align: left;
	}
	.text-lg-center {
		text-align: center;
	}
}