.voices-icon {
    display: inline-block;
    width: 1em;
    height: 1em;
    fill: currentColor;
    vertical-align: -.125em;

    &.beat {
        animation-name: voices-icon-beat;
        animation-delay: 0s;
        animation-direction: normal;
        animation-duration: 1s;
        animation-iteration-count: infinite;
        animation-timing-function: ease-in-out;
    }

    &.bounce {
        animation-name: voices-icon-bounce;
        animation-delay: 0s;
        animation-direction: normal;
        animation-duration: 1s;
        animation-iteration-count: infinite;
        animation-timing-function: cubic-bezier(.28,.84,.42,1);
    }

    &.fade {
        animation-name: voices-icon-fade;
        animation-iteration-count: infinite;
        animation-timing-function: cubic-bezier(.4,0,.6,1);
        animation-delay: 0s;
        animation-direction: normal;
        animation-duration: 1s;
    }

    &.beat-fade {
        animation-name: voices-icon-beat-fade;
        animation-iteration-count: infinite;
        animation-timing-function: cubic-bezier(.4,0,.6,1);
        animation-delay: 0s;
        animation-direction: normal;
        animation-duration: 1s;
    }

    &.flip {
        animation-name: voices-icon-flip;
        animation-delay: 0s;
        animation-direction: normal;
        animation-duration: 1s;
        animation-iteration-count: infinite;
        animation-timing-function: ease-in-out
    }

    &.shake {
        animation-name: voices-icon-shake;
        animation-duration: 1s;
        animation-iteration-count: infinite;
        animation-timing-function: linear;
        animation-delay: 0s;
        animation-direction: normal;
    }

    &.spin {
        animation-name: voices-icon-spin;
        animation-duration: 2s;
        animation-iteration-count: infinite;
        animation-timing-function: linear;
        animation-delay: 0s;
        animation-direction: normal;
    }
}

.stack-2xs {
    font-size: .625em;
}

.stack-xs {
    font-size: .75em;
}

.stack-sm {
    font-size: .875em;
}

.stack-lg {
    font-size: 1.25em;
}

.stack-xl {
    font-size: 1.5em;
}

.stack-2xl {
    font-size: 2em;
}

.icon-stack {
    display: inline-grid;
    place-items: center;
    vertical-align: middle;
}

.icon-stack svg {
  grid-row: 1;
  grid-column: 1;
}

.icon-0\.5x {
    font-size: 0.5em;
}

.icon-1x {
    font-size: 1em;
}

.icon-1\.75x {
    font-size: 1.75em;
}

.icon-1\.3x{
    font-size: 1.3em;
}

.icon-2x {
    font-size: 2em;
}

.category-icon-blue-bg {
    color: $blue5;
}
.category-icon-blue {
    color: $blue1;
}

@keyframes voices-icon-beat {
    0%,90% {
        transform: scale(1)
    }

    45% {
        transform: scale(1.25)
    }
}

@keyframes voices-icon-bounce {
    0% {
        transform: scale(1) translateY(0)
    }

    10% {
        transform: scale(1.1, .9) translateY(0)
    }

    30% {
        transform: scale(.9, 1.1) translateY(-.5em)
    }

    50% {
        transform: scale(1.05, .95) translateY(0)
    }

    57% {
        transform: scale(1) translateY(-.125em)
    }

    64% {
        transform: scale(1) translateY(0)
    }

    to {
        transform: scale(1) translateY(0)
    }
}

@keyframes voices-icon-fade {
    50% {
        opacity: .4
    }
}

@keyframes voices-icon-beat-fade {
    0%,to {
        opacity: .4;
        transform: scale(1)
    }

    50% {
        opacity: 1;
        transform: scale(1.125)
    }
}

@keyframes voices-icon-flip {
    50% {
        transform: rotate3d(0, 1, 0, -180deg)
    }
}

@keyframes voices-icon-shake {
    0% { transform: rotate(-15deg) }
    4% { transform: rotate(15deg) }
    8%,24% { transform: rotate(-18deg) }
    12%,28% { transform: rotate(18deg) }
    16% { transform: rotate(-22deg) }
    20% { transform: rotate(22deg) }
    32% { transform: rotate(-12deg) }
    36% { transform: rotate(12deg) }
    40%,to { transform: rotate(0deg) }
}

@keyframes voices-icon-spin {
    0% { transform: rotate(0deg) }
    to { transform: rotate(1turn) }
}

@media (prefers-reduced-motion:reduce) {
    .voices-icon.beat,
    .voices-icon.beat-fade,
    .voices-icon.bounce,
    .voices-icon.fade,
    .voices-icon.flip,
    .voices-icon.pulse,
    .voices-icon.shake,
    .voices-icon.spin {
        animation-delay: -1ms;
        animation-duration: 1ms;
        animation-iteration-count: 1;
        transition-delay: 0s;
        transition-duration: 0s
    }
}