/* For JS Sortable Plugin. Used to re-order list items with cursor grab. */
.move-item {
	display: flex;
	min-height: 1px;
	height: 100%;
	color: #88b8dd;
	font-size: 1.5rem;
	justify-content: center;
	align-items: center;
	text-align: center;
	cursor: move;
	&.left {
		position: relative;
		right: auto;
		left: 0;
	}
}