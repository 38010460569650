@import '@styles/variables/global-variables';

@import '../../../voicesdotcom-styles/components/shared/public-project-list-item';

.project-public-list-item {
	.project-public-item-talent-name {
		a {
			color: $text-dark;

			&:hover,
			&:focus {
				color: $text-dark;
			}
		}
	}
}

// ========================== Pagination Text ========================== \\
.search-results-pagination-text {
	color: $grey2;
	font-size: 1.4rem;
	font-weight: 400;
	margin-top: 1rem;
	margin-bottom: 0.4rem;
	line-height: 1.5;
}

#pagination-navigation .pagination {
	margin-top: 0; // since .search-results has margin-bottom
}

// ========================== Search Sidebar ========================== \\
.search-menu-heading {
	font-size: 1.6rem;
	font-weight: 500;
	display: block;
	margin-bottom: 0.4rem;
}

#search-filters-container {
	.action-btns {
		margin-top: $spacing-medium;

		.btn {
			display: block;
			width: 100%;

			&[title="Apply"] {
				@media (min-width: $screen-md) {
					display: none;
				}
			}

			~ .btn {
				margin-top: $spacing-smaller;
			}
		}
	}

	.toggle-input label .toggle-input-on-text, .toggle-input label .toggle-input-off-text {
		top: 0;
		left: 4.5rem;
		font-size: 1.6rem;
		width: 175px;
	}
}

#search-type-accordion-container {
	.search-filter-radio-input {
		position: relative;

		.hotspot {
			top: 50%;
			right: 4rem;
			transform: translateY(-50%);
		}
	}
}

.search-filter-radio-input {
	&.service-category-radio-input {
		&:not(:first-child) {
			input[type="radio"] + label {
				margin-left: 3.2rem;
			}
		}
	}

	margin-bottom: 5px;

	input[type="radio"]:checked + label {
		color: $blue1;
		font-weight: bold !important;

		&::before {
			content: ' ';
			position: absolute;
			top: 0;
			left: -1.6rem;
			width: 0.4rem;
			height: 100%;
			border-radius: 4px;
			background-color: $blue1;
		}
	}

	input[type="radio"] + label {
		position: relative;
		display: block;
		cursor: pointer;
		margin-left: 1.6rem;
		padding-left: 0;
		font-weight: 400 !important;
		margin-bottom: 0;
	}
}

form[name="advanced_search_filters"] {
	label:not(.sr-only) {
		font-size: 1.6rem;
		font-weight: 500;
	}
}

.search-filter-disclaimer-text {
	color: $grey1;
	font-size: 1.1rem;
}

.project-budget {
	display: flex;
	align-items: center;

	div {
		width: 50%;

		input {
			max-width: 100%;
		}

		& + div {
			margin-left: $spacing-smaller;
		}
	}
}

// ========================== Search Page Heading / Keyword Input ========================== \\
#search-keyword-header {
	#keyword-heading {
		display: inline-block;
		@media (min-width: $screen-md) {
			margin: 0;
		}
	}

	&[data-marketing="true"] {
		margin-top: 2rem;
	}

	@media (min-width: $screen-md) {
		display: inline-block;
		margin-top: 2rem;
		margin-bottom: 5.6rem;
	}
}

#search-keyword-header-input-container {
	position: relative;
	display: block;
	@media (min-width: $screen-sm) {
		display: inline-block;
		padding-left: .5rem;
	}

	input {
		border: 0; //Focus has a 1px border so without this it jumps down/side to side
		padding: 0;
		border-radius: 0;
		border-bottom: 2px solid $grey4;
		color: $grey2;
		padding: 0 90px 0 0;
		width: 100%;
		overflow: hidden;
		text-overflow: ellipsis;

		&:focus {
			box-shadow: none !important;
			border-width: 0 0 2px 0 !important;
			border-radius: 0 !important;
			border-color: $blue1 !important;
		}
	}

	.edit-search-keyword {
		color: $blue1;
		position: absolute;
		right: 0;
		bottom: 10px;
		font-size: 1.4rem;
		cursor: pointer;
		background: none;
		border: none;
		padding: 0;
	}

	button[name="submit_search_header"] {
		display: none;
		position: absolute;
		right: 0;
		top: 4px;
	}
}

// ========================== Advanced Search Filters ========================== \\
form[name="advanced_search_filters"] {
	.form-group {
		&:last-child {
			margin-bottom: 3.2rem;
		}
	}
}

// ========================== Mobile - Advanced Search Filters (button / modal) ========================== \\
#mobile-search-filters-btn-container {
	margin-bottom: 2.6rem;
	// tablet+
	@media (min-width: $screen-md) {
		display: none;
	}
}

#mobile-search-filters-btn-container {
	span#search-filters-applied-text {
		color: $grey1;
		font-size: 1.2rem;
	}
}

// Open button
#mobile-search-filters-open-btn {
	i.fa {
		padding-left: .65rem;
		padding-right: 0;
	}
}

#search-filters-container {
	// devices smaller than tablets
	@media (max-width: ($screen-md - 1px)) {
		position: fixed;
		z-index: ($header-zindex + 1);
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		overflow: scroll;
		background-color: $white;
		transform: translateX(100%);
		-webkit-transform: translateX(100%);
		-moz-transform: translateX(100%);
		-ms-transform: translateX(100%);
		-o-transform: translateX(100%);
		transition: transform 0.3s ease;
		padding-left: 1.6rem;
		padding-right: 1.6rem;
		padding-bottom: 3.2rem;

		&.opened {
			transform: translateX(0);
			-webkit-transform: translateX(0);
			-moz-transform: translateX(0);
			-ms-transform: translateX(0);
			-o-transform: translateX(0);
		}

		// remove accordion handler
		.panel-heading[href="#advancedsearch"] {
			display: none;
		}

		// copy styles that would be given to this element in the .collapse.in state
		#advancedsearch {
			display: block;
			visibility: visible;
			height: auto;
		}
	}

	#collapseGender {
		.radio-input {
			label {
				width: 105px;
			}
		}
	}

	#collapseAge,
	#collapseRating {
		.radio-input-group {
			flex-direction: column;
		}

		.radio-input {
			label {
				width: 180px;
			}
		}
	}

	#collapseRating .radio-input {
		label {
			.fa-star {
				color: $yellow1;
			}
		}

		input:checked + label {
			.fa-star {
				color: $white;
			}
		}
	}
}

#search-filters-heading {
	h2 {
		margin-bottom: 3.2rem;
		@media (min-width: $screen-md) {
			@include responsive-heading-styles(4);
			color: $grey1;
		}
	}
}

// Close button
// Re-purpose the Mobile Menu Hamburger
#mobile-search-filters-close-btn {
	@include hamburger-button-styles($hamburger-state: 'active');
	transition: transform 0.3s ease;

	@media (min-width: $screen-md) {
		display: none;
	}

	&:hover {
		transform: scale(1.15);
	}

	[class^="line-"] {
		@include hamburger-button-line-styles($hamburger-line-color: #000);
	}

	.line-1 {
		@include hamburger-line-1;
	}

	.line-2 {
		@include hamburger-line-3;
	}
}

// Search filters label subheading
#search-filters-container small.form-text-small {
	color: $grey1;
	font-size: 1.4rem;
}

// ========================== Search Results ========================== \\

.search-results {
	margin-bottom: 3.2rem;
	transition: opacity 0.3s ease;

	&-loading {
		opacity: 0.4;
		pointer-events: none;
	}
}

.invite-to-job-btn-placeholder {
	width: 122px;
	visibility: hidden;
}

// ========================== Limited Search Results ========================== \\

// Not logged in, and viewing the organic search page template
.hidden-portfolio-list-item {
	position: relative;
}

.hidden-portfolio-list-item-content {
	opacity: 0.05;
}

.hidden-portfolio-list-item-cta {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 2;
	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center;
	flex-direction: column;

	.btn-primary {
		margin-right: 0;
	}
}

// ========================== Marketing Search Results(/role, /style) ========================== \\
#voice-actors-header {
	background: $blue5;
	padding-bottom: 3.2rem;
	@media (min-width: $screen-md) {
		padding-bottom: 6.4rem;
	}
}

#tag-breadcrumb {
	ul {
		padding: 0;
		margin: 3.2rem 0;
		font-size: 1.2rem;
		line-height: 1;

		li {
			list-style-type: none;
			display: inline-block;
			text-transform: uppercase;
			font-weight: 500;

			a {
				color: black;
				text-decoration: none;

				&:hover {
					color: $blue0;
				}
			}

			i {
				margin: 0 5px;
			}
		}
	}
}

#voice-actors-title {
	margin: 0;
	font-size: 4.8rem;

	#voice-actors-subtitle {
		font-size: 2.6rem;
		color: $grey1;
		margin-top: 1.6rem;
	}
}

#voice-actors-description {
	margin-bottom: 0;
	font-size: 2rem;
	font-weight: 300;
	margin-top: 3.2rem;
}

#voice-actors-resources {
	border-top: 2px solid #e4e4e4;
	@media (max-width: $screen-sm - 1px) {
		margin-bottom: 3.2rem;
	}

	.va-resource-title {
		margin-top: 3.2rem;
		margin-bottom: 1.6rem;
		@media (min-width: $screen-sm) {
			margin-top: 6.4rem;
		}
	}

	.va-resource-desc {
		font-size: 1.8rem;
		font-weight: 300;
		line-height: 1.56;
		margin-bottom: 1.6rem;
		@media (min-width: $screen-sm) {
			margin-bottom: 3.2rem;
		}
		@media (min-width: $screen-sm) and (max-width: $screen-md - 1px) {
			min-height: 200px;
		}
	}

	.va-resource-link {
		display: inline-block;
		@media (min-width: $screen-sm) {
			margin-bottom: 6.4rem;
		}

		i {
			font-size: 1.4rem;
			transition: margin-left 0.2s ease-in-out;
		}

		&:hover {
			i {
				margin-left: 0.6rem;
			}
		}
	}
}

#voice-actors-cta {
	background: $blue0;
	padding: 6.4rem 1.5rem;

	#va-cta-heading {
		@extend .text-white;
		margin: 0 0 1.6rem;
	}

	a {
		@extend .text-white;
		border-color: $white;
		font-size: 18px;
		padding: 12px 24px;
	}
}

.profile-name-tier {
	margin-left: 0.2rem;
	display: inline-block;
	font-size: 1.2rem;
	color: $grey1;

	i {
		margin-right: 0.2rem;
	}

	.bull-text-dot {
		margin-left: 0.4rem;
		margin-right: 0rem;

		&::before {
			font-size: 1.2rem;
			color: $grey1;
			display: inline-block;
			content: "\2022";
		}
	}

}


// ========================== Zero Search Results ========================== \\
#empty-state-search-results {
	.empty-state-content {
		max-width: 100%;
	}

	h2 {
		text-align: center;

		span {
			display: block;
			font-size: 2rem;
			margin-top: 1.6rem;
		}
	}

	p {
		text-align: center;
		margin-top: 3.2rem;
	}

	ul {
		text-align: left;
		padding-left: 20px;
		width: 100%;
		max-width: 445px;
		margin: 2rem auto 0;

		li + li {
			margin-top: .8rem;
		}

		a {
			font-weight: bold;
		}
	}
}

button.sortOption.active i {
	position: absolute;
	right: 10px;
	color: $blue1;
	padding-top: 5px;
}

.action-bar-results-refinement {
	.action-list .action-list-dropdown {
		li button:not(.link).active {
			background: $white;
		}

		@media (max-width: ($screen-md - 1px)) {
			width: 100%;
		}
	}
}

#mobile-search-filters-open-btn {
	@media (max-width: ($screen-md - 1px)) {
		display: block !important;
		border: 1px solid $grey3 !important;
		width: 100%;
		background-color: $white !important;
	}
}

.mobile-sorting-action-list {
	.action-bar-results-refinement {
		.action-list {
			.action-list-btn {
				@media (max-width: ($screen-md - 1px)) {
					display: block !important;
					border: 1px solid $grey3 !important;
					width: 100%;
					background-color: $white !important;
				}
			}

			.action-list-right {
				.btn-default {
					background: transparent;
				}
			}
		}
	}
}

.action-bar-row {
	display: flex;
	align-items: center;
	margin-bottom: 1rem;

	h2 {
		margin-bottom: 0;
	}
}

.sortOption.active {
	&::after {
		content: "";
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		right: 1rem;
		height: 1.6rem;
		width: 1.6rem;
		background-image: url("data:image/svg+xml,%3Csvg fill='%231171bb' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'%3E%3C!-- Font Awesome Pro 5.15.4 by $fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) --%3E%3Cpath d='M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z'/%3E%3C/svg%3E");
		background-position: center center;
		background-repeat: no-repeat;
	}
}

#search-pending {
	background: rgba(200, 200, 200, 0.7);
	position: absolute;
	height: 100%;
	width: 100%;
	top: 0;
	left: 0;
	z-index: 999;
	display: none;
	@media (max-width: ($screen-md - 1px)) {
		z-index: -1;
		visibility: hidden;
	}
	@media (min-width: $screen-md) {
		height: 104%;
		top: -2%;
		left: -2%;
		width: 104%;
	}

	#results-loader {
		position: absolute;
		z-index: 99999;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		background-color: $white;
		padding: 1rem;
		font-size: 1.6rem;
		border-radius: $border-radius-normal-size;
	}
}

.filterSelected {
	background-color: $blue5 !important;
	border: 1px solid $blue1 !important;

	&.btn.btn-default:focus {
		box-shadow: 0 0 0 0 #0098d5 !important;
	}
}
